export const metroMap = [
  {
    "CBSA Code": 10100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Aberdeen, SD",
    "CBSA Solo": "Aberdeen",
    "CBSA State": "SD",
    CBSA: "Aberdeen, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Brown County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Brown County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "013",
    "County\nCode": 46013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Aberdeen, SD",
    "CBSA Solo": "Aberdeen",
    "CBSA State": "SD",
    CBSA: "Aberdeen, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Edmunds County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Edmunds County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "045",
    "County\nCode": 46045,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 10140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Aberdeen, WA",
    "CBSA Solo": "Aberdeen",
    "CBSA State": "WA",
    CBSA: "Aberdeen, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Grays Harbor County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Grays Harbor County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "027",
    "County\nCode": 53027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Abilene, TX",
    "CBSA Solo": "Abilene",
    "CBSA State": "TX",
    CBSA: "Abilene, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Callahan County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Callahan County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "059",
    "County\nCode": 48059,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 10180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Abilene, TX",
    "CBSA Solo": "Abilene",
    "CBSA State": "TX",
    CBSA: "Abilene, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jones County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Jones County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 253,
    "County\nCode": 48253,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 10180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Abilene, TX",
    "CBSA Solo": "Abilene",
    "CBSA State": "TX",
    CBSA: "Abilene, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Taylor County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Taylor County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 441,
    "County\nCode": 48441,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Ada, OK",
    "CBSA Solo": "Ada",
    "CBSA State": "OK",
    CBSA: "Ada, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pontotoc County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Pontotoc County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 123,
    "County\nCode": 40123,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10300,
    "Metropolitan Division Code": "",
    "CSA Code": 220,
    "CBSA Full Title": "Adrian, MI",
    "CBSA Solo": "Adrian",
    "CBSA State": "MI",
    CBSA: "Adrian, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Detroit-Warren-Ann Arbor, MI",
    "County/County Equivalent": "Lenawee County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Lenawee County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "091",
    "County\nCode": 26091,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Aguadilla-Isabela, PR",
    "CBSA Solo": "Aguadilla",
    "CBSA State": "PR",
    CBSA: "Aguadilla, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Aguada Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Aguada Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "003",
    "County\nCode": 72003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Aguadilla-Isabela, PR",
    "CBSA Solo": "Aguadilla",
    "CBSA State": "PR",
    CBSA: "Aguadilla, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Aguadilla Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Aguadilla Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "005",
    "County\nCode": 72005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Aguadilla-Isabela, PR",
    "CBSA Solo": "Aguadilla",
    "CBSA State": "PR",
    CBSA: "Aguadilla, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Añasco Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Añasco Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "011",
    "County\nCode": 72011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Aguadilla-Isabela, PR",
    "CBSA Solo": "Aguadilla",
    "CBSA State": "PR",
    CBSA: "Aguadilla, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Isabela Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Isabela Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "071",
    "County\nCode": 72071,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Aguadilla-Isabela, PR",
    "CBSA Solo": "Aguadilla",
    "CBSA State": "PR",
    CBSA: "Aguadilla, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lares Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Lares Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "081",
    "County\nCode": 72081,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Aguadilla-Isabela, PR",
    "CBSA Solo": "Aguadilla",
    "CBSA State": "PR",
    CBSA: "Aguadilla, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Moca Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Moca Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "099",
    "County\nCode": 72099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Aguadilla-Isabela, PR",
    "CBSA Solo": "Aguadilla",
    "CBSA State": "PR",
    CBSA: "Aguadilla, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Rincón Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Rincón Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 117,
    "County\nCode": 72117,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Aguadilla-Isabela, PR",
    "CBSA Solo": "Aguadilla",
    "CBSA State": "PR",
    CBSA: "Aguadilla, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "San Sebastián Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "San Sebastián Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 131,
    "County\nCode": 72131,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Aguadilla-Isabela, PR",
    "CBSA Solo": "Aguadilla",
    "CBSA State": "PR",
    CBSA: "Aguadilla, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Utuado Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Utuado Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 141,
    "County\nCode": 72141,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10420,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "Akron, OH",
    "CBSA Solo": "Akron",
    "CBSA State": "OH",
    CBSA: "Akron, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Portage County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Portage County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 133,
    "County\nCode": 39133,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10420,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "Akron, OH",
    "CBSA Solo": "Akron",
    "CBSA State": "OH",
    CBSA: "Akron, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Summit County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Summit County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 153,
    "County\nCode": 39153,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Alamogordo, NM",
    "CBSA Solo": "Alamogordo",
    "CBSA State": "NM",
    CBSA: "Alamogordo, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Otero County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Otero County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "035",
    "County\nCode": 35035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Albany, GA",
    "CBSA Solo": "Albany",
    "CBSA State": "GA",
    CBSA: "Albany, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Dougherty County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Dougherty County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "095",
    "County\nCode": 13095,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Albany, GA",
    "CBSA Solo": "Albany",
    "CBSA State": "GA",
    CBSA: "Albany, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lee County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Lee County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 177,
    "County\nCode": 13177,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Albany, GA",
    "CBSA Solo": "Albany",
    "CBSA State": "GA",
    CBSA: "Albany, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Terrell County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Terrell County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 273,
    "County\nCode": 13273,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 10500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Albany, GA",
    "CBSA Solo": "Albany",
    "CBSA State": "GA",
    CBSA: "Albany, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Worth County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Worth County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 321,
    "County\nCode": 13321,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 10540,
    "Metropolitan Division Code": "",
    "CSA Code": 440,
    "CBSA Full Title": "Albany-Lebanon, OR",
    "CBSA Solo": "Albany",
    "CBSA State": "OR",
    CBSA: "Albany, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Vancouver-Salem, OR-WA",
    "County/County Equivalent": "Linn County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Linn County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "043",
    "County\nCode": 41043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10580,
    "Metropolitan Division Code": "",
    "CSA Code": 104,
    "CBSA Full Title": "Albany-Schenectady-Troy, NY",
    "CBSA Solo": "Albany",
    "CBSA State": "NY",
    CBSA: "Albany, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albany-Schenectady, NY",
    "County/County Equivalent": "Albany County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Albany County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "001",
    "County\nCode": 36001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10580,
    "Metropolitan Division Code": "",
    "CSA Code": 104,
    "CBSA Full Title": "Albany-Schenectady-Troy, NY",
    "CBSA Solo": "Albany",
    "CBSA State": "NY",
    CBSA: "Albany, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albany-Schenectady, NY",
    "County/County Equivalent": "Rensselaer County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Rensselaer County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "083",
    "County\nCode": 36083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10580,
    "Metropolitan Division Code": "",
    "CSA Code": 104,
    "CBSA Full Title": "Albany-Schenectady-Troy, NY",
    "CBSA Solo": "Albany",
    "CBSA State": "NY",
    CBSA: "Albany, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albany-Schenectady, NY",
    "County/County Equivalent": "Saratoga County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Saratoga County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "091",
    "County\nCode": 36091,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10580,
    "Metropolitan Division Code": "",
    "CSA Code": 104,
    "CBSA Full Title": "Albany-Schenectady-Troy, NY",
    "CBSA Solo": "Albany",
    "CBSA State": "NY",
    CBSA: "Albany, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albany-Schenectady, NY",
    "County/County Equivalent": "Schenectady County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Schenectady County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "093",
    "County\nCode": 36093,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10580,
    "Metropolitan Division Code": "",
    "CSA Code": 104,
    "CBSA Full Title": "Albany-Schenectady-Troy, NY",
    "CBSA Solo": "Albany",
    "CBSA State": "NY",
    CBSA: "Albany, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albany-Schenectady, NY",
    "County/County Equivalent": "Schoharie County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Schoharie County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "095",
    "County\nCode": 36095,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 10620,
    "Metropolitan Division Code": "",
    "CSA Code": 172,
    "CBSA Full Title": "Albemarle, NC",
    "CBSA Solo": "Albemarle",
    "CBSA State": "NC",
    CBSA: "Albemarle, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charlotte-Concord, NC-SC",
    "County/County Equivalent": "Stanly County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Stanly County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 167,
    "County\nCode": 37167,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Albert Lea, MN",
    "CBSA Solo": "Albert Lea",
    "CBSA State": "MN",
    CBSA: "Albert Lea, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Freeborn County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Freeborn County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "047",
    "County\nCode": 27047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Albertville, AL",
    "CBSA Solo": "Albertville",
    "CBSA State": "AL",
    CBSA: "Albertville, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Marshall County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Marshall County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "095",
    "County\nCode": "01095",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10740,
    "Metropolitan Division Code": "",
    "CSA Code": 106,
    "CBSA Full Title": "Albuquerque, NM",
    "CBSA Solo": "Albuquerque",
    "CBSA State": "NM",
    CBSA: "Albuquerque, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albuquerque-Santa Fe-Las Vegas, NM",
    "County/County Equivalent": "Bernalillo County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Bernalillo County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "001",
    "County\nCode": 35001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10740,
    "Metropolitan Division Code": "",
    "CSA Code": 106,
    "CBSA Full Title": "Albuquerque, NM",
    "CBSA Solo": "Albuquerque",
    "CBSA State": "NM",
    CBSA: "Albuquerque, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albuquerque-Santa Fe-Las Vegas, NM",
    "County/County Equivalent": "Sandoval County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Sandoval County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "043",
    "County\nCode": 35043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10740,
    "Metropolitan Division Code": "",
    "CSA Code": 106,
    "CBSA Full Title": "Albuquerque, NM",
    "CBSA Solo": "Albuquerque",
    "CBSA State": "NM",
    CBSA: "Albuquerque, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albuquerque-Santa Fe-Las Vegas, NM",
    "County/County Equivalent": "Torrance County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Torrance County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "057",
    "County\nCode": 35057,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 10740,
    "Metropolitan Division Code": "",
    "CSA Code": 106,
    "CBSA Full Title": "Albuquerque, NM",
    "CBSA Solo": "Albuquerque",
    "CBSA State": "NM",
    CBSA: "Albuquerque, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albuquerque-Santa Fe-Las Vegas, NM",
    "County/County Equivalent": "Valencia County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Valencia County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "061",
    "County\nCode": 35061,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 10760,
    "Metropolitan Division Code": "",
    "CSA Code": 388,
    "CBSA Full Title": "Alexander City, AL",
    "CBSA Solo": "Alexander City",
    "CBSA State": "AL",
    CBSA: "Alexander City, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Montgomery-Selma-Alexander City, AL",
    "County/County Equivalent": "Coosa County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Coosa County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "037",
    "County\nCode": "01037",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 10760,
    "Metropolitan Division Code": "",
    "CSA Code": 388,
    "CBSA Full Title": "Alexander City, AL",
    "CBSA Solo": "Alexander City",
    "CBSA State": "AL",
    CBSA: "Alexander City, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Montgomery-Selma-Alexander City, AL",
    "County/County Equivalent": "Tallapoosa County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Tallapoosa County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": 123,
    "County\nCode": "01123",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Alexandria, LA",
    "CBSA Solo": "Alexandria",
    "CBSA State": "LA",
    CBSA: "Alexandria, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Grant Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Grant Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "043",
    "County\nCode": 22043,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 10780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Alexandria, LA",
    "CBSA Solo": "Alexandria",
    "CBSA State": "LA",
    CBSA: "Alexandria, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Rapides Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Rapides Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "079",
    "County\nCode": 22079,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Alexandria, MN",
    "CBSA Solo": "Alexandria",
    "CBSA State": "MN",
    CBSA: "Alexandria, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Douglas County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Douglas County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "041",
    "County\nCode": 27041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10860,
    "Metropolitan Division Code": "",
    "CSA Code": 204,
    "CBSA Full Title": "Alice, TX",
    "CBSA Solo": "Alice",
    "CBSA State": "TX",
    CBSA: "Alice, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Corpus Christi-Kingsville-Alice, TX",
    "County/County Equivalent": "Duval County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Duval County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 131,
    "County\nCode": 48131,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 10860,
    "Metropolitan Division Code": "",
    "CSA Code": 204,
    "CBSA Full Title": "Alice, TX",
    "CBSA Solo": "Alice",
    "CBSA State": "TX",
    CBSA: "Alice, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Corpus Christi-Kingsville-Alice, TX",
    "County/County Equivalent": "Jim Wells County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Jim Wells County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 249,
    "County\nCode": 48249,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Allentown-Bethlehem-Easton, PA-NJ",
    "CBSA Solo": "Allentown",
    "CBSA State": "PA",
    CBSA: "Allentown, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Carbon County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Carbon County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "025",
    "County\nCode": 42025,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Allentown-Bethlehem-Easton, PA-NJ",
    "CBSA Solo": "Allentown",
    "CBSA State": "PA",
    CBSA: "Allentown, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Warren County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Warren County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "041",
    "County\nCode": 34041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Allentown-Bethlehem-Easton, PA-NJ",
    "CBSA Solo": "Allentown",
    "CBSA State": "PA",
    CBSA: "Allentown, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lehigh County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Lehigh County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "077",
    "County\nCode": 42077,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Allentown-Bethlehem-Easton, PA-NJ",
    "CBSA Solo": "Allentown",
    "CBSA State": "PA",
    CBSA: "Allentown, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Northampton County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Northampton County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "095",
    "County\nCode": 42095,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10940,
    "Metropolitan Division Code": "",
    "CSA Code": 394,
    "CBSA Full Title": "Alma, MI",
    "CBSA Solo": "Alma",
    "CBSA State": "MI",
    CBSA: "Alma, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mount Pleasant-Alma, MI",
    "County/County Equivalent": "Gratiot County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Gratiot County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "057",
    "County\nCode": 26057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 10980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Alpena, MI",
    "CBSA Solo": "Alpena",
    "CBSA State": "MI",
    CBSA: "Alpena, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Alpena County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Alpena County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "007",
    "County\nCode": 26007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11020,
    "Metropolitan Division Code": "",
    "CSA Code": 107,
    "CBSA Full Title": "Altoona, PA",
    "CBSA Solo": "Altoona",
    "CBSA State": "PA",
    CBSA: "Altoona, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Altoona-Huntingdon, PA",
    "County/County Equivalent": "Blair County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Blair County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "013",
    "County\nCode": 42013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Altus, OK",
    "CBSA Solo": "Altus",
    "CBSA State": "OK",
    CBSA: "Altus, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jackson County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Jackson County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "065",
    "County\nCode": 40065,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11100,
    "Metropolitan Division Code": "",
    "CSA Code": 108,
    "CBSA Full Title": "Amarillo, TX",
    "CBSA Solo": "Amarillo",
    "CBSA State": "TX",
    CBSA: "Amarillo, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Amarillo-Pampa-Borger, TX",
    "County/County Equivalent": "Armstrong County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Armstrong County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "011",
    "County\nCode": 48011,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 11100,
    "Metropolitan Division Code": "",
    "CSA Code": 108,
    "CBSA Full Title": "Amarillo, TX",
    "CBSA Solo": "Amarillo",
    "CBSA State": "TX",
    CBSA: "Amarillo, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Amarillo-Pampa-Borger, TX",
    "County/County Equivalent": "Carson County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Carson County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "065",
    "County\nCode": 48065,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 11100,
    "Metropolitan Division Code": "",
    "CSA Code": 108,
    "CBSA Full Title": "Amarillo, TX",
    "CBSA Solo": "Amarillo",
    "CBSA State": "TX",
    CBSA: "Amarillo, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Amarillo-Pampa-Borger, TX",
    "County/County Equivalent": "Oldham County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Oldham County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 359,
    "County\nCode": 48359,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 11100,
    "Metropolitan Division Code": "",
    "CSA Code": 108,
    "CBSA Full Title": "Amarillo, TX",
    "CBSA Solo": "Amarillo",
    "CBSA State": "TX",
    CBSA: "Amarillo, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Amarillo-Pampa-Borger, TX",
    "County/County Equivalent": "Potter County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Potter County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 375,
    "County\nCode": 48375,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11100,
    "Metropolitan Division Code": "",
    "CSA Code": 108,
    "CBSA Full Title": "Amarillo, TX",
    "CBSA Solo": "Amarillo",
    "CBSA State": "TX",
    CBSA: "Amarillo, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Amarillo-Pampa-Borger, TX",
    "County/County Equivalent": "Randall County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Randall County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 381,
    "County\nCode": 48381,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Americus, GA",
    "CBSA Solo": "Americus",
    "CBSA State": "GA",
    CBSA: "Americus, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Schley County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Schley County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 249,
    "County\nCode": 13249,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 11140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Americus, GA",
    "CBSA Solo": "Americus",
    "CBSA State": "GA",
    CBSA: "Americus, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Sumter County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Sumter County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 261,
    "County\nCode": 13261,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11180,
    "Metropolitan Division Code": "",
    "CSA Code": 218,
    "CBSA Full Title": "Ames, IA",
    "CBSA Solo": "Ames",
    "CBSA State": "IA",
    CBSA: "Ames, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Des Moines-Ames-West Des Moines, IA",
    "County/County Equivalent": "Boone County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Boone County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "015",
    "County\nCode": 19015,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 11180,
    "Metropolitan Division Code": "",
    "CSA Code": 218,
    "CBSA Full Title": "Ames, IA",
    "CBSA Solo": "Ames",
    "CBSA State": "IA",
    CBSA: "Ames, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Des Moines-Ames-West Des Moines, IA",
    "County/County Equivalent": "Story County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Story County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 169,
    "County\nCode": 19169,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11220,
    "Metropolitan Division Code": "",
    "CSA Code": 104,
    "CBSA Full Title": "Amsterdam, NY",
    "CBSA Solo": "Amsterdam",
    "CBSA State": "NY",
    CBSA: "Amsterdam, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albany-Schenectady, NY",
    "County/County Equivalent": "Montgomery County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Montgomery County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "057",
    "County\nCode": 36057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Anchorage, AK",
    "CBSA Solo": "Anchorage",
    "CBSA State": "AK",
    CBSA: "Anchorage, AK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Anchorage Municipality",
    "State Name": "Alaska",
    "State Abbr": "AK",
    "County, State": "Anchorage Municipality, AK",
    "FIPS State Code": "02",
    "FIPS County Code": "020",
    "County\nCode": "02020",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Anchorage, AK",
    "CBSA Solo": "Anchorage",
    "CBSA State": "AK",
    CBSA: "Anchorage, AK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Matanuska-Susitna Borough",
    "State Name": "Alaska",
    "State Abbr": "AK",
    "County, State": "Matanuska-Susitna Borough, AK",
    "FIPS State Code": "02",
    "FIPS County Code": 170,
    "County\nCode": "02170",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 11380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Andrews, TX",
    "CBSA Solo": "Andrews",
    "CBSA State": "TX",
    CBSA: "Andrews, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Andrews County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Andrews County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "003",
    "County\nCode": 48003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11420,
    "Metropolitan Division Code": "",
    "CSA Code": 258,
    "CBSA Full Title": "Angola, IN",
    "CBSA Solo": "Angola",
    "CBSA State": "IN",
    CBSA: "Angola, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fort Wayne-Huntington-Auburn, IN",
    "County/County Equivalent": "Steuben County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Steuben County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 151,
    "County\nCode": 18151,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11460,
    "Metropolitan Division Code": "",
    "CSA Code": 220,
    "CBSA Full Title": "Ann Arbor, MI",
    "CBSA Solo": "Ann Arbor",
    "CBSA State": "MI",
    CBSA: "Ann Arbor, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Detroit-Warren-Ann Arbor, MI",
    "County/County Equivalent": "Washtenaw County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Washtenaw County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 161,
    "County\nCode": 26161,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Anniston-Oxford, AL",
    "CBSA Solo": "Anniston",
    "CBSA State": "AL",
    CBSA: "Anniston, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Calhoun County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Calhoun County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "015",
    "County\nCode": "01015",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11540,
    "Metropolitan Division Code": "",
    "CSA Code": 118,
    "CBSA Full Title": "Appleton, WI",
    "CBSA Solo": "Appleton",
    "CBSA State": "WI",
    CBSA: "Appleton, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Appleton-Oshkosh-Neenah, WI",
    "County/County Equivalent": "Calumet County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Calumet County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "015",
    "County\nCode": 55015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11540,
    "Metropolitan Division Code": "",
    "CSA Code": 118,
    "CBSA Full Title": "Appleton, WI",
    "CBSA Solo": "Appleton",
    "CBSA State": "WI",
    CBSA: "Appleton, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Appleton-Oshkosh-Neenah, WI",
    "County/County Equivalent": "Outagamie County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Outagamie County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "087",
    "County\nCode": 55087,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11580,
    "Metropolitan Division Code": "",
    "CSA Code": 412,
    "CBSA Full Title": "Arcadia, FL",
    "CBSA Solo": "Arcadia",
    "CBSA State": "FL",
    CBSA: "Arcadia, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "North Port-Sarasota, FL",
    "County/County Equivalent": "DeSoto County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "DeSoto County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "027",
    "County\nCode": 12027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Ardmore, OK",
    "CBSA Solo": "Ardmore",
    "CBSA State": "OK",
    CBSA: "Ardmore, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Carter County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Carter County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "019",
    "County\nCode": 40019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Ardmore, OK",
    "CBSA Solo": "Ardmore",
    "CBSA State": "OK",
    CBSA: "Ardmore, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Love County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Love County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "085",
    "County\nCode": 40085,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 11640,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "Arecibo, PR",
    "CBSA Solo": "Arecibo",
    "CBSA State": "PR",
    CBSA: "Arecibo, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Arecibo Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Arecibo Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "013",
    "County\nCode": 72013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11640,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "Arecibo, PR",
    "CBSA Solo": "Arecibo",
    "CBSA State": "PR",
    CBSA: "Arecibo, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Camuy Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Camuy Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "027",
    "County\nCode": 72027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11640,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "Arecibo, PR",
    "CBSA Solo": "Arecibo",
    "CBSA State": "PR",
    CBSA: "Arecibo, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Hatillo Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Hatillo Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "065",
    "County\nCode": 72065,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11640,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "Arecibo, PR",
    "CBSA Solo": "Arecibo",
    "CBSA State": "PR",
    CBSA: "Arecibo, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Quebradillas Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Quebradillas Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 115,
    "County\nCode": 72115,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Arkadelphia, AR",
    "CBSA Solo": "Arkadelphia",
    "CBSA State": "AR",
    CBSA: "Arkadelphia, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Clark County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Clark County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "019",
    "County\nCode": "05019",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11700,
    "Metropolitan Division Code": "",
    "CSA Code": 120,
    "CBSA Full Title": "Asheville, NC",
    "CBSA Solo": "Asheville",
    "CBSA State": "NC",
    CBSA: "Asheville, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Asheville-Marion-Brevard, NC",
    "County/County Equivalent": "Buncombe County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Buncombe County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "021",
    "County\nCode": 37021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11700,
    "Metropolitan Division Code": "",
    "CSA Code": 120,
    "CBSA Full Title": "Asheville, NC",
    "CBSA Solo": "Asheville",
    "CBSA State": "NC",
    CBSA: "Asheville, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Asheville-Marion-Brevard, NC",
    "County/County Equivalent": "Haywood County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Haywood County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "087",
    "County\nCode": 37087,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11700,
    "Metropolitan Division Code": "",
    "CSA Code": 120,
    "CBSA Full Title": "Asheville, NC",
    "CBSA Solo": "Asheville",
    "CBSA State": "NC",
    CBSA: "Asheville, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Asheville-Marion-Brevard, NC",
    "County/County Equivalent": "Henderson County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Henderson County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "089",
    "County\nCode": 37089,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11700,
    "Metropolitan Division Code": "",
    "CSA Code": 120,
    "CBSA Full Title": "Asheville, NC",
    "CBSA Solo": "Asheville",
    "CBSA State": "NC",
    CBSA: "Asheville, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Asheville-Marion-Brevard, NC",
    "County/County Equivalent": "Madison County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Madison County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 115,
    "County\nCode": 37115,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 11740,
    "Metropolitan Division Code": "",
    "CSA Code": 360,
    "CBSA Full Title": "Ashland, OH",
    "CBSA Solo": "Ashland",
    "CBSA State": "OH",
    CBSA: "Ashland, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mansfield-Ashland-Bucyrus, OH",
    "County/County Equivalent": "Ashland County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Ashland County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "005",
    "County\nCode": 39005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11780,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "Ashtabula, OH",
    "CBSA Solo": "Ashtabula",
    "CBSA State": "OH",
    CBSA: "Ashtabula, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Ashtabula County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Ashtabula County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "007",
    "County\nCode": 39007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Astoria, OR",
    "CBSA Solo": "Astoria",
    "CBSA State": "OR",
    CBSA: "Astoria, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Clatsop County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Clatsop County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "007",
    "County\nCode": 41007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11860,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Atchison, KS",
    "CBSA Solo": "Atchison",
    "CBSA State": "KS",
    CBSA: "Atchison, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Atchison County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Atchison County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "005",
    "County\nCode": 20005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Athens, OH",
    "CBSA Solo": "Athens",
    "CBSA State": "OH",
    CBSA: "Athens, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Athens County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Athens County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "009",
    "County\nCode": 39009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17420,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Cleveland, TN",
    "CBSA Solo": "Cleveland",
    "CBSA State": "TN",
    CBSA: "Cleveland, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "Polk County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Polk County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 139,
    "County\nCode": 47139,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 11940,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Athens, TN",
    "CBSA Solo": "Athens",
    "CBSA State": "TN",
    CBSA: "Athens, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "McMinn County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "McMinn County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 107,
    "County\nCode": 47107,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 11980,
    "Metropolitan Division Code": "",
    "CSA Code": 206,
    "CBSA Full Title": "Athens, TX",
    "CBSA Solo": "Athens",
    "CBSA State": "TX",
    CBSA: "Athens, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Henderson County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Henderson County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 213,
    "County\nCode": 48213,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12020,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Athens-Clarke County, GA",
    "CBSA Solo": "Athens",
    "CBSA State": "GA",
    CBSA: "Athens, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Clarke County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Clarke County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "059",
    "County\nCode": 13059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12020,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Athens-Clarke County, GA",
    "CBSA Solo": "Athens",
    "CBSA State": "GA",
    CBSA: "Athens, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Madison County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Madison County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 195,
    "County\nCode": 13195,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12020,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Athens-Clarke County, GA",
    "CBSA Solo": "Athens",
    "CBSA State": "GA",
    CBSA: "Athens, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Oconee County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Oconee County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 219,
    "County\nCode": 13219,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12020,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Athens-Clarke County, GA",
    "CBSA Solo": "Athens",
    "CBSA State": "GA",
    CBSA: "Athens, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Oglethorpe County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Oglethorpe County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 221,
    "County\nCode": 13221,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Barrow County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Barrow County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "013",
    "County\nCode": 13013,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Bartow County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Bartow County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "015",
    "County\nCode": 13015,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Butts County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Butts County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "035",
    "County\nCode": 13035,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Carroll County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Carroll County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "045",
    "County\nCode": 13045,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Cherokee County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Cherokee County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "057",
    "County\nCode": 13057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Clayton County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Clayton County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "063",
    "County\nCode": 13063,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Cobb County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Cobb County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "067",
    "County\nCode": 13067,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Coweta County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Coweta County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "077",
    "County\nCode": 13077,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Dawson County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Dawson County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "085",
    "County\nCode": 13085,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "DeKalb County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "DeKalb County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "089",
    "County\nCode": 13089,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Douglas County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Douglas County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "097",
    "County\nCode": 13097,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Fayette County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Fayette County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 113,
    "County\nCode": 13113,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Forsyth County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Forsyth County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 117,
    "County\nCode": 13117,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Fulton County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Fulton County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 121,
    "County\nCode": 13121,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Gwinnett County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Gwinnett County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 135,
    "County\nCode": 13135,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Haralson County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Haralson County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 143,
    "County\nCode": 13143,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Heard County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Heard County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 149,
    "County\nCode": 13149,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Henry County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Henry County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 151,
    "County\nCode": 13151,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Jasper County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Jasper County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 159,
    "County\nCode": 13159,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Lamar County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Lamar County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 171,
    "County\nCode": 13171,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Meriwether County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Meriwether County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 199,
    "County\nCode": 13199,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Morgan County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Morgan County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 211,
    "County\nCode": 13211,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Newton County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Newton County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 217,
    "County\nCode": 13217,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Paulding County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Paulding County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 223,
    "County\nCode": 13223,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Pickens County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Pickens County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 227,
    "County\nCode": 13227,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Pike County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Pike County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 231,
    "County\nCode": 13231,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Rockdale County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Rockdale County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 247,
    "County\nCode": 13247,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Spalding County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Spalding County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 255,
    "County\nCode": 13255,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12060,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Atlanta-Sandy Springs-Alpharetta, GA",
    "CBSA Solo": "Atlanta",
    "CBSA State": "GA",
    CBSA: "Atlanta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Walton County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Walton County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 297,
    "County\nCode": 13297,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12100,
    "Metropolitan Division Code": "",
    "CSA Code": 428,
    "CBSA Full Title": "Atlantic City-Hammonton, NJ",
    "CBSA Solo": "Atlantic City",
    "CBSA State": "NJ",
    CBSA: "Atlantic City, NJ",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Atlantic County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Atlantic County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "001",
    "County\nCode": 34001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12120,
    "Metropolitan Division Code": "",
    "CSA Code": 426,
    "CBSA Full Title": "Atmore, AL",
    "CBSA Solo": "Atmore",
    "CBSA State": "AL",
    CBSA: "Atmore, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pensacola-Ferry Pass, FL-AL",
    "County/County Equivalent": "Escambia County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Escambia County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "053",
    "County\nCode": "01053",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12140,
    "Metropolitan Division Code": "",
    "CSA Code": 258,
    "CBSA Full Title": "Auburn, IN",
    "CBSA Solo": "Auburn",
    "CBSA State": "IN",
    CBSA: "Auburn, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fort Wayne-Huntington-Auburn, IN",
    "County/County Equivalent": "DeKalb County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "DeKalb County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "033",
    "County\nCode": 18033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12180,
    "Metropolitan Division Code": "",
    "CSA Code": 532,
    "CBSA Full Title": "Auburn, NY",
    "CBSA Solo": "Auburn",
    "CBSA State": "NY",
    CBSA: "Auburn, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Syracuse-Auburn, NY",
    "County/County Equivalent": "Cayuga County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Cayuga County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "011",
    "County\nCode": 36011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12220,
    "Metropolitan Division Code": "",
    "CSA Code": 194,
    "CBSA Full Title": "Auburn-Opelika, AL",
    "CBSA Solo": "Auburn",
    "CBSA State": "AL",
    CBSA: "Auburn, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Auburn-Opelika, GA-AL",
    "County/County Equivalent": "Lee County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Lee County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "081",
    "County\nCode": "01081",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Augusta-Richmond County, GA-SC",
    "CBSA Solo": "Augusta",
    "CBSA State": "GA",
    CBSA: "Augusta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Burke County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Burke County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "033",
    "County\nCode": 13033,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Augusta-Richmond County, GA-SC",
    "CBSA Solo": "Augusta",
    "CBSA State": "GA",
    CBSA: "Augusta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Columbia County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Columbia County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "073",
    "County\nCode": 13073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Augusta-Richmond County, GA-SC",
    "CBSA Solo": "Augusta",
    "CBSA State": "GA",
    CBSA: "Augusta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lincoln County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Lincoln County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 181,
    "County\nCode": 13181,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Augusta-Richmond County, GA-SC",
    "CBSA Solo": "Augusta",
    "CBSA State": "GA",
    CBSA: "Augusta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "McDuffie County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "McDuffie County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 189,
    "County\nCode": 13189,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Augusta-Richmond County, GA-SC",
    "CBSA Solo": "Augusta",
    "CBSA State": "GA",
    CBSA: "Augusta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Richmond County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Richmond County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 245,
    "County\nCode": 13245,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Augusta-Richmond County, GA-SC",
    "CBSA Solo": "Augusta",
    "CBSA State": "GA",
    CBSA: "Augusta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Aiken County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Aiken County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "003",
    "County\nCode": 45003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Augusta-Richmond County, GA-SC",
    "CBSA Solo": "Augusta",
    "CBSA State": "GA",
    CBSA: "Augusta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Edgefield County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Edgefield County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "037",
    "County\nCode": 45037,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Augusta-Waterville, ME",
    "CBSA Solo": "Augusta",
    "CBSA State": "ME",
    CBSA: "Augusta, ME",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Kennebec County",
    "State Name": "Maine",
    "State Abbr": "ME",
    "County, State": "Kennebec County, ME",
    "FIPS State Code": 23,
    "FIPS County Code": "011",
    "County\nCode": 23011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12380,
    "Metropolitan Division Code": "",
    "CSA Code": 462,
    "CBSA Full Title": "Austin, MN",
    "CBSA Solo": "Austin",
    "CBSA State": "MN",
    CBSA: "Austin, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rochester-Austin, MN",
    "County/County Equivalent": "Mower County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Mower County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "099",
    "County\nCode": 27099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Austin-Round Rock-Georgetown, TX",
    "CBSA Solo": "Austin",
    "CBSA State": "TX",
    CBSA: "Austin, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Bastrop County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Bastrop County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "021",
    "County\nCode": 48021,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Austin-Round Rock-Georgetown, TX",
    "CBSA Solo": "Austin",
    "CBSA State": "TX",
    CBSA: "Austin, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Caldwell County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Caldwell County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "055",
    "County\nCode": 48055,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Austin-Round Rock-Georgetown, TX",
    "CBSA Solo": "Austin",
    "CBSA State": "TX",
    CBSA: "Austin, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hays County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Hays County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 209,
    "County\nCode": 48209,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Austin-Round Rock-Georgetown, TX",
    "CBSA Solo": "Austin",
    "CBSA State": "TX",
    CBSA: "Austin, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Travis County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Travis County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 453,
    "County\nCode": 48453,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Austin-Round Rock-Georgetown, TX",
    "CBSA Solo": "Austin",
    "CBSA State": "TX",
    CBSA: "Austin, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Williamson County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Williamson County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 491,
    "County\nCode": 48491,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bainbridge, GA",
    "CBSA Solo": "Bainbridge",
    "CBSA State": "GA",
    CBSA: "Bainbridge, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Decatur County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Decatur County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "087",
    "County\nCode": 13087,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bakersfield, CA",
    "CBSA Solo": "Bakersfield",
    "CBSA State": "CA",
    CBSA: "Bakersfield, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Kern County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Kern County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "029",
    "County\nCode": "06029",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12580,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Baltimore-Columbia-Towson, MD",
    "CBSA Solo": "Baltimore",
    "CBSA State": "MD",
    CBSA: "Baltimore, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Anne Arundel County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Anne Arundel County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "003",
    "County\nCode": 24003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12580,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Baltimore-Columbia-Towson, MD",
    "CBSA Solo": "Baltimore",
    "CBSA State": "MD",
    CBSA: "Baltimore, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Baltimore County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Baltimore County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "005",
    "County\nCode": 24005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12580,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Baltimore-Columbia-Towson, MD",
    "CBSA Solo": "Baltimore",
    "CBSA State": "MD",
    CBSA: "Baltimore, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Carroll County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Carroll County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "013",
    "County\nCode": 24013,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12580,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Baltimore-Columbia-Towson, MD",
    "CBSA Solo": "Baltimore",
    "CBSA State": "MD",
    CBSA: "Baltimore, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Harford County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Harford County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "025",
    "County\nCode": 24025,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12580,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Baltimore-Columbia-Towson, MD",
    "CBSA Solo": "Baltimore",
    "CBSA State": "MD",
    CBSA: "Baltimore, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Howard County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Howard County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "027",
    "County\nCode": 24027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12580,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Baltimore-Columbia-Towson, MD",
    "CBSA Solo": "Baltimore",
    "CBSA State": "MD",
    CBSA: "Baltimore, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Queen Anne's County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Queen Anne's County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "035",
    "County\nCode": 24035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12580,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Baltimore-Columbia-Towson, MD",
    "CBSA Solo": "Baltimore",
    "CBSA State": "MD",
    CBSA: "Baltimore, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Baltimore city",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Baltimore city, MD",
    "FIPS State Code": 24,
    "FIPS County Code": 510,
    "County\nCode": 24510,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bangor, ME",
    "CBSA Solo": "Bangor",
    "CBSA State": "ME",
    CBSA: "Bangor, ME",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Penobscot County",
    "State Name": "Maine",
    "State Abbr": "ME",
    "County, State": "Penobscot County, ME",
    "FIPS State Code": 23,
    "FIPS County Code": "019",
    "County\nCode": 23019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12660,
    "Metropolitan Division Code": "",
    "CSA Code": 357,
    "CBSA Full Title": "Baraboo, WI",
    "CBSA Solo": "Baraboo",
    "CBSA State": "WI",
    CBSA: "Baraboo, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Madison-Janesville-Beloit, WI",
    "County/County Equivalent": "Sauk County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Sauk County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": 111,
    "County\nCode": 55111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12680,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Bardstown, KY",
    "CBSA Solo": "Bardstown",
    "CBSA State": "KY",
    CBSA: "Bardstown, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Nelson County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Nelson County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 179,
    "County\nCode": 21179,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12700,
    "Metropolitan Division Code": "",
    "CSA Code": 148,
    "CBSA Full Title": "Barnstable Town, MA",
    "CBSA Solo": "Barnstable Town",
    "CBSA State": "MA",
    CBSA: "Barnstable Town, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Barnstable County",
    "State Name": "Massachusetts",
    "State Abbr": "MA",
    "County, State": "Barnstable County, MA",
    "FIPS State Code": 25,
    "FIPS County Code": "001",
    "County\nCode": 25001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12740,
    "Metropolitan Division Code": "",
    "CSA Code": 162,
    "CBSA Full Title": "Barre, VT",
    "CBSA Solo": "Barre",
    "CBSA State": "VT",
    CBSA: "Barre, VT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Burlington-South Burlington-Barre, VT",
    "County/County Equivalent": "Washington County",
    "State Name": "Vermont",
    "State Abbr": "VT",
    "County, State": "Washington County, VT",
    "FIPS State Code": 50,
    "FIPS County Code": "023",
    "County\nCode": 50023,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12780,
    "Metropolitan Division Code": "",
    "CSA Code": 538,
    "CBSA Full Title": "Bartlesville, OK",
    "CBSA Solo": "Bartlesville",
    "CBSA State": "OK",
    CBSA: "Bartlesville, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tulsa-Muskogee-Bartlesville, OK",
    "County/County Equivalent": "Washington County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Washington County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 147,
    "County\nCode": 40147,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12860,
    "Metropolitan Division Code": "",
    "CSA Code": 464,
    "CBSA Full Title": "Batavia, NY",
    "CBSA Solo": "Batavia",
    "CBSA State": "NY",
    CBSA: "Batavia, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rochester-Batavia-Seneca Falls, NY",
    "County/County Equivalent": "Genesee County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Genesee County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "037",
    "County\nCode": 36037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Batesville, AR",
    "CBSA Solo": "Batesville",
    "CBSA State": "AR",
    CBSA: "Batesville, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Independence County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Independence County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "063",
    "County\nCode": "05063",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Batesville, AR",
    "CBSA Solo": "Batesville",
    "CBSA State": "AR",
    CBSA: "Batesville, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Sharp County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Sharp County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 135,
    "County\nCode": "05135",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Baton Rouge, LA",
    "CBSA Solo": "Baton Rouge",
    "CBSA State": "LA",
    CBSA: "Baton Rouge, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ascension Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Ascension Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "005",
    "County\nCode": 22005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Baton Rouge, LA",
    "CBSA Solo": "Baton Rouge",
    "CBSA State": "LA",
    CBSA: "Baton Rouge, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Assumption Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Assumption Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "007",
    "County\nCode": 22007,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Baton Rouge, LA",
    "CBSA Solo": "Baton Rouge",
    "CBSA State": "LA",
    CBSA: "Baton Rouge, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "East Baton Rouge Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "East Baton Rouge Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "033",
    "County\nCode": 22033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Baton Rouge, LA",
    "CBSA Solo": "Baton Rouge",
    "CBSA State": "LA",
    CBSA: "Baton Rouge, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "East Feliciana Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "East Feliciana Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "037",
    "County\nCode": 22037,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Baton Rouge, LA",
    "CBSA Solo": "Baton Rouge",
    "CBSA State": "LA",
    CBSA: "Baton Rouge, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Iberville Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Iberville Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "047",
    "County\nCode": 22047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Baton Rouge, LA",
    "CBSA Solo": "Baton Rouge",
    "CBSA State": "LA",
    CBSA: "Baton Rouge, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Livingston Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Livingston Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "063",
    "County\nCode": 22063,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Baton Rouge, LA",
    "CBSA Solo": "Baton Rouge",
    "CBSA State": "LA",
    CBSA: "Baton Rouge, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pointe Coupee Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Pointe Coupee Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "077",
    "County\nCode": 22077,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Baton Rouge, LA",
    "CBSA Solo": "Baton Rouge",
    "CBSA State": "LA",
    CBSA: "Baton Rouge, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "St. Helena Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "St. Helena Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "091",
    "County\nCode": 22091,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Baton Rouge, LA",
    "CBSA Solo": "Baton Rouge",
    "CBSA State": "LA",
    CBSA: "Baton Rouge, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "West Baton Rouge Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "West Baton Rouge Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": 121,
    "County\nCode": 22121,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 12940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Baton Rouge, LA",
    "CBSA Solo": "Baton Rouge",
    "CBSA State": "LA",
    CBSA: "Baton Rouge, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "West Feliciana Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "West Feliciana Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": 125,
    "County\nCode": 22125,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 12980,
    "Metropolitan Division Code": "",
    "CSA Code": 310,
    "CBSA Full Title": "Battle Creek, MI",
    "CBSA Solo": "Battle Creek",
    "CBSA State": "MI",
    CBSA: "Battle Creek, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kalamazoo-Battle Creek-Portage, MI",
    "County/County Equivalent": "Calhoun County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Calhoun County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "025",
    "County\nCode": 26025,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13020,
    "Metropolitan Division Code": "",
    "CSA Code": 474,
    "CBSA Full Title": "Bay City, MI",
    "CBSA Solo": "Bay City",
    "CBSA State": "MI",
    CBSA: "Bay City, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Saginaw-Midland-Bay City, MI",
    "County/County Equivalent": "Bay County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Bay County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "017",
    "County\nCode": 26017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13060,
    "Metropolitan Division Code": "",
    "CSA Code": 288,
    "CBSA Full Title": "Bay City, TX",
    "CBSA Solo": "Bay City",
    "CBSA State": "TX",
    CBSA: "Bay City, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Houston-The Woodlands, TX",
    "County/County Equivalent": "Matagorda County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Matagorda County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 321,
    "County\nCode": 48321,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13100,
    "Metropolitan Division Code": "",
    "CSA Code": 339,
    "CBSA Full Title": "Beatrice, NE",
    "CBSA Solo": "Beatrice",
    "CBSA State": "NE",
    CBSA: "Beatrice, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lincoln-Beatrice, NE",
    "County/County Equivalent": "Gage County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Gage County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "067",
    "County\nCode": 31067,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Beaumont-Port Arthur, TX",
    "CBSA Solo": "Beaumont",
    "CBSA State": "TX",
    CBSA: "Beaumont, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hardin County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Hardin County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 199,
    "County\nCode": 48199,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 13140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Beaumont-Port Arthur, TX",
    "CBSA Solo": "Beaumont",
    "CBSA State": "TX",
    CBSA: "Beaumont, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Jefferson County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 245,
    "County\nCode": 48245,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Beaumont-Port Arthur, TX",
    "CBSA Solo": "Beaumont",
    "CBSA State": "TX",
    CBSA: "Beaumont, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Orange County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Orange County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 361,
    "County\nCode": 48361,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13180,
    "Metropolitan Division Code": "",
    "CSA Code": 376,
    "CBSA Full Title": "Beaver Dam, WI",
    "CBSA Solo": "Beaver Dam",
    "CBSA State": "WI",
    CBSA: "Beaver Dam, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Milwaukee-Racine-Waukesha, WI",
    "County/County Equivalent": "Dodge County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Dodge County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "027",
    "County\nCode": 55027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Beckley, WV",
    "CBSA Solo": "Beckley",
    "CBSA State": "WV",
    CBSA: "Beckley, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Fayette County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Fayette County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "019",
    "County\nCode": 54019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Beckley, WV",
    "CBSA Solo": "Beckley",
    "CBSA State": "WV",
    CBSA: "Beckley, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Raleigh County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Raleigh County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "081",
    "County\nCode": 54081,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13260,
    "Metropolitan Division Code": "",
    "CSA Code": 144,
    "CBSA Full Title": "Bedford, IN",
    "CBSA Solo": "Bedford",
    "CBSA State": "IN",
    CBSA: "Bedford, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bloomington-Bedford, IN",
    "County/County Equivalent": "Lawrence County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Lawrence County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "093",
    "County\nCode": 18093,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Beeville, TX",
    "CBSA Solo": "Beeville",
    "CBSA State": "TX",
    CBSA: "Beeville, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Bee County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Bee County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "025",
    "County\nCode": 48025,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13340,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Bellefontaine, OH",
    "CBSA Solo": "Bellefontaine",
    "CBSA State": "OH",
    CBSA: "Bellefontaine, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Logan County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Logan County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "091",
    "County\nCode": 39091,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bellingham, WA",
    "CBSA Solo": "Bellingham",
    "CBSA State": "WA",
    CBSA: "Bellingham, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Whatcom County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Whatcom County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "073",
    "County\nCode": 53073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bemidji, MN",
    "CBSA Solo": "Bemidji",
    "CBSA State": "MN",
    CBSA: "Bemidji, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Beltrami County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Beltrami County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "007",
    "County\nCode": 27007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13460,
    "Metropolitan Division Code": "",
    "CSA Code": 140,
    "CBSA Full Title": "Bend, OR",
    "CBSA Solo": "Bend",
    "CBSA State": "OR",
    CBSA: "Bend, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bend-Prineville, OR",
    "County/County Equivalent": "Deschutes County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Deschutes County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "017",
    "County\nCode": 41017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bennettsville, SC",
    "CBSA Solo": "Bennettsville",
    "CBSA State": "SC",
    CBSA: "Bennettsville, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Marlboro County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Marlboro County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "069",
    "County\nCode": 45069,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bennington, VT",
    "CBSA Solo": "Bennington",
    "CBSA State": "VT",
    CBSA: "Bennington, VT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Bennington County",
    "State Name": "Vermont",
    "State Abbr": "VT",
    "County, State": "Bennington County, VT",
    "FIPS State Code": 50,
    "FIPS County Code": "003",
    "County\nCode": 50003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Berlin, NH",
    "CBSA Solo": "Berlin",
    "CBSA State": "NH",
    CBSA: "Berlin, NH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Coos County",
    "State Name": "New Hampshire",
    "State Abbr": "NH",
    "County, State": "Coos County, NH",
    "FIPS State Code": 33,
    "FIPS County Code": "007",
    "County\nCode": 33007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13660,
    "Metropolitan Division Code": "",
    "CSA Code": 266,
    "CBSA Full Title": "Big Rapids, MI",
    "CBSA Solo": "Big Rapids",
    "CBSA State": "MI",
    CBSA: "Big Rapids, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Grand Rapids-Kentwood-Muskegon, MI",
    "County/County Equivalent": "Mecosta County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Mecosta County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 107,
    "County\nCode": 26107,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Big Spring, TX",
    "CBSA Solo": "Big Spring",
    "CBSA State": "TX",
    CBSA: "Big Spring, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Howard County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Howard County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 227,
    "County\nCode": 48227,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13720,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Big Stone Gap, VA",
    "CBSA Solo": "Big Stone Gap",
    "CBSA State": "VA",
    CBSA: "Big Stone Gap, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Wise County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Wise County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 195,
    "County\nCode": 51195,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13720,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Big Stone Gap, VA",
    "CBSA Solo": "Big Stone Gap",
    "CBSA State": "VA",
    CBSA: "Big Stone Gap, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Norton city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Norton city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 720,
    "County\nCode": 51720,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Billings, MT",
    "CBSA Solo": "Billings",
    "CBSA State": "MT",
    CBSA: "Billings, MT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Carbon County",
    "State Name": "Montana",
    "State Abbr": "MT",
    "County, State": "Carbon County, MT",
    "FIPS State Code": 30,
    "FIPS County Code": "009",
    "County\nCode": 30009,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 13740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Billings, MT",
    "CBSA Solo": "Billings",
    "CBSA State": "MT",
    CBSA: "Billings, MT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Stillwater County",
    "State Name": "Montana",
    "State Abbr": "MT",
    "County, State": "Stillwater County, MT",
    "FIPS State Code": 30,
    "FIPS County Code": "095",
    "County\nCode": 30095,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 13740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Billings, MT",
    "CBSA Solo": "Billings",
    "CBSA State": "MT",
    CBSA: "Billings, MT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Yellowstone County",
    "State Name": "Montana",
    "State Abbr": "MT",
    "County, State": "Yellowstone County, MT",
    "FIPS State Code": 30,
    "FIPS County Code": 111,
    "County\nCode": 30111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Binghamton, NY",
    "CBSA Solo": "Binghamton",
    "CBSA State": "NY",
    CBSA: "Binghamton, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Broome County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Broome County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "007",
    "County\nCode": 36007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Binghamton, NY",
    "CBSA Solo": "Binghamton",
    "CBSA State": "NY",
    CBSA: "Binghamton, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Tioga County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Tioga County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": 107,
    "County\nCode": 36107,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13820,
    "Metropolitan Division Code": "",
    "CSA Code": 142,
    "CBSA Full Title": "Birmingham-Hoover, AL",
    "CBSA Solo": "Birmingham",
    "CBSA State": "AL",
    CBSA: "Birmingham, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Birmingham-Hoover-Talladega, AL",
    "County/County Equivalent": "Bibb County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Bibb County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "007",
    "County\nCode": "01007",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 13820,
    "Metropolitan Division Code": "",
    "CSA Code": 142,
    "CBSA Full Title": "Birmingham-Hoover, AL",
    "CBSA Solo": "Birmingham",
    "CBSA State": "AL",
    CBSA: "Birmingham, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Birmingham-Hoover-Talladega, AL",
    "County/County Equivalent": "Blount County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Blount County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "009",
    "County\nCode": "01009",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 13820,
    "Metropolitan Division Code": "",
    "CSA Code": 142,
    "CBSA Full Title": "Birmingham-Hoover, AL",
    "CBSA Solo": "Birmingham",
    "CBSA State": "AL",
    CBSA: "Birmingham, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Birmingham-Hoover-Talladega, AL",
    "County/County Equivalent": "Chilton County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Chilton County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "021",
    "County\nCode": "01021",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 13820,
    "Metropolitan Division Code": "",
    "CSA Code": 142,
    "CBSA Full Title": "Birmingham-Hoover, AL",
    "CBSA Solo": "Birmingham",
    "CBSA State": "AL",
    CBSA: "Birmingham, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Birmingham-Hoover-Talladega, AL",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Jefferson County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "073",
    "County\nCode": "01073",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13820,
    "Metropolitan Division Code": "",
    "CSA Code": 142,
    "CBSA Full Title": "Birmingham-Hoover, AL",
    "CBSA Solo": "Birmingham",
    "CBSA State": "AL",
    CBSA: "Birmingham, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Birmingham-Hoover-Talladega, AL",
    "County/County Equivalent": "St. Clair County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "St. Clair County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": 115,
    "County\nCode": "01115",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 13820,
    "Metropolitan Division Code": "",
    "CSA Code": 142,
    "CBSA Full Title": "Birmingham-Hoover, AL",
    "CBSA Solo": "Birmingham",
    "CBSA State": "AL",
    CBSA: "Birmingham, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Birmingham-Hoover-Talladega, AL",
    "County/County Equivalent": "Shelby County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Shelby County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": 117,
    "County\nCode": "01117",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bismarck, ND",
    "CBSA Solo": "Bismarck",
    "CBSA State": "ND",
    CBSA: "Bismarck, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Burleigh County",
    "State Name": "North Dakota",
    "State Abbr": "ND",
    "County, State": "Burleigh County, ND",
    "FIPS State Code": 38,
    "FIPS County Code": "015",
    "County\nCode": 38015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bismarck, ND",
    "CBSA Solo": "Bismarck",
    "CBSA State": "ND",
    CBSA: "Bismarck, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Morton County",
    "State Name": "North Dakota",
    "State Abbr": "ND",
    "County, State": "Morton County, ND",
    "FIPS State Code": 38,
    "FIPS County Code": "059",
    "County\nCode": 38059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bismarck, ND",
    "CBSA Solo": "Bismarck",
    "CBSA State": "ND",
    CBSA: "Bismarck, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Oliver County",
    "State Name": "North Dakota",
    "State Abbr": "ND",
    "County, State": "Oliver County, ND",
    "FIPS State Code": 38,
    "FIPS County Code": "065",
    "County\nCode": 38065,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 13940,
    "Metropolitan Division Code": "",
    "CSA Code": 292,
    "CBSA Full Title": "Blackfoot, ID",
    "CBSA Solo": "Blackfoot",
    "CBSA State": "ID",
    CBSA: "Blackfoot, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Idaho Falls-Rexburg-Blackfoot, ID",
    "County/County Equivalent": "Bingham County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Bingham County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "011",
    "County\nCode": 16011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Blacksburg-Christiansburg, VA",
    "CBSA Solo": "Blacksburg",
    "CBSA State": "VA",
    CBSA: "Blacksburg, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Giles County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Giles County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "071",
    "County\nCode": 51071,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 13980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Blacksburg-Christiansburg, VA",
    "CBSA Solo": "Blacksburg",
    "CBSA State": "VA",
    CBSA: "Blacksburg, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Montgomery County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Montgomery County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 121,
    "County\nCode": 51121,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 13980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Blacksburg-Christiansburg, VA",
    "CBSA Solo": "Blacksburg",
    "CBSA State": "VA",
    CBSA: "Blacksburg, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pulaski County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Pulaski County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 155,
    "County\nCode": 51155,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 13980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Blacksburg-Christiansburg, VA",
    "CBSA Solo": "Blacksburg",
    "CBSA State": "VA",
    CBSA: "Blacksburg, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Radford city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Radford city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 750,
    "County\nCode": 51750,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14010,
    "Metropolitan Division Code": "",
    "CSA Code": 145,
    "CBSA Full Title": "Bloomington, IL",
    "CBSA Solo": "Bloomington",
    "CBSA State": "IL",
    CBSA: "Bloomington, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bloomington-Pontiac, IL",
    "County/County Equivalent": "McLean County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "McLean County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 113,
    "County\nCode": 17113,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14020,
    "Metropolitan Division Code": "",
    "CSA Code": 144,
    "CBSA Full Title": "Bloomington, IN",
    "CBSA Solo": "Bloomington",
    "CBSA State": "IN",
    CBSA: "Bloomington, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bloomington-Bedford, IN",
    "County/County Equivalent": "Monroe County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Monroe County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 105,
    "County\nCode": 18105,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14020,
    "Metropolitan Division Code": "",
    "CSA Code": 144,
    "CBSA Full Title": "Bloomington, IN",
    "CBSA Solo": "Bloomington",
    "CBSA State": "IN",
    CBSA: "Bloomington, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bloomington-Bedford, IN",
    "County/County Equivalent": "Owen County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Owen County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 119,
    "County\nCode": 18119,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 14100,
    "Metropolitan Division Code": "",
    "CSA Code": 146,
    "CBSA Full Title": "Bloomsburg-Berwick, PA",
    "CBSA Solo": "Bloomsburg",
    "CBSA State": "PA",
    CBSA: "Bloomsburg, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bloomsburg-Berwick-Sunbury, PA",
    "County/County Equivalent": "Columbia County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Columbia County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "037",
    "County\nCode": 42037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14100,
    "Metropolitan Division Code": "",
    "CSA Code": 146,
    "CBSA Full Title": "Bloomsburg-Berwick, PA",
    "CBSA Solo": "Bloomsburg",
    "CBSA State": "PA",
    CBSA: "Bloomsburg, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bloomsburg-Berwick-Sunbury, PA",
    "County/County Equivalent": "Montour County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Montour County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "093",
    "County\nCode": 42093,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bluefield, WV-VA",
    "CBSA Solo": "Bluefield",
    "CBSA State": "WV",
    CBSA: "Bluefield, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Bland County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Bland County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "021",
    "County\nCode": 51021,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 14140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bluefield, WV-VA",
    "CBSA Solo": "Bluefield",
    "CBSA State": "WV",
    CBSA: "Bluefield, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Tazewell County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Tazewell County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 185,
    "County\nCode": 51185,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bluefield, WV-VA",
    "CBSA Solo": "Bluefield",
    "CBSA State": "WV",
    CBSA: "Bluefield, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Mercer County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Mercer County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "055",
    "County\nCode": 54055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14160,
    "Metropolitan Division Code": "",
    "CSA Code": 258,
    "CBSA Full Title": "Bluffton, IN",
    "CBSA Solo": "Bluffton",
    "CBSA State": "IN",
    CBSA: "Bluffton, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fort Wayne-Huntington-Auburn, IN",
    "County/County Equivalent": "Wells County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Wells County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 179,
    "County\nCode": 18179,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Blytheville, AR",
    "CBSA Solo": "Blytheville",
    "CBSA State": "AR",
    CBSA: "Blytheville, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Mississippi County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Mississippi County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "093",
    "County\nCode": "05093",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14220,
    "Metropolitan Division Code": "",
    "CSA Code": 406,
    "CBSA Full Title": "Bogalusa, LA",
    "CBSA Solo": "Bogalusa",
    "CBSA State": "LA",
    CBSA: "Bogalusa, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Orleans-Metairie-Hammond, LA-MS",
    "County/County Equivalent": "Washington Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Washington Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": 117,
    "County\nCode": 22117,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14260,
    "Metropolitan Division Code": "",
    "CSA Code": 147,
    "CBSA Full Title": "Boise City, ID",
    "CBSA Solo": "Boise City",
    "CBSA State": "ID",
    CBSA: "Boise City, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boise City-Mountain Home-Ontario, ID-OR",
    "County/County Equivalent": "Ada County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Ada County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "001",
    "County\nCode": 16001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14260,
    "Metropolitan Division Code": "",
    "CSA Code": 147,
    "CBSA Full Title": "Boise City, ID",
    "CBSA Solo": "Boise City",
    "CBSA State": "ID",
    CBSA: "Boise City, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boise City-Mountain Home-Ontario, ID-OR",
    "County/County Equivalent": "Boise County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Boise County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "015",
    "County\nCode": 16015,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 14260,
    "Metropolitan Division Code": "",
    "CSA Code": 147,
    "CBSA Full Title": "Boise City, ID",
    "CBSA Solo": "Boise City",
    "CBSA State": "ID",
    CBSA: "Boise City, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boise City-Mountain Home-Ontario, ID-OR",
    "County/County Equivalent": "Canyon County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Canyon County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "027",
    "County\nCode": 16027,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 14260,
    "Metropolitan Division Code": "",
    "CSA Code": 147,
    "CBSA Full Title": "Boise City, ID",
    "CBSA Solo": "Boise City",
    "CBSA State": "ID",
    CBSA: "Boise City, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boise City-Mountain Home-Ontario, ID-OR",
    "County/County Equivalent": "Gem County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Gem County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "045",
    "County\nCode": 16045,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 14260,
    "Metropolitan Division Code": "",
    "CSA Code": 147,
    "CBSA Full Title": "Boise City, ID",
    "CBSA Solo": "Boise City",
    "CBSA State": "ID",
    CBSA: "Boise City, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boise City-Mountain Home-Ontario, ID-OR",
    "County/County Equivalent": "Owyhee County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Owyhee County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "073",
    "County\nCode": 16073,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 14300,
    "Metropolitan Division Code": "",
    "CSA Code": 206,
    "CBSA Full Title": "Bonham, TX",
    "CBSA Solo": "Bonham",
    "CBSA State": "TX",
    CBSA: "Bonham, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Fannin County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Fannin County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 147,
    "County\nCode": 48147,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Boone, NC",
    "CBSA Solo": "Boone",
    "CBSA State": "NC",
    CBSA: "Boone, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Watauga County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Watauga County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 189,
    "County\nCode": 37189,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14420,
    "Metropolitan Division Code": "",
    "CSA Code": 108,
    "CBSA Full Title": "Borger, TX",
    "CBSA Solo": "Borger",
    "CBSA State": "TX",
    CBSA: "Borger, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Amarillo-Pampa-Borger, TX",
    "County/County Equivalent": "Hutchinson County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Hutchinson County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 233,
    "County\nCode": 48233,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14460,
    "Metropolitan Division Code": 14454,
    "CSA Code": 148,
    "CBSA Full Title": "Boston-Cambridge-Newton, MA-NH",
    "CBSA Solo": "Boston",
    "CBSA State": "MA",
    CBSA: "Boston, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Boston, MA",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Norfolk County",
    "State Name": "Massachusetts",
    "State Abbr": "MA",
    "County, State": "Norfolk County, MA",
    "FIPS State Code": 25,
    "FIPS County Code": "021",
    "County\nCode": 25021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14460,
    "Metropolitan Division Code": 14454,
    "CSA Code": 148,
    "CBSA Full Title": "Boston-Cambridge-Newton, MA-NH",
    "CBSA Solo": "Boston",
    "CBSA State": "MA",
    CBSA: "Boston, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Boston, MA",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Plymouth County",
    "State Name": "Massachusetts",
    "State Abbr": "MA",
    "County, State": "Plymouth County, MA",
    "FIPS State Code": 25,
    "FIPS County Code": "023",
    "County\nCode": 25023,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14460,
    "Metropolitan Division Code": 14454,
    "CSA Code": 148,
    "CBSA Full Title": "Boston-Cambridge-Newton, MA-NH",
    "CBSA Solo": "Boston",
    "CBSA State": "MA",
    CBSA: "Boston, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Boston, MA",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Suffolk County",
    "State Name": "Massachusetts",
    "State Abbr": "MA",
    "County, State": "Suffolk County, MA",
    "FIPS State Code": 25,
    "FIPS County Code": "025",
    "County\nCode": 25025,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14460,
    "Metropolitan Division Code": 15764,
    "CSA Code": 148,
    "CBSA Full Title": "Boston-Cambridge-Newton, MA-NH",
    "CBSA Solo": "Boston",
    "CBSA State": "MA",
    CBSA: "Boston, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Cambridge-Newton-Framingham, MA",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Essex County",
    "State Name": "Massachusetts",
    "State Abbr": "MA",
    "County, State": "Essex County, MA",
    "FIPS State Code": 25,
    "FIPS County Code": "009",
    "County\nCode": 25009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14460,
    "Metropolitan Division Code": 15764,
    "CSA Code": 148,
    "CBSA Full Title": "Boston-Cambridge-Newton, MA-NH",
    "CBSA Solo": "Boston",
    "CBSA State": "MA",
    CBSA: "Boston, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Cambridge-Newton-Framingham, MA",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Middlesex County",
    "State Name": "Massachusetts",
    "State Abbr": "MA",
    "County, State": "Middlesex County, MA",
    "FIPS State Code": 25,
    "FIPS County Code": "017",
    "County\nCode": 25017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14460,
    "Metropolitan Division Code": 40484,
    "CSA Code": 148,
    "CBSA Full Title": "Boston-Cambridge-Newton, MA-NH",
    "CBSA Solo": "Boston",
    "CBSA State": "MA",
    CBSA: "Boston, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Rockingham County-Strafford County, NH",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Rockingham County",
    "State Name": "New Hampshire",
    "State Abbr": "NH",
    "County, State": "Rockingham County, NH",
    "FIPS State Code": 33,
    "FIPS County Code": "015",
    "County\nCode": 33015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14460,
    "Metropolitan Division Code": 40484,
    "CSA Code": 148,
    "CBSA Full Title": "Boston-Cambridge-Newton, MA-NH",
    "CBSA Solo": "Boston",
    "CBSA State": "MA",
    CBSA: "Boston, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Rockingham County-Strafford County, NH",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Strafford County",
    "State Name": "New Hampshire",
    "State Abbr": "NH",
    "County, State": "Strafford County, NH",
    "FIPS State Code": 33,
    "FIPS County Code": "017",
    "County\nCode": 33017,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 14500,
    "Metropolitan Division Code": "",
    "CSA Code": 216,
    "CBSA Full Title": "Boulder, CO",
    "CBSA Solo": "Boulder",
    "CBSA State": "CO",
    CBSA: "Boulder, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Denver-Aurora, CO",
    "County/County Equivalent": "Boulder County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Boulder County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "013",
    "County\nCode": "08013",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14540,
    "Metropolitan Division Code": "",
    "CSA Code": 150,
    "CBSA Full Title": "Bowling Green, KY",
    "CBSA Solo": "Bowling Green",
    "CBSA State": "KY",
    CBSA: "Bowling Green, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bowling Green-Glasgow, KY",
    "County/County Equivalent": "Allen County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Allen County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "003",
    "County\nCode": 21003,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 14540,
    "Metropolitan Division Code": "",
    "CSA Code": 150,
    "CBSA Full Title": "Bowling Green, KY",
    "CBSA Solo": "Bowling Green",
    "CBSA State": "KY",
    CBSA: "Bowling Green, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bowling Green-Glasgow, KY",
    "County/County Equivalent": "Butler County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Butler County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "031",
    "County\nCode": 21031,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 14540,
    "Metropolitan Division Code": "",
    "CSA Code": 150,
    "CBSA Full Title": "Bowling Green, KY",
    "CBSA Solo": "Bowling Green",
    "CBSA State": "KY",
    CBSA: "Bowling Green, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bowling Green-Glasgow, KY",
    "County/County Equivalent": "Edmonson County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Edmonson County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "061",
    "County\nCode": 21061,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 14540,
    "Metropolitan Division Code": "",
    "CSA Code": 150,
    "CBSA Full Title": "Bowling Green, KY",
    "CBSA Solo": "Bowling Green",
    "CBSA State": "KY",
    CBSA: "Bowling Green, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bowling Green-Glasgow, KY",
    "County/County Equivalent": "Warren County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Warren County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 227,
    "County\nCode": 21227,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bozeman, MT",
    "CBSA Solo": "Bozeman",
    "CBSA State": "MT",
    CBSA: "Bozeman, MT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Gallatin County",
    "State Name": "Montana",
    "State Abbr": "MT",
    "County, State": "Gallatin County, MT",
    "FIPS State Code": 30,
    "FIPS County Code": "031",
    "County\nCode": 30031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Bradford, PA",
    "CBSA Solo": "Bradford",
    "CBSA State": "PA",
    CBSA: "Bradford, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "McKean County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "McKean County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "083",
    "County\nCode": 42083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Brainerd, MN",
    "CBSA Solo": "Brainerd",
    "CBSA State": "MN",
    CBSA: "Brainerd, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cass County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Cass County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "021",
    "County\nCode": 27021,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 14660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Brainerd, MN",
    "CBSA Solo": "Brainerd",
    "CBSA State": "MN",
    CBSA: "Brainerd, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Crow Wing County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Crow Wing County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "035",
    "County\nCode": 27035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Branson, MO",
    "CBSA Solo": "Branson",
    "CBSA State": "MO",
    CBSA: "Branson, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Taney County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Taney County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 213,
    "County\nCode": 29213,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14720,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Breckenridge, CO",
    "CBSA Solo": "Breckenridge",
    "CBSA State": "CO",
    CBSA: "Breckenridge, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Summit County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Summit County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": 117,
    "County\nCode": "08117",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14740,
    "Metropolitan Division Code": "",
    "CSA Code": 500,
    "CBSA Full Title": "Bremerton-Silverdale-Port Orchard, WA",
    "CBSA Solo": "Bremerton",
    "CBSA State": "WA",
    CBSA: "Bremerton, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Seattle-Tacoma, WA",
    "County/County Equivalent": "Kitsap County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Kitsap County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "035",
    "County\nCode": 53035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14780,
    "Metropolitan Division Code": "",
    "CSA Code": 288,
    "CBSA Full Title": "Brenham, TX",
    "CBSA Solo": "Brenham",
    "CBSA State": "TX",
    CBSA: "Brenham, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Houston-The Woodlands, TX",
    "County/County Equivalent": "Washington County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Washington County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 477,
    "County\nCode": 48477,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14820,
    "Metropolitan Division Code": "",
    "CSA Code": 120,
    "CBSA Full Title": "Brevard, NC",
    "CBSA Solo": "Brevard",
    "CBSA State": "NC",
    CBSA: "Brevard, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Asheville-Marion-Brevard, NC",
    "County/County Equivalent": "Transylvania County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Transylvania County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 175,
    "County\nCode": 37175,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 14860,
    "Metropolitan Division Code": "",
    "CSA Code": 408,
    "CBSA Full Title": "Bridgeport-Stamford-Norwalk, CT",
    "CBSA Solo": "Bridgeport",
    "CBSA State": "CT",
    CBSA: "Bridgeport, CT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Fairfield County",
    "State Name": "Connecticut",
    "State Abbr": "CT",
    "County, State": "Fairfield County, CT",
    "FIPS State Code": "09",
    "FIPS County Code": "001",
    "County\nCode": "09001",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15020,
    "Metropolitan Division Code": "",
    "CSA Code": 298,
    "CBSA Full Title": "Brookhaven, MS",
    "CBSA Solo": "Brookhaven",
    "CBSA State": "MS",
    CBSA: "Brookhaven, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Vicksburg-Brookhaven, MS",
    "County/County Equivalent": "Lincoln County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Lincoln County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "085",
    "County\nCode": 28085,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Brookings, OR",
    "CBSA Solo": "Brookings",
    "CBSA State": "OR",
    CBSA: "Brookings, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Curry County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Curry County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "015",
    "County\nCode": 41015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Brookings, SD",
    "CBSA Solo": "Brookings",
    "CBSA State": "SD",
    CBSA: "Brookings, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Brookings County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Brookings County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "011",
    "County\nCode": 46011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15140,
    "Metropolitan Division Code": "",
    "CSA Code": 297,
    "CBSA Full Title": "Brownsville, TN",
    "CBSA Solo": "Brownsville",
    "CBSA State": "TN",
    CBSA: "Brownsville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Brownsville, TN",
    "County/County Equivalent": "Haywood County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Haywood County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "075",
    "County\nCode": 47075,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15180,
    "Metropolitan Division Code": "",
    "CSA Code": 154,
    "CBSA Full Title": "Brownsville-Harlingen, TX",
    "CBSA Solo": "Brownsville",
    "CBSA State": "TX",
    CBSA: "Brownsville, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Brownsville-Harlingen-Raymondville, TX",
    "County/County Equivalent": "Cameron County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Cameron County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "061",
    "County\nCode": 48061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Brownwood, TX",
    "CBSA Solo": "Brownwood",
    "CBSA State": "TX",
    CBSA: "Brownwood, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Brown County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Brown County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "049",
    "County\nCode": 48049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Brunswick, GA",
    "CBSA Solo": "Brunswick",
    "CBSA State": "GA",
    CBSA: "Brunswick, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Brantley County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Brantley County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "025",
    "County\nCode": 13025,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 15260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Brunswick, GA",
    "CBSA Solo": "Brunswick",
    "CBSA State": "GA",
    CBSA: "Brunswick, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Glynn County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Glynn County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 127,
    "County\nCode": 13127,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Brunswick, GA",
    "CBSA Solo": "Brunswick",
    "CBSA State": "GA",
    CBSA: "Brunswick, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "McIntosh County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "McIntosh County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 191,
    "County\nCode": 13191,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 15340,
    "Metropolitan Division Code": "",
    "CSA Code": 360,
    "CBSA Full Title": "Bucyrus-Galion, OH",
    "CBSA Solo": "Bucyrus",
    "CBSA State": "OH",
    CBSA: "Bucyrus, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mansfield-Ashland-Bucyrus, OH",
    "County/County Equivalent": "Crawford County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Crawford County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "033",
    "County\nCode": 39033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15380,
    "Metropolitan Division Code": "",
    "CSA Code": 160,
    "CBSA Full Title": "Buffalo-Cheektowaga, NY",
    "CBSA Solo": "Buffalo",
    "CBSA State": "NY",
    CBSA: "Buffalo, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Buffalo-Cheektowaga-Olean, NY",
    "County/County Equivalent": "Erie County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Erie County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "029",
    "County\nCode": 36029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15380,
    "Metropolitan Division Code": "",
    "CSA Code": 160,
    "CBSA Full Title": "Buffalo-Cheektowaga, NY",
    "CBSA Solo": "Buffalo",
    "CBSA State": "NY",
    CBSA: "Buffalo, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Buffalo-Cheektowaga-Olean, NY",
    "County/County Equivalent": "Niagara County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Niagara County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "063",
    "County\nCode": 36063,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Burley, ID",
    "CBSA Solo": "Burley",
    "CBSA State": "ID",
    CBSA: "Burley, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cassia County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Cassia County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "031",
    "County\nCode": 16031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Burley, ID",
    "CBSA Solo": "Burley",
    "CBSA State": "ID",
    CBSA: "Burley, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Minidoka County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Minidoka County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "067",
    "County\nCode": 16067,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 15460,
    "Metropolitan Division Code": "",
    "CSA Code": 161,
    "CBSA Full Title": "Burlington, IA-IL",
    "CBSA Solo": "Burlington",
    "CBSA State": "IA",
    CBSA: "Burlington, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Burlington-Fort Madison-Keokuk, IA-IL-MO",
    "County/County Equivalent": "Henderson County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Henderson County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "071",
    "County\nCode": 17071,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 15460,
    "Metropolitan Division Code": "",
    "CSA Code": 161,
    "CBSA Full Title": "Burlington, IA-IL",
    "CBSA Solo": "Burlington",
    "CBSA State": "IA",
    CBSA: "Burlington, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Burlington-Fort Madison-Keokuk, IA-IL-MO",
    "County/County Equivalent": "Des Moines County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Des Moines County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "057",
    "County\nCode": 19057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15500,
    "Metropolitan Division Code": "",
    "CSA Code": 268,
    "CBSA Full Title": "Burlington, NC",
    "CBSA Solo": "Burlington",
    "CBSA State": "NC",
    CBSA: "Burlington, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greensboro--Winston-Salem--High Point, NC",
    "County/County Equivalent": "Alamance County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Alamance County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "001",
    "County\nCode": 37001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15540,
    "Metropolitan Division Code": "",
    "CSA Code": 162,
    "CBSA Full Title": "Burlington-South Burlington, VT",
    "CBSA Solo": "Burlington",
    "CBSA State": "VT",
    CBSA: "Burlington, VT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Burlington-South Burlington-Barre, VT",
    "County/County Equivalent": "Chittenden County",
    "State Name": "Vermont",
    "State Abbr": "VT",
    "County, State": "Chittenden County, VT",
    "FIPS State Code": 50,
    "FIPS County Code": "007",
    "County\nCode": 50007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15540,
    "Metropolitan Division Code": "",
    "CSA Code": 162,
    "CBSA Full Title": "Burlington-South Burlington, VT",
    "CBSA Solo": "Burlington",
    "CBSA State": "VT",
    CBSA: "Burlington, VT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Burlington-South Burlington-Barre, VT",
    "County/County Equivalent": "Franklin County",
    "State Name": "Vermont",
    "State Abbr": "VT",
    "County, State": "Franklin County, VT",
    "FIPS State Code": 50,
    "FIPS County Code": "011",
    "County\nCode": 50011,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 15540,
    "Metropolitan Division Code": "",
    "CSA Code": 162,
    "CBSA Full Title": "Burlington-South Burlington, VT",
    "CBSA Solo": "Burlington",
    "CBSA State": "VT",
    CBSA: "Burlington, VT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Burlington-South Burlington-Barre, VT",
    "County/County Equivalent": "Grand Isle County",
    "State Name": "Vermont",
    "State Abbr": "VT",
    "County, State": "Grand Isle County, VT",
    "FIPS State Code": 50,
    "FIPS County Code": "013",
    "County\nCode": 50013,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 15580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Butte-Silver Bow, MT",
    "CBSA Solo": "Butte",
    "CBSA State": "MT",
    CBSA: "Butte, MT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Silver Bow County",
    "State Name": "Montana",
    "State Abbr": "MT",
    "County, State": "Silver Bow County, MT",
    "FIPS State Code": 30,
    "FIPS County Code": "093",
    "County\nCode": 30093,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Cadillac, MI",
    "CBSA Solo": "Cadillac",
    "CBSA State": "MI",
    CBSA: "Cadillac, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Missaukee County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Missaukee County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 113,
    "County\nCode": 26113,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 15620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Cadillac, MI",
    "CBSA Solo": "Cadillac",
    "CBSA State": "MI",
    CBSA: "Cadillac, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Wexford County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Wexford County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 165,
    "County\nCode": 26165,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15660,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Calhoun, GA",
    "CBSA Solo": "Calhoun",
    "CBSA State": "GA",
    CBSA: "Calhoun, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "Gordon County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Gordon County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 129,
    "County\nCode": 13129,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15680,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "California-Lexington Park, MD",
    "CBSA Solo": "California",
    "CBSA State": "MD",
    CBSA: "California, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "St. Mary's County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "St. Mary's County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "037",
    "County\nCode": 24037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15700,
    "Metropolitan Division Code": "",
    "CSA Code": 480,
    "CBSA Full Title": "Cambridge, MD",
    "CBSA Solo": "Cambridge",
    "CBSA State": "MD",
    CBSA: "Cambridge, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salisbury-Cambridge, MD-DE",
    "County/County Equivalent": "Dorchester County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Dorchester County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "019",
    "County\nCode": 24019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15740,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Cambridge, OH",
    "CBSA Solo": "Cambridge",
    "CBSA State": "OH",
    CBSA: "Cambridge, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Guernsey County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Guernsey County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "059",
    "County\nCode": 39059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Camden, AR",
    "CBSA Solo": "Camden",
    "CBSA State": "AR",
    CBSA: "Camden, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Calhoun County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Calhoun County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "013",
    "County\nCode": "05013",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 15780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Camden, AR",
    "CBSA Solo": "Camden",
    "CBSA State": "AR",
    CBSA: "Camden, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ouachita County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Ouachita County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 103,
    "County\nCode": "05103",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Campbellsville, KY",
    "CBSA Solo": "Campbellsville",
    "CBSA State": "KY",
    CBSA: "Campbellsville, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Green County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Green County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "087",
    "County\nCode": 21087,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 15820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Campbellsville, KY",
    "CBSA Solo": "Campbellsville",
    "CBSA State": "KY",
    CBSA: "Campbellsville, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Taylor County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Taylor County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 217,
    "County\nCode": 21217,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15860,
    "Metropolitan Division Code": "",
    "CSA Code": 444,
    "CBSA Full Title": "Cañon City, CO",
    "CBSA Solo": "Cañon City",
    "CBSA State": "CO",
    CBSA: "Cañon City, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pueblo-Cañon City, CO",
    "County/County Equivalent": "Fremont County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Fremont County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "043",
    "County\nCode": "08043",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15940,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "Canton-Massillon, OH",
    "CBSA Solo": "Canton",
    "CBSA State": "OH",
    CBSA: "Canton, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Carroll County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Carroll County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "019",
    "County\nCode": 39019,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 15940,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "Canton-Massillon, OH",
    "CBSA Solo": "Canton",
    "CBSA State": "OH",
    CBSA: "Canton, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Stark County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Stark County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 151,
    "County\nCode": 39151,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 15980,
    "Metropolitan Division Code": "",
    "CSA Code": 163,
    "CBSA Full Title": "Cape Coral-Fort Myers, FL",
    "CBSA Solo": "Cape Coral",
    "CBSA State": "FL",
    CBSA: "Cape Coral, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cape Coral-Fort Myers-Naples, FL",
    "County/County Equivalent": "Lee County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Lee County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "071",
    "County\nCode": 12071,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16020,
    "Metropolitan Division Code": "",
    "CSA Code": 164,
    "CBSA Full Title": "Cape Girardeau, MO-IL",
    "CBSA Solo": "Cape Girardeau",
    "CBSA State": "MO",
    CBSA: "Cape Girardeau, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cape Girardeau-Sikeston, MO-IL",
    "County/County Equivalent": "Bollinger County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Bollinger County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "017",
    "County\nCode": 29017,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16020,
    "Metropolitan Division Code": "",
    "CSA Code": 164,
    "CBSA Full Title": "Cape Girardeau, MO-IL",
    "CBSA Solo": "Cape Girardeau",
    "CBSA State": "MO",
    CBSA: "Cape Girardeau, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cape Girardeau-Sikeston, MO-IL",
    "County/County Equivalent": "Alexander County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Alexander County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "003",
    "County\nCode": 17003,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16020,
    "Metropolitan Division Code": "",
    "CSA Code": 164,
    "CBSA Full Title": "Cape Girardeau, MO-IL",
    "CBSA Solo": "Cape Girardeau",
    "CBSA State": "MO",
    CBSA: "Cape Girardeau, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cape Girardeau-Sikeston, MO-IL",
    "County/County Equivalent": "Cape Girardeau County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Cape Girardeau County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "031",
    "County\nCode": 29031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Carbondale-Marion, IL",
    "CBSA Solo": "Carbondale",
    "CBSA State": "IL",
    CBSA: "Carbondale, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jackson County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Jackson County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "077",
    "County\nCode": 17077,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Carbondale-Marion, IL",
    "CBSA Solo": "Carbondale",
    "CBSA State": "IL",
    CBSA: "Carbondale, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Johnson County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Johnson County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "087",
    "County\nCode": 17087,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Carbondale-Marion, IL",
    "CBSA Solo": "Carbondale",
    "CBSA State": "IL",
    CBSA: "Carbondale, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Williamson County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Williamson County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 199,
    "County\nCode": 17199,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Carlsbad-Artesia, NM",
    "CBSA Solo": "Carlsbad",
    "CBSA State": "NM",
    CBSA: "Carlsbad, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Eddy County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Eddy County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "015",
    "County\nCode": 35015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Carroll, IA",
    "CBSA Solo": "Carroll",
    "CBSA State": "IA",
    CBSA: "Carroll, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Carroll County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Carroll County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "027",
    "County\nCode": 19027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16180,
    "Metropolitan Division Code": "",
    "CSA Code": 456,
    "CBSA Full Title": "Carson City, NV",
    "CBSA Solo": "Carson City",
    "CBSA State": "NV",
    CBSA: "Carson City, NV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Reno-Carson City-Fernley, NV",
    "County/County Equivalent": "Carson City",
    "State Name": "Nevada",
    "State Abbr": "NV",
    "County, State": "Carson City, NV",
    "FIPS State Code": 32,
    "FIPS County Code": 510,
    "County\nCode": 32510,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Casper, WY",
    "CBSA Solo": "Casper",
    "CBSA State": "WY",
    CBSA: "Casper, WY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Natrona County",
    "State Name": "Wyoming",
    "State Abbr": "WY",
    "County, State": "Natrona County, WY",
    "FIPS State Code": 56,
    "FIPS County Code": "025",
    "County\nCode": 56025,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Cedar City, UT",
    "CBSA Solo": "Cedar City",
    "CBSA State": "UT",
    CBSA: "Cedar City, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Iron County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Iron County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "021",
    "County\nCode": 49021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16300,
    "Metropolitan Division Code": "",
    "CSA Code": 168,
    "CBSA Full Title": "Cedar Rapids, IA",
    "CBSA Solo": "Cedar Rapids",
    "CBSA State": "IA",
    CBSA: "Cedar Rapids, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cedar Rapids-Iowa City, IA",
    "County/County Equivalent": "Benton County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Benton County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "011",
    "County\nCode": 19011,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16300,
    "Metropolitan Division Code": "",
    "CSA Code": 168,
    "CBSA Full Title": "Cedar Rapids, IA",
    "CBSA Solo": "Cedar Rapids",
    "CBSA State": "IA",
    CBSA: "Cedar Rapids, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cedar Rapids-Iowa City, IA",
    "County/County Equivalent": "Jones County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Jones County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 105,
    "County\nCode": 19105,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16300,
    "Metropolitan Division Code": "",
    "CSA Code": 168,
    "CBSA Full Title": "Cedar Rapids, IA",
    "CBSA Solo": "Cedar Rapids",
    "CBSA State": "IA",
    CBSA: "Cedar Rapids, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cedar Rapids-Iowa City, IA",
    "County/County Equivalent": "Linn County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Linn County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 113,
    "County\nCode": 19113,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16340,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Cedartown, GA",
    "CBSA Solo": "Cedartown",
    "CBSA State": "GA",
    CBSA: "Cedartown, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Polk County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Polk County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 233,
    "County\nCode": 13233,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16380,
    "Metropolitan Division Code": "",
    "CSA Code": 338,
    "CBSA Full Title": "Celina, OH",
    "CBSA Solo": "Celina",
    "CBSA State": "OH",
    CBSA: "Celina, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lima-Van Wert-Celina, OH",
    "County/County Equivalent": "Mercer County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Mercer County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 107,
    "County\nCode": 39107,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Central City, KY",
    "CBSA Solo": "Central City",
    "CBSA State": "KY",
    CBSA: "Central City, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Muhlenberg County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Muhlenberg County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 177,
    "County\nCode": 21177,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16460,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "Centralia, IL",
    "CBSA Solo": "Centralia",
    "CBSA State": "IL",
    CBSA: "Centralia, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "Marion County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Marion County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 121,
    "County\nCode": 17121,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16500,
    "Metropolitan Division Code": "",
    "CSA Code": 500,
    "CBSA Full Title": "Centralia, WA",
    "CBSA Solo": "Centralia",
    "CBSA State": "WA",
    CBSA: "Centralia, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Seattle-Tacoma, WA",
    "County/County Equivalent": "Lewis County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Lewis County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "041",
    "County\nCode": 53041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16540,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Chambersburg-Waynesboro, PA",
    "CBSA Solo": "Chambersburg",
    "CBSA State": "PA",
    CBSA: "Chambersburg, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Franklin County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Franklin County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "055",
    "County\nCode": 42055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Champaign-Urbana, IL",
    "CBSA Solo": "Champaign",
    "CBSA State": "IL",
    CBSA: "Champaign, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Champaign County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Champaign County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "019",
    "County\nCode": 17019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Champaign-Urbana, IL",
    "CBSA Solo": "Champaign",
    "CBSA State": "IL",
    CBSA: "Champaign, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Piatt County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Piatt County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 147,
    "County\nCode": 17147,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16620,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Charleston, WV",
    "CBSA Solo": "Charleston",
    "CBSA State": "WV",
    CBSA: "Charleston, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Boone County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Boone County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "005",
    "County\nCode": 54005,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16620,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Charleston, WV",
    "CBSA Solo": "Charleston",
    "CBSA State": "WV",
    CBSA: "Charleston, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Clay County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Clay County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "015",
    "County\nCode": 54015,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16620,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Charleston, WV",
    "CBSA Solo": "Charleston",
    "CBSA State": "WV",
    CBSA: "Charleston, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Jackson County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Jackson County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "035",
    "County\nCode": 54035,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16620,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Charleston, WV",
    "CBSA Solo": "Charleston",
    "CBSA State": "WV",
    CBSA: "Charleston, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Kanawha County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Kanawha County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "039",
    "County\nCode": 54039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16620,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Charleston, WV",
    "CBSA Solo": "Charleston",
    "CBSA State": "WV",
    CBSA: "Charleston, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Lincoln County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Lincoln County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "043",
    "County\nCode": 54043,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Charleston-Mattoon, IL",
    "CBSA Solo": "Charleston",
    "CBSA State": "IL",
    CBSA: "Charleston, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Coles County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Coles County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "029",
    "County\nCode": 17029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Charleston-Mattoon, IL",
    "CBSA Solo": "Charleston",
    "CBSA State": "IL",
    CBSA: "Charleston, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cumberland County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Cumberland County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "035",
    "County\nCode": 17035,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Charleston-North Charleston, SC",
    "CBSA Solo": "Charleston",
    "CBSA State": "SC",
    CBSA: "Charleston, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Berkeley County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Berkeley County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "015",
    "County\nCode": 45015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Charleston-North Charleston, SC",
    "CBSA Solo": "Charleston",
    "CBSA State": "SC",
    CBSA: "Charleston, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Charleston County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Charleston County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "019",
    "County\nCode": 45019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Charleston-North Charleston, SC",
    "CBSA Solo": "Charleston",
    "CBSA State": "SC",
    CBSA: "Charleston, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Dorchester County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Dorchester County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "035",
    "County\nCode": 45035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16740,
    "Metropolitan Division Code": "",
    "CSA Code": 172,
    "CBSA Full Title": "Charlotte-Concord-Gastonia, NC-SC",
    "CBSA Solo": "Charlotte",
    "CBSA State": "NC",
    CBSA: "Charlotte, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charlotte-Concord, NC-SC",
    "County/County Equivalent": "Anson County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Anson County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "007",
    "County\nCode": 37007,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16740,
    "Metropolitan Division Code": "",
    "CSA Code": 172,
    "CBSA Full Title": "Charlotte-Concord-Gastonia, NC-SC",
    "CBSA Solo": "Charlotte",
    "CBSA State": "NC",
    CBSA: "Charlotte, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charlotte-Concord, NC-SC",
    "County/County Equivalent": "Cabarrus County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Cabarrus County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "025",
    "County\nCode": 37025,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16740,
    "Metropolitan Division Code": "",
    "CSA Code": 172,
    "CBSA Full Title": "Charlotte-Concord-Gastonia, NC-SC",
    "CBSA Solo": "Charlotte",
    "CBSA State": "NC",
    CBSA: "Charlotte, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charlotte-Concord, NC-SC",
    "County/County Equivalent": "Gaston County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Gaston County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "071",
    "County\nCode": 37071,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16740,
    "Metropolitan Division Code": "",
    "CSA Code": 172,
    "CBSA Full Title": "Charlotte-Concord-Gastonia, NC-SC",
    "CBSA Solo": "Charlotte",
    "CBSA State": "NC",
    CBSA: "Charlotte, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charlotte-Concord, NC-SC",
    "County/County Equivalent": "Iredell County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Iredell County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "097",
    "County\nCode": 37097,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16740,
    "Metropolitan Division Code": "",
    "CSA Code": 172,
    "CBSA Full Title": "Charlotte-Concord-Gastonia, NC-SC",
    "CBSA Solo": "Charlotte",
    "CBSA State": "NC",
    CBSA: "Charlotte, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charlotte-Concord, NC-SC",
    "County/County Equivalent": "Lincoln County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Lincoln County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 109,
    "County\nCode": 37109,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16740,
    "Metropolitan Division Code": "",
    "CSA Code": 172,
    "CBSA Full Title": "Charlotte-Concord-Gastonia, NC-SC",
    "CBSA Solo": "Charlotte",
    "CBSA State": "NC",
    CBSA: "Charlotte, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charlotte-Concord, NC-SC",
    "County/County Equivalent": "Mecklenburg County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Mecklenburg County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 119,
    "County\nCode": 37119,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16740,
    "Metropolitan Division Code": "",
    "CSA Code": 172,
    "CBSA Full Title": "Charlotte-Concord-Gastonia, NC-SC",
    "CBSA Solo": "Charlotte",
    "CBSA State": "NC",
    CBSA: "Charlotte, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charlotte-Concord, NC-SC",
    "County/County Equivalent": "Rowan County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Rowan County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 159,
    "County\nCode": 37159,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16740,
    "Metropolitan Division Code": "",
    "CSA Code": 172,
    "CBSA Full Title": "Charlotte-Concord-Gastonia, NC-SC",
    "CBSA Solo": "Charlotte",
    "CBSA State": "NC",
    CBSA: "Charlotte, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charlotte-Concord, NC-SC",
    "County/County Equivalent": "Union County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Union County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 179,
    "County\nCode": 37179,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16740,
    "Metropolitan Division Code": "",
    "CSA Code": 172,
    "CBSA Full Title": "Charlotte-Concord-Gastonia, NC-SC",
    "CBSA Solo": "Charlotte",
    "CBSA State": "NC",
    CBSA: "Charlotte, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charlotte-Concord, NC-SC",
    "County/County Equivalent": "Chester County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Chester County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "023",
    "County\nCode": 45023,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16740,
    "Metropolitan Division Code": "",
    "CSA Code": 172,
    "CBSA Full Title": "Charlotte-Concord-Gastonia, NC-SC",
    "CBSA Solo": "Charlotte",
    "CBSA State": "NC",
    CBSA: "Charlotte, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charlotte-Concord, NC-SC",
    "County/County Equivalent": "Lancaster County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Lancaster County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "057",
    "County\nCode": 45057,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16740,
    "Metropolitan Division Code": "",
    "CSA Code": 172,
    "CBSA Full Title": "Charlotte-Concord-Gastonia, NC-SC",
    "CBSA Solo": "Charlotte",
    "CBSA State": "NC",
    CBSA: "Charlotte, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charlotte-Concord, NC-SC",
    "County/County Equivalent": "York County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "York County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "091",
    "County\nCode": 45091,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Charlottesville, VA",
    "CBSA Solo": "Charlottesville",
    "CBSA State": "VA",
    CBSA: "Charlottesville, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Albemarle County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Albemarle County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "003",
    "County\nCode": 51003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Charlottesville, VA",
    "CBSA Solo": "Charlottesville",
    "CBSA State": "VA",
    CBSA: "Charlottesville, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Fluvanna County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Fluvanna County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "065",
    "County\nCode": 51065,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Charlottesville, VA",
    "CBSA Solo": "Charlottesville",
    "CBSA State": "VA",
    CBSA: "Charlottesville, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Greene County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Greene County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "079",
    "County\nCode": 51079,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Charlottesville, VA",
    "CBSA Solo": "Charlottesville",
    "CBSA State": "VA",
    CBSA: "Charlottesville, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Nelson County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Nelson County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 125,
    "County\nCode": 51125,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Charlottesville, VA",
    "CBSA Solo": "Charlottesville",
    "CBSA State": "VA",
    CBSA: "Charlottesville, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Charlottesville city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Charlottesville city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 540,
    "County\nCode": 51540,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16860,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Chattanooga, TN-GA",
    "CBSA Solo": "Chattanooga",
    "CBSA State": "TN",
    CBSA: "Chattanooga, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "Hamilton County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Hamilton County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "065",
    "County\nCode": 47065,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16860,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Chattanooga, TN-GA",
    "CBSA Solo": "Chattanooga",
    "CBSA State": "TN",
    CBSA: "Chattanooga, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "Catoosa County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Catoosa County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "047",
    "County\nCode": 13047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16860,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Chattanooga, TN-GA",
    "CBSA Solo": "Chattanooga",
    "CBSA State": "TN",
    CBSA: "Chattanooga, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "Dade County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Dade County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "083",
    "County\nCode": 13083,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16860,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Chattanooga, TN-GA",
    "CBSA Solo": "Chattanooga",
    "CBSA State": "TN",
    CBSA: "Chattanooga, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "Walker County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Walker County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 295,
    "County\nCode": 13295,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16860,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Chattanooga, TN-GA",
    "CBSA Solo": "Chattanooga",
    "CBSA State": "TN",
    CBSA: "Chattanooga, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "Marion County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Marion County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 115,
    "County\nCode": 47115,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16860,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Chattanooga, TN-GA",
    "CBSA Solo": "Chattanooga",
    "CBSA State": "TN",
    CBSA: "Chattanooga, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "Sequatchie County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Sequatchie County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 153,
    "County\nCode": 47153,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Cheyenne, WY",
    "CBSA Solo": "Cheyenne",
    "CBSA State": "WY",
    CBSA: "Cheyenne, WY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Laramie County",
    "State Name": "Wyoming",
    "State Abbr": "WY",
    "County, State": "Laramie County, WY",
    "FIPS State Code": 56,
    "FIPS County Code": "021",
    "County\nCode": 56021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 16984,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Chicago-Naperville-Evanston, IL",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Cook County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Cook County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "031",
    "County\nCode": 17031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 16984,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Chicago-Naperville-Evanston, IL",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "DuPage County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "DuPage County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "043",
    "County\nCode": 17043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 16984,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Chicago-Naperville-Evanston, IL",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Grundy County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Grundy County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "063",
    "County\nCode": 17063,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 16984,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Chicago-Naperville-Evanston, IL",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "McHenry County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "McHenry County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 111,
    "County\nCode": 17111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 16984,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Chicago-Naperville-Evanston, IL",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Will County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Will County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 197,
    "County\nCode": 17197,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 20994,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Elgin, IL",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "DeKalb County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "DeKalb County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "037",
    "County\nCode": 17037,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 20994,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Elgin, IL",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Kane County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Kane County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "089",
    "County\nCode": 17089,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 20994,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Elgin, IL",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Kendall County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Kendall County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "093",
    "County\nCode": 17093,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 23844,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Gary, IN",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Jasper County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Jasper County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "073",
    "County\nCode": 18073,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 23844,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Gary, IN",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Lake County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Lake County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "089",
    "County\nCode": 18089,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 23844,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Gary, IN",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Newton County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Newton County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 111,
    "County\nCode": 18111,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 23844,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Gary, IN",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Porter County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Porter County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 127,
    "County\nCode": 18127,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 29404,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Lake County-Kenosha County, IL-WI",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Lake County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Lake County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "097",
    "County\nCode": 17097,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 16980,
    "Metropolitan Division Code": 29404,
    "CSA Code": 176,
    "CBSA Full Title": "Chicago-Naperville-Elgin, IL-IN-WI",
    "CBSA Solo": "Chicago",
    "CBSA State": "IL",
    CBSA: "Chicago, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Lake County-Kenosha County, IL-WI",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Kenosha County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Kenosha County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "059",
    "County\nCode": 55059,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17020,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Chico, CA",
    "CBSA Solo": "Chico",
    "CBSA State": "CA",
    CBSA: "Chico, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Butte County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Butte County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "007",
    "County\nCode": "06007",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17060,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Chillicothe, OH",
    "CBSA Solo": "Chillicothe",
    "CBSA State": "OH",
    CBSA: "Chillicothe, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Ross County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Ross County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 141,
    "County\nCode": 39141,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Hamilton County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Hamilton County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "061",
    "County\nCode": 39061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Dearborn County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Dearborn County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "029",
    "County\nCode": 18029,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Franklin County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Franklin County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "047",
    "County\nCode": 18047,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Ohio County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Ohio County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 115,
    "County\nCode": 18115,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Union County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Union County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 161,
    "County\nCode": 18161,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Boone County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Boone County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "015",
    "County\nCode": 21015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Bracken County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Bracken County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "023",
    "County\nCode": 21023,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Campbell County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Campbell County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "037",
    "County\nCode": 21037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Gallatin County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Gallatin County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "077",
    "County\nCode": 21077,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Grant County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Grant County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "081",
    "County\nCode": 21081,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Kenton County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Kenton County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 117,
    "County\nCode": 21117,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Pendleton County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Pendleton County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 191,
    "County\nCode": 21191,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Brown County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Brown County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "015",
    "County\nCode": 39015,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Butler County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Butler County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "017",
    "County\nCode": 39017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Clermont County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Clermont County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "025",
    "County\nCode": 39025,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17140,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Cincinnati, OH-KY-IN",
    "CBSA Solo": "Cincinnati",
    "CBSA State": "OH",
    CBSA: "Cincinnati, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Warren County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Warren County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 165,
    "County\nCode": 39165,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Clarksburg, WV",
    "CBSA Solo": "Clarksburg",
    "CBSA State": "WV",
    CBSA: "Clarksburg, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Doddridge County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Doddridge County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "017",
    "County\nCode": 54017,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Clarksburg, WV",
    "CBSA Solo": "Clarksburg",
    "CBSA State": "WV",
    CBSA: "Clarksburg, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Harrison County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Harrison County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "033",
    "County\nCode": 54033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Clarksburg, WV",
    "CBSA Solo": "Clarksburg",
    "CBSA State": "WV",
    CBSA: "Clarksburg, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Taylor County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Taylor County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "091",
    "County\nCode": 54091,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Clarksdale, MS",
    "CBSA Solo": "Clarksdale",
    "CBSA State": "MS",
    CBSA: "Clarksdale, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Coahoma County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Coahoma County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "027",
    "County\nCode": 28027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Clarksville, TN-KY",
    "CBSA Solo": "Clarksville",
    "CBSA State": "TN",
    CBSA: "Clarksville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Montgomery County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Montgomery County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 125,
    "County\nCode": 47125,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Clarksville, TN-KY",
    "CBSA Solo": "Clarksville",
    "CBSA State": "TN",
    CBSA: "Clarksville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Christian County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Christian County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "047",
    "County\nCode": 21047,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Clarksville, TN-KY",
    "CBSA Solo": "Clarksville",
    "CBSA State": "TN",
    CBSA: "Clarksville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Trigg County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Trigg County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 221,
    "County\nCode": 21221,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Clarksville, TN-KY",
    "CBSA Solo": "Clarksville",
    "CBSA State": "TN",
    CBSA: "Clarksville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Stewart County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Stewart County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 161,
    "County\nCode": 47161,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Clearlake, CA",
    "CBSA Solo": "Clearlake",
    "CBSA State": "CA",
    CBSA: "Clearlake, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lake County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Lake County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "033",
    "County\nCode": "06033",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17380,
    "Metropolitan Division Code": "",
    "CSA Code": 185,
    "CBSA Full Title": "Cleveland, MS",
    "CBSA Solo": "Cleveland",
    "CBSA State": "MS",
    CBSA: "Cleveland, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Indianola, MS",
    "County/County Equivalent": "Bolivar County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Bolivar County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "011",
    "County\nCode": 28011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17420,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Cleveland, TN",
    "CBSA Solo": "Cleveland",
    "CBSA State": "TN",
    CBSA: "Cleveland, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "Bradley County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Bradley County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "011",
    "County\nCode": 47011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17460,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "Cleveland-Elyria, OH",
    "CBSA Solo": "Cleveland",
    "CBSA State": "OH",
    CBSA: "Cleveland, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Cuyahoga County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Cuyahoga County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "035",
    "County\nCode": 39035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17460,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "Cleveland-Elyria, OH",
    "CBSA Solo": "Cleveland",
    "CBSA State": "OH",
    CBSA: "Cleveland, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Geauga County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Geauga County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "055",
    "County\nCode": 39055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17460,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "Cleveland-Elyria, OH",
    "CBSA Solo": "Cleveland",
    "CBSA State": "OH",
    CBSA: "Cleveland, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Lake County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Lake County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "085",
    "County\nCode": 39085,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17460,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "Cleveland-Elyria, OH",
    "CBSA Solo": "Cleveland",
    "CBSA State": "OH",
    CBSA: "Cleveland, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Lorain County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Lorain County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "093",
    "County\nCode": 39093,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17460,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "Cleveland-Elyria, OH",
    "CBSA Solo": "Cleveland",
    "CBSA State": "OH",
    CBSA: "Cleveland, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Medina County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Medina County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 103,
    "County\nCode": 39103,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17500,
    "Metropolitan Division Code": "",
    "CSA Code": 163,
    "CBSA Full Title": "Clewiston, FL",
    "CBSA Solo": "Clewiston",
    "CBSA State": "FL",
    CBSA: "Clewiston, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cape Coral-Fort Myers-Naples, FL",
    "County/County Equivalent": "Hendry County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Hendry County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "051",
    "County\nCode": 12051,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17540,
    "Metropolitan Division Code": "",
    "CSA Code": 209,
    "CBSA Full Title": "Clinton, IA",
    "CBSA Solo": "Clinton",
    "CBSA State": "IA",
    CBSA: "Clinton, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Davenport-Moline, IA-IL",
    "County/County Equivalent": "Clinton County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Clinton County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "045",
    "County\nCode": 19045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17580,
    "Metropolitan Division Code": "",
    "CSA Code": 188,
    "CBSA Full Title": "Clovis, NM",
    "CBSA Solo": "Clovis",
    "CBSA State": "NM",
    CBSA: "Clovis, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Clovis-Portales, NM",
    "County/County Equivalent": "Curry County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Curry County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "009",
    "County\nCode": 35009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17620,
    "Metropolitan Division Code": "",
    "CSA Code": 434,
    "CBSA Full Title": "Coamo, PR",
    "CBSA Solo": "Coamo",
    "CBSA State": "PR",
    CBSA: "Coamo, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Ponce-Yauco-Coamo, PR",
    "County/County Equivalent": "Coamo Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Coamo Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "043",
    "County\nCode": 72043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17640,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "Coco, PR",
    "CBSA Solo": "Coco",
    "CBSA State": "PR",
    CBSA: "Coco, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Salinas Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Salinas Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 123,
    "County\nCode": 72123,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17660,
    "Metropolitan Division Code": "",
    "CSA Code": 518,
    "CBSA Full Title": "Coeur d'Alene, ID",
    "CBSA Solo": "Coeur d'Alene",
    "CBSA State": "ID",
    CBSA: "Coeur d'Alene, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Spokane-Spokane Valley-Coeur d'Alene, WA-ID",
    "County/County Equivalent": "Kootenai County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Kootenai County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "055",
    "County\nCode": 16055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Coffeyville, KS",
    "CBSA Solo": "Coffeyville",
    "CBSA State": "KS",
    CBSA: "Coffeyville, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Montgomery County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Montgomery County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 125,
    "County\nCode": 20125,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17740,
    "Metropolitan Division Code": "",
    "CSA Code": 310,
    "CBSA Full Title": "Coldwater, MI",
    "CBSA Solo": "Coldwater",
    "CBSA State": "MI",
    CBSA: "Coldwater, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kalamazoo-Battle Creek-Portage, MI",
    "County/County Equivalent": "Branch County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Branch County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "023",
    "County\nCode": 26023,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "College Station-Bryan, TX",
    "CBSA Solo": "College Station",
    "CBSA State": "TX",
    CBSA: "College Station, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Brazos County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Brazos County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "041",
    "County\nCode": 48041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "College Station-Bryan, TX",
    "CBSA Solo": "College Station",
    "CBSA State": "TX",
    CBSA: "College Station, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Burleson County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Burleson County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "051",
    "County\nCode": 48051,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "College Station-Bryan, TX",
    "CBSA Solo": "College Station",
    "CBSA State": "TX",
    CBSA: "College Station, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Robertson County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Robertson County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 395,
    "County\nCode": 48395,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Colorado Springs, CO",
    "CBSA Solo": "Colorado Springs",
    "CBSA State": "CO",
    CBSA: "Colorado Springs, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "El Paso County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "El Paso County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "041",
    "County\nCode": "08041",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Colorado Springs, CO",
    "CBSA Solo": "Colorado Springs",
    "CBSA State": "CO",
    CBSA: "Colorado Springs, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Teller County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Teller County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": 119,
    "County\nCode": "08119",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17860,
    "Metropolitan Division Code": "",
    "CSA Code": 190,
    "CBSA Full Title": "Columbia, MO",
    "CBSA Solo": "Columbia",
    "CBSA State": "MO",
    CBSA: "Columbia, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbia-Moberly-Mexico, MO",
    "County/County Equivalent": "Boone County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Boone County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "019",
    "County\nCode": 29019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17860,
    "Metropolitan Division Code": "",
    "CSA Code": 190,
    "CBSA Full Title": "Columbia, MO",
    "CBSA Solo": "Columbia",
    "CBSA State": "MO",
    CBSA: "Columbia, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbia-Moberly-Mexico, MO",
    "County/County Equivalent": "Cooper County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Cooper County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "053",
    "County\nCode": 29053,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17860,
    "Metropolitan Division Code": "",
    "CSA Code": 190,
    "CBSA Full Title": "Columbia, MO",
    "CBSA Solo": "Columbia",
    "CBSA State": "MO",
    CBSA: "Columbia, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbia-Moberly-Mexico, MO",
    "County/County Equivalent": "Howard County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Howard County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "089",
    "County\nCode": 29089,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17900,
    "Metropolitan Division Code": "",
    "CSA Code": 192,
    "CBSA Full Title": "Columbia, SC",
    "CBSA Solo": "Columbia",
    "CBSA State": "SC",
    CBSA: "Columbia, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbia-Orangeburg-Newberry, SC",
    "County/County Equivalent": "Calhoun County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Calhoun County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "017",
    "County\nCode": 45017,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17900,
    "Metropolitan Division Code": "",
    "CSA Code": 192,
    "CBSA Full Title": "Columbia, SC",
    "CBSA Solo": "Columbia",
    "CBSA State": "SC",
    CBSA: "Columbia, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbia-Orangeburg-Newberry, SC",
    "County/County Equivalent": "Fairfield County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Fairfield County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "039",
    "County\nCode": 45039,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17900,
    "Metropolitan Division Code": "",
    "CSA Code": 192,
    "CBSA Full Title": "Columbia, SC",
    "CBSA Solo": "Columbia",
    "CBSA State": "SC",
    CBSA: "Columbia, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbia-Orangeburg-Newberry, SC",
    "County/County Equivalent": "Kershaw County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Kershaw County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "055",
    "County\nCode": 45055,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17900,
    "Metropolitan Division Code": "",
    "CSA Code": 192,
    "CBSA Full Title": "Columbia, SC",
    "CBSA Solo": "Columbia",
    "CBSA State": "SC",
    CBSA: "Columbia, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbia-Orangeburg-Newberry, SC",
    "County/County Equivalent": "Lexington County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Lexington County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "063",
    "County\nCode": 45063,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17900,
    "Metropolitan Division Code": "",
    "CSA Code": 192,
    "CBSA Full Title": "Columbia, SC",
    "CBSA Solo": "Columbia",
    "CBSA State": "SC",
    CBSA: "Columbia, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbia-Orangeburg-Newberry, SC",
    "County/County Equivalent": "Richland County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Richland County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "079",
    "County\nCode": 45079,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17900,
    "Metropolitan Division Code": "",
    "CSA Code": 192,
    "CBSA Full Title": "Columbia, SC",
    "CBSA Solo": "Columbia",
    "CBSA State": "SC",
    CBSA: "Columbia, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbia-Orangeburg-Newberry, SC",
    "County/County Equivalent": "Saluda County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Saluda County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "081",
    "County\nCode": 45081,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17980,
    "Metropolitan Division Code": "",
    "CSA Code": 194,
    "CBSA Full Title": "Columbus, GA-AL",
    "CBSA Solo": "Columbus",
    "CBSA State": "GA",
    CBSA: "Columbus, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Auburn-Opelika, GA-AL",
    "County/County Equivalent": "Chattahoochee County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Chattahoochee County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "053",
    "County\nCode": 13053,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17980,
    "Metropolitan Division Code": "",
    "CSA Code": 194,
    "CBSA Full Title": "Columbus, GA-AL",
    "CBSA Solo": "Columbus",
    "CBSA State": "GA",
    CBSA: "Columbus, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Auburn-Opelika, GA-AL",
    "County/County Equivalent": "Russell County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Russell County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": 113,
    "County\nCode": "01113",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17980,
    "Metropolitan Division Code": "",
    "CSA Code": 194,
    "CBSA Full Title": "Columbus, GA-AL",
    "CBSA Solo": "Columbus",
    "CBSA State": "GA",
    CBSA: "Columbus, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Auburn-Opelika, GA-AL",
    "County/County Equivalent": "Harris County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Harris County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 145,
    "County\nCode": 13145,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17980,
    "Metropolitan Division Code": "",
    "CSA Code": 194,
    "CBSA Full Title": "Columbus, GA-AL",
    "CBSA Solo": "Columbus",
    "CBSA State": "GA",
    CBSA: "Columbus, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Auburn-Opelika, GA-AL",
    "County/County Equivalent": "Marion County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Marion County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 197,
    "County\nCode": 13197,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17980,
    "Metropolitan Division Code": "",
    "CSA Code": 194,
    "CBSA Full Title": "Columbus, GA-AL",
    "CBSA Solo": "Columbus",
    "CBSA State": "GA",
    CBSA: "Columbus, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Auburn-Opelika, GA-AL",
    "County/County Equivalent": "Muscogee County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Muscogee County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 215,
    "County\nCode": 13215,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 17980,
    "Metropolitan Division Code": "",
    "CSA Code": 194,
    "CBSA Full Title": "Columbus, GA-AL",
    "CBSA Solo": "Columbus",
    "CBSA State": "GA",
    CBSA: "Columbus, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Auburn-Opelika, GA-AL",
    "County/County Equivalent": "Stewart County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Stewart County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 259,
    "County\nCode": 13259,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 17980,
    "Metropolitan Division Code": "",
    "CSA Code": 194,
    "CBSA Full Title": "Columbus, GA-AL",
    "CBSA Solo": "Columbus",
    "CBSA State": "GA",
    CBSA: "Columbus, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Auburn-Opelika, GA-AL",
    "County/County Equivalent": "Talbot County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Talbot County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 263,
    "County\nCode": 13263,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 18020,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Columbus, IN",
    "CBSA Solo": "Columbus",
    "CBSA State": "IN",
    CBSA: "Columbus, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Bartholomew County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Bartholomew County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "005",
    "County\nCode": 18005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18060,
    "Metropolitan Division Code": "",
    "CSA Code": 200,
    "CBSA Full Title": "Columbus, MS",
    "CBSA Solo": "Columbus",
    "CBSA State": "MS",
    CBSA: "Columbus, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-West Point, MS",
    "County/County Equivalent": "Lowndes County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Lowndes County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "087",
    "County\nCode": 28087,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Columbus, NE",
    "CBSA Solo": "Columbus",
    "CBSA State": "NE",
    CBSA: "Columbus, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Platte County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Platte County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 141,
    "County\nCode": 31141,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18140,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Columbus, OH",
    "CBSA Solo": "Columbus",
    "CBSA State": "OH",
    CBSA: "Columbus, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Delaware County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Delaware County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "041",
    "County\nCode": 39041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18140,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Columbus, OH",
    "CBSA Solo": "Columbus",
    "CBSA State": "OH",
    CBSA: "Columbus, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Fairfield County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Fairfield County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "045",
    "County\nCode": 39045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18140,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Columbus, OH",
    "CBSA Solo": "Columbus",
    "CBSA State": "OH",
    CBSA: "Columbus, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Franklin County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Franklin County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "049",
    "County\nCode": 39049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18140,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Columbus, OH",
    "CBSA Solo": "Columbus",
    "CBSA State": "OH",
    CBSA: "Columbus, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Hocking County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Hocking County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "073",
    "County\nCode": 39073,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 18140,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Columbus, OH",
    "CBSA Solo": "Columbus",
    "CBSA State": "OH",
    CBSA: "Columbus, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Licking County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Licking County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "089",
    "County\nCode": 39089,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 18140,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Columbus, OH",
    "CBSA Solo": "Columbus",
    "CBSA State": "OH",
    CBSA: "Columbus, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Madison County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Madison County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "097",
    "County\nCode": 39097,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 18140,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Columbus, OH",
    "CBSA Solo": "Columbus",
    "CBSA State": "OH",
    CBSA: "Columbus, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Morrow County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Morrow County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 117,
    "County\nCode": 39117,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 18140,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Columbus, OH",
    "CBSA Solo": "Columbus",
    "CBSA State": "OH",
    CBSA: "Columbus, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Perry County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Perry County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 127,
    "County\nCode": 39127,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 18140,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Columbus, OH",
    "CBSA Solo": "Columbus",
    "CBSA State": "OH",
    CBSA: "Columbus, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Pickaway County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Pickaway County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 129,
    "County\nCode": 39129,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 18140,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Columbus, OH",
    "CBSA Solo": "Columbus",
    "CBSA State": "OH",
    CBSA: "Columbus, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Union County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Union County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 159,
    "County\nCode": 39159,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 18180,
    "Metropolitan Division Code": "",
    "CSA Code": 148,
    "CBSA Full Title": "Concord, NH",
    "CBSA Solo": "Concord",
    "CBSA State": "NH",
    CBSA: "Concord, NH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Merrimack County",
    "State Name": "New Hampshire",
    "State Abbr": "NH",
    "County, State": "Merrimack County, NH",
    "FIPS State Code": 33,
    "FIPS County Code": "013",
    "County\nCode": 33013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18220,
    "Metropolitan Division Code": "",
    "CSA Code": 458,
    "CBSA Full Title": "Connersville, IN",
    "CBSA Solo": "Connersville",
    "CBSA State": "IN",
    CBSA: "Connersville, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Richmond-Connersville, IN",
    "County/County Equivalent": "Fayette County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Fayette County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "041",
    "County\nCode": 18041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Cookeville, TN",
    "CBSA Solo": "Cookeville",
    "CBSA State": "TN",
    CBSA: "Cookeville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jackson County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Jackson County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "087",
    "County\nCode": 47087,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 18260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Cookeville, TN",
    "CBSA Solo": "Cookeville",
    "CBSA State": "TN",
    CBSA: "Cookeville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Overton County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Overton County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 133,
    "County\nCode": 47133,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 18260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Cookeville, TN",
    "CBSA Solo": "Cookeville",
    "CBSA State": "TN",
    CBSA: "Cookeville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Putnam County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Putnam County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 141,
    "County\nCode": 47141,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Coos Bay, OR",
    "CBSA Solo": "Coos Bay",
    "CBSA State": "OR",
    CBSA: "Coos Bay, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Coos County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Coos County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "011",
    "County\nCode": 41011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Cordele, GA",
    "CBSA Solo": "Cordele",
    "CBSA State": "GA",
    CBSA: "Cordele, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Crisp County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Crisp County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "081",
    "County\nCode": 13081,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18420,
    "Metropolitan Division Code": "",
    "CSA Code": 539,
    "CBSA Full Title": "Corinth, MS",
    "CBSA Solo": "Corinth",
    "CBSA State": "MS",
    CBSA: "Corinth, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tupelo-Corinth, MS",
    "County/County Equivalent": "Alcorn County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Alcorn County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "003",
    "County\nCode": 28003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18460,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Cornelia, GA",
    "CBSA Solo": "Cornelia",
    "CBSA State": "GA",
    CBSA: "Cornelia, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Habersham County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Habersham County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 137,
    "County\nCode": 13137,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18500,
    "Metropolitan Division Code": "",
    "CSA Code": 236,
    "CBSA Full Title": "Corning, NY",
    "CBSA Solo": "Corning",
    "CBSA State": "NY",
    CBSA: "Corning, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Elmira-Corning, NY",
    "County/County Equivalent": "Steuben County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Steuben County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": 101,
    "County\nCode": 36101,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18580,
    "Metropolitan Division Code": "",
    "CSA Code": 204,
    "CBSA Full Title": "Corpus Christi, TX",
    "CBSA Solo": "Corpus Christi",
    "CBSA State": "TX",
    CBSA: "Corpus Christi, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Corpus Christi-Kingsville-Alice, TX",
    "County/County Equivalent": "Nueces County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Nueces County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 355,
    "County\nCode": 48355,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18580,
    "Metropolitan Division Code": "",
    "CSA Code": 204,
    "CBSA Full Title": "Corpus Christi, TX",
    "CBSA Solo": "Corpus Christi",
    "CBSA State": "TX",
    CBSA: "Corpus Christi, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Corpus Christi-Kingsville-Alice, TX",
    "County/County Equivalent": "San Patricio County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "San Patricio County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 409,
    "County\nCode": 48409,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18620,
    "Metropolitan Division Code": "",
    "CSA Code": 206,
    "CBSA Full Title": "Corsicana, TX",
    "CBSA Solo": "Corsicana",
    "CBSA State": "TX",
    CBSA: "Corsicana, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Navarro County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Navarro County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 349,
    "County\nCode": 48349,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18660,
    "Metropolitan Division Code": "",
    "CSA Code": 296,
    "CBSA Full Title": "Cortland, NY",
    "CBSA Solo": "Cortland",
    "CBSA State": "NY",
    CBSA: "Cortland, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Ithaca-Cortland, NY",
    "County/County Equivalent": "Cortland County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Cortland County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "023",
    "County\nCode": 36023,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18700,
    "Metropolitan Division Code": "",
    "CSA Code": 440,
    "CBSA Full Title": "Corvallis, OR",
    "CBSA Solo": "Corvallis",
    "CBSA State": "OR",
    CBSA: "Corvallis, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Vancouver-Salem, OR-WA",
    "County/County Equivalent": "Benton County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Benton County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "003",
    "County\nCode": 41003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Coshocton, OH",
    "CBSA Solo": "Coshocton",
    "CBSA State": "OH",
    CBSA: "Coshocton, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Coshocton County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Coshocton County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "031",
    "County\nCode": 39031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18780,
    "Metropolitan Division Code": "",
    "CSA Code": 525,
    "CBSA Full Title": "Craig, CO",
    "CBSA Solo": "Craig",
    "CBSA State": "CO",
    CBSA: "Craig, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Steamboat Springs-Craig, CO",
    "County/County Equivalent": "Moffat County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Moffat County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "081",
    "County\nCode": "08081",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18820,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Crawfordsville, IN",
    "CBSA Solo": "Crawfordsville",
    "CBSA State": "IN",
    CBSA: "Crawfordsville, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Montgomery County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Montgomery County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 107,
    "County\nCode": 18107,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Crescent City, CA",
    "CBSA Solo": "Crescent City",
    "CBSA State": "CA",
    CBSA: "Crescent City, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Del Norte County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Del Norte County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "015",
    "County\nCode": "06015",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18880,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Crestview-Fort Walton Beach-Destin, FL",
    "CBSA Solo": "Crestview",
    "CBSA State": "FL",
    CBSA: "Crestview, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Okaloosa County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Okaloosa County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "091",
    "County\nCode": 12091,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18880,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Crestview-Fort Walton Beach-Destin, FL",
    "CBSA Solo": "Crestview",
    "CBSA State": "FL",
    CBSA: "Crestview, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Walton County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Walton County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": 131,
    "County\nCode": 12131,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Crossville, TN",
    "CBSA Solo": "Crossville",
    "CBSA State": "TN",
    CBSA: "Crossville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cumberland County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Cumberland County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "035",
    "County\nCode": 47035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 18980,
    "Metropolitan Division Code": "",
    "CSA Code": 142,
    "CBSA Full Title": "Cullman, AL",
    "CBSA Solo": "Cullman",
    "CBSA State": "AL",
    CBSA: "Cullman, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Birmingham-Hoover-Talladega, AL",
    "County/County Equivalent": "Cullman County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Cullman County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "043",
    "County\nCode": "01043",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19000,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Cullowhee, NC",
    "CBSA Solo": "Cullowhee",
    "CBSA State": "NC",
    CBSA: "Cullowhee, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jackson County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Jackson County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "099",
    "County\nCode": 37099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19000,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Cullowhee, NC",
    "CBSA Solo": "Cullowhee",
    "CBSA State": "NC",
    CBSA: "Cullowhee, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Swain County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Swain County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 173,
    "County\nCode": 37173,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Cumberland, MD-WV",
    "CBSA Solo": "Cumberland",
    "CBSA State": "MD",
    CBSA: "Cumberland, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Allegany County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Allegany County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "001",
    "County\nCode": 24001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Cumberland, MD-WV",
    "CBSA Solo": "Cumberland",
    "CBSA State": "MD",
    CBSA: "Cumberland, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Mineral County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Mineral County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "057",
    "County\nCode": 54057,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19100,
    "Metropolitan Division Code": 19124,
    "CSA Code": 206,
    "CBSA Full Title": "Dallas-Fort Worth-Arlington, TX",
    "CBSA Solo": "Dallas",
    "CBSA State": "TX",
    CBSA: "Dallas, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Dallas-Plano-Irving, TX",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Collin County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Collin County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "085",
    "County\nCode": 48085,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19100,
    "Metropolitan Division Code": 19124,
    "CSA Code": 206,
    "CBSA Full Title": "Dallas-Fort Worth-Arlington, TX",
    "CBSA Solo": "Dallas",
    "CBSA State": "TX",
    CBSA: "Dallas, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Dallas-Plano-Irving, TX",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Dallas County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Dallas County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 113,
    "County\nCode": 48113,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19100,
    "Metropolitan Division Code": 19124,
    "CSA Code": 206,
    "CBSA Full Title": "Dallas-Fort Worth-Arlington, TX",
    "CBSA Solo": "Dallas",
    "CBSA State": "TX",
    CBSA: "Dallas, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Dallas-Plano-Irving, TX",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Denton County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Denton County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 121,
    "County\nCode": 48121,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19100,
    "Metropolitan Division Code": 19124,
    "CSA Code": 206,
    "CBSA Full Title": "Dallas-Fort Worth-Arlington, TX",
    "CBSA Solo": "Dallas",
    "CBSA State": "TX",
    CBSA: "Dallas, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Dallas-Plano-Irving, TX",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Ellis County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Ellis County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 139,
    "County\nCode": 48139,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19100,
    "Metropolitan Division Code": 19124,
    "CSA Code": 206,
    "CBSA Full Title": "Dallas-Fort Worth-Arlington, TX",
    "CBSA Solo": "Dallas",
    "CBSA State": "TX",
    CBSA: "Dallas, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Dallas-Plano-Irving, TX",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Hunt County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Hunt County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 231,
    "County\nCode": 48231,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19100,
    "Metropolitan Division Code": 19124,
    "CSA Code": 206,
    "CBSA Full Title": "Dallas-Fort Worth-Arlington, TX",
    "CBSA Solo": "Dallas",
    "CBSA State": "TX",
    CBSA: "Dallas, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Dallas-Plano-Irving, TX",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Kaufman County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Kaufman County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 257,
    "County\nCode": 48257,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19100,
    "Metropolitan Division Code": 19124,
    "CSA Code": 206,
    "CBSA Full Title": "Dallas-Fort Worth-Arlington, TX",
    "CBSA Solo": "Dallas",
    "CBSA State": "TX",
    CBSA: "Dallas, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Dallas-Plano-Irving, TX",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Rockwall County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Rockwall County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 397,
    "County\nCode": 48397,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19100,
    "Metropolitan Division Code": 23104,
    "CSA Code": 206,
    "CBSA Full Title": "Dallas-Fort Worth-Arlington, TX",
    "CBSA Solo": "Dallas",
    "CBSA State": "TX",
    CBSA: "Dallas, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Fort Worth-Arlington-Grapevine, TX",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Johnson County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Johnson County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 251,
    "County\nCode": 48251,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19100,
    "Metropolitan Division Code": 23104,
    "CSA Code": 206,
    "CBSA Full Title": "Dallas-Fort Worth-Arlington, TX",
    "CBSA Solo": "Dallas",
    "CBSA State": "TX",
    CBSA: "Dallas, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Fort Worth-Arlington-Grapevine, TX",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Parker County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Parker County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 367,
    "County\nCode": 48367,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19100,
    "Metropolitan Division Code": 23104,
    "CSA Code": 206,
    "CBSA Full Title": "Dallas-Fort Worth-Arlington, TX",
    "CBSA Solo": "Dallas",
    "CBSA State": "TX",
    CBSA: "Dallas, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Fort Worth-Arlington-Grapevine, TX",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Tarrant County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Tarrant County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 439,
    "County\nCode": 48439,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19100,
    "Metropolitan Division Code": 23104,
    "CSA Code": 206,
    "CBSA Full Title": "Dallas-Fort Worth-Arlington, TX",
    "CBSA Solo": "Dallas",
    "CBSA State": "TX",
    CBSA: "Dallas, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Fort Worth-Arlington-Grapevine, TX",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Wise County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Wise County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 497,
    "County\nCode": 48497,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19140,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Dalton, GA",
    "CBSA Solo": "Dalton",
    "CBSA State": "GA",
    CBSA: "Dalton, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "Murray County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Murray County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 213,
    "County\nCode": 13213,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19140,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Dalton, GA",
    "CBSA Solo": "Dalton",
    "CBSA State": "GA",
    CBSA: "Dalton, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "Whitfield County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Whitfield County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 313,
    "County\nCode": 13313,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Danville, IL",
    "CBSA Solo": "Danville",
    "CBSA State": "IL",
    CBSA: "Danville, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Vermilion County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Vermilion County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 183,
    "County\nCode": 17183,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Danville, KY",
    "CBSA Solo": "Danville",
    "CBSA State": "KY",
    CBSA: "Danville, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Boyle County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Boyle County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "021",
    "County\nCode": 21021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Danville, KY",
    "CBSA Solo": "Danville",
    "CBSA State": "KY",
    CBSA: "Danville, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lincoln County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Lincoln County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 137,
    "County\nCode": 21137,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Danville, VA",
    "CBSA Solo": "Danville",
    "CBSA State": "VA",
    CBSA: "Danville, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pittsylvania County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Pittsylvania County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 143,
    "County\nCode": 51143,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Danville, VA",
    "CBSA Solo": "Danville",
    "CBSA State": "VA",
    CBSA: "Danville, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Danville city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Danville city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 590,
    "County\nCode": 51590,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19300,
    "Metropolitan Division Code": "",
    "CSA Code": 380,
    "CBSA Full Title": "Daphne-Fairhope-Foley, AL",
    "CBSA Solo": "Daphne",
    "CBSA State": "AL",
    CBSA: "Daphne, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mobile-Daphne-Fairhope, AL",
    "County/County Equivalent": "Baldwin County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Baldwin County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "003",
    "County\nCode": "01003",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19340,
    "Metropolitan Division Code": "",
    "CSA Code": 209,
    "CBSA Full Title": "Davenport-Moline-Rock Island, IA-IL",
    "CBSA Solo": "Davenport",
    "CBSA State": "IA",
    CBSA: "Davenport, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Davenport-Moline, IA-IL",
    "County/County Equivalent": "Scott County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Scott County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 163,
    "County\nCode": 19163,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19340,
    "Metropolitan Division Code": "",
    "CSA Code": 209,
    "CBSA Full Title": "Davenport-Moline-Rock Island, IA-IL",
    "CBSA Solo": "Davenport",
    "CBSA State": "IA",
    CBSA: "Davenport, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Davenport-Moline, IA-IL",
    "County/County Equivalent": "Henry County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Henry County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "073",
    "County\nCode": 17073,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19340,
    "Metropolitan Division Code": "",
    "CSA Code": 209,
    "CBSA Full Title": "Davenport-Moline-Rock Island, IA-IL",
    "CBSA Solo": "Davenport",
    "CBSA State": "IA",
    CBSA: "Davenport, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Davenport-Moline, IA-IL",
    "County/County Equivalent": "Mercer County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Mercer County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 131,
    "County\nCode": 17131,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19340,
    "Metropolitan Division Code": "",
    "CSA Code": 209,
    "CBSA Full Title": "Davenport-Moline-Rock Island, IA-IL",
    "CBSA Solo": "Davenport",
    "CBSA State": "IA",
    CBSA: "Davenport, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Davenport-Moline, IA-IL",
    "County/County Equivalent": "Rock Island County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Rock Island County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 161,
    "County\nCode": 17161,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19420,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Dayton, TN",
    "CBSA Solo": "Dayton",
    "CBSA State": "TN",
    CBSA: "Dayton, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "Rhea County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Rhea County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 143,
    "County\nCode": 47143,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19430,
    "Metropolitan Division Code": "",
    "CSA Code": 212,
    "CBSA Full Title": "Dayton-Kettering, OH",
    "CBSA Solo": "Dayton",
    "CBSA State": "OH",
    CBSA: "Dayton, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dayton-Springfield-Kettering, OH",
    "County/County Equivalent": "Greene County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Greene County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "057",
    "County\nCode": 39057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19430,
    "Metropolitan Division Code": "",
    "CSA Code": 212,
    "CBSA Full Title": "Dayton-Kettering, OH",
    "CBSA Solo": "Dayton",
    "CBSA State": "OH",
    CBSA: "Dayton, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dayton-Springfield-Kettering, OH",
    "County/County Equivalent": "Miami County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Miami County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 109,
    "County\nCode": 39109,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19430,
    "Metropolitan Division Code": "",
    "CSA Code": 212,
    "CBSA Full Title": "Dayton-Kettering, OH",
    "CBSA Solo": "Dayton",
    "CBSA State": "OH",
    CBSA: "Dayton, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dayton-Springfield-Kettering, OH",
    "County/County Equivalent": "Montgomery County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Montgomery County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 113,
    "County\nCode": 39113,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19460,
    "Metropolitan Division Code": "",
    "CSA Code": 290,
    "CBSA Full Title": "Decatur, AL",
    "CBSA Solo": "Decatur",
    "CBSA State": "AL",
    CBSA: "Decatur, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Huntsville-Decatur, AL",
    "County/County Equivalent": "Lawrence County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Lawrence County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "079",
    "County\nCode": "01079",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19460,
    "Metropolitan Division Code": "",
    "CSA Code": 290,
    "CBSA Full Title": "Decatur, AL",
    "CBSA Solo": "Decatur",
    "CBSA State": "AL",
    CBSA: "Decatur, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Huntsville-Decatur, AL",
    "County/County Equivalent": "Morgan County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Morgan County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": 103,
    "County\nCode": "01103",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Decatur, IL",
    "CBSA Solo": "Decatur",
    "CBSA State": "IL",
    CBSA: "Decatur, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Macon County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Macon County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 115,
    "County\nCode": 17115,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19540,
    "Metropolitan Division Code": "",
    "CSA Code": 258,
    "CBSA Full Title": "Decatur, IN",
    "CBSA Solo": "Decatur",
    "CBSA State": "IN",
    CBSA: "Decatur, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fort Wayne-Huntington-Auburn, IN",
    "County/County Equivalent": "Adams County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Adams County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "001",
    "County\nCode": 18001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Defiance, OH",
    "CBSA Solo": "Defiance",
    "CBSA State": "OH",
    CBSA: "Defiance, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Defiance County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Defiance County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "039",
    "County\nCode": 39039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Del Rio, TX",
    "CBSA Solo": "Del Rio",
    "CBSA State": "TX",
    CBSA: "Del Rio, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Val Verde County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Val Verde County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 465,
    "County\nCode": 48465,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19660,
    "Metropolitan Division Code": "",
    "CSA Code": 422,
    "CBSA Full Title": "Deltona-Daytona Beach-Ormond Beach, FL",
    "CBSA Solo": "Deltona",
    "CBSA State": "FL",
    CBSA: "Deltona, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Orlando-Lakeland-Deltona, FL",
    "County/County Equivalent": "Flagler County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Flagler County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "035",
    "County\nCode": 12035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19660,
    "Metropolitan Division Code": "",
    "CSA Code": 422,
    "CBSA Full Title": "Deltona-Daytona Beach-Ormond Beach, FL",
    "CBSA Solo": "Deltona",
    "CBSA State": "FL",
    CBSA: "Deltona, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Orlando-Lakeland-Deltona, FL",
    "County/County Equivalent": "Volusia County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Volusia County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": 127,
    "County\nCode": 12127,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Deming, NM",
    "CBSA Solo": "Deming",
    "CBSA State": "NM",
    CBSA: "Deming, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Luna County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Luna County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "029",
    "County\nCode": 35029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19740,
    "Metropolitan Division Code": "",
    "CSA Code": 216,
    "CBSA Full Title": "Denver-Aurora-Lakewood, CO",
    "CBSA Solo": "Denver",
    "CBSA State": "CO",
    CBSA: "Denver, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Denver-Aurora, CO",
    "County/County Equivalent": "Adams County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Adams County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "001",
    "County\nCode": "08001",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19740,
    "Metropolitan Division Code": "",
    "CSA Code": 216,
    "CBSA Full Title": "Denver-Aurora-Lakewood, CO",
    "CBSA Solo": "Denver",
    "CBSA State": "CO",
    CBSA: "Denver, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Denver-Aurora, CO",
    "County/County Equivalent": "Arapahoe County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Arapahoe County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "005",
    "County\nCode": "08005",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19740,
    "Metropolitan Division Code": "",
    "CSA Code": 216,
    "CBSA Full Title": "Denver-Aurora-Lakewood, CO",
    "CBSA Solo": "Denver",
    "CBSA State": "CO",
    CBSA: "Denver, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Denver-Aurora, CO",
    "County/County Equivalent": "Broomfield County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Broomfield County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "014",
    "County\nCode": "08014",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19740,
    "Metropolitan Division Code": "",
    "CSA Code": 216,
    "CBSA Full Title": "Denver-Aurora-Lakewood, CO",
    "CBSA Solo": "Denver",
    "CBSA State": "CO",
    CBSA: "Denver, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Denver-Aurora, CO",
    "County/County Equivalent": "Clear Creek County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Clear Creek County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "019",
    "County\nCode": "08019",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19740,
    "Metropolitan Division Code": "",
    "CSA Code": 216,
    "CBSA Full Title": "Denver-Aurora-Lakewood, CO",
    "CBSA Solo": "Denver",
    "CBSA State": "CO",
    CBSA: "Denver, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Denver-Aurora, CO",
    "County/County Equivalent": "Denver County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Denver County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "031",
    "County\nCode": "08031",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19740,
    "Metropolitan Division Code": "",
    "CSA Code": 216,
    "CBSA Full Title": "Denver-Aurora-Lakewood, CO",
    "CBSA Solo": "Denver",
    "CBSA State": "CO",
    CBSA: "Denver, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Denver-Aurora, CO",
    "County/County Equivalent": "Douglas County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Douglas County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "035",
    "County\nCode": "08035",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19740,
    "Metropolitan Division Code": "",
    "CSA Code": 216,
    "CBSA Full Title": "Denver-Aurora-Lakewood, CO",
    "CBSA Solo": "Denver",
    "CBSA State": "CO",
    CBSA: "Denver, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Denver-Aurora, CO",
    "County/County Equivalent": "Elbert County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Elbert County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "039",
    "County\nCode": "08039",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19740,
    "Metropolitan Division Code": "",
    "CSA Code": 216,
    "CBSA Full Title": "Denver-Aurora-Lakewood, CO",
    "CBSA Solo": "Denver",
    "CBSA State": "CO",
    CBSA: "Denver, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Denver-Aurora, CO",
    "County/County Equivalent": "Gilpin County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Gilpin County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "047",
    "County\nCode": "08047",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19740,
    "Metropolitan Division Code": "",
    "CSA Code": 216,
    "CBSA Full Title": "Denver-Aurora-Lakewood, CO",
    "CBSA Solo": "Denver",
    "CBSA State": "CO",
    CBSA: "Denver, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Denver-Aurora, CO",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Jefferson County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "059",
    "County\nCode": "08059",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19740,
    "Metropolitan Division Code": "",
    "CSA Code": 216,
    "CBSA Full Title": "Denver-Aurora-Lakewood, CO",
    "CBSA Solo": "Denver",
    "CBSA State": "CO",
    CBSA: "Denver, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Denver-Aurora, CO",
    "County/County Equivalent": "Park County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Park County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "093",
    "County\nCode": "08093",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19760,
    "Metropolitan Division Code": "",
    "CSA Code": 217,
    "CBSA Full Title": "DeRidder, LA",
    "CBSA Solo": "DeRidder",
    "CBSA State": "LA",
    CBSA: "DeRidder, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "DeRidder-Fort Polk South, LA",
    "County/County Equivalent": "Beauregard Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Beauregard Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "011",
    "County\nCode": 22011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19780,
    "Metropolitan Division Code": "",
    "CSA Code": 218,
    "CBSA Full Title": "Des Moines-West Des Moines, IA",
    "CBSA Solo": "Des Moines",
    "CBSA State": "IA",
    CBSA: "Des Moines, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Des Moines-Ames-West Des Moines, IA",
    "County/County Equivalent": "Dallas County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Dallas County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "049",
    "County\nCode": 19049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19780,
    "Metropolitan Division Code": "",
    "CSA Code": 218,
    "CBSA Full Title": "Des Moines-West Des Moines, IA",
    "CBSA Solo": "Des Moines",
    "CBSA State": "IA",
    CBSA: "Des Moines, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Des Moines-Ames-West Des Moines, IA",
    "County/County Equivalent": "Guthrie County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Guthrie County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "077",
    "County\nCode": 19077,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19780,
    "Metropolitan Division Code": "",
    "CSA Code": 218,
    "CBSA Full Title": "Des Moines-West Des Moines, IA",
    "CBSA Solo": "Des Moines",
    "CBSA State": "IA",
    CBSA: "Des Moines, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Des Moines-Ames-West Des Moines, IA",
    "County/County Equivalent": "Jasper County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Jasper County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "099",
    "County\nCode": 19099,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19780,
    "Metropolitan Division Code": "",
    "CSA Code": 218,
    "CBSA Full Title": "Des Moines-West Des Moines, IA",
    "CBSA Solo": "Des Moines",
    "CBSA State": "IA",
    CBSA: "Des Moines, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Des Moines-Ames-West Des Moines, IA",
    "County/County Equivalent": "Madison County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Madison County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 121,
    "County\nCode": 19121,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19780,
    "Metropolitan Division Code": "",
    "CSA Code": 218,
    "CBSA Full Title": "Des Moines-West Des Moines, IA",
    "CBSA Solo": "Des Moines",
    "CBSA State": "IA",
    CBSA: "Des Moines, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Des Moines-Ames-West Des Moines, IA",
    "County/County Equivalent": "Polk County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Polk County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 153,
    "County\nCode": 19153,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19780,
    "Metropolitan Division Code": "",
    "CSA Code": 218,
    "CBSA Full Title": "Des Moines-West Des Moines, IA",
    "CBSA Solo": "Des Moines",
    "CBSA State": "IA",
    CBSA: "Des Moines, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Des Moines-Ames-West Des Moines, IA",
    "County/County Equivalent": "Warren County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Warren County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 181,
    "County\nCode": 19181,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19820,
    "Metropolitan Division Code": 19804,
    "CSA Code": 220,
    "CBSA Full Title": "Detroit-Warren-Dearborn, MI",
    "CBSA Solo": "Detroit",
    "CBSA State": "MI",
    CBSA: "Detroit, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Detroit-Dearborn-Livonia, MI",
    "CSA Title": "Detroit-Warren-Ann Arbor, MI",
    "County/County Equivalent": "Wayne County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Wayne County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 163,
    "County\nCode": 26163,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19820,
    "Metropolitan Division Code": 47664,
    "CSA Code": 220,
    "CBSA Full Title": "Detroit-Warren-Dearborn, MI",
    "CBSA Solo": "Detroit",
    "CBSA State": "MI",
    CBSA: "Detroit, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Warren-Troy-Farmington Hills, MI",
    "CSA Title": "Detroit-Warren-Ann Arbor, MI",
    "County/County Equivalent": "Lapeer County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Lapeer County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "087",
    "County\nCode": 26087,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19820,
    "Metropolitan Division Code": 47664,
    "CSA Code": 220,
    "CBSA Full Title": "Detroit-Warren-Dearborn, MI",
    "CBSA Solo": "Detroit",
    "CBSA State": "MI",
    CBSA: "Detroit, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Warren-Troy-Farmington Hills, MI",
    "CSA Title": "Detroit-Warren-Ann Arbor, MI",
    "County/County Equivalent": "Livingston County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Livingston County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "093",
    "County\nCode": 26093,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19820,
    "Metropolitan Division Code": 47664,
    "CSA Code": 220,
    "CBSA Full Title": "Detroit-Warren-Dearborn, MI",
    "CBSA Solo": "Detroit",
    "CBSA State": "MI",
    CBSA: "Detroit, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Warren-Troy-Farmington Hills, MI",
    "CSA Title": "Detroit-Warren-Ann Arbor, MI",
    "County/County Equivalent": "Macomb County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Macomb County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "099",
    "County\nCode": 26099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19820,
    "Metropolitan Division Code": 47664,
    "CSA Code": 220,
    "CBSA Full Title": "Detroit-Warren-Dearborn, MI",
    "CBSA Solo": "Detroit",
    "CBSA State": "MI",
    CBSA: "Detroit, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Warren-Troy-Farmington Hills, MI",
    "CSA Title": "Detroit-Warren-Ann Arbor, MI",
    "County/County Equivalent": "Oakland County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Oakland County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 125,
    "County\nCode": 26125,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19820,
    "Metropolitan Division Code": 47664,
    "CSA Code": 220,
    "CBSA Full Title": "Detroit-Warren-Dearborn, MI",
    "CBSA Solo": "Detroit",
    "CBSA State": "MI",
    CBSA: "Detroit, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Warren-Troy-Farmington Hills, MI",
    "CSA Title": "Detroit-Warren-Ann Arbor, MI",
    "County/County Equivalent": "St. Clair County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "St. Clair County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 147,
    "County\nCode": 26147,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Dickinson, ND",
    "CBSA Solo": "Dickinson",
    "CBSA State": "ND",
    CBSA: "Dickinson, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Billings County",
    "State Name": "North Dakota",
    "State Abbr": "ND",
    "County, State": "Billings County, ND",
    "FIPS State Code": 38,
    "FIPS County Code": "007",
    "County\nCode": 38007,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 19860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Dickinson, ND",
    "CBSA Solo": "Dickinson",
    "CBSA State": "ND",
    CBSA: "Dickinson, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Stark County",
    "State Name": "North Dakota",
    "State Abbr": "ND",
    "County, State": "Stark County, ND",
    "FIPS State Code": 38,
    "FIPS County Code": "089",
    "County\nCode": 38089,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19940,
    "Metropolitan Division Code": "",
    "CSA Code": 221,
    "CBSA Full Title": "Dixon, IL",
    "CBSA Solo": "Dixon",
    "CBSA State": "IL",
    CBSA: "Dixon, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dixon-Sterling, IL",
    "County/County Equivalent": "Lee County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Lee County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 103,
    "County\nCode": 17103,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 19980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Dodge City, KS",
    "CBSA Solo": "Dodge City",
    "CBSA State": "KS",
    CBSA: "Dodge City, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ford County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Ford County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "057",
    "County\nCode": 20057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20020,
    "Metropolitan Division Code": "",
    "CSA Code": 222,
    "CBSA Full Title": "Dothan, AL",
    "CBSA Solo": "Dothan",
    "CBSA State": "AL",
    CBSA: "Dothan, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dothan-Ozark, AL",
    "County/County Equivalent": "Geneva County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Geneva County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "061",
    "County\nCode": "01061",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 20020,
    "Metropolitan Division Code": "",
    "CSA Code": 222,
    "CBSA Full Title": "Dothan, AL",
    "CBSA Solo": "Dothan",
    "CBSA State": "AL",
    CBSA: "Dothan, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dothan-Ozark, AL",
    "County/County Equivalent": "Henry County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Henry County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "067",
    "County\nCode": "01067",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 20020,
    "Metropolitan Division Code": "",
    "CSA Code": 222,
    "CBSA Full Title": "Dothan, AL",
    "CBSA Solo": "Dothan",
    "CBSA State": "AL",
    CBSA: "Dothan, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dothan-Ozark, AL",
    "County/County Equivalent": "Houston County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Houston County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "069",
    "County\nCode": "01069",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Douglas, GA",
    "CBSA Solo": "Douglas",
    "CBSA State": "GA",
    CBSA: "Douglas, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Atkinson County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Atkinson County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "003",
    "County\nCode": 13003,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 20060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Douglas, GA",
    "CBSA Solo": "Douglas",
    "CBSA State": "GA",
    CBSA: "Douglas, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Coffee County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Coffee County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "069",
    "County\nCode": 13069,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20100,
    "Metropolitan Division Code": "",
    "CSA Code": 428,
    "CBSA Full Title": "Dover, DE",
    "CBSA Solo": "Dover",
    "CBSA State": "DE",
    CBSA: "Dover, DE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Kent County",
    "State Name": "Delaware",
    "State Abbr": "DE",
    "County, State": "Kent County, DE",
    "FIPS State Code": 10,
    "FIPS County Code": "001",
    "County\nCode": 10001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Dublin, GA",
    "CBSA Solo": "Dublin",
    "CBSA State": "GA",
    CBSA: "Dublin, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Johnson County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Johnson County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 167,
    "County\nCode": 13167,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 20140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Dublin, GA",
    "CBSA Solo": "Dublin",
    "CBSA State": "GA",
    CBSA: "Dublin, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Laurens County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Laurens County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 175,
    "County\nCode": 13175,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Dublin, GA",
    "CBSA Solo": "Dublin",
    "CBSA State": "GA",
    CBSA: "Dublin, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Treutlen County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Treutlen County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 283,
    "County\nCode": 13283,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 20180,
    "Metropolitan Division Code": "",
    "CSA Code": 524,
    "CBSA Full Title": "DuBois, PA",
    "CBSA Solo": "DuBois",
    "CBSA State": "PA",
    CBSA: "DuBois, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "State College-DuBois, PA",
    "County/County Equivalent": "Clearfield County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Clearfield County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "033",
    "County\nCode": 42033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Dubuque, IA",
    "CBSA Solo": "Dubuque",
    "CBSA State": "IA",
    CBSA: "Dubuque, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Dubuque County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Dubuque County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "061",
    "County\nCode": 19061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Duluth, MN-WI",
    "CBSA Solo": "Duluth",
    "CBSA State": "MN",
    CBSA: "Duluth, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Carlton County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Carlton County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "017",
    "County\nCode": 27017,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 20260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Duluth, MN-WI",
    "CBSA Solo": "Duluth",
    "CBSA State": "MN",
    CBSA: "Duluth, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lake County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Lake County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "075",
    "County\nCode": 27075,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 20260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Duluth, MN-WI",
    "CBSA Solo": "Duluth",
    "CBSA State": "MN",
    CBSA: "Duluth, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "St. Louis County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "St. Louis County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 137,
    "County\nCode": 27137,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Duluth, MN-WI",
    "CBSA Solo": "Duluth",
    "CBSA State": "MN",
    CBSA: "Duluth, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Douglas County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Douglas County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "031",
    "County\nCode": 55031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Dumas, TX",
    "CBSA Solo": "Dumas",
    "CBSA State": "TX",
    CBSA: "Dumas, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Moore County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Moore County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 341,
    "County\nCode": 48341,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Duncan, OK",
    "CBSA Solo": "Duncan",
    "CBSA State": "OK",
    CBSA: "Duncan, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Stephens County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Stephens County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 137,
    "County\nCode": 40137,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Durango, CO",
    "CBSA Solo": "Durango",
    "CBSA State": "CO",
    CBSA: "Durango, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "La Plata County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "La Plata County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "067",
    "County\nCode": "08067",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20460,
    "Metropolitan Division Code": "",
    "CSA Code": 206,
    "CBSA Full Title": "Durant, OK",
    "CBSA Solo": "Durant",
    "CBSA State": "OK",
    CBSA: "Durant, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Bryan County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Bryan County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "013",
    "County\nCode": 40013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20500,
    "Metropolitan Division Code": "",
    "CSA Code": 450,
    "CBSA Full Title": "Durham-Chapel Hill, NC",
    "CBSA Solo": "Durham",
    "CBSA State": "NC",
    CBSA: "Durham, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Raleigh-Durham-Cary, NC",
    "County/County Equivalent": "Chatham County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Chatham County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "037",
    "County\nCode": 37037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20500,
    "Metropolitan Division Code": "",
    "CSA Code": 450,
    "CBSA Full Title": "Durham-Chapel Hill, NC",
    "CBSA Solo": "Durham",
    "CBSA State": "NC",
    CBSA: "Durham, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Raleigh-Durham-Cary, NC",
    "County/County Equivalent": "Durham County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Durham County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "063",
    "County\nCode": 37063,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20500,
    "Metropolitan Division Code": "",
    "CSA Code": 450,
    "CBSA Full Title": "Durham-Chapel Hill, NC",
    "CBSA Solo": "Durham",
    "CBSA State": "NC",
    CBSA: "Durham, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Raleigh-Durham-Cary, NC",
    "County/County Equivalent": "Granville County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Granville County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "077",
    "County\nCode": 37077,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 20500,
    "Metropolitan Division Code": "",
    "CSA Code": 450,
    "CBSA Full Title": "Durham-Chapel Hill, NC",
    "CBSA Solo": "Durham",
    "CBSA State": "NC",
    CBSA: "Durham, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Raleigh-Durham-Cary, NC",
    "County/County Equivalent": "Orange County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Orange County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 135,
    "County\nCode": 37135,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20500,
    "Metropolitan Division Code": "",
    "CSA Code": 450,
    "CBSA Full Title": "Durham-Chapel Hill, NC",
    "CBSA Solo": "Durham",
    "CBSA State": "NC",
    CBSA: "Durham, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Raleigh-Durham-Cary, NC",
    "County/County Equivalent": "Person County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Person County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 145,
    "County\nCode": 37145,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 20540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Dyersburg, TN",
    "CBSA Solo": "Dyersburg",
    "CBSA State": "TN",
    CBSA: "Dyersburg, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Dyer County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Dyer County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "045",
    "County\nCode": 47045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Eagle Pass, TX",
    "CBSA Solo": "Eagle Pass",
    "CBSA State": "TX",
    CBSA: "Eagle Pass, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Maverick County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Maverick County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 323,
    "County\nCode": 48323,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20660,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Easton, MD",
    "CBSA Solo": "Easton",
    "CBSA State": "MD",
    CBSA: "Easton, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Talbot County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Talbot County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "041",
    "County\nCode": 24041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20700,
    "Metropolitan Division Code": "",
    "CSA Code": 408,
    "CBSA Full Title": "East Stroudsburg, PA",
    "CBSA Solo": "East Stroudsburg",
    "CBSA State": "PA",
    CBSA: "East Stroudsburg, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Monroe County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Monroe County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "089",
    "County\nCode": 42089,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20740,
    "Metropolitan Division Code": "",
    "CSA Code": 232,
    "CBSA Full Title": "Eau Claire, WI",
    "CBSA Solo": "Eau Claire",
    "CBSA State": "WI",
    CBSA: "Eau Claire, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Eau Claire-Menomonie, WI",
    "County/County Equivalent": "Chippewa County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Chippewa County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "017",
    "County\nCode": 55017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20740,
    "Metropolitan Division Code": "",
    "CSA Code": 232,
    "CBSA Full Title": "Eau Claire, WI",
    "CBSA Solo": "Eau Claire",
    "CBSA State": "WI",
    CBSA: "Eau Claire, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Eau Claire-Menomonie, WI",
    "County/County Equivalent": "Eau Claire County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Eau Claire County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "035",
    "County\nCode": 55035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20780,
    "Metropolitan Division Code": "",
    "CSA Code": 233,
    "CBSA Full Title": "Edwards, CO",
    "CBSA Solo": "Edwards",
    "CBSA State": "CO",
    CBSA: "Edwards, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Edwards-Glenwood Springs, CO",
    "County/County Equivalent": "Eagle County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Eagle County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "037",
    "County\nCode": "08037",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Effingham, IL",
    "CBSA Solo": "Effingham",
    "CBSA State": "IL",
    CBSA: "Effingham, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Effingham County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Effingham County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "049",
    "County\nCode": 17049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20900,
    "Metropolitan Division Code": "",
    "CSA Code": 288,
    "CBSA Full Title": "El Campo, TX",
    "CBSA Solo": "El Campo",
    "CBSA State": "TX",
    CBSA: "El Campo, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Houston-The Woodlands, TX",
    "County/County Equivalent": "Wharton County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Wharton County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 481,
    "County\nCode": 48481,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "El Centro, CA",
    "CBSA Solo": "El Centro",
    "CBSA State": "CA",
    CBSA: "El Centro, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Imperial County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Imperial County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "025",
    "County\nCode": "06025",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 20980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "El Dorado, AR",
    "CBSA Solo": "El Dorado",
    "CBSA State": "AR",
    CBSA: "El Dorado, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Union County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Union County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 139,
    "County\nCode": "05139",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21020,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Elizabeth City, NC",
    "CBSA Solo": "Elizabeth City",
    "CBSA State": "NC",
    CBSA: "Elizabeth City, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Pasquotank County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Pasquotank County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 139,
    "County\nCode": 37139,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21020,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Elizabeth City, NC",
    "CBSA Solo": "Elizabeth City",
    "CBSA State": "NC",
    CBSA: "Elizabeth City, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Perquimans County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Perquimans County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 143,
    "County\nCode": 37143,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 21060,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Elizabethtown-Fort Knox, KY",
    "CBSA Solo": "Elizabethtown",
    "CBSA State": "KY",
    CBSA: "Elizabethtown, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Hardin County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Hardin County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "093",
    "County\nCode": 21093,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21060,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Elizabethtown-Fort Knox, KY",
    "CBSA Solo": "Elizabethtown",
    "CBSA State": "KY",
    CBSA: "Elizabethtown, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Larue County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Larue County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 123,
    "County\nCode": 21123,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 21060,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Elizabethtown-Fort Knox, KY",
    "CBSA Solo": "Elizabethtown",
    "CBSA State": "KY",
    CBSA: "Elizabethtown, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Meade County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Meade County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 163,
    "County\nCode": 21163,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 21120,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Elk City, OK",
    "CBSA Solo": "Elk City",
    "CBSA State": "OK",
    CBSA: "Elk City, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Beckham County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Beckham County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "009",
    "County\nCode": 40009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21140,
    "Metropolitan Division Code": "",
    "CSA Code": 515,
    "CBSA Full Title": "Elkhart-Goshen, IN",
    "CBSA Solo": "Elkhart",
    "CBSA State": "IN",
    CBSA: "Elkhart, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "South Bend-Elkhart-Mishawaka, IN-MI",
    "County/County Equivalent": "Elkhart County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Elkhart County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "039",
    "County\nCode": 18039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Elkins, WV",
    "CBSA Solo": "Elkins",
    "CBSA State": "WV",
    CBSA: "Elkins, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Randolph County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Randolph County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "083",
    "County\nCode": 54083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Elko, NV",
    "CBSA Solo": "Elko",
    "CBSA State": "NV",
    CBSA: "Elko, NV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Elko County",
    "State Name": "Nevada",
    "State Abbr": "NV",
    "County, State": "Elko County, NV",
    "FIPS State Code": 32,
    "FIPS County Code": "007",
    "County\nCode": 32007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Elko, NV",
    "CBSA Solo": "Elko",
    "CBSA State": "NV",
    CBSA: "Elko, NV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Eureka County",
    "State Name": "Nevada",
    "State Abbr": "NV",
    "County, State": "Eureka County, NV",
    "FIPS State Code": 32,
    "FIPS County Code": "011",
    "County\nCode": 32011,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 21260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Ellensburg, WA",
    "CBSA Solo": "Ellensburg",
    "CBSA State": "WA",
    CBSA: "Ellensburg, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Kittitas County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Kittitas County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "037",
    "County\nCode": 53037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21300,
    "Metropolitan Division Code": "",
    "CSA Code": 236,
    "CBSA Full Title": "Elmira, NY",
    "CBSA Solo": "Elmira",
    "CBSA State": "NY",
    CBSA: "Elmira, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Elmira-Corning, NY",
    "County/County Equivalent": "Chemung County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Chemung County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "015",
    "County\nCode": 36015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21340,
    "Metropolitan Division Code": "",
    "CSA Code": 238,
    "CBSA Full Title": "El Paso, TX",
    "CBSA Solo": "El Paso",
    "CBSA State": "TX",
    CBSA: "El Paso, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "El Paso-Las Cruces, TX-NM",
    "County/County Equivalent": "El Paso County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "El Paso County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 141,
    "County\nCode": 48141,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21340,
    "Metropolitan Division Code": "",
    "CSA Code": 238,
    "CBSA Full Title": "El Paso, TX",
    "CBSA Solo": "El Paso",
    "CBSA State": "TX",
    CBSA: "El Paso, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "El Paso-Las Cruces, TX-NM",
    "County/County Equivalent": "Hudspeth County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Hudspeth County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 229,
    "County\nCode": 48229,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 21380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Emporia, KS",
    "CBSA Solo": "Emporia",
    "CBSA State": "KS",
    CBSA: "Emporia, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Chase County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Chase County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "017",
    "County\nCode": 20017,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 21380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Emporia, KS",
    "CBSA Solo": "Emporia",
    "CBSA State": "KS",
    CBSA: "Emporia, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lyon County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Lyon County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 111,
    "County\nCode": 20111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Enid, OK",
    "CBSA Solo": "Enid",
    "CBSA State": "OK",
    CBSA: "Enid, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Garfield County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Garfield County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "047",
    "County\nCode": 40047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Enterprise, AL",
    "CBSA Solo": "Enterprise",
    "CBSA State": "AL",
    CBSA: "Enterprise, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Coffee County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Coffee County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "031",
    "County\nCode": "01031",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21500,
    "Metropolitan Division Code": "",
    "CSA Code": 240,
    "CBSA Full Title": "Erie, PA",
    "CBSA Solo": "Erie",
    "CBSA State": "PA",
    CBSA: "Erie, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Erie-Meadville, PA",
    "County/County Equivalent": "Erie County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Erie County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "049",
    "County\nCode": 42049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Escanaba, MI",
    "CBSA Solo": "Escanaba",
    "CBSA State": "MI",
    CBSA: "Escanaba, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Delta County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Delta County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "041",
    "County\nCode": 26041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21580,
    "Metropolitan Division Code": "",
    "CSA Code": 106,
    "CBSA Full Title": "Española, NM",
    "CBSA Solo": "Española",
    "CBSA State": "NM",
    CBSA: "Española, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albuquerque-Santa Fe-Las Vegas, NM",
    "County/County Equivalent": "Rio Arriba County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Rio Arriba County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "039",
    "County\nCode": 35039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21640,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Eufaula, AL-GA",
    "CBSA Solo": "Eufaula",
    "CBSA State": "AL",
    CBSA: "Eufaula, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Barbour County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Barbour County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "005",
    "County\nCode": "01005",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21640,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Eufaula, AL-GA",
    "CBSA Solo": "Eufaula",
    "CBSA State": "AL",
    CBSA: "Eufaula, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Quitman County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Quitman County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 239,
    "County\nCode": 13239,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 21660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Eugene-Springfield, OR",
    "CBSA Solo": "Eugene",
    "CBSA State": "OR",
    CBSA: "Eugene, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lane County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Lane County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "039",
    "County\nCode": 41039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Eureka-Arcata, CA",
    "CBSA Solo": "Eureka",
    "CBSA State": "CA",
    CBSA: "Eureka, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Humboldt County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Humboldt County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "023",
    "County\nCode": "06023",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Evanston, WY",
    "CBSA Solo": "Evanston",
    "CBSA State": "WY",
    CBSA: "Evanston, WY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Uinta County",
    "State Name": "Wyoming",
    "State Abbr": "WY",
    "County, State": "Uinta County, WY",
    "FIPS State Code": 56,
    "FIPS County Code": "041",
    "County\nCode": 56041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Evansville, IN-KY",
    "CBSA Solo": "Evansville",
    "CBSA State": "IN",
    CBSA: "Evansville, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Posey County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Posey County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 129,
    "County\nCode": 18129,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 21780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Evansville, IN-KY",
    "CBSA Solo": "Evansville",
    "CBSA State": "IN",
    CBSA: "Evansville, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Vanderburgh County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Vanderburgh County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 163,
    "County\nCode": 18163,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Evansville, IN-KY",
    "CBSA Solo": "Evansville",
    "CBSA State": "IN",
    CBSA: "Evansville, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Warrick County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Warrick County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 173,
    "County\nCode": 18173,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Evansville, IN-KY",
    "CBSA Solo": "Evansville",
    "CBSA State": "IN",
    CBSA: "Evansville, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Henderson County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Henderson County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 101,
    "County\nCode": 21101,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fairbanks, AK",
    "CBSA Solo": "Fairbanks",
    "CBSA State": "AK",
    CBSA: "Fairbanks, AK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Fairbanks North Star Borough",
    "State Name": "Alaska",
    "State Abbr": "AK",
    "County, State": "Fairbanks North Star Borough, AK",
    "FIPS State Code": "02",
    "FIPS County Code": "090",
    "County\nCode": "02090",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21840,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fairfield, IA",
    "CBSA Solo": "Fairfield",
    "CBSA State": "IA",
    CBSA: "Fairfield, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Jefferson County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 101,
    "County\nCode": 19101,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fairmont, MN",
    "CBSA Solo": "Fairmont",
    "CBSA State": "MN",
    CBSA: "Fairmont, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Martin County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Martin County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "091",
    "County\nCode": 27091,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21900,
    "Metropolitan Division Code": "",
    "CSA Code": 390,
    "CBSA Full Title": "Fairmont, WV",
    "CBSA Solo": "Fairmont",
    "CBSA State": "WV",
    CBSA: "Fairmont, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Morgantown-Fairmont, WV",
    "County/County Equivalent": "Marion County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Marion County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "049",
    "County\nCode": 54049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 21980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fallon, NV",
    "CBSA Solo": "Fallon",
    "CBSA State": "NV",
    CBSA: "Fallon, NV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Churchill County",
    "State Name": "Nevada",
    "State Abbr": "NV",
    "County, State": "Churchill County, NV",
    "FIPS State Code": 32,
    "FIPS County Code": "001",
    "County\nCode": 32001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22020,
    "Metropolitan Division Code": "",
    "CSA Code": 244,
    "CBSA Full Title": "Fargo, ND-MN",
    "CBSA Solo": "Fargo",
    "CBSA State": "ND",
    CBSA: "Fargo, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fargo-Wahpeton, ND-MN",
    "County/County Equivalent": "Cass County",
    "State Name": "North Dakota",
    "State Abbr": "ND",
    "County, State": "Cass County, ND",
    "FIPS State Code": 38,
    "FIPS County Code": "017",
    "County\nCode": 38017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22020,
    "Metropolitan Division Code": "",
    "CSA Code": 244,
    "CBSA Full Title": "Fargo, ND-MN",
    "CBSA Solo": "Fargo",
    "CBSA State": "ND",
    CBSA: "Fargo, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fargo-Wahpeton, ND-MN",
    "County/County Equivalent": "Clay County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Clay County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "027",
    "County\nCode": 27027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22060,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Faribault-Northfield, MN",
    "CBSA Solo": "Faribault",
    "CBSA State": "MN",
    CBSA: "Faribault, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Rice County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Rice County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 131,
    "County\nCode": 27131,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22100,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "Farmington, MO",
    "CBSA Solo": "Farmington",
    "CBSA State": "MO",
    CBSA: "Farmington, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "St. Francois County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "St. Francois County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 187,
    "County\nCode": 29187,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Farmington, NM",
    "CBSA Solo": "Farmington",
    "CBSA State": "NM",
    CBSA: "Farmington, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "San Juan County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "San Juan County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "045",
    "County\nCode": 35045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22180,
    "Metropolitan Division Code": "",
    "CSA Code": 246,
    "CBSA Full Title": "Fayetteville, NC",
    "CBSA Solo": "Fayetteville",
    "CBSA State": "NC",
    CBSA: "Fayetteville, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fayetteville-Sanford-Lumberton, NC",
    "County/County Equivalent": "Cumberland County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Cumberland County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "051",
    "County\nCode": 37051,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22180,
    "Metropolitan Division Code": "",
    "CSA Code": 246,
    "CBSA Full Title": "Fayetteville, NC",
    "CBSA Solo": "Fayetteville",
    "CBSA State": "NC",
    CBSA: "Fayetteville, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fayetteville-Sanford-Lumberton, NC",
    "County/County Equivalent": "Harnett County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Harnett County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "085",
    "County\nCode": 37085,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 22180,
    "Metropolitan Division Code": "",
    "CSA Code": 246,
    "CBSA Full Title": "Fayetteville, NC",
    "CBSA Solo": "Fayetteville",
    "CBSA State": "NC",
    CBSA: "Fayetteville, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fayetteville-Sanford-Lumberton, NC",
    "County/County Equivalent": "Hoke County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Hoke County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "093",
    "County\nCode": 37093,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fayetteville-Springdale-Rogers, AR",
    "CBSA Solo": "Fayetteville",
    "CBSA State": "AR",
    CBSA: "Fayetteville, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Benton County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Benton County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "007",
    "County\nCode": "05007",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fayetteville-Springdale-Rogers, AR",
    "CBSA Solo": "Fayetteville",
    "CBSA State": "AR",
    CBSA: "Fayetteville, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Madison County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Madison County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "087",
    "County\nCode": "05087",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 22220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fayetteville-Springdale-Rogers, AR",
    "CBSA Solo": "Fayetteville",
    "CBSA State": "AR",
    CBSA: "Fayetteville, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Washington County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Washington County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 143,
    "County\nCode": "05143",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fergus Falls, MN",
    "CBSA Solo": "Fergus Falls",
    "CBSA State": "MN",
    CBSA: "Fergus Falls, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Otter Tail County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Otter Tail County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 111,
    "County\nCode": 27111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22280,
    "Metropolitan Division Code": "",
    "CSA Code": 456,
    "CBSA Full Title": "Fernley, NV",
    "CBSA Solo": "Fernley",
    "CBSA State": "NV",
    CBSA: "Fernley, NV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Reno-Carson City-Fernley, NV",
    "County/County Equivalent": "Lyon County",
    "State Name": "Nevada",
    "State Abbr": "NV",
    "County, State": "Lyon County, NV",
    "FIPS State Code": 32,
    "FIPS County Code": "019",
    "County\nCode": 32019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22300,
    "Metropolitan Division Code": "",
    "CSA Code": 534,
    "CBSA Full Title": "Findlay, OH",
    "CBSA Solo": "Findlay",
    "CBSA State": "OH",
    CBSA: "Findlay, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Toledo-Findlay-Tiffin, OH",
    "County/County Equivalent": "Hancock County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Hancock County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "063",
    "County\nCode": 39063,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fitzgerald, GA",
    "CBSA Solo": "Fitzgerald",
    "CBSA State": "GA",
    CBSA: "Fitzgerald, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ben Hill County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Ben Hill County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "017",
    "County\nCode": 13017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Flagstaff, AZ",
    "CBSA Solo": "Flagstaff",
    "CBSA State": "AZ",
    CBSA: "Flagstaff, AZ",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Coconino County",
    "State Name": "Arizona",
    "State Abbr": "AZ",
    "County, State": "Coconino County, AZ",
    "FIPS State Code": "04",
    "FIPS County Code": "005",
    "County\nCode": "04005",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22420,
    "Metropolitan Division Code": "",
    "CSA Code": 220,
    "CBSA Full Title": "Flint, MI",
    "CBSA Solo": "Flint",
    "CBSA State": "MI",
    CBSA: "Flint, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Detroit-Warren-Ann Arbor, MI",
    "County/County Equivalent": "Genesee County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Genesee County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "049",
    "County\nCode": 26049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Florence, SC",
    "CBSA Solo": "Florence",
    "CBSA State": "SC",
    CBSA: "Florence, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Darlington County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Darlington County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "031",
    "County\nCode": 45031,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 22500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Florence, SC",
    "CBSA Solo": "Florence",
    "CBSA State": "SC",
    CBSA: "Florence, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Florence County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Florence County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "041",
    "County\nCode": 45041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22520,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Florence-Muscle Shoals, AL",
    "CBSA Solo": "Florence",
    "CBSA State": "AL",
    CBSA: "Florence, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Colbert County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Colbert County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "033",
    "County\nCode": "01033",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22520,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Florence-Muscle Shoals, AL",
    "CBSA Solo": "Florence",
    "CBSA State": "AL",
    CBSA: "Florence, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lauderdale County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Lauderdale County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "077",
    "County\nCode": "01077",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fond du Lac, WI",
    "CBSA Solo": "Fond du Lac",
    "CBSA State": "WI",
    CBSA: "Fond du Lac, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Fond du Lac County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Fond du Lac County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "039",
    "County\nCode": 55039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Forest City, NC",
    "CBSA Solo": "Forest City",
    "CBSA State": "NC",
    CBSA: "Forest City, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Rutherford County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Rutherford County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 161,
    "County\nCode": 37161,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22620,
    "Metropolitan Division Code": "",
    "CSA Code": 368,
    "CBSA Full Title": "Forrest City, AR",
    "CBSA Solo": "Forrest City",
    "CBSA State": "AR",
    CBSA: "Forrest City, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Memphis-Forrest City, TN-MS-AR",
    "County/County Equivalent": "St. Francis County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "St. Francis County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 123,
    "County\nCode": "05123",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fort Collins, CO",
    "CBSA Solo": "Fort Collins",
    "CBSA State": "CO",
    CBSA: "Fort Collins, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Larimer County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Larimer County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "069",
    "County\nCode": "08069",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fort Dodge, IA",
    "CBSA Solo": "Fort Dodge",
    "CBSA State": "IA",
    CBSA: "Fort Dodge, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Webster County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Webster County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 187,
    "County\nCode": 19187,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fort Leonard Wood, MO",
    "CBSA Solo": "Fort Leonard Wood",
    "CBSA State": "MO",
    CBSA: "Fort Leonard Wood, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pulaski County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Pulaski County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 169,
    "County\nCode": 29169,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22800,
    "Metropolitan Division Code": "",
    "CSA Code": 161,
    "CBSA Full Title": "Fort Madison-Keokuk, IA-IL-MO",
    "CBSA Solo": "Fort Madison",
    "CBSA State": "IA",
    CBSA: "Fort Madison, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Burlington-Fort Madison-Keokuk, IA-IL-MO",
    "County/County Equivalent": "Hancock County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Hancock County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "067",
    "County\nCode": 17067,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 22800,
    "Metropolitan Division Code": "",
    "CSA Code": 161,
    "CBSA Full Title": "Fort Madison-Keokuk, IA-IL-MO",
    "CBSA Solo": "Fort Madison",
    "CBSA State": "IA",
    CBSA: "Fort Madison, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Burlington-Fort Madison-Keokuk, IA-IL-MO",
    "County/County Equivalent": "Lee County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Lee County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 111,
    "County\nCode": 19111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22800,
    "Metropolitan Division Code": "",
    "CSA Code": 161,
    "CBSA Full Title": "Fort Madison-Keokuk, IA-IL-MO",
    "CBSA Solo": "Fort Madison",
    "CBSA State": "IA",
    CBSA: "Fort Madison, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Burlington-Fort Madison-Keokuk, IA-IL-MO",
    "County/County Equivalent": "Clark County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Clark County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "045",
    "County\nCode": 29045,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 22820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fort Morgan, CO",
    "CBSA Solo": "Fort Morgan",
    "CBSA State": "CO",
    CBSA: "Fort Morgan, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Morgan County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Morgan County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "087",
    "County\nCode": "08087",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22840,
    "Metropolitan Division Code": "",
    "CSA Code": 497,
    "CBSA Full Title": "Fort Payne, AL",
    "CBSA Solo": "Fort Payne",
    "CBSA State": "AL",
    CBSA: "Fort Payne, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Scottsboro-Fort Payne, AL",
    "County/County Equivalent": "DeKalb County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "DeKalb County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "049",
    "County\nCode": "01049",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22860,
    "Metropolitan Division Code": "",
    "CSA Code": 217,
    "CBSA Full Title": "Fort Polk South, LA",
    "CBSA Solo": "Fort Polk South",
    "CBSA State": "LA",
    CBSA: "Fort Polk South, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "DeRidder-Fort Polk South, LA",
    "County/County Equivalent": "Vernon Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Vernon Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": 115,
    "County\nCode": 22115,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fort Smith, AR-OK",
    "CBSA Solo": "Fort Smith",
    "CBSA State": "AR",
    CBSA: "Fort Smith, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Crawford County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Crawford County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "033",
    "County\nCode": "05033",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fort Smith, AR-OK",
    "CBSA Solo": "Fort Smith",
    "CBSA State": "AR",
    CBSA: "Fort Smith, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Franklin County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Franklin County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "047",
    "County\nCode": "05047",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 22900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fort Smith, AR-OK",
    "CBSA Solo": "Fort Smith",
    "CBSA State": "AR",
    CBSA: "Fort Smith, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Sebastian County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Sebastian County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 131,
    "County\nCode": "05131",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 22900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Fort Smith, AR-OK",
    "CBSA Solo": "Fort Smith",
    "CBSA State": "AR",
    CBSA: "Fort Smith, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Sequoyah County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Sequoyah County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 135,
    "County\nCode": 40135,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 23060,
    "Metropolitan Division Code": "",
    "CSA Code": 258,
    "CBSA Full Title": "Fort Wayne, IN",
    "CBSA Solo": "Fort Wayne",
    "CBSA State": "IN",
    CBSA: "Fort Wayne, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fort Wayne-Huntington-Auburn, IN",
    "County/County Equivalent": "Allen County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Allen County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "003",
    "County\nCode": 18003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23060,
    "Metropolitan Division Code": "",
    "CSA Code": 258,
    "CBSA Full Title": "Fort Wayne, IN",
    "CBSA Solo": "Fort Wayne",
    "CBSA State": "IN",
    CBSA: "Fort Wayne, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fort Wayne-Huntington-Auburn, IN",
    "County/County Equivalent": "Whitley County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Whitley County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 183,
    "County\nCode": 18183,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 23140,
    "Metropolitan Division Code": "",
    "CSA Code": 320,
    "CBSA Full Title": "Frankfort, IN",
    "CBSA Solo": "Frankfort",
    "CBSA State": "IN",
    CBSA: "Frankfort, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lafayette-West Lafayette-Frankfort, IN",
    "County/County Equivalent": "Clinton County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Clinton County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "023",
    "County\nCode": 18023,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23180,
    "Metropolitan Division Code": "",
    "CSA Code": 336,
    "CBSA Full Title": "Frankfort, KY",
    "CBSA Solo": "Frankfort",
    "CBSA State": "KY",
    CBSA: "Frankfort, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lexington-Fayette--Richmond--Frankfort, KY",
    "County/County Equivalent": "Anderson County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Anderson County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "005",
    "County\nCode": 21005,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 23180,
    "Metropolitan Division Code": "",
    "CSA Code": 336,
    "CBSA Full Title": "Frankfort, KY",
    "CBSA Solo": "Frankfort",
    "CBSA State": "KY",
    CBSA: "Frankfort, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lexington-Fayette--Richmond--Frankfort, KY",
    "County/County Equivalent": "Franklin County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Franklin County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "073",
    "County\nCode": 21073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23240,
    "Metropolitan Division Code": "",
    "CSA Code": 314,
    "CBSA Full Title": "Fredericksburg, TX",
    "CBSA Solo": "Fredericksburg",
    "CBSA State": "TX",
    CBSA: "Fredericksburg, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kerrville-Fredericksburg, TX",
    "County/County Equivalent": "Gillespie County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Gillespie County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 171,
    "County\nCode": 48171,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23300,
    "Metropolitan Division Code": "",
    "CSA Code": 466,
    "CBSA Full Title": "Freeport, IL",
    "CBSA Solo": "Freeport",
    "CBSA State": "IL",
    CBSA: "Freeport, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rockford-Freeport-Rochelle, IL",
    "County/County Equivalent": "Stephenson County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Stephenson County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 177,
    "County\nCode": 17177,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23340,
    "Metropolitan Division Code": "",
    "CSA Code": 420,
    "CBSA Full Title": "Fremont, NE",
    "CBSA Solo": "Fremont",
    "CBSA State": "NE",
    CBSA: "Fremont, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Omaha-Council Bluffs-Fremont, NE-IA",
    "County/County Equivalent": "Dodge County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Dodge County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "053",
    "County\nCode": 31053,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23380,
    "Metropolitan Division Code": "",
    "CSA Code": 534,
    "CBSA Full Title": "Fremont, OH",
    "CBSA Solo": "Fremont",
    "CBSA State": "OH",
    CBSA: "Fremont, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Toledo-Findlay-Tiffin, OH",
    "County/County Equivalent": "Sandusky County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Sandusky County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 143,
    "County\nCode": 39143,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23420,
    "Metropolitan Division Code": "",
    "CSA Code": 260,
    "CBSA Full Title": "Fresno, CA",
    "CBSA Solo": "Fresno",
    "CBSA State": "CA",
    CBSA: "Fresno, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fresno-Madera-Hanford, CA",
    "County/County Equivalent": "Fresno County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Fresno County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "019",
    "County\nCode": "06019",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Gadsden, AL",
    "CBSA Solo": "Gadsden",
    "CBSA State": "AL",
    CBSA: "Gadsden, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Etowah County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Etowah County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "055",
    "County\nCode": "01055",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23500,
    "Metropolitan Division Code": "",
    "CSA Code": 273,
    "CBSA Full Title": "Gaffney, SC",
    "CBSA Solo": "Gaffney",
    "CBSA State": "SC",
    CBSA: "Gaffney, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greenville-Spartanburg-Anderson, SC",
    "County/County Equivalent": "Cherokee County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Cherokee County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "021",
    "County\nCode": 45021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23540,
    "Metropolitan Division Code": "",
    "CSA Code": 264,
    "CBSA Full Title": "Gainesville, FL",
    "CBSA Solo": "Gainesville",
    "CBSA State": "FL",
    CBSA: "Gainesville, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Gainesville-Lake City, FL",
    "County/County Equivalent": "Alachua County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Alachua County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "001",
    "County\nCode": 12001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23540,
    "Metropolitan Division Code": "",
    "CSA Code": 264,
    "CBSA Full Title": "Gainesville, FL",
    "CBSA Solo": "Gainesville",
    "CBSA State": "FL",
    CBSA: "Gainesville, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Gainesville-Lake City, FL",
    "County/County Equivalent": "Gilchrist County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Gilchrist County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "041",
    "County\nCode": 12041,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 23540,
    "Metropolitan Division Code": "",
    "CSA Code": 264,
    "CBSA Full Title": "Gainesville, FL",
    "CBSA Solo": "Gainesville",
    "CBSA State": "FL",
    CBSA: "Gainesville, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Gainesville-Lake City, FL",
    "County/County Equivalent": "Levy County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Levy County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "075",
    "County\nCode": 12075,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 23580,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Gainesville, GA",
    "CBSA Solo": "Gainesville",
    "CBSA State": "GA",
    CBSA: "Gainesville, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Hall County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Hall County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 139,
    "County\nCode": 13139,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23620,
    "Metropolitan Division Code": "",
    "CSA Code": 206,
    "CBSA Full Title": "Gainesville, TX",
    "CBSA Solo": "Gainesville",
    "CBSA State": "TX",
    CBSA: "Gainesville, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Cooke County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Cooke County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "097",
    "County\nCode": 48097,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Galesburg, IL",
    "CBSA Solo": "Galesburg",
    "CBSA State": "IL",
    CBSA: "Galesburg, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Knox County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Knox County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "095",
    "County\nCode": 17095,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Gallup, NM",
    "CBSA Solo": "Gallup",
    "CBSA State": "NM",
    CBSA: "Gallup, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "McKinley County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "McKinley County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "031",
    "County\nCode": 35031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Garden City, KS",
    "CBSA Solo": "Garden City",
    "CBSA State": "KS",
    CBSA: "Garden City, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Finney County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Finney County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "055",
    "County\nCode": 20055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Garden City, KS",
    "CBSA Solo": "Garden City",
    "CBSA State": "KS",
    CBSA: "Garden City, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Kearny County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Kearny County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "093",
    "County\nCode": 20093,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 23820,
    "Metropolitan Division Code": "",
    "CSA Code": 456,
    "CBSA Full Title": "Gardnerville Ranchos, NV",
    "CBSA Solo": "Gardnerville Ranchos",
    "CBSA State": "NV",
    CBSA: "Gardnerville Ranchos, NV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Reno-Carson City-Fernley, NV",
    "County/County Equivalent": "Douglas County",
    "State Name": "Nevada",
    "State Abbr": "NV",
    "County, State": "Douglas County, NV",
    "FIPS State Code": 32,
    "FIPS County Code": "005",
    "County\nCode": 32005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23860,
    "Metropolitan Division Code": "",
    "CSA Code": 396,
    "CBSA Full Title": "Georgetown, SC",
    "CBSA Solo": "Georgetown",
    "CBSA State": "SC",
    CBSA: "Georgetown, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Myrtle Beach-Conway, SC-NC",
    "County/County Equivalent": "Georgetown County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Georgetown County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "043",
    "County\nCode": 45043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23900,
    "Metropolitan Division Code": "",
    "CSA Code": 276,
    "CBSA Full Title": "Gettysburg, PA",
    "CBSA Solo": "Gettysburg",
    "CBSA State": "PA",
    CBSA: "Gettysburg, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Harrisburg-York-Lebanon, PA",
    "County/County Equivalent": "Adams County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Adams County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "001",
    "County\nCode": 42001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Gillette, WY",
    "CBSA Solo": "Gillette",
    "CBSA State": "WY",
    CBSA: "Gillette, WY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Campbell County",
    "State Name": "Wyoming",
    "State Abbr": "WY",
    "County, State": "Campbell County, WY",
    "FIPS State Code": 56,
    "FIPS County Code": "005",
    "County\nCode": 56005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Gillette, WY",
    "CBSA Solo": "Gillette",
    "CBSA State": "WY",
    CBSA: "Gillette, WY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Crook County",
    "State Name": "Wyoming",
    "State Abbr": "WY",
    "County, State": "Crook County, WY",
    "FIPS State Code": 56,
    "FIPS County Code": "011",
    "County\nCode": 56011,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 23940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Gillette, WY",
    "CBSA Solo": "Gillette",
    "CBSA State": "WY",
    CBSA: "Gillette, WY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Weston County",
    "State Name": "Wyoming",
    "State Abbr": "WY",
    "County, State": "Weston County, WY",
    "FIPS State Code": 56,
    "FIPS County Code": "045",
    "County\nCode": 56045,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 23980,
    "Metropolitan Division Code": "",
    "CSA Code": 150,
    "CBSA Full Title": "Glasgow, KY",
    "CBSA Solo": "Glasgow",
    "CBSA State": "KY",
    CBSA: "Glasgow, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bowling Green-Glasgow, KY",
    "County/County Equivalent": "Barren County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Barren County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "009",
    "County\nCode": 21009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 23980,
    "Metropolitan Division Code": "",
    "CSA Code": 150,
    "CBSA Full Title": "Glasgow, KY",
    "CBSA Solo": "Glasgow",
    "CBSA State": "KY",
    CBSA: "Glasgow, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bowling Green-Glasgow, KY",
    "County/County Equivalent": "Metcalfe County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Metcalfe County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 169,
    "County\nCode": 21169,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24020,
    "Metropolitan Division Code": "",
    "CSA Code": 104,
    "CBSA Full Title": "Glens Falls, NY",
    "CBSA Solo": "Glens Falls",
    "CBSA State": "NY",
    CBSA: "Glens Falls, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albany-Schenectady, NY",
    "County/County Equivalent": "Warren County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Warren County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": 113,
    "County\nCode": 36113,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24020,
    "Metropolitan Division Code": "",
    "CSA Code": 104,
    "CBSA Full Title": "Glens Falls, NY",
    "CBSA Solo": "Glens Falls",
    "CBSA State": "NY",
    CBSA: "Glens Falls, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albany-Schenectady, NY",
    "County/County Equivalent": "Washington County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Washington County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": 115,
    "County\nCode": 36115,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24060,
    "Metropolitan Division Code": "",
    "CSA Code": 233,
    "CBSA Full Title": "Glenwood Springs, CO",
    "CBSA Solo": "Glenwood Springs",
    "CBSA State": "CO",
    CBSA: "Glenwood Springs, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Edwards-Glenwood Springs, CO",
    "County/County Equivalent": "Garfield County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Garfield County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "045",
    "County\nCode": "08045",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24060,
    "Metropolitan Division Code": "",
    "CSA Code": 233,
    "CBSA Full Title": "Glenwood Springs, CO",
    "CBSA Solo": "Glenwood Springs",
    "CBSA State": "CO",
    CBSA: "Glenwood Springs, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Edwards-Glenwood Springs, CO",
    "County/County Equivalent": "Pitkin County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Pitkin County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "097",
    "County\nCode": "08097",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24100,
    "Metropolitan Division Code": "",
    "CSA Code": 104,
    "CBSA Full Title": "Gloversville, NY",
    "CBSA Solo": "Gloversville",
    "CBSA State": "NY",
    CBSA: "Gloversville, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albany-Schenectady, NY",
    "County/County Equivalent": "Fulton County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Fulton County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "035",
    "County\nCode": 36035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Goldsboro, NC",
    "CBSA Solo": "Goldsboro",
    "CBSA State": "NC",
    CBSA: "Goldsboro, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Wayne County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Wayne County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 191,
    "County\nCode": 37191,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24180,
    "Metropolitan Division Code": "",
    "CSA Code": 206,
    "CBSA Full Title": "Granbury, TX",
    "CBSA Solo": "Granbury",
    "CBSA State": "TX",
    CBSA: "Granbury, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Hood County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Hood County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 221,
    "County\nCode": 48221,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Grand Forks, ND-MN",
    "CBSA Solo": "Grand Forks",
    "CBSA State": "ND",
    CBSA: "Grand Forks, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Grand Forks County",
    "State Name": "North Dakota",
    "State Abbr": "ND",
    "County, State": "Grand Forks County, ND",
    "FIPS State Code": 38,
    "FIPS County Code": "035",
    "County\nCode": 38035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Grand Forks, ND-MN",
    "CBSA Solo": "Grand Forks",
    "CBSA State": "ND",
    CBSA: "Grand Forks, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Polk County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Polk County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 119,
    "County\nCode": 27119,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Grand Island, NE",
    "CBSA Solo": "Grand Island",
    "CBSA State": "NE",
    CBSA: "Grand Island, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hall County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Hall County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "079",
    "County\nCode": 31079,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Grand Island, NE",
    "CBSA Solo": "Grand Island",
    "CBSA State": "NE",
    CBSA: "Grand Island, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Howard County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Howard County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "093",
    "County\nCode": 31093,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Grand Island, NE",
    "CBSA Solo": "Grand Island",
    "CBSA State": "NE",
    CBSA: "Grand Island, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Merrick County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Merrick County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 121,
    "County\nCode": 31121,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Grand Junction, CO",
    "CBSA Solo": "Grand Junction",
    "CBSA State": "CO",
    CBSA: "Grand Junction, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Mesa County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Mesa County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "077",
    "County\nCode": "08077",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24330,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Grand Rapids, MN",
    "CBSA Solo": "Grand Rapids",
    "CBSA State": "MN",
    CBSA: "Grand Rapids, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Itasca County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Itasca County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "061",
    "County\nCode": 27061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24340,
    "Metropolitan Division Code": "",
    "CSA Code": 266,
    "CBSA Full Title": "Grand Rapids-Kentwood, MI",
    "CBSA Solo": "Grand Rapids",
    "CBSA State": "MI",
    CBSA: "Grand Rapids, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Grand Rapids-Kentwood-Muskegon, MI",
    "County/County Equivalent": "Ionia County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Ionia County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "067",
    "County\nCode": 26067,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24340,
    "Metropolitan Division Code": "",
    "CSA Code": 266,
    "CBSA Full Title": "Grand Rapids-Kentwood, MI",
    "CBSA Solo": "Grand Rapids",
    "CBSA State": "MI",
    CBSA: "Grand Rapids, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Grand Rapids-Kentwood-Muskegon, MI",
    "County/County Equivalent": "Kent County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Kent County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "081",
    "County\nCode": 26081,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24340,
    "Metropolitan Division Code": "",
    "CSA Code": 266,
    "CBSA Full Title": "Grand Rapids-Kentwood, MI",
    "CBSA Solo": "Grand Rapids",
    "CBSA State": "MI",
    CBSA: "Grand Rapids, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Grand Rapids-Kentwood-Muskegon, MI",
    "County/County Equivalent": "Montcalm County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Montcalm County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 117,
    "County\nCode": 26117,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24340,
    "Metropolitan Division Code": "",
    "CSA Code": 266,
    "CBSA Full Title": "Grand Rapids-Kentwood, MI",
    "CBSA Solo": "Grand Rapids",
    "CBSA State": "MI",
    CBSA: "Grand Rapids, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Grand Rapids-Kentwood-Muskegon, MI",
    "County/County Equivalent": "Ottawa County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Ottawa County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 139,
    "County\nCode": 26139,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Grants, NM",
    "CBSA Solo": "Grants",
    "CBSA State": "NM",
    CBSA: "Grants, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cibola County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Cibola County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "006",
    "County\nCode": 35006,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24420,
    "Metropolitan Division Code": "",
    "CSA Code": 366,
    "CBSA Full Title": "Grants Pass, OR",
    "CBSA Solo": "Grants Pass",
    "CBSA State": "OR",
    CBSA: "Grants Pass, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Medford-Grants Pass, OR",
    "County/County Equivalent": "Josephine County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Josephine County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "033",
    "County\nCode": 41033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Great Bend, KS",
    "CBSA Solo": "Great Bend",
    "CBSA State": "KS",
    CBSA: "Great Bend, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Barton County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Barton County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "009",
    "County\nCode": 20009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Great Falls, MT",
    "CBSA Solo": "Great Falls",
    "CBSA State": "MT",
    CBSA: "Great Falls, MT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cascade County",
    "State Name": "Montana",
    "State Abbr": "MT",
    "County, State": "Cascade County, MT",
    "FIPS State Code": 30,
    "FIPS County Code": "013",
    "County\nCode": 30013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24540,
    "Metropolitan Division Code": "",
    "CSA Code": 216,
    "CBSA Full Title": "Greeley, CO",
    "CBSA Solo": "Greeley",
    "CBSA State": "CO",
    CBSA: "Greeley, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Denver-Aurora, CO",
    "County/County Equivalent": "Weld County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Weld County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": 123,
    "County\nCode": "08123",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24580,
    "Metropolitan Division Code": "",
    "CSA Code": 267,
    "CBSA Full Title": "Green Bay, WI",
    "CBSA Solo": "Green Bay",
    "CBSA State": "WI",
    CBSA: "Green Bay, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Green Bay-Shawano, WI",
    "County/County Equivalent": "Brown County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Brown County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "009",
    "County\nCode": 55009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24580,
    "Metropolitan Division Code": "",
    "CSA Code": 267,
    "CBSA Full Title": "Green Bay, WI",
    "CBSA Solo": "Green Bay",
    "CBSA State": "WI",
    CBSA: "Green Bay, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Green Bay-Shawano, WI",
    "County/County Equivalent": "Kewaunee County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Kewaunee County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "061",
    "County\nCode": 55061,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24580,
    "Metropolitan Division Code": "",
    "CSA Code": 267,
    "CBSA Full Title": "Green Bay, WI",
    "CBSA Solo": "Green Bay",
    "CBSA State": "WI",
    CBSA: "Green Bay, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Green Bay-Shawano, WI",
    "County/County Equivalent": "Oconto County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Oconto County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "083",
    "County\nCode": 55083,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Greeneville, TN",
    "CBSA Solo": "Greeneville",
    "CBSA State": "TN",
    CBSA: "Greeneville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Greene County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Greene County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "059",
    "County\nCode": 47059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24660,
    "Metropolitan Division Code": "",
    "CSA Code": 268,
    "CBSA Full Title": "Greensboro-High Point, NC",
    "CBSA Solo": "Greensboro",
    "CBSA State": "NC",
    CBSA: "Greensboro, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greensboro--Winston-Salem--High Point, NC",
    "County/County Equivalent": "Guilford County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Guilford County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "081",
    "County\nCode": 37081,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24660,
    "Metropolitan Division Code": "",
    "CSA Code": 268,
    "CBSA Full Title": "Greensboro-High Point, NC",
    "CBSA Solo": "Greensboro",
    "CBSA State": "NC",
    CBSA: "Greensboro, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greensboro--Winston-Salem--High Point, NC",
    "County/County Equivalent": "Randolph County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Randolph County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 151,
    "County\nCode": 37151,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24660,
    "Metropolitan Division Code": "",
    "CSA Code": 268,
    "CBSA Full Title": "Greensboro-High Point, NC",
    "CBSA Solo": "Greensboro",
    "CBSA State": "NC",
    CBSA: "Greensboro, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greensboro--Winston-Salem--High Point, NC",
    "County/County Equivalent": "Rockingham County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Rockingham County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 157,
    "County\nCode": 37157,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24700,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Greensburg, IN",
    "CBSA Solo": "Greensburg",
    "CBSA State": "IN",
    CBSA: "Greensburg, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Decatur County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Decatur County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "031",
    "County\nCode": 18031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Greenville, MS",
    "CBSA Solo": "Greenville",
    "CBSA State": "MS",
    CBSA: "Greenville, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Washington County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Washington County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 151,
    "County\nCode": 28151,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24780,
    "Metropolitan Division Code": "",
    "CSA Code": 272,
    "CBSA Full Title": "Greenville, NC",
    "CBSA Solo": "Greenville",
    "CBSA State": "NC",
    CBSA: "Greenville, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greenville-Kinston-Washington, NC",
    "County/County Equivalent": "Pitt County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Pitt County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 147,
    "County\nCode": 37147,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24820,
    "Metropolitan Division Code": "",
    "CSA Code": 212,
    "CBSA Full Title": "Greenville, OH",
    "CBSA Solo": "Greenville",
    "CBSA State": "OH",
    CBSA: "Greenville, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dayton-Springfield-Kettering, OH",
    "County/County Equivalent": "Darke County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Darke County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "037",
    "County\nCode": 39037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24860,
    "Metropolitan Division Code": "",
    "CSA Code": 273,
    "CBSA Full Title": "Greenville-Anderson, SC",
    "CBSA Solo": "Greenville",
    "CBSA State": "SC",
    CBSA: "Greenville, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greenville-Spartanburg-Anderson, SC",
    "County/County Equivalent": "Anderson County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Anderson County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "007",
    "County\nCode": 45007,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24860,
    "Metropolitan Division Code": "",
    "CSA Code": 273,
    "CBSA Full Title": "Greenville-Anderson, SC",
    "CBSA Solo": "Greenville",
    "CBSA State": "SC",
    CBSA: "Greenville, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greenville-Spartanburg-Anderson, SC",
    "County/County Equivalent": "Greenville County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Greenville County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "045",
    "County\nCode": 45045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24860,
    "Metropolitan Division Code": "",
    "CSA Code": 273,
    "CBSA Full Title": "Greenville-Anderson, SC",
    "CBSA Solo": "Greenville",
    "CBSA State": "SC",
    CBSA: "Greenville, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greenville-Spartanburg-Anderson, SC",
    "County/County Equivalent": "Laurens County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Laurens County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "059",
    "County\nCode": 45059,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24860,
    "Metropolitan Division Code": "",
    "CSA Code": 273,
    "CBSA Full Title": "Greenville-Anderson, SC",
    "CBSA Solo": "Greenville",
    "CBSA State": "SC",
    CBSA: "Greenville, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greenville-Spartanburg-Anderson, SC",
    "County/County Equivalent": "Pickens County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Pickens County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "077",
    "County\nCode": 45077,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Greenwood, MS",
    "CBSA Solo": "Greenwood",
    "CBSA State": "MS",
    CBSA: "Greenwood, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Carroll County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Carroll County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "015",
    "County\nCode": 28015,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 24900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Greenwood, MS",
    "CBSA Solo": "Greenwood",
    "CBSA State": "MS",
    CBSA: "Greenwood, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Leflore County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Leflore County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "083",
    "County\nCode": 28083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24940,
    "Metropolitan Division Code": "",
    "CSA Code": 273,
    "CBSA Full Title": "Greenwood, SC",
    "CBSA Solo": "Greenwood",
    "CBSA State": "SC",
    CBSA: "Greenwood, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greenville-Spartanburg-Anderson, SC",
    "County/County Equivalent": "Greenwood County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Greenwood County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "047",
    "County\nCode": 45047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 24980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Grenada, MS",
    "CBSA Solo": "Grenada",
    "CBSA State": "MS",
    CBSA: "Grenada, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Grenada County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Grenada County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "043",
    "County\nCode": 28043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25020,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "Guayama, PR",
    "CBSA Solo": "Guayama",
    "CBSA State": "PR",
    CBSA: "Guayama, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Arroyo Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Arroyo Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "015",
    "County\nCode": 72015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25020,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "Guayama, PR",
    "CBSA Solo": "Guayama",
    "CBSA State": "PR",
    CBSA: "Guayama, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Guayama Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Guayama Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "057",
    "County\nCode": 72057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25020,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "Guayama, PR",
    "CBSA Solo": "Guayama",
    "CBSA State": "PR",
    CBSA: "Guayama, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Patillas Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Patillas Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 109,
    "County\nCode": 72109,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Gulfport-Biloxi, MS",
    "CBSA Solo": "Gulfport",
    "CBSA State": "MS",
    CBSA: "Gulfport, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hancock County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Hancock County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "045",
    "County\nCode": 28045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Gulfport-Biloxi, MS",
    "CBSA Solo": "Gulfport",
    "CBSA State": "MS",
    CBSA: "Gulfport, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Harrison County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Harrison County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "047",
    "County\nCode": 28047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Gulfport-Biloxi, MS",
    "CBSA Solo": "Gulfport",
    "CBSA State": "MS",
    CBSA: "Gulfport, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jackson County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Jackson County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "059",
    "County\nCode": 28059,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Gulfport-Biloxi, MS",
    "CBSA Solo": "Gulfport",
    "CBSA State": "MS",
    CBSA: "Gulfport, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Stone County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Stone County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 131,
    "County\nCode": 28131,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Guymon, OK",
    "CBSA Solo": "Guymon",
    "CBSA State": "OK",
    CBSA: "Guymon, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Texas County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Texas County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 139,
    "County\nCode": 40139,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25180,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Hagerstown-Martinsburg, MD-WV",
    "CBSA Solo": "Hagerstown",
    "CBSA State": "MD",
    CBSA: "Hagerstown, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Washington County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Washington County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "043",
    "County\nCode": 24043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25180,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Hagerstown-Martinsburg, MD-WV",
    "CBSA Solo": "Hagerstown",
    "CBSA State": "MD",
    CBSA: "Hagerstown, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Berkeley County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Berkeley County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "003",
    "County\nCode": 54003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25180,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Hagerstown-Martinsburg, MD-WV",
    "CBSA Solo": "Hagerstown",
    "CBSA State": "MD",
    CBSA: "Hagerstown, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Morgan County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Morgan County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "065",
    "County\nCode": 54065,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25200,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hailey, ID",
    "CBSA Solo": "Hailey",
    "CBSA State": "ID",
    CBSA: "Hailey, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Blaine County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Blaine County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "013",
    "County\nCode": 16013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25200,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hailey, ID",
    "CBSA Solo": "Hailey",
    "CBSA State": "ID",
    CBSA: "Hailey, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Camas County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Camas County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "025",
    "County\nCode": 16025,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25220,
    "Metropolitan Division Code": "",
    "CSA Code": 406,
    "CBSA Full Title": "Hammond, LA",
    "CBSA Solo": "Hammond",
    "CBSA State": "LA",
    CBSA: "Hammond, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Orleans-Metairie-Hammond, LA-MS",
    "County/County Equivalent": "Tangipahoa Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Tangipahoa Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": 105,
    "County\nCode": 22105,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25260,
    "Metropolitan Division Code": "",
    "CSA Code": 260,
    "CBSA Full Title": "Hanford-Corcoran, CA",
    "CBSA Solo": "Hanford",
    "CBSA State": "CA",
    CBSA: "Hanford, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fresno-Madera-Hanford, CA",
    "County/County Equivalent": "Kings County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Kings County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "031",
    "County\nCode": "06031",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25300,
    "Metropolitan Division Code": "",
    "CSA Code": 448,
    "CBSA Full Title": "Hannibal, MO",
    "CBSA Solo": "Hannibal",
    "CBSA State": "MO",
    CBSA: "Hannibal, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Quincy-Hannibal, IL-MO",
    "County/County Equivalent": "Marion County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Marion County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 127,
    "County\nCode": 29127,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25300,
    "Metropolitan Division Code": "",
    "CSA Code": 448,
    "CBSA Full Title": "Hannibal, MO",
    "CBSA Solo": "Hannibal",
    "CBSA State": "MO",
    CBSA: "Hannibal, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Quincy-Hannibal, IL-MO",
    "County/County Equivalent": "Ralls County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Ralls County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 173,
    "County\nCode": 29173,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25420,
    "Metropolitan Division Code": "",
    "CSA Code": 276,
    "CBSA Full Title": "Harrisburg-Carlisle, PA",
    "CBSA Solo": "Harrisburg",
    "CBSA State": "PA",
    CBSA: "Harrisburg, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Harrisburg-York-Lebanon, PA",
    "County/County Equivalent": "Cumberland County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Cumberland County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "041",
    "County\nCode": 42041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25420,
    "Metropolitan Division Code": "",
    "CSA Code": 276,
    "CBSA Full Title": "Harrisburg-Carlisle, PA",
    "CBSA Solo": "Harrisburg",
    "CBSA State": "PA",
    CBSA: "Harrisburg, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Harrisburg-York-Lebanon, PA",
    "County/County Equivalent": "Dauphin County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Dauphin County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "043",
    "County\nCode": 42043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25420,
    "Metropolitan Division Code": "",
    "CSA Code": 276,
    "CBSA Full Title": "Harrisburg-Carlisle, PA",
    "CBSA Solo": "Harrisburg",
    "CBSA State": "PA",
    CBSA: "Harrisburg, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Harrisburg-York-Lebanon, PA",
    "County/County Equivalent": "Perry County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Perry County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "099",
    "County\nCode": 42099,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Harrison, AR",
    "CBSA Solo": "Harrison",
    "CBSA State": "AR",
    CBSA: "Harrison, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Boone County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Boone County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "009",
    "County\nCode": "05009",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Harrison, AR",
    "CBSA Solo": "Harrison",
    "CBSA State": "AR",
    CBSA: "Harrison, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Newton County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Newton County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 101,
    "County\nCode": "05101",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25500,
    "Metropolitan Division Code": "",
    "CSA Code": 277,
    "CBSA Full Title": "Harrisonburg, VA",
    "CBSA Solo": "Harrisonburg",
    "CBSA State": "VA",
    CBSA: "Harrisonburg, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Harrisonburg-Staunton, VA",
    "County/County Equivalent": "Rockingham County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Rockingham County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 165,
    "County\nCode": 51165,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25500,
    "Metropolitan Division Code": "",
    "CSA Code": 277,
    "CBSA Full Title": "Harrisonburg, VA",
    "CBSA Solo": "Harrisonburg",
    "CBSA State": "VA",
    CBSA: "Harrisonburg, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Harrisonburg-Staunton, VA",
    "County/County Equivalent": "Harrisonburg city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Harrisonburg city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 660,
    "County\nCode": 51660,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25540,
    "Metropolitan Division Code": "",
    "CSA Code": 278,
    "CBSA Full Title": "Hartford-East Hartford-Middletown, CT",
    "CBSA Solo": "Hartford",
    "CBSA State": "CT",
    CBSA: "Hartford, CT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Hartford-East Hartford, CT",
    "County/County Equivalent": "Hartford County",
    "State Name": "Connecticut",
    "State Abbr": "CT",
    "County, State": "Hartford County, CT",
    "FIPS State Code": "09",
    "FIPS County Code": "003",
    "County\nCode": "09003",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25540,
    "Metropolitan Division Code": "",
    "CSA Code": 278,
    "CBSA Full Title": "Hartford-East Hartford-Middletown, CT",
    "CBSA Solo": "Hartford",
    "CBSA State": "CT",
    CBSA: "Hartford, CT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Hartford-East Hartford, CT",
    "County/County Equivalent": "Middlesex County",
    "State Name": "Connecticut",
    "State Abbr": "CT",
    "County, State": "Middlesex County, CT",
    "FIPS State Code": "09",
    "FIPS County Code": "007",
    "County\nCode": "09007",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25540,
    "Metropolitan Division Code": "",
    "CSA Code": 278,
    "CBSA Full Title": "Hartford-East Hartford-Middletown, CT",
    "CBSA Solo": "Hartford",
    "CBSA State": "CT",
    CBSA: "Hartford, CT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Hartford-East Hartford, CT",
    "County/County Equivalent": "Tolland County",
    "State Name": "Connecticut",
    "State Abbr": "CT",
    "County, State": "Tolland County, CT",
    "FIPS State Code": "09",
    "FIPS County Code": "013",
    "County\nCode": "09013",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hastings, NE",
    "CBSA Solo": "Hastings",
    "CBSA State": "NE",
    CBSA: "Hastings, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Adams County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Adams County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "001",
    "County\nCode": 31001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25620,
    "Metropolitan Division Code": "",
    "CSA Code": 279,
    "CBSA Full Title": "Hattiesburg, MS",
    "CBSA Solo": "Hattiesburg",
    "CBSA State": "MS",
    CBSA: "Hattiesburg, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Hattiesburg-Laurel, MS",
    "County/County Equivalent": "Covington County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Covington County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "031",
    "County\nCode": 28031,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25620,
    "Metropolitan Division Code": "",
    "CSA Code": 279,
    "CBSA Full Title": "Hattiesburg, MS",
    "CBSA Solo": "Hattiesburg",
    "CBSA State": "MS",
    CBSA: "Hattiesburg, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Hattiesburg-Laurel, MS",
    "County/County Equivalent": "Forrest County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Forrest County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "035",
    "County\nCode": 28035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25620,
    "Metropolitan Division Code": "",
    "CSA Code": 279,
    "CBSA Full Title": "Hattiesburg, MS",
    "CBSA Solo": "Hattiesburg",
    "CBSA State": "MS",
    CBSA: "Hattiesburg, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Hattiesburg-Laurel, MS",
    "County/County Equivalent": "Lamar County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Lamar County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "073",
    "County\nCode": 28073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25620,
    "Metropolitan Division Code": "",
    "CSA Code": 279,
    "CBSA Full Title": "Hattiesburg, MS",
    "CBSA Solo": "Hattiesburg",
    "CBSA State": "MS",
    CBSA: "Hattiesburg, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Hattiesburg-Laurel, MS",
    "County/County Equivalent": "Perry County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Perry County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 111,
    "County\nCode": 28111,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hays, KS",
    "CBSA Solo": "Hays",
    "CBSA State": "KS",
    CBSA: "Hays, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ellis County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Ellis County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "051",
    "County\nCode": 20051,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25720,
    "Metropolitan Division Code": "",
    "CSA Code": 482,
    "CBSA Full Title": "Heber, UT",
    "CBSA Solo": "Heber",
    "CBSA State": "UT",
    CBSA: "Heber, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salt Lake City-Provo-Orem, UT",
    "County/County Equivalent": "Summit County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Summit County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "043",
    "County\nCode": 49043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25720,
    "Metropolitan Division Code": "",
    "CSA Code": 482,
    "CBSA Full Title": "Heber, UT",
    "CBSA Solo": "Heber",
    "CBSA State": "UT",
    CBSA: "Heber, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salt Lake City-Provo-Orem, UT",
    "County/County Equivalent": "Wasatch County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Wasatch County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "051",
    "County\nCode": 49051,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Helena, MT",
    "CBSA Solo": "Helena",
    "CBSA State": "MT",
    CBSA: "Helena, MT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Montana",
    "State Abbr": "MT",
    "County, State": "Jefferson County, MT",
    "FIPS State Code": 30,
    "FIPS County Code": "043",
    "County\nCode": 30043,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Helena, MT",
    "CBSA Solo": "Helena",
    "CBSA State": "MT",
    CBSA: "Helena, MT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lewis and Clark County",
    "State Name": "Montana",
    "State Abbr": "MT",
    "County, State": "Lewis and Clark County, MT",
    "FIPS State Code": 30,
    "FIPS County Code": "049",
    "County\nCode": 30049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25760,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Helena-West Helena, AR",
    "CBSA Solo": "Helena",
    "CBSA State": "AR",
    CBSA: "Helena, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Phillips County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Phillips County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 107,
    "County\nCode": "05107",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25780,
    "Metropolitan Division Code": "",
    "CSA Code": 450,
    "CBSA Full Title": "Henderson, NC",
    "CBSA Solo": "Henderson",
    "CBSA State": "NC",
    CBSA: "Henderson, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Raleigh-Durham-Cary, NC",
    "County/County Equivalent": "Vance County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Vance County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 181,
    "County\nCode": 37181,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hereford, TX",
    "CBSA Solo": "Hereford",
    "CBSA State": "TX",
    CBSA: "Hereford, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Deaf Smith County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Deaf Smith County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 117,
    "County\nCode": 48117,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25840,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hermiston-Pendleton, OR",
    "CBSA Solo": "Hermiston",
    "CBSA State": "OR",
    CBSA: "Hermiston, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Morrow County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Morrow County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "049",
    "County\nCode": 41049,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25840,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hermiston-Pendleton, OR",
    "CBSA Solo": "Hermiston",
    "CBSA State": "OR",
    CBSA: "Hermiston, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Umatilla County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Umatilla County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "059",
    "County\nCode": 41059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hickory-Lenoir-Morganton, NC",
    "CBSA Solo": "Hickory",
    "CBSA State": "NC",
    CBSA: "Hickory, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Alexander County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Alexander County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "003",
    "County\nCode": 37003,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hickory-Lenoir-Morganton, NC",
    "CBSA Solo": "Hickory",
    "CBSA State": "NC",
    CBSA: "Hickory, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Burke County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Burke County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "023",
    "County\nCode": 37023,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hickory-Lenoir-Morganton, NC",
    "CBSA Solo": "Hickory",
    "CBSA State": "NC",
    CBSA: "Hickory, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Caldwell County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Caldwell County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "027",
    "County\nCode": 37027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hickory-Lenoir-Morganton, NC",
    "CBSA Solo": "Hickory",
    "CBSA State": "NC",
    CBSA: "Hickory, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Catawba County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Catawba County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "035",
    "County\nCode": 37035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25880,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hillsdale, MI",
    "CBSA Solo": "Hillsdale",
    "CBSA State": "MI",
    CBSA: "Hillsdale, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hillsdale County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Hillsdale County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "059",
    "County\nCode": 26059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hilo, HI",
    "CBSA Solo": "Hilo",
    "CBSA State": "HI",
    CBSA: "Hilo, HI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hawaii County",
    "State Name": "Hawaii",
    "State Abbr": "HI",
    "County, State": "Hawaii County, HI",
    "FIPS State Code": 15,
    "FIPS County Code": "001",
    "County\nCode": 15001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hilton Head Island-Bluffton, SC",
    "CBSA Solo": "Hilton Head Island",
    "CBSA State": "SC",
    CBSA: "Hilton Head Island, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Beaufort County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Beaufort County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "013",
    "County\nCode": 45013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hilton Head Island-Bluffton, SC",
    "CBSA Solo": "Hilton Head Island",
    "CBSA State": "SC",
    CBSA: "Hilton Head Island, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jasper County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Jasper County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "053",
    "County\nCode": 45053,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 25980,
    "Metropolitan Division Code": "",
    "CSA Code": 496,
    "CBSA Full Title": "Hinesville, GA",
    "CBSA Solo": "Hinesville",
    "CBSA State": "GA",
    CBSA: "Hinesville, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Savannah-Hinesville-Statesboro, GA",
    "County/County Equivalent": "Liberty County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Liberty County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 179,
    "County\nCode": 13179,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 25980,
    "Metropolitan Division Code": "",
    "CSA Code": 496,
    "CBSA Full Title": "Hinesville, GA",
    "CBSA Solo": "Hinesville",
    "CBSA State": "GA",
    CBSA: "Hinesville, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Savannah-Hinesville-Statesboro, GA",
    "County/County Equivalent": "Long County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Long County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 183,
    "County\nCode": 13183,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26020,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hobbs, NM",
    "CBSA Solo": "Hobbs",
    "CBSA State": "NM",
    CBSA: "Hobbs, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lea County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Lea County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "025",
    "County\nCode": 35025,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26090,
    "Metropolitan Division Code": "",
    "CSA Code": 266,
    "CBSA Full Title": "Holland, MI",
    "CBSA Solo": "Holland",
    "CBSA State": "MI",
    CBSA: "Holland, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Grand Rapids-Kentwood-Muskegon, MI",
    "County/County Equivalent": "Allegan County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Allegan County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "005",
    "County\nCode": 26005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Homosassa Springs, FL",
    "CBSA Solo": "Homosassa Springs",
    "CBSA State": "FL",
    CBSA: "Homosassa Springs, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Citrus County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Citrus County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "017",
    "County\nCode": 12017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hood River, OR",
    "CBSA Solo": "Hood River",
    "CBSA State": "OR",
    CBSA: "Hood River, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hood River County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Hood River County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "027",
    "County\nCode": 41027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hope, AR",
    "CBSA Solo": "Hope",
    "CBSA State": "AR",
    CBSA: "Hope, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hempstead County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Hempstead County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "057",
    "County\nCode": "05057",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hope, AR",
    "CBSA Solo": "Hope",
    "CBSA State": "AR",
    CBSA: "Hope, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Nevada County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Nevada County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "099",
    "County\nCode": "05099",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26300,
    "Metropolitan Division Code": "",
    "CSA Code": 284,
    "CBSA Full Title": "Hot Springs, AR",
    "CBSA Solo": "Hot Springs",
    "CBSA State": "AR",
    CBSA: "Hot Springs, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Hot Springs-Malvern, AR",
    "County/County Equivalent": "Garland County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Garland County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "051",
    "County\nCode": "05051",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Houghton, MI",
    "CBSA Solo": "Houghton",
    "CBSA State": "MI",
    CBSA: "Houghton, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Houghton County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Houghton County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "061",
    "County\nCode": 26061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Houghton, MI",
    "CBSA Solo": "Houghton",
    "CBSA State": "MI",
    CBSA: "Houghton, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Keweenaw County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Keweenaw County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "083",
    "County\nCode": 26083,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Houma-Thibodaux, LA",
    "CBSA Solo": "Houma",
    "CBSA State": "LA",
    CBSA: "Houma, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lafourche Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Lafourche Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "057",
    "County\nCode": 22057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Houma-Thibodaux, LA",
    "CBSA Solo": "Houma",
    "CBSA State": "LA",
    CBSA: "Houma, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Terrebonne Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Terrebonne Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": 109,
    "County\nCode": 22109,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26420,
    "Metropolitan Division Code": "",
    "CSA Code": 288,
    "CBSA Full Title": "Houston-The Woodlands-Sugar Land, TX",
    "CBSA Solo": "Houston",
    "CBSA State": "TX",
    CBSA: "Houston, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Houston-The Woodlands, TX",
    "County/County Equivalent": "Austin County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Austin County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "015",
    "County\nCode": 48015,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26420,
    "Metropolitan Division Code": "",
    "CSA Code": 288,
    "CBSA Full Title": "Houston-The Woodlands-Sugar Land, TX",
    "CBSA Solo": "Houston",
    "CBSA State": "TX",
    CBSA: "Houston, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Houston-The Woodlands, TX",
    "County/County Equivalent": "Brazoria County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Brazoria County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "039",
    "County\nCode": 48039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26420,
    "Metropolitan Division Code": "",
    "CSA Code": 288,
    "CBSA Full Title": "Houston-The Woodlands-Sugar Land, TX",
    "CBSA Solo": "Houston",
    "CBSA State": "TX",
    CBSA: "Houston, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Houston-The Woodlands, TX",
    "County/County Equivalent": "Chambers County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Chambers County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "071",
    "County\nCode": 48071,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26420,
    "Metropolitan Division Code": "",
    "CSA Code": 288,
    "CBSA Full Title": "Houston-The Woodlands-Sugar Land, TX",
    "CBSA Solo": "Houston",
    "CBSA State": "TX",
    CBSA: "Houston, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Houston-The Woodlands, TX",
    "County/County Equivalent": "Fort Bend County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Fort Bend County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 157,
    "County\nCode": 48157,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26420,
    "Metropolitan Division Code": "",
    "CSA Code": 288,
    "CBSA Full Title": "Houston-The Woodlands-Sugar Land, TX",
    "CBSA Solo": "Houston",
    "CBSA State": "TX",
    CBSA: "Houston, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Houston-The Woodlands, TX",
    "County/County Equivalent": "Galveston County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Galveston County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 167,
    "County\nCode": 48167,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26420,
    "Metropolitan Division Code": "",
    "CSA Code": 288,
    "CBSA Full Title": "Houston-The Woodlands-Sugar Land, TX",
    "CBSA Solo": "Houston",
    "CBSA State": "TX",
    CBSA: "Houston, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Houston-The Woodlands, TX",
    "County/County Equivalent": "Harris County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Harris County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 201,
    "County\nCode": 48201,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26420,
    "Metropolitan Division Code": "",
    "CSA Code": 288,
    "CBSA Full Title": "Houston-The Woodlands-Sugar Land, TX",
    "CBSA Solo": "Houston",
    "CBSA State": "TX",
    CBSA: "Houston, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Houston-The Woodlands, TX",
    "County/County Equivalent": "Liberty County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Liberty County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 291,
    "County\nCode": 48291,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26420,
    "Metropolitan Division Code": "",
    "CSA Code": 288,
    "CBSA Full Title": "Houston-The Woodlands-Sugar Land, TX",
    "CBSA Solo": "Houston",
    "CBSA State": "TX",
    CBSA: "Houston, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Houston-The Woodlands, TX",
    "County/County Equivalent": "Montgomery County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Montgomery County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 339,
    "County\nCode": 48339,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26420,
    "Metropolitan Division Code": "",
    "CSA Code": 288,
    "CBSA Full Title": "Houston-The Woodlands-Sugar Land, TX",
    "CBSA Solo": "Houston",
    "CBSA State": "TX",
    CBSA: "Houston, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Houston-The Woodlands, TX",
    "County/County Equivalent": "Waller County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Waller County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 473,
    "County\nCode": 48473,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26460,
    "Metropolitan Division Code": "",
    "CSA Code": 104,
    "CBSA Full Title": "Hudson, NY",
    "CBSA Solo": "Hudson",
    "CBSA State": "NY",
    CBSA: "Hudson, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albany-Schenectady, NY",
    "County/County Equivalent": "Columbia County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Columbia County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "021",
    "County\nCode": 36021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26500,
    "Metropolitan Division Code": "",
    "CSA Code": 107,
    "CBSA Full Title": "Huntingdon, PA",
    "CBSA Solo": "Huntingdon",
    "CBSA State": "PA",
    CBSA: "Huntingdon, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Altoona-Huntingdon, PA",
    "County/County Equivalent": "Huntingdon County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Huntingdon County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "061",
    "County\nCode": 42061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26540,
    "Metropolitan Division Code": "",
    "CSA Code": 258,
    "CBSA Full Title": "Huntington, IN",
    "CBSA Solo": "Huntington",
    "CBSA State": "IN",
    CBSA: "Huntington, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fort Wayne-Huntington-Auburn, IN",
    "County/County Equivalent": "Huntington County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Huntington County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "069",
    "County\nCode": 18069,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26580,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Huntington-Ashland, WV-KY-OH",
    "CBSA Solo": "Huntington",
    "CBSA State": "WV",
    CBSA: "Huntington, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Cabell County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Cabell County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "011",
    "County\nCode": 54011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26580,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Huntington-Ashland, WV-KY-OH",
    "CBSA Solo": "Huntington",
    "CBSA State": "WV",
    CBSA: "Huntington, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Boyd County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Boyd County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "019",
    "County\nCode": 21019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26580,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Huntington-Ashland, WV-KY-OH",
    "CBSA Solo": "Huntington",
    "CBSA State": "WV",
    CBSA: "Huntington, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Carter County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Carter County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "043",
    "County\nCode": 21043,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26580,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Huntington-Ashland, WV-KY-OH",
    "CBSA Solo": "Huntington",
    "CBSA State": "WV",
    CBSA: "Huntington, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Greenup County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Greenup County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "089",
    "County\nCode": 21089,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26580,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Huntington-Ashland, WV-KY-OH",
    "CBSA Solo": "Huntington",
    "CBSA State": "WV",
    CBSA: "Huntington, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Lawrence County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Lawrence County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "087",
    "County\nCode": 39087,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26580,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Huntington-Ashland, WV-KY-OH",
    "CBSA Solo": "Huntington",
    "CBSA State": "WV",
    CBSA: "Huntington, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Putnam County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Putnam County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "079",
    "County\nCode": 54079,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26580,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Huntington-Ashland, WV-KY-OH",
    "CBSA Solo": "Huntington",
    "CBSA State": "WV",
    CBSA: "Huntington, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Wayne County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Wayne County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "099",
    "County\nCode": 54099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26620,
    "Metropolitan Division Code": "",
    "CSA Code": 290,
    "CBSA Full Title": "Huntsville, AL",
    "CBSA Solo": "Huntsville",
    "CBSA State": "AL",
    CBSA: "Huntsville, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Huntsville-Decatur, AL",
    "County/County Equivalent": "Limestone County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Limestone County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "083",
    "County\nCode": "01083",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26620,
    "Metropolitan Division Code": "",
    "CSA Code": 290,
    "CBSA Full Title": "Huntsville, AL",
    "CBSA Solo": "Huntsville",
    "CBSA State": "AL",
    CBSA: "Huntsville, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Huntsville-Decatur, AL",
    "County/County Equivalent": "Madison County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Madison County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "089",
    "County\nCode": "01089",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26660,
    "Metropolitan Division Code": "",
    "CSA Code": 288,
    "CBSA Full Title": "Huntsville, TX",
    "CBSA Solo": "Huntsville",
    "CBSA State": "TX",
    CBSA: "Huntsville, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Houston-The Woodlands, TX",
    "County/County Equivalent": "Walker County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Walker County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 471,
    "County\nCode": 48471,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Huron, SD",
    "CBSA Solo": "Huron",
    "CBSA State": "SD",
    CBSA: "Huron, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Beadle County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Beadle County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "005",
    "County\nCode": 46005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Huron, SD",
    "CBSA Solo": "Huron",
    "CBSA State": "SD",
    CBSA: "Huron, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jerauld County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Jerauld County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "073",
    "County\nCode": 46073,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Hutchinson, KS",
    "CBSA Solo": "Hutchinson",
    "CBSA State": "KS",
    CBSA: "Hutchinson, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Reno County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Reno County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 155,
    "County\nCode": 20155,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26780,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Hutchinson, MN",
    "CBSA Solo": "Hutchinson",
    "CBSA State": "MN",
    CBSA: "Hutchinson, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "McLeod County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "McLeod County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "085",
    "County\nCode": 27085,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26820,
    "Metropolitan Division Code": "",
    "CSA Code": 292,
    "CBSA Full Title": "Idaho Falls, ID",
    "CBSA Solo": "Idaho Falls",
    "CBSA State": "ID",
    CBSA: "Idaho Falls, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Idaho Falls-Rexburg-Blackfoot, ID",
    "County/County Equivalent": "Bonneville County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Bonneville County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "019",
    "County\nCode": 16019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26820,
    "Metropolitan Division Code": "",
    "CSA Code": 292,
    "CBSA Full Title": "Idaho Falls, ID",
    "CBSA Solo": "Idaho Falls",
    "CBSA State": "ID",
    CBSA: "Idaho Falls, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Idaho Falls-Rexburg-Blackfoot, ID",
    "County/County Equivalent": "Butte County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Butte County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "023",
    "County\nCode": 16023,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26820,
    "Metropolitan Division Code": "",
    "CSA Code": 292,
    "CBSA Full Title": "Idaho Falls, ID",
    "CBSA Solo": "Idaho Falls",
    "CBSA State": "ID",
    CBSA: "Idaho Falls, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Idaho Falls-Rexburg-Blackfoot, ID",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Jefferson County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "051",
    "County\nCode": 16051,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26860,
    "Metropolitan Division Code": "",
    "CSA Code": 430,
    "CBSA Full Title": "Indiana, PA",
    "CBSA Solo": "Indiana",
    "CBSA State": "PA",
    CBSA: "Indiana, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    "County/County Equivalent": "Indiana County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Indiana County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "063",
    "County\nCode": 42063,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26900,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Indianapolis-Carmel-Anderson, IN",
    "CBSA Solo": "Indianapolis",
    "CBSA State": "IN",
    CBSA: "Indianapolis, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Boone County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Boone County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "011",
    "County\nCode": 18011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26900,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Indianapolis-Carmel-Anderson, IN",
    "CBSA Solo": "Indianapolis",
    "CBSA State": "IN",
    CBSA: "Indianapolis, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Brown County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Brown County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "013",
    "County\nCode": 18013,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26900,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Indianapolis-Carmel-Anderson, IN",
    "CBSA Solo": "Indianapolis",
    "CBSA State": "IN",
    CBSA: "Indianapolis, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Hamilton County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Hamilton County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "057",
    "County\nCode": 18057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26900,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Indianapolis-Carmel-Anderson, IN",
    "CBSA Solo": "Indianapolis",
    "CBSA State": "IN",
    CBSA: "Indianapolis, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Hancock County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Hancock County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "059",
    "County\nCode": 18059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26900,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Indianapolis-Carmel-Anderson, IN",
    "CBSA Solo": "Indianapolis",
    "CBSA State": "IN",
    CBSA: "Indianapolis, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Hendricks County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Hendricks County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "063",
    "County\nCode": 18063,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26900,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Indianapolis-Carmel-Anderson, IN",
    "CBSA Solo": "Indianapolis",
    "CBSA State": "IN",
    CBSA: "Indianapolis, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Johnson County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Johnson County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "081",
    "County\nCode": 18081,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26900,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Indianapolis-Carmel-Anderson, IN",
    "CBSA Solo": "Indianapolis",
    "CBSA State": "IN",
    CBSA: "Indianapolis, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Madison County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Madison County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "095",
    "County\nCode": 18095,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26900,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Indianapolis-Carmel-Anderson, IN",
    "CBSA Solo": "Indianapolis",
    "CBSA State": "IN",
    CBSA: "Indianapolis, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Marion County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Marion County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "097",
    "County\nCode": 18097,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26900,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Indianapolis-Carmel-Anderson, IN",
    "CBSA Solo": "Indianapolis",
    "CBSA State": "IN",
    CBSA: "Indianapolis, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Morgan County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Morgan County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 109,
    "County\nCode": 18109,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26900,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Indianapolis-Carmel-Anderson, IN",
    "CBSA Solo": "Indianapolis",
    "CBSA State": "IN",
    CBSA: "Indianapolis, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Putnam County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Putnam County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 133,
    "County\nCode": 18133,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26900,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Indianapolis-Carmel-Anderson, IN",
    "CBSA Solo": "Indianapolis",
    "CBSA State": "IN",
    CBSA: "Indianapolis, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Shelby County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Shelby County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 145,
    "County\nCode": 18145,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 26940,
    "Metropolitan Division Code": "",
    "CSA Code": 185,
    "CBSA Full Title": "Indianola, MS",
    "CBSA Solo": "Indianola",
    "CBSA State": "MS",
    CBSA: "Indianola, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Indianola, MS",
    "County/County Equivalent": "Sunflower County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Sunflower County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 133,
    "County\nCode": 28133,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26980,
    "Metropolitan Division Code": "",
    "CSA Code": 168,
    "CBSA Full Title": "Iowa City, IA",
    "CBSA Solo": "Iowa City",
    "CBSA State": "IA",
    CBSA: "Iowa City, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cedar Rapids-Iowa City, IA",
    "County/County Equivalent": "Johnson County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Johnson County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 103,
    "County\nCode": 19103,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 26980,
    "Metropolitan Division Code": "",
    "CSA Code": 168,
    "CBSA Full Title": "Iowa City, IA",
    "CBSA Solo": "Iowa City",
    "CBSA State": "IA",
    CBSA: "Iowa City, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cedar Rapids-Iowa City, IA",
    "County/County Equivalent": "Washington County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Washington County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 183,
    "County\nCode": 19183,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27020,
    "Metropolitan Division Code": "",
    "CSA Code": 361,
    "CBSA Full Title": "Iron Mountain, MI-WI",
    "CBSA Solo": "Iron Mountain",
    "CBSA State": "MI",
    CBSA: "Iron Mountain, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Marinette-Iron Mountain, WI-MI",
    "County/County Equivalent": "Dickinson County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Dickinson County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "043",
    "County\nCode": 26043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27020,
    "Metropolitan Division Code": "",
    "CSA Code": 361,
    "CBSA Full Title": "Iron Mountain, MI-WI",
    "CBSA Solo": "Iron Mountain",
    "CBSA State": "MI",
    CBSA: "Iron Mountain, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Marinette-Iron Mountain, WI-MI",
    "County/County Equivalent": "Florence County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Florence County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "037",
    "County\nCode": 55037,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27060,
    "Metropolitan Division Code": "",
    "CSA Code": 296,
    "CBSA Full Title": "Ithaca, NY",
    "CBSA Solo": "Ithaca",
    "CBSA State": "NY",
    CBSA: "Ithaca, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Ithaca-Cortland, NY",
    "County/County Equivalent": "Tompkins County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Tompkins County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": 109,
    "County\nCode": 36109,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jackson, MI",
    "CBSA Solo": "Jackson",
    "CBSA State": "MI",
    CBSA: "Jackson, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jackson County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Jackson County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "075",
    "County\nCode": 26075,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27140,
    "Metropolitan Division Code": "",
    "CSA Code": 298,
    "CBSA Full Title": "Jackson, MS",
    "CBSA Solo": "Jackson",
    "CBSA State": "MS",
    CBSA: "Jackson, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Vicksburg-Brookhaven, MS",
    "County/County Equivalent": "Copiah County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Copiah County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "029",
    "County\nCode": 28029,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27140,
    "Metropolitan Division Code": "",
    "CSA Code": 298,
    "CBSA Full Title": "Jackson, MS",
    "CBSA Solo": "Jackson",
    "CBSA State": "MS",
    CBSA: "Jackson, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Vicksburg-Brookhaven, MS",
    "County/County Equivalent": "Hinds County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Hinds County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "049",
    "County\nCode": 28049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27140,
    "Metropolitan Division Code": "",
    "CSA Code": 298,
    "CBSA Full Title": "Jackson, MS",
    "CBSA Solo": "Jackson",
    "CBSA State": "MS",
    CBSA: "Jackson, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Vicksburg-Brookhaven, MS",
    "County/County Equivalent": "Holmes County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Holmes County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "051",
    "County\nCode": 28051,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27140,
    "Metropolitan Division Code": "",
    "CSA Code": 298,
    "CBSA Full Title": "Jackson, MS",
    "CBSA Solo": "Jackson",
    "CBSA State": "MS",
    CBSA: "Jackson, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Vicksburg-Brookhaven, MS",
    "County/County Equivalent": "Madison County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Madison County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "089",
    "County\nCode": 28089,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27140,
    "Metropolitan Division Code": "",
    "CSA Code": 298,
    "CBSA Full Title": "Jackson, MS",
    "CBSA Solo": "Jackson",
    "CBSA State": "MS",
    CBSA: "Jackson, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Vicksburg-Brookhaven, MS",
    "County/County Equivalent": "Rankin County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Rankin County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 121,
    "County\nCode": 28121,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27140,
    "Metropolitan Division Code": "",
    "CSA Code": 298,
    "CBSA Full Title": "Jackson, MS",
    "CBSA Solo": "Jackson",
    "CBSA State": "MS",
    CBSA: "Jackson, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Vicksburg-Brookhaven, MS",
    "County/County Equivalent": "Simpson County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Simpson County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 127,
    "County\nCode": 28127,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27140,
    "Metropolitan Division Code": "",
    "CSA Code": 298,
    "CBSA Full Title": "Jackson, MS",
    "CBSA Solo": "Jackson",
    "CBSA State": "MS",
    CBSA: "Jackson, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Vicksburg-Brookhaven, MS",
    "County/County Equivalent": "Yazoo County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Yazoo County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 163,
    "County\nCode": 28163,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27160,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jackson, OH",
    "CBSA Solo": "Jackson",
    "CBSA State": "OH",
    CBSA: "Jackson, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jackson County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Jackson County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "079",
    "County\nCode": 39079,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27180,
    "Metropolitan Division Code": "",
    "CSA Code": 297,
    "CBSA Full Title": "Jackson, TN",
    "CBSA Solo": "Jackson",
    "CBSA State": "TN",
    CBSA: "Jackson, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Brownsville, TN",
    "County/County Equivalent": "Chester County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Chester County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "023",
    "County\nCode": 47023,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27180,
    "Metropolitan Division Code": "",
    "CSA Code": 297,
    "CBSA Full Title": "Jackson, TN",
    "CBSA Solo": "Jackson",
    "CBSA State": "TN",
    CBSA: "Jackson, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Brownsville, TN",
    "County/County Equivalent": "Crockett County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Crockett County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "033",
    "County\nCode": 47033,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27180,
    "Metropolitan Division Code": "",
    "CSA Code": 297,
    "CBSA Full Title": "Jackson, TN",
    "CBSA Solo": "Jackson",
    "CBSA State": "TN",
    CBSA: "Jackson, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Brownsville, TN",
    "County/County Equivalent": "Gibson County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Gibson County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "053",
    "County\nCode": 47053,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27180,
    "Metropolitan Division Code": "",
    "CSA Code": 297,
    "CBSA Full Title": "Jackson, TN",
    "CBSA Solo": "Jackson",
    "CBSA State": "TN",
    CBSA: "Jackson, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Brownsville, TN",
    "County/County Equivalent": "Madison County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Madison County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 113,
    "County\nCode": 47113,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jackson, WY-ID",
    "CBSA Solo": "Jackson",
    "CBSA State": "WY",
    CBSA: "Jackson, WY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Teton County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Teton County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "081",
    "County\nCode": 16081,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jackson, WY-ID",
    "CBSA Solo": "Jackson",
    "CBSA State": "WY",
    CBSA: "Jackson, WY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Teton County",
    "State Name": "Wyoming",
    "State Abbr": "WY",
    "County, State": "Teton County, WY",
    "FIPS State Code": 56,
    "FIPS County Code": "039",
    "County\nCode": 56039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27260,
    "Metropolitan Division Code": "",
    "CSA Code": 300,
    "CBSA Full Title": "Jacksonville, FL",
    "CBSA Solo": "Jacksonville",
    "CBSA State": "FL",
    CBSA: "Jacksonville, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jacksonville-St. Marys-Palatka, FL-GA",
    "County/County Equivalent": "Baker County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Baker County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "003",
    "County\nCode": 12003,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27260,
    "Metropolitan Division Code": "",
    "CSA Code": 300,
    "CBSA Full Title": "Jacksonville, FL",
    "CBSA Solo": "Jacksonville",
    "CBSA State": "FL",
    CBSA: "Jacksonville, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jacksonville-St. Marys-Palatka, FL-GA",
    "County/County Equivalent": "Clay County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Clay County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "019",
    "County\nCode": 12019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27260,
    "Metropolitan Division Code": "",
    "CSA Code": 300,
    "CBSA Full Title": "Jacksonville, FL",
    "CBSA Solo": "Jacksonville",
    "CBSA State": "FL",
    CBSA: "Jacksonville, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jacksonville-St. Marys-Palatka, FL-GA",
    "County/County Equivalent": "Duval County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Duval County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "031",
    "County\nCode": 12031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27260,
    "Metropolitan Division Code": "",
    "CSA Code": 300,
    "CBSA Full Title": "Jacksonville, FL",
    "CBSA Solo": "Jacksonville",
    "CBSA State": "FL",
    CBSA: "Jacksonville, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jacksonville-St. Marys-Palatka, FL-GA",
    "County/County Equivalent": "Nassau County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Nassau County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "089",
    "County\nCode": 12089,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27260,
    "Metropolitan Division Code": "",
    "CSA Code": 300,
    "CBSA Full Title": "Jacksonville, FL",
    "CBSA Solo": "Jacksonville",
    "CBSA State": "FL",
    CBSA: "Jacksonville, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jacksonville-St. Marys-Palatka, FL-GA",
    "County/County Equivalent": "St. Johns County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "St. Johns County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": 109,
    "County\nCode": 12109,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27300,
    "Metropolitan Division Code": "",
    "CSA Code": 522,
    "CBSA Full Title": "Jacksonville, IL",
    "CBSA Solo": "Jacksonville",
    "CBSA State": "IL",
    CBSA: "Jacksonville, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Springfield-Jacksonville-Lincoln, IL",
    "County/County Equivalent": "Morgan County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Morgan County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 137,
    "County\nCode": 17137,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27300,
    "Metropolitan Division Code": "",
    "CSA Code": 522,
    "CBSA Full Title": "Jacksonville, IL",
    "CBSA Solo": "Jacksonville",
    "CBSA State": "IL",
    CBSA: "Jacksonville, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Springfield-Jacksonville-Lincoln, IL",
    "County/County Equivalent": "Scott County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Scott County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 171,
    "County\nCode": 17171,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jacksonville, NC",
    "CBSA Solo": "Jacksonville",
    "CBSA State": "NC",
    CBSA: "Jacksonville, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Onslow County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Onslow County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 133,
    "County\nCode": 37133,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27380,
    "Metropolitan Division Code": "",
    "CSA Code": 540,
    "CBSA Full Title": "Jacksonville, TX",
    "CBSA Solo": "Jacksonville",
    "CBSA State": "TX",
    CBSA: "Jacksonville, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tyler-Jacksonville, TX",
    "County/County Equivalent": "Cherokee County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Cherokee County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "073",
    "County\nCode": 48073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jamestown, ND",
    "CBSA Solo": "Jamestown",
    "CBSA State": "ND",
    CBSA: "Jamestown, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Stutsman County",
    "State Name": "North Dakota",
    "State Abbr": "ND",
    "County, State": "Stutsman County, ND",
    "FIPS State Code": 38,
    "FIPS County Code": "093",
    "County\nCode": 38093,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jamestown-Dunkirk-Fredonia, NY",
    "CBSA Solo": "Jamestown",
    "CBSA State": "NY",
    CBSA: "Jamestown, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Chautauqua County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Chautauqua County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "013",
    "County\nCode": 36013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27500,
    "Metropolitan Division Code": "",
    "CSA Code": 357,
    "CBSA Full Title": "Janesville-Beloit, WI",
    "CBSA Solo": "Janesville",
    "CBSA State": "WI",
    CBSA: "Janesville, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Madison-Janesville-Beloit, WI",
    "County/County Equivalent": "Rock County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Rock County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": 105,
    "County\nCode": 55105,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27530,
    "Metropolitan Division Code": "",
    "CSA Code": 142,
    "CBSA Full Title": "Jasper, AL",
    "CBSA Solo": "Jasper",
    "CBSA State": "AL",
    CBSA: "Jasper, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Birmingham-Hoover-Talladega, AL",
    "County/County Equivalent": "Walker County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Walker County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": 127,
    "County\nCode": "01127",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jasper, IN",
    "CBSA Solo": "Jasper",
    "CBSA State": "IN",
    CBSA: "Jasper, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Dubois County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Dubois County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "037",
    "County\nCode": 18037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jasper, IN",
    "CBSA Solo": "Jasper",
    "CBSA State": "IN",
    CBSA: "Jasper, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pike County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Pike County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 125,
    "County\nCode": 18125,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jayuya, PR",
    "CBSA Solo": "Jayuya",
    "CBSA State": "PR",
    CBSA: "Jayuya, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jayuya Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Jayuya Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "073",
    "County\nCode": 72073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27600,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Jefferson, GA",
    "CBSA Solo": "Jefferson",
    "CBSA State": "GA",
    CBSA: "Jefferson, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Jackson County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Jackson County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 157,
    "County\nCode": 13157,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jefferson City, MO",
    "CBSA Solo": "Jefferson City",
    "CBSA State": "MO",
    CBSA: "Jefferson City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Callaway County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Callaway County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "027",
    "County\nCode": 29027,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jefferson City, MO",
    "CBSA Solo": "Jefferson City",
    "CBSA State": "MO",
    CBSA: "Jefferson City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cole County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Cole County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "051",
    "County\nCode": 29051,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jefferson City, MO",
    "CBSA Solo": "Jefferson City",
    "CBSA State": "MO",
    CBSA: "Jefferson City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Moniteau County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Moniteau County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 135,
    "County\nCode": 29135,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Jefferson City, MO",
    "CBSA Solo": "Jefferson City",
    "CBSA State": "MO",
    CBSA: "Jefferson City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Osage County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Osage County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 151,
    "County\nCode": 29151,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27660,
    "Metropolitan Division Code": "",
    "CSA Code": 324,
    "CBSA Full Title": "Jennings, LA",
    "CBSA Solo": "Jennings",
    "CBSA State": "LA",
    CBSA: "Jennings, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lake Charles-Jennings, LA",
    "County/County Equivalent": "Jefferson Davis Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Jefferson Davis Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "053",
    "County\nCode": 22053,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27700,
    "Metropolitan Division Code": "",
    "CSA Code": 496,
    "CBSA Full Title": "Jesup, GA",
    "CBSA Solo": "Jesup",
    "CBSA State": "GA",
    CBSA: "Jesup, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Savannah-Hinesville-Statesboro, GA",
    "County/County Equivalent": "Wayne County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Wayne County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 305,
    "County\nCode": 13305,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27740,
    "Metropolitan Division Code": "",
    "CSA Code": 304,
    "CBSA Full Title": "Johnson City, TN",
    "CBSA Solo": "Johnson City",
    "CBSA State": "TN",
    CBSA: "Johnson City, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Johnson City-Kingsport-Bristol, TN-VA",
    "County/County Equivalent": "Carter County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Carter County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "019",
    "County\nCode": 47019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27740,
    "Metropolitan Division Code": "",
    "CSA Code": 304,
    "CBSA Full Title": "Johnson City, TN",
    "CBSA Solo": "Johnson City",
    "CBSA State": "TN",
    CBSA: "Johnson City, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Johnson City-Kingsport-Bristol, TN-VA",
    "County/County Equivalent": "Unicoi County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Unicoi County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 171,
    "County\nCode": 47171,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27740,
    "Metropolitan Division Code": "",
    "CSA Code": 304,
    "CBSA Full Title": "Johnson City, TN",
    "CBSA Solo": "Johnson City",
    "CBSA State": "TN",
    CBSA: "Johnson City, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Johnson City-Kingsport-Bristol, TN-VA",
    "County/County Equivalent": "Washington County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Washington County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 179,
    "County\nCode": 47179,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27780,
    "Metropolitan Division Code": "",
    "CSA Code": 306,
    "CBSA Full Title": "Johnstown, PA",
    "CBSA Solo": "Johnstown",
    "CBSA State": "PA",
    CBSA: "Johnstown, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Johnstown-Somerset, PA",
    "County/County Equivalent": "Cambria County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Cambria County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "021",
    "County\nCode": 42021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27860,
    "Metropolitan Division Code": "",
    "CSA Code": 308,
    "CBSA Full Title": "Jonesboro, AR",
    "CBSA Solo": "Jonesboro",
    "CBSA State": "AR",
    CBSA: "Jonesboro, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jonesboro-Paragould, AR",
    "County/County Equivalent": "Craighead County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Craighead County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "031",
    "County\nCode": "05031",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27860,
    "Metropolitan Division Code": "",
    "CSA Code": 308,
    "CBSA Full Title": "Jonesboro, AR",
    "CBSA Solo": "Jonesboro",
    "CBSA State": "AR",
    CBSA: "Jonesboro, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jonesboro-Paragould, AR",
    "County/County Equivalent": "Poinsett County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Poinsett County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 111,
    "County\nCode": "05111",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27900,
    "Metropolitan Division Code": "",
    "CSA Code": 309,
    "CBSA Full Title": "Joplin, MO",
    "CBSA Solo": "Joplin",
    "CBSA State": "MO",
    CBSA: "Joplin, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Joplin-Miami, MO-OK",
    "County/County Equivalent": "Jasper County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Jasper County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "097",
    "County\nCode": 29097,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27900,
    "Metropolitan Division Code": "",
    "CSA Code": 309,
    "CBSA Full Title": "Joplin, MO",
    "CBSA Solo": "Joplin",
    "CBSA State": "MO",
    CBSA: "Joplin, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Joplin-Miami, MO-OK",
    "County/County Equivalent": "Newton County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Newton County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 145,
    "County\nCode": 29145,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 27940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Juneau, AK",
    "CBSA Solo": "Juneau",
    "CBSA State": "AK",
    CBSA: "Juneau, AK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Juneau City and Borough",
    "State Name": "Alaska",
    "State Abbr": "AK",
    "County, State": "Juneau City and Borough, AK",
    "FIPS State Code": "02",
    "FIPS County Code": 110,
    "County\nCode": "02110",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 27980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Kahului-Wailuku-Lahaina, HI",
    "CBSA Solo": "Kahului",
    "CBSA State": "HI",
    CBSA: "Kahului, HI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Maui County",
    "State Name": "Hawaii",
    "State Abbr": "HI",
    "County, State": "Maui County, HI",
    "FIPS State Code": 15,
    "FIPS County Code": "009",
    "County\nCode": 15009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28020,
    "Metropolitan Division Code": "",
    "CSA Code": 310,
    "CBSA Full Title": "Kalamazoo-Portage, MI",
    "CBSA Solo": "Kalamazoo",
    "CBSA State": "MI",
    CBSA: "Kalamazoo, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kalamazoo-Battle Creek-Portage, MI",
    "County/County Equivalent": "Kalamazoo County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Kalamazoo County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "077",
    "County\nCode": 26077,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Kalispell, MT",
    "CBSA Solo": "Kalispell",
    "CBSA State": "MT",
    CBSA: "Kalispell, MT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Flathead County",
    "State Name": "Montana",
    "State Abbr": "MT",
    "County, State": "Flathead County, MT",
    "FIPS State Code": 30,
    "FIPS County Code": "029",
    "County\nCode": 30029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28100,
    "Metropolitan Division Code": "",
    "CSA Code": 176,
    "CBSA Full Title": "Kankakee, IL",
    "CBSA Solo": "Kankakee",
    "CBSA State": "IL",
    CBSA: "Kankakee, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Kankakee County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Kankakee County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "091",
    "County\nCode": 17091,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Bates County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Bates County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "013",
    "County\nCode": 29013,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Johnson County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Johnson County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "091",
    "County\nCode": 20091,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Leavenworth County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Leavenworth County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 103,
    "County\nCode": 20103,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Linn County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Linn County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 107,
    "County\nCode": 20107,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Miami County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Miami County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 121,
    "County\nCode": 20121,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Wyandotte County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Wyandotte County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 209,
    "County\nCode": 20209,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Caldwell County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Caldwell County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "025",
    "County\nCode": 29025,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Cass County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Cass County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "037",
    "County\nCode": 29037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Clay County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Clay County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "047",
    "County\nCode": 29047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Clinton County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Clinton County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "049",
    "County\nCode": 29049,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Jackson County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Jackson County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "095",
    "County\nCode": 29095,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Lafayette County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Lafayette County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 107,
    "County\nCode": 29107,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Platte County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Platte County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 165,
    "County\nCode": 29165,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Kansas City, MO-KS",
    "CBSA Solo": "Kansas City",
    "CBSA State": "MO",
    CBSA: "Kansas City, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Ray County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Ray County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 177,
    "County\nCode": 29177,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Kapaa, HI",
    "CBSA Solo": "Kapaa",
    "CBSA State": "HI",
    CBSA: "Kapaa, HI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Kauai County",
    "State Name": "Hawaii",
    "State Abbr": "HI",
    "County, State": "Kauai County, HI",
    "FIPS State Code": 15,
    "FIPS County Code": "007",
    "County\nCode": 15007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Kearney, NE",
    "CBSA Solo": "Kearney",
    "CBSA State": "NE",
    CBSA: "Kearney, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Buffalo County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Buffalo County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "019",
    "County\nCode": 31019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Kearney, NE",
    "CBSA Solo": "Kearney",
    "CBSA State": "NE",
    CBSA: "Kearney, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Kearney County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Kearney County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "099",
    "County\nCode": 31099,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Keene, NH",
    "CBSA Solo": "Keene",
    "CBSA State": "NH",
    CBSA: "Keene, NH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cheshire County",
    "State Name": "New Hampshire",
    "State Abbr": "NH",
    "County, State": "Cheshire County, NH",
    "FIPS State Code": 33,
    "FIPS County Code": "005",
    "County\nCode": 33005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28340,
    "Metropolitan Division Code": "",
    "CSA Code": 258,
    "CBSA Full Title": "Kendallville, IN",
    "CBSA Solo": "Kendallville",
    "CBSA State": "IN",
    CBSA: "Kendallville, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fort Wayne-Huntington-Auburn, IN",
    "County/County Equivalent": "Noble County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Noble County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 113,
    "County\nCode": 18113,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Kennett, MO",
    "CBSA Solo": "Kennett",
    "CBSA State": "MO",
    CBSA: "Kennett, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Dunklin County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Dunklin County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "069",
    "County\nCode": 29069,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28420,
    "Metropolitan Division Code": "",
    "CSA Code": 313,
    "CBSA Full Title": "Kennewick-Richland, WA",
    "CBSA Solo": "Kennewick",
    "CBSA State": "WA",
    CBSA: "Kennewick, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kennewick-Richland-Walla Walla, WA",
    "County/County Equivalent": "Benton County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Benton County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "005",
    "County\nCode": 53005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28420,
    "Metropolitan Division Code": "",
    "CSA Code": 313,
    "CBSA Full Title": "Kennewick-Richland, WA",
    "CBSA Solo": "Kennewick",
    "CBSA State": "WA",
    CBSA: "Kennewick, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kennewick-Richland-Walla Walla, WA",
    "County/County Equivalent": "Franklin County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Franklin County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "021",
    "County\nCode": 53021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28500,
    "Metropolitan Division Code": "",
    "CSA Code": 314,
    "CBSA Full Title": "Kerrville, TX",
    "CBSA Solo": "Kerrville",
    "CBSA State": "TX",
    CBSA: "Kerrville, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kerrville-Fredericksburg, TX",
    "County/County Equivalent": "Kerr County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Kerr County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 265,
    "County\nCode": 48265,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Ketchikan, AK",
    "CBSA Solo": "Ketchikan",
    "CBSA State": "AK",
    CBSA: "Ketchikan, AK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ketchikan Gateway Borough",
    "State Name": "Alaska",
    "State Abbr": "AK",
    "County, State": "Ketchikan Gateway Borough, AK",
    "FIPS State Code": "02",
    "FIPS County Code": 130,
    "County\nCode": "02130",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28580,
    "Metropolitan Division Code": "",
    "CSA Code": 370,
    "CBSA Full Title": "Key West, FL",
    "CBSA Solo": "Key West",
    "CBSA State": "FL",
    CBSA: "Key West, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Miami-Port St. Lucie-Fort Lauderdale, FL",
    "County/County Equivalent": "Monroe County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Monroe County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "087",
    "County\nCode": 12087,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28620,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Kill Devil Hills, NC",
    "CBSA Solo": "Kill Devil Hills",
    "CBSA State": "NC",
    CBSA: "Kill Devil Hills, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Dare County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Dare County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "055",
    "County\nCode": 37055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Killeen-Temple, TX",
    "CBSA Solo": "Killeen",
    "CBSA State": "TX",
    CBSA: "Killeen, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Bell County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Bell County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "027",
    "County\nCode": 48027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Killeen-Temple, TX",
    "CBSA Solo": "Killeen",
    "CBSA State": "TX",
    CBSA: "Killeen, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Coryell County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Coryell County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "099",
    "County\nCode": 48099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Killeen-Temple, TX",
    "CBSA Solo": "Killeen",
    "CBSA State": "TX",
    CBSA: "Killeen, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lampasas County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Lampasas County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 281,
    "County\nCode": 48281,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28700,
    "Metropolitan Division Code": "",
    "CSA Code": 304,
    "CBSA Full Title": "Kingsport-Bristol, TN-VA",
    "CBSA Solo": "Kingsport",
    "CBSA State": "TN",
    CBSA: "Kingsport, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Johnson City-Kingsport-Bristol, TN-VA",
    "County/County Equivalent": "Hawkins County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Hawkins County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "073",
    "County\nCode": 47073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28700,
    "Metropolitan Division Code": "",
    "CSA Code": 304,
    "CBSA Full Title": "Kingsport-Bristol, TN-VA",
    "CBSA Solo": "Kingsport",
    "CBSA State": "TN",
    CBSA: "Kingsport, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Johnson City-Kingsport-Bristol, TN-VA",
    "County/County Equivalent": "Sullivan County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Sullivan County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 163,
    "County\nCode": 47163,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28700,
    "Metropolitan Division Code": "",
    "CSA Code": 304,
    "CBSA Full Title": "Kingsport-Bristol, TN-VA",
    "CBSA Solo": "Kingsport",
    "CBSA State": "TN",
    CBSA: "Kingsport, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Johnson City-Kingsport-Bristol, TN-VA",
    "County/County Equivalent": "Scott County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Scott County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 169,
    "County\nCode": 51169,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28700,
    "Metropolitan Division Code": "",
    "CSA Code": 304,
    "CBSA Full Title": "Kingsport-Bristol, TN-VA",
    "CBSA Solo": "Kingsport",
    "CBSA State": "TN",
    CBSA: "Kingsport, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Johnson City-Kingsport-Bristol, TN-VA",
    "County/County Equivalent": "Washington County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Washington County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 191,
    "County\nCode": 51191,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28700,
    "Metropolitan Division Code": "",
    "CSA Code": 304,
    "CBSA Full Title": "Kingsport-Bristol, TN-VA",
    "CBSA Solo": "Kingsport",
    "CBSA State": "TN",
    CBSA: "Kingsport, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Johnson City-Kingsport-Bristol, TN-VA",
    "County/County Equivalent": "Bristol city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Bristol city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 520,
    "County\nCode": 51520,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28740,
    "Metropolitan Division Code": "",
    "CSA Code": 408,
    "CBSA Full Title": "Kingston, NY",
    "CBSA Solo": "Kingston",
    "CBSA State": "NY",
    CBSA: "Kingston, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Ulster County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Ulster County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": 111,
    "County\nCode": 36111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28780,
    "Metropolitan Division Code": "",
    "CSA Code": 204,
    "CBSA Full Title": "Kingsville, TX",
    "CBSA Solo": "Kingsville",
    "CBSA State": "TX",
    CBSA: "Kingsville, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Corpus Christi-Kingsville-Alice, TX",
    "County/County Equivalent": "Kenedy County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Kenedy County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 261,
    "County\nCode": 48261,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28780,
    "Metropolitan Division Code": "",
    "CSA Code": 204,
    "CBSA Full Title": "Kingsville, TX",
    "CBSA Solo": "Kingsville",
    "CBSA State": "TX",
    CBSA: "Kingsville, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Corpus Christi-Kingsville-Alice, TX",
    "County/County Equivalent": "Kleberg County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Kleberg County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 273,
    "County\nCode": 48273,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28820,
    "Metropolitan Division Code": "",
    "CSA Code": 272,
    "CBSA Full Title": "Kinston, NC",
    "CBSA Solo": "Kinston",
    "CBSA State": "NC",
    CBSA: "Kinston, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greenville-Kinston-Washington, NC",
    "County/County Equivalent": "Lenoir County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Lenoir County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 107,
    "County\nCode": 37107,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Kirksville, MO",
    "CBSA Solo": "Kirksville",
    "CBSA State": "MO",
    CBSA: "Kirksville, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Adair County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Adair County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "001",
    "County\nCode": 29001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Kirksville, MO",
    "CBSA Solo": "Kirksville",
    "CBSA State": "MO",
    CBSA: "Kirksville, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Schuyler County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Schuyler County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 197,
    "County\nCode": 29197,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Klamath Falls, OR",
    "CBSA Solo": "Klamath Falls",
    "CBSA State": "OR",
    CBSA: "Klamath Falls, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Klamath County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Klamath County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "035",
    "County\nCode": 41035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28940,
    "Metropolitan Division Code": "",
    "CSA Code": 315,
    "CBSA Full Title": "Knoxville, TN",
    "CBSA Solo": "Knoxville",
    "CBSA State": "TN",
    CBSA: "Knoxville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Knoxville-Morristown-Sevierville, TN",
    "County/County Equivalent": "Anderson County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Anderson County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "001",
    "County\nCode": 47001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28940,
    "Metropolitan Division Code": "",
    "CSA Code": 315,
    "CBSA Full Title": "Knoxville, TN",
    "CBSA Solo": "Knoxville",
    "CBSA State": "TN",
    CBSA: "Knoxville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Knoxville-Morristown-Sevierville, TN",
    "County/County Equivalent": "Blount County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Blount County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "009",
    "County\nCode": 47009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28940,
    "Metropolitan Division Code": "",
    "CSA Code": 315,
    "CBSA Full Title": "Knoxville, TN",
    "CBSA Solo": "Knoxville",
    "CBSA State": "TN",
    CBSA: "Knoxville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Knoxville-Morristown-Sevierville, TN",
    "County/County Equivalent": "Campbell County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Campbell County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "013",
    "County\nCode": 47013,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28940,
    "Metropolitan Division Code": "",
    "CSA Code": 315,
    "CBSA Full Title": "Knoxville, TN",
    "CBSA Solo": "Knoxville",
    "CBSA State": "TN",
    CBSA: "Knoxville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Knoxville-Morristown-Sevierville, TN",
    "County/County Equivalent": "Knox County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Knox County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "093",
    "County\nCode": 47093,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28940,
    "Metropolitan Division Code": "",
    "CSA Code": 315,
    "CBSA Full Title": "Knoxville, TN",
    "CBSA Solo": "Knoxville",
    "CBSA State": "TN",
    CBSA: "Knoxville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Knoxville-Morristown-Sevierville, TN",
    "County/County Equivalent": "Loudon County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Loudon County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 105,
    "County\nCode": 47105,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 28940,
    "Metropolitan Division Code": "",
    "CSA Code": 315,
    "CBSA Full Title": "Knoxville, TN",
    "CBSA Solo": "Knoxville",
    "CBSA State": "TN",
    CBSA: "Knoxville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Knoxville-Morristown-Sevierville, TN",
    "County/County Equivalent": "Morgan County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Morgan County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 129,
    "County\nCode": 47129,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28940,
    "Metropolitan Division Code": "",
    "CSA Code": 315,
    "CBSA Full Title": "Knoxville, TN",
    "CBSA Solo": "Knoxville",
    "CBSA State": "TN",
    CBSA: "Knoxville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Knoxville-Morristown-Sevierville, TN",
    "County/County Equivalent": "Roane County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Roane County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 145,
    "County\nCode": 47145,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 28940,
    "Metropolitan Division Code": "",
    "CSA Code": 315,
    "CBSA Full Title": "Knoxville, TN",
    "CBSA Solo": "Knoxville",
    "CBSA State": "TN",
    CBSA: "Knoxville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Knoxville-Morristown-Sevierville, TN",
    "County/County Equivalent": "Union County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Union County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 173,
    "County\nCode": 47173,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 29020,
    "Metropolitan Division Code": "",
    "CSA Code": 316,
    "CBSA Full Title": "Kokomo, IN",
    "CBSA Solo": "Kokomo",
    "CBSA State": "IN",
    CBSA: "Kokomo, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kokomo-Peru, IN",
    "County/County Equivalent": "Howard County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Howard County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "067",
    "County\nCode": 18067,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29060,
    "Metropolitan Division Code": "",
    "CSA Code": 148,
    "CBSA Full Title": "Laconia, NH",
    "CBSA Solo": "Laconia",
    "CBSA State": "NH",
    CBSA: "Laconia, NH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Belknap County",
    "State Name": "New Hampshire",
    "State Abbr": "NH",
    "County, State": "Belknap County, NH",
    "FIPS State Code": 33,
    "FIPS County Code": "001",
    "County\nCode": 33001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "La Crosse-Onalaska, WI-MN",
    "CBSA Solo": "La Crosse",
    "CBSA State": "WI",
    CBSA: "La Crosse, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "La Crosse County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "La Crosse County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "063",
    "County\nCode": 55063,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "La Crosse-Onalaska, WI-MN",
    "CBSA Solo": "La Crosse",
    "CBSA State": "WI",
    CBSA: "La Crosse, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Houston County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Houston County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "055",
    "County\nCode": 27055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29180,
    "Metropolitan Division Code": "",
    "CSA Code": 318,
    "CBSA Full Title": "Lafayette, LA",
    "CBSA Solo": "Lafayette",
    "CBSA State": "LA",
    CBSA: "Lafayette, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lafayette-Opelousas-Morgan City, LA",
    "County/County Equivalent": "Acadia Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Acadia Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "001",
    "County\nCode": 22001,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 29180,
    "Metropolitan Division Code": "",
    "CSA Code": 318,
    "CBSA Full Title": "Lafayette, LA",
    "CBSA Solo": "Lafayette",
    "CBSA State": "LA",
    CBSA: "Lafayette, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lafayette-Opelousas-Morgan City, LA",
    "County/County Equivalent": "Iberia Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Iberia Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "045",
    "County\nCode": 22045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29180,
    "Metropolitan Division Code": "",
    "CSA Code": 318,
    "CBSA Full Title": "Lafayette, LA",
    "CBSA Solo": "Lafayette",
    "CBSA State": "LA",
    CBSA: "Lafayette, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lafayette-Opelousas-Morgan City, LA",
    "County/County Equivalent": "Lafayette Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Lafayette Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "055",
    "County\nCode": 22055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29180,
    "Metropolitan Division Code": "",
    "CSA Code": 318,
    "CBSA Full Title": "Lafayette, LA",
    "CBSA Solo": "Lafayette",
    "CBSA State": "LA",
    CBSA: "Lafayette, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lafayette-Opelousas-Morgan City, LA",
    "County/County Equivalent": "St. Martin Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "St. Martin Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "099",
    "County\nCode": 22099,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 29180,
    "Metropolitan Division Code": "",
    "CSA Code": 318,
    "CBSA Full Title": "Lafayette, LA",
    "CBSA Solo": "Lafayette",
    "CBSA State": "LA",
    CBSA: "Lafayette, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lafayette-Opelousas-Morgan City, LA",
    "County/County Equivalent": "Vermilion Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Vermilion Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": 113,
    "County\nCode": 22113,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 29200,
    "Metropolitan Division Code": "",
    "CSA Code": 320,
    "CBSA Full Title": "Lafayette-West Lafayette, IN",
    "CBSA Solo": "Lafayette",
    "CBSA State": "IN",
    CBSA: "Lafayette, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lafayette-West Lafayette-Frankfort, IN",
    "County/County Equivalent": "Benton County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Benton County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "007",
    "County\nCode": 18007,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 29200,
    "Metropolitan Division Code": "",
    "CSA Code": 320,
    "CBSA Full Title": "Lafayette-West Lafayette, IN",
    "CBSA Solo": "Lafayette",
    "CBSA State": "IN",
    CBSA: "Lafayette, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lafayette-West Lafayette-Frankfort, IN",
    "County/County Equivalent": "Carroll County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Carroll County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "015",
    "County\nCode": 18015,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 29200,
    "Metropolitan Division Code": "",
    "CSA Code": 320,
    "CBSA Full Title": "Lafayette-West Lafayette, IN",
    "CBSA Solo": "Lafayette",
    "CBSA State": "IN",
    CBSA: "Lafayette, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lafayette-West Lafayette-Frankfort, IN",
    "County/County Equivalent": "Tippecanoe County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Tippecanoe County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 157,
    "County\nCode": 18157,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29200,
    "Metropolitan Division Code": "",
    "CSA Code": 320,
    "CBSA Full Title": "Lafayette-West Lafayette, IN",
    "CBSA Solo": "Lafayette",
    "CBSA State": "IN",
    CBSA: "Lafayette, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lafayette-West Lafayette-Frankfort, IN",
    "County/County Equivalent": "Warren County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Warren County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 171,
    "County\nCode": 18171,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 29260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "La Grande, OR",
    "CBSA Solo": "La Grande",
    "CBSA State": "OR",
    CBSA: "La Grande, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Union County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Union County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "061",
    "County\nCode": 41061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29300,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "LaGrange, GA-AL",
    "CBSA Solo": "LaGrange",
    "CBSA State": "GA",
    CBSA: "LaGrange, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Chambers County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Chambers County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "017",
    "County\nCode": "01017",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 29300,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "LaGrange, GA-AL",
    "CBSA Solo": "LaGrange",
    "CBSA State": "GA",
    CBSA: "LaGrange, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Troup County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Troup County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 285,
    "County\nCode": 13285,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29340,
    "Metropolitan Division Code": "",
    "CSA Code": 324,
    "CBSA Full Title": "Lake Charles, LA",
    "CBSA Solo": "Lake Charles",
    "CBSA State": "LA",
    CBSA: "Lake Charles, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lake Charles-Jennings, LA",
    "County/County Equivalent": "Calcasieu Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Calcasieu Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "019",
    "County\nCode": 22019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29340,
    "Metropolitan Division Code": "",
    "CSA Code": 324,
    "CBSA Full Title": "Lake Charles, LA",
    "CBSA Solo": "Lake Charles",
    "CBSA State": "LA",
    CBSA: "Lake Charles, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lake Charles-Jennings, LA",
    "County/County Equivalent": "Cameron Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Cameron Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "023",
    "County\nCode": 22023,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 29380,
    "Metropolitan Division Code": "",
    "CSA Code": 264,
    "CBSA Full Title": "Lake City, FL",
    "CBSA Solo": "Lake City",
    "CBSA State": "FL",
    CBSA: "Lake City, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Gainesville-Lake City, FL",
    "County/County Equivalent": "Columbia County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Columbia County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "023",
    "County\nCode": 12023,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lake Havasu City-Kingman, AZ",
    "CBSA Solo": "Lake Havasu City",
    "CBSA State": "AZ",
    CBSA: "Lake Havasu City, AZ",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Mohave County",
    "State Name": "Arizona",
    "State Abbr": "AZ",
    "County, State": "Mohave County, AZ",
    "FIPS State Code": "04",
    "FIPS County Code": "015",
    "County\nCode": "04015",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29460,
    "Metropolitan Division Code": "",
    "CSA Code": 422,
    "CBSA Full Title": "Lakeland-Winter Haven, FL",
    "CBSA Solo": "Lakeland",
    "CBSA State": "FL",
    CBSA: "Lakeland, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Orlando-Lakeland-Deltona, FL",
    "County/County Equivalent": "Polk County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Polk County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": 105,
    "County\nCode": 12105,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lamesa, TX",
    "CBSA Solo": "Lamesa",
    "CBSA State": "TX",
    CBSA: "Lamesa, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Dawson County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Dawson County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 115,
    "County\nCode": 48115,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lancaster, PA",
    "CBSA Solo": "Lancaster",
    "CBSA State": "PA",
    CBSA: "Lancaster, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lancaster County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Lancaster County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "071",
    "County\nCode": 42071,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lansing-East Lansing, MI",
    "CBSA Solo": "Lansing",
    "CBSA State": "MI",
    CBSA: "Lansing, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Clinton County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Clinton County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "037",
    "County\nCode": 26037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lansing-East Lansing, MI",
    "CBSA Solo": "Lansing",
    "CBSA State": "MI",
    CBSA: "Lansing, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Eaton County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Eaton County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "045",
    "County\nCode": 26045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lansing-East Lansing, MI",
    "CBSA Solo": "Lansing",
    "CBSA State": "MI",
    CBSA: "Lansing, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ingham County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Ingham County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "065",
    "County\nCode": 26065,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lansing-East Lansing, MI",
    "CBSA Solo": "Lansing",
    "CBSA State": "MI",
    CBSA: "Lansing, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Shiawassee County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Shiawassee County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 155,
    "County\nCode": 26155,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 29660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Laramie, WY",
    "CBSA Solo": "Laramie",
    "CBSA State": "WY",
    CBSA: "Laramie, WY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Albany County",
    "State Name": "Wyoming",
    "State Abbr": "WY",
    "County, State": "Albany County, WY",
    "FIPS State Code": 56,
    "FIPS County Code": "001",
    "County\nCode": 56001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Laredo, TX",
    "CBSA Solo": "Laredo",
    "CBSA State": "TX",
    CBSA: "Laredo, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Webb County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Webb County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 479,
    "County\nCode": 48479,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29740,
    "Metropolitan Division Code": "",
    "CSA Code": 238,
    "CBSA Full Title": "Las Cruces, NM",
    "CBSA Solo": "Las Cruces",
    "CBSA State": "NM",
    CBSA: "Las Cruces, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "El Paso-Las Cruces, TX-NM",
    "County/County Equivalent": "Doña Ana County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Doña Ana County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "013",
    "County\nCode": 35013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29780,
    "Metropolitan Division Code": "",
    "CSA Code": 106,
    "CBSA Full Title": "Las Vegas, NM",
    "CBSA Solo": "Las Vegas",
    "CBSA State": "NM",
    CBSA: "Las Vegas, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albuquerque-Santa Fe-Las Vegas, NM",
    "County/County Equivalent": "Mora County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Mora County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "033",
    "County\nCode": 35033,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 29780,
    "Metropolitan Division Code": "",
    "CSA Code": 106,
    "CBSA Full Title": "Las Vegas, NM",
    "CBSA Solo": "Las Vegas",
    "CBSA State": "NM",
    CBSA: "Las Vegas, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albuquerque-Santa Fe-Las Vegas, NM",
    "County/County Equivalent": "San Miguel County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "San Miguel County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "047",
    "County\nCode": 35047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29820,
    "Metropolitan Division Code": "",
    "CSA Code": 332,
    "CBSA Full Title": "Las Vegas-Henderson-Paradise, NV",
    "CBSA Solo": "Las Vegas",
    "CBSA State": "NV",
    CBSA: "Las Vegas, NV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Las Vegas-Henderson, NV",
    "County/County Equivalent": "Clark County",
    "State Name": "Nevada",
    "State Abbr": "NV",
    "County, State": "Clark County, NV",
    "FIPS State Code": 32,
    "FIPS County Code": "003",
    "County\nCode": 32003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29860,
    "Metropolitan Division Code": "",
    "CSA Code": 279,
    "CBSA Full Title": "Laurel, MS",
    "CBSA Solo": "Laurel",
    "CBSA State": "MS",
    CBSA: "Laurel, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Hattiesburg-Laurel, MS",
    "County/County Equivalent": "Jasper County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Jasper County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "061",
    "County\nCode": 28061,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 29860,
    "Metropolitan Division Code": "",
    "CSA Code": 279,
    "CBSA Full Title": "Laurel, MS",
    "CBSA Solo": "Laurel",
    "CBSA State": "MS",
    CBSA: "Laurel, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Hattiesburg-Laurel, MS",
    "County/County Equivalent": "Jones County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Jones County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "067",
    "County\nCode": 28067,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29900,
    "Metropolitan Division Code": "",
    "CSA Code": 246,
    "CBSA Full Title": "Laurinburg, NC",
    "CBSA Solo": "Laurinburg",
    "CBSA State": "NC",
    CBSA: "Laurinburg, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fayetteville-Sanford-Lumberton, NC",
    "County/County Equivalent": "Scotland County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Scotland County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 165,
    "County\nCode": 37165,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29940,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Lawrence, KS",
    "CBSA Solo": "Lawrence",
    "CBSA State": "KS",
    CBSA: "Lawrence, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Douglas County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Douglas County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "045",
    "County\nCode": 20045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 29980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Lawrenceburg, TN",
    "CBSA Solo": "Lawrenceburg",
    "CBSA State": "TN",
    CBSA: "Lawrenceburg, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Lawrence County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Lawrence County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "099",
    "County\nCode": 47099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30020,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lawton, OK",
    "CBSA Solo": "Lawton",
    "CBSA State": "OK",
    CBSA: "Lawton, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Comanche County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Comanche County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "031",
    "County\nCode": 40031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30020,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lawton, OK",
    "CBSA Solo": "Lawton",
    "CBSA State": "OK",
    CBSA: "Lawton, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cotton County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Cotton County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "033",
    "County\nCode": 40033,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lebanon, MO",
    "CBSA Solo": "Lebanon",
    "CBSA State": "MO",
    CBSA: "Lebanon, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Laclede County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Laclede County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 105,
    "County\nCode": 29105,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lebanon, NH-VT",
    "CBSA Solo": "Lebanon",
    "CBSA State": "NH",
    CBSA: "Lebanon, NH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Grafton County",
    "State Name": "New Hampshire",
    "State Abbr": "NH",
    "County, State": "Grafton County, NH",
    "FIPS State Code": 33,
    "FIPS County Code": "009",
    "County\nCode": 33009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lebanon, NH-VT",
    "CBSA Solo": "Lebanon",
    "CBSA State": "NH",
    CBSA: "Lebanon, NH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Sullivan County",
    "State Name": "New Hampshire",
    "State Abbr": "NH",
    "County, State": "Sullivan County, NH",
    "FIPS State Code": 33,
    "FIPS County Code": "019",
    "County\nCode": 33019,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lebanon, NH-VT",
    "CBSA Solo": "Lebanon",
    "CBSA State": "NH",
    CBSA: "Lebanon, NH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Orange County",
    "State Name": "Vermont",
    "State Abbr": "VT",
    "County, State": "Orange County, VT",
    "FIPS State Code": 50,
    "FIPS County Code": "017",
    "County\nCode": 50017,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lebanon, NH-VT",
    "CBSA Solo": "Lebanon",
    "CBSA State": "NH",
    CBSA: "Lebanon, NH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Windsor County",
    "State Name": "Vermont",
    "State Abbr": "VT",
    "County, State": "Windsor County, VT",
    "FIPS State Code": 50,
    "FIPS County Code": "027",
    "County\nCode": 50027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30140,
    "Metropolitan Division Code": "",
    "CSA Code": 276,
    "CBSA Full Title": "Lebanon, PA",
    "CBSA Solo": "Lebanon",
    "CBSA State": "PA",
    CBSA: "Lebanon, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Harrisburg-York-Lebanon, PA",
    "County/County Equivalent": "Lebanon County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Lebanon County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "075",
    "County\nCode": 42075,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30220,
    "Metropolitan Division Code": "",
    "CSA Code": 352,
    "CBSA Full Title": "Levelland, TX",
    "CBSA Solo": "Levelland",
    "CBSA State": "TX",
    CBSA: "Levelland, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lubbock-Plainview-Levelland, TX",
    "County/County Equivalent": "Hockley County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Hockley County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 219,
    "County\nCode": 48219,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30260,
    "Metropolitan Division Code": "",
    "CSA Code": 146,
    "CBSA Full Title": "Lewisburg, PA",
    "CBSA Solo": "Lewisburg",
    "CBSA State": "PA",
    CBSA: "Lewisburg, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bloomsburg-Berwick-Sunbury, PA",
    "County/County Equivalent": "Union County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Union County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": 119,
    "County\nCode": 42119,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30280,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Lewisburg, TN",
    "CBSA Solo": "Lewisburg",
    "CBSA State": "TN",
    CBSA: "Lewisburg, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Marshall County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Marshall County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 117,
    "County\nCode": 47117,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lewiston, ID-WA",
    "CBSA Solo": "Lewiston",
    "CBSA State": "ID",
    CBSA: "Lewiston, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Nez Perce County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Nez Perce County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "069",
    "County\nCode": 16069,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lewiston, ID-WA",
    "CBSA Solo": "Lewiston",
    "CBSA State": "ID",
    CBSA: "Lewiston, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Asotin County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Asotin County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "003",
    "County\nCode": 53003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30340,
    "Metropolitan Division Code": "",
    "CSA Code": 438,
    "CBSA Full Title": "Lewiston-Auburn, ME",
    "CBSA Solo": "Lewiston",
    "CBSA State": "ME",
    CBSA: "Lewiston, ME",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Lewiston-South Portland, ME",
    "County/County Equivalent": "Androscoggin County",
    "State Name": "Maine",
    "State Abbr": "ME",
    "County, State": "Androscoggin County, ME",
    "FIPS State Code": 23,
    "FIPS County Code": "001",
    "County\nCode": 23001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lewistown, PA",
    "CBSA Solo": "Lewistown",
    "CBSA State": "PA",
    CBSA: "Lewistown, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Mifflin County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Mifflin County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "087",
    "County\nCode": 42087,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lexington, NE",
    "CBSA Solo": "Lexington",
    "CBSA State": "NE",
    CBSA: "Lexington, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Dawson County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Dawson County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "047",
    "County\nCode": 31047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lexington, NE",
    "CBSA Solo": "Lexington",
    "CBSA State": "NE",
    CBSA: "Lexington, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Gosper County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Gosper County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "073",
    "County\nCode": 31073,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30460,
    "Metropolitan Division Code": "",
    "CSA Code": 336,
    "CBSA Full Title": "Lexington-Fayette, KY",
    "CBSA Solo": "Lexington",
    "CBSA State": "KY",
    CBSA: "Lexington, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lexington-Fayette--Richmond--Frankfort, KY",
    "County/County Equivalent": "Bourbon County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Bourbon County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "017",
    "County\nCode": 21017,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30460,
    "Metropolitan Division Code": "",
    "CSA Code": 336,
    "CBSA Full Title": "Lexington-Fayette, KY",
    "CBSA Solo": "Lexington",
    "CBSA State": "KY",
    CBSA: "Lexington, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lexington-Fayette--Richmond--Frankfort, KY",
    "County/County Equivalent": "Clark County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Clark County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "049",
    "County\nCode": 21049,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30460,
    "Metropolitan Division Code": "",
    "CSA Code": 336,
    "CBSA Full Title": "Lexington-Fayette, KY",
    "CBSA Solo": "Lexington",
    "CBSA State": "KY",
    CBSA: "Lexington, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lexington-Fayette--Richmond--Frankfort, KY",
    "County/County Equivalent": "Fayette County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Fayette County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "067",
    "County\nCode": 21067,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30460,
    "Metropolitan Division Code": "",
    "CSA Code": 336,
    "CBSA Full Title": "Lexington-Fayette, KY",
    "CBSA Solo": "Lexington",
    "CBSA State": "KY",
    CBSA: "Lexington, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lexington-Fayette--Richmond--Frankfort, KY",
    "County/County Equivalent": "Jessamine County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Jessamine County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 113,
    "County\nCode": 21113,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30460,
    "Metropolitan Division Code": "",
    "CSA Code": 336,
    "CBSA Full Title": "Lexington-Fayette, KY",
    "CBSA Solo": "Lexington",
    "CBSA State": "KY",
    CBSA: "Lexington, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lexington-Fayette--Richmond--Frankfort, KY",
    "County/County Equivalent": "Scott County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Scott County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 209,
    "County\nCode": 21209,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30460,
    "Metropolitan Division Code": "",
    "CSA Code": 336,
    "CBSA Full Title": "Lexington-Fayette, KY",
    "CBSA Solo": "Lexington",
    "CBSA State": "KY",
    CBSA: "Lexington, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lexington-Fayette--Richmond--Frankfort, KY",
    "County/County Equivalent": "Woodford County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Woodford County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 239,
    "County\nCode": 21239,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Liberal, KS",
    "CBSA Solo": "Liberal",
    "CBSA State": "KS",
    CBSA: "Liberal, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Seward County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Seward County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 175,
    "County\nCode": 20175,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30620,
    "Metropolitan Division Code": "",
    "CSA Code": 338,
    "CBSA Full Title": "Lima, OH",
    "CBSA Solo": "Lima",
    "CBSA State": "OH",
    CBSA: "Lima, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lima-Van Wert-Celina, OH",
    "County/County Equivalent": "Allen County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Allen County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "003",
    "County\nCode": 39003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30660,
    "Metropolitan Division Code": "",
    "CSA Code": 522,
    "CBSA Full Title": "Lincoln, IL",
    "CBSA Solo": "Lincoln",
    "CBSA State": "IL",
    CBSA: "Lincoln, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Springfield-Jacksonville-Lincoln, IL",
    "County/County Equivalent": "Logan County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Logan County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 107,
    "County\nCode": 17107,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30700,
    "Metropolitan Division Code": "",
    "CSA Code": 339,
    "CBSA Full Title": "Lincoln, NE",
    "CBSA Solo": "Lincoln",
    "CBSA State": "NE",
    CBSA: "Lincoln, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lincoln-Beatrice, NE",
    "County/County Equivalent": "Lancaster County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Lancaster County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 109,
    "County\nCode": 31109,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30700,
    "Metropolitan Division Code": "",
    "CSA Code": 339,
    "CBSA Full Title": "Lincoln, NE",
    "CBSA Solo": "Lincoln",
    "CBSA State": "NE",
    CBSA: "Lincoln, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lincoln-Beatrice, NE",
    "County/County Equivalent": "Seward County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Seward County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 159,
    "County\nCode": 31159,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30780,
    "Metropolitan Division Code": "",
    "CSA Code": 340,
    "CBSA Full Title": "Little Rock-North Little Rock-Conway, AR",
    "CBSA Solo": "Little Rock",
    "CBSA State": "AR",
    CBSA: "Little Rock, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Little Rock-North Little Rock, AR",
    "County/County Equivalent": "Faulkner County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Faulkner County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "045",
    "County\nCode": "05045",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30780,
    "Metropolitan Division Code": "",
    "CSA Code": 340,
    "CBSA Full Title": "Little Rock-North Little Rock-Conway, AR",
    "CBSA Solo": "Little Rock",
    "CBSA State": "AR",
    CBSA: "Little Rock, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Little Rock-North Little Rock, AR",
    "County/County Equivalent": "Grant County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Grant County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "053",
    "County\nCode": "05053",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30780,
    "Metropolitan Division Code": "",
    "CSA Code": 340,
    "CBSA Full Title": "Little Rock-North Little Rock-Conway, AR",
    "CBSA Solo": "Little Rock",
    "CBSA State": "AR",
    CBSA: "Little Rock, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Little Rock-North Little Rock, AR",
    "County/County Equivalent": "Lonoke County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Lonoke County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "085",
    "County\nCode": "05085",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30780,
    "Metropolitan Division Code": "",
    "CSA Code": 340,
    "CBSA Full Title": "Little Rock-North Little Rock-Conway, AR",
    "CBSA Solo": "Little Rock",
    "CBSA State": "AR",
    CBSA: "Little Rock, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Little Rock-North Little Rock, AR",
    "County/County Equivalent": "Perry County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Perry County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 105,
    "County\nCode": "05105",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30780,
    "Metropolitan Division Code": "",
    "CSA Code": 340,
    "CBSA Full Title": "Little Rock-North Little Rock-Conway, AR",
    "CBSA Solo": "Little Rock",
    "CBSA State": "AR",
    CBSA: "Little Rock, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Little Rock-North Little Rock, AR",
    "County/County Equivalent": "Pulaski County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Pulaski County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 119,
    "County\nCode": "05119",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30780,
    "Metropolitan Division Code": "",
    "CSA Code": 340,
    "CBSA Full Title": "Little Rock-North Little Rock-Conway, AR",
    "CBSA Solo": "Little Rock",
    "CBSA State": "AR",
    CBSA: "Little Rock, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Little Rock-North Little Rock, AR",
    "County/County Equivalent": "Saline County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Saline County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 125,
    "County\nCode": "05125",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30820,
    "Metropolitan Division Code": "",
    "CSA Code": 558,
    "CBSA Full Title": "Lock Haven, PA",
    "CBSA Solo": "Lock Haven",
    "CBSA State": "PA",
    CBSA: "Lock Haven, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Williamsport-Lock Haven, PA",
    "County/County Equivalent": "Clinton County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Clinton County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "035",
    "County\nCode": 42035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Logan, UT-ID",
    "CBSA Solo": "Logan",
    "CBSA State": "UT",
    CBSA: "Logan, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cache County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Cache County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "005",
    "County\nCode": 49005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Logan, UT-ID",
    "CBSA Solo": "Logan",
    "CBSA State": "UT",
    CBSA: "Logan, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Franklin County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Franklin County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "041",
    "County\nCode": 16041,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Logansport, IN",
    "CBSA Solo": "Logansport",
    "CBSA State": "IN",
    CBSA: "Logansport, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cass County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Cass County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "017",
    "County\nCode": 18017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "London, KY",
    "CBSA Solo": "London",
    "CBSA State": "KY",
    CBSA: "London, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Clay County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Clay County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "051",
    "County\nCode": 21051,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "London, KY",
    "CBSA Solo": "London",
    "CBSA State": "KY",
    CBSA: "London, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Knox County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Knox County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 121,
    "County\nCode": 21121,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "London, KY",
    "CBSA Solo": "London",
    "CBSA State": "KY",
    CBSA: "London, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Laurel County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Laurel County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 125,
    "County\nCode": 21125,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "London, KY",
    "CBSA Solo": "London",
    "CBSA State": "KY",
    CBSA: "London, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Whitley County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Whitley County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 235,
    "County\nCode": 21235,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Longview, TX",
    "CBSA Solo": "Longview",
    "CBSA State": "TX",
    CBSA: "Longview, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Gregg County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Gregg County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 183,
    "County\nCode": 48183,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 30980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Longview, TX",
    "CBSA Solo": "Longview",
    "CBSA State": "TX",
    CBSA: "Longview, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Harrison County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Harrison County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 203,
    "County\nCode": 48203,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Longview, TX",
    "CBSA Solo": "Longview",
    "CBSA State": "TX",
    CBSA: "Longview, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Rusk County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Rusk County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 401,
    "County\nCode": 48401,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 30980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Longview, TX",
    "CBSA Solo": "Longview",
    "CBSA State": "TX",
    CBSA: "Longview, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Upshur County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Upshur County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 459,
    "County\nCode": 48459,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31020,
    "Metropolitan Division Code": "",
    "CSA Code": 440,
    "CBSA Full Title": "Longview, WA",
    "CBSA Solo": "Longview",
    "CBSA State": "WA",
    CBSA: "Longview, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Vancouver-Salem, OR-WA",
    "County/County Equivalent": "Cowlitz County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Cowlitz County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "015",
    "County\nCode": 53015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31060,
    "Metropolitan Division Code": "",
    "CSA Code": 106,
    "CBSA Full Title": "Los Alamos, NM",
    "CBSA Solo": "Los Alamos",
    "CBSA State": "NM",
    CBSA: "Los Alamos, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albuquerque-Santa Fe-Las Vegas, NM",
    "County/County Equivalent": "Los Alamos County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Los Alamos County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "028",
    "County\nCode": 35028,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31080,
    "Metropolitan Division Code": 11244,
    "CSA Code": 348,
    "CBSA Full Title": "Los Angeles-Long Beach-Anaheim, CA",
    "CBSA Solo": "Los Angeles",
    "CBSA State": "CA",
    CBSA: "Los Angeles, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Anaheim-Santa Ana-Irvine, CA",
    "CSA Title": "Los Angeles-Long Beach, CA",
    "County/County Equivalent": "Orange County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Orange County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "059",
    "County\nCode": "06059",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31080,
    "Metropolitan Division Code": 31084,
    "CSA Code": 348,
    "CBSA Full Title": "Los Angeles-Long Beach-Anaheim, CA",
    "CBSA Solo": "Los Angeles",
    "CBSA State": "CA",
    CBSA: "Los Angeles, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Los Angeles-Long Beach-Glendale, CA",
    "CSA Title": "Los Angeles-Long Beach, CA",
    "County/County Equivalent": "Los Angeles County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Los Angeles County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "037",
    "County\nCode": "06037",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31140,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Louisville/Jefferson County, KY-IN",
    "CBSA Solo": "Louisville/Jefferson County",
    "CBSA State": "KY",
    CBSA: "Louisville/Jefferson County, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Bullitt County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Bullitt County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "029",
    "County\nCode": 21029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31140,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Louisville/Jefferson County, KY-IN",
    "CBSA Solo": "Louisville/Jefferson County",
    "CBSA State": "KY",
    CBSA: "Louisville/Jefferson County, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Clark County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Clark County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "019",
    "County\nCode": 18019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31140,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Louisville/Jefferson County, KY-IN",
    "CBSA Solo": "Louisville/Jefferson County",
    "CBSA State": "KY",
    CBSA: "Louisville/Jefferson County, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Floyd County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Floyd County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "043",
    "County\nCode": 18043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31140,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Louisville/Jefferson County, KY-IN",
    "CBSA Solo": "Louisville/Jefferson County",
    "CBSA State": "KY",
    CBSA: "Louisville/Jefferson County, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Harrison County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Harrison County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "061",
    "County\nCode": 18061,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31140,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Louisville/Jefferson County, KY-IN",
    "CBSA Solo": "Louisville/Jefferson County",
    "CBSA State": "KY",
    CBSA: "Louisville/Jefferson County, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Washington County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Washington County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 175,
    "County\nCode": 18175,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31140,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Louisville/Jefferson County, KY-IN",
    "CBSA Solo": "Louisville/Jefferson County",
    "CBSA State": "KY",
    CBSA: "Louisville/Jefferson County, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Henry County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Henry County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 103,
    "County\nCode": 21103,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31140,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Louisville/Jefferson County, KY-IN",
    "CBSA Solo": "Louisville/Jefferson County",
    "CBSA State": "KY",
    CBSA: "Louisville/Jefferson County, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Jefferson County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 111,
    "County\nCode": 21111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31140,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Louisville/Jefferson County, KY-IN",
    "CBSA Solo": "Louisville/Jefferson County",
    "CBSA State": "KY",
    CBSA: "Louisville/Jefferson County, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Oldham County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Oldham County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 185,
    "County\nCode": 21185,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31140,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Louisville/Jefferson County, KY-IN",
    "CBSA Solo": "Louisville/Jefferson County",
    "CBSA State": "KY",
    CBSA: "Louisville/Jefferson County, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Shelby County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Shelby County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 211,
    "County\nCode": 21211,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31140,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Louisville/Jefferson County, KY-IN",
    "CBSA Solo": "Louisville/Jefferson County",
    "CBSA State": "KY",
    CBSA: "Louisville/Jefferson County, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Spencer County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Spencer County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 215,
    "County\nCode": 21215,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31180,
    "Metropolitan Division Code": "",
    "CSA Code": 352,
    "CBSA Full Title": "Lubbock, TX",
    "CBSA Solo": "Lubbock",
    "CBSA State": "TX",
    CBSA: "Lubbock, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lubbock-Plainview-Levelland, TX",
    "County/County Equivalent": "Crosby County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Crosby County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 107,
    "County\nCode": 48107,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31180,
    "Metropolitan Division Code": "",
    "CSA Code": 352,
    "CBSA Full Title": "Lubbock, TX",
    "CBSA Solo": "Lubbock",
    "CBSA State": "TX",
    CBSA: "Lubbock, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lubbock-Plainview-Levelland, TX",
    "County/County Equivalent": "Lubbock County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Lubbock County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 303,
    "County\nCode": 48303,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31180,
    "Metropolitan Division Code": "",
    "CSA Code": 352,
    "CBSA Full Title": "Lubbock, TX",
    "CBSA Solo": "Lubbock",
    "CBSA State": "TX",
    CBSA: "Lubbock, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lubbock-Plainview-Levelland, TX",
    "County/County Equivalent": "Lynn County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Lynn County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 305,
    "County\nCode": 48305,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Ludington, MI",
    "CBSA Solo": "Ludington",
    "CBSA State": "MI",
    CBSA: "Ludington, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Mason County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Mason County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 105,
    "County\nCode": 26105,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lufkin, TX",
    "CBSA Solo": "Lufkin",
    "CBSA State": "TX",
    CBSA: "Lufkin, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Angelina County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Angelina County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "005",
    "County\nCode": 48005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31300,
    "Metropolitan Division Code": "",
    "CSA Code": 246,
    "CBSA Full Title": "Lumberton, NC",
    "CBSA Solo": "Lumberton",
    "CBSA State": "NC",
    CBSA: "Lumberton, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fayetteville-Sanford-Lumberton, NC",
    "County/County Equivalent": "Robeson County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Robeson County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 155,
    "County\nCode": 37155,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lynchburg, VA",
    "CBSA Solo": "Lynchburg",
    "CBSA State": "VA",
    CBSA: "Lynchburg, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Amherst County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Amherst County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "009",
    "County\nCode": 51009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lynchburg, VA",
    "CBSA Solo": "Lynchburg",
    "CBSA State": "VA",
    CBSA: "Lynchburg, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Appomattox County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Appomattox County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "011",
    "County\nCode": 51011,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lynchburg, VA",
    "CBSA Solo": "Lynchburg",
    "CBSA State": "VA",
    CBSA: "Lynchburg, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Bedford County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Bedford County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "019",
    "County\nCode": 51019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lynchburg, VA",
    "CBSA Solo": "Lynchburg",
    "CBSA State": "VA",
    CBSA: "Lynchburg, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Campbell County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Campbell County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "031",
    "County\nCode": 51031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Lynchburg, VA",
    "CBSA Solo": "Lynchburg",
    "CBSA State": "VA",
    CBSA: "Lynchburg, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lynchburg city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Lynchburg city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 680,
    "County\nCode": 51680,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Macomb, IL",
    "CBSA Solo": "Macomb",
    "CBSA State": "IL",
    CBSA: "Macomb, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "McDonough County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "McDonough County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 109,
    "County\nCode": 17109,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31420,
    "Metropolitan Division Code": "",
    "CSA Code": 356,
    "CBSA Full Title": "Macon-Bibb County, GA",
    "CBSA Solo": "Macon",
    "CBSA State": "GA",
    CBSA: "Macon, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Macon-Bibb County--Warner Robins, GA",
    "County/County Equivalent": "Bibb County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Bibb County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "021",
    "County\nCode": 13021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31420,
    "Metropolitan Division Code": "",
    "CSA Code": 356,
    "CBSA Full Title": "Macon-Bibb County, GA",
    "CBSA Solo": "Macon",
    "CBSA State": "GA",
    CBSA: "Macon, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Macon-Bibb County--Warner Robins, GA",
    "County/County Equivalent": "Crawford County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Crawford County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "079",
    "County\nCode": 13079,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31420,
    "Metropolitan Division Code": "",
    "CSA Code": 356,
    "CBSA Full Title": "Macon-Bibb County, GA",
    "CBSA Solo": "Macon",
    "CBSA State": "GA",
    CBSA: "Macon, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Macon-Bibb County--Warner Robins, GA",
    "County/County Equivalent": "Jones County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Jones County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 169,
    "County\nCode": 13169,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31420,
    "Metropolitan Division Code": "",
    "CSA Code": 356,
    "CBSA Full Title": "Macon-Bibb County, GA",
    "CBSA Solo": "Macon",
    "CBSA State": "GA",
    CBSA: "Macon, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Macon-Bibb County--Warner Robins, GA",
    "County/County Equivalent": "Monroe County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Monroe County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 207,
    "County\nCode": 13207,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31420,
    "Metropolitan Division Code": "",
    "CSA Code": 356,
    "CBSA Full Title": "Macon-Bibb County, GA",
    "CBSA Solo": "Macon",
    "CBSA State": "GA",
    CBSA: "Macon, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Macon-Bibb County--Warner Robins, GA",
    "County/County Equivalent": "Twiggs County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Twiggs County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 289,
    "County\nCode": 13289,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31460,
    "Metropolitan Division Code": "",
    "CSA Code": 260,
    "CBSA Full Title": "Madera, CA",
    "CBSA Solo": "Madera",
    "CBSA State": "CA",
    CBSA: "Madera, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fresno-Madera-Hanford, CA",
    "County/County Equivalent": "Madera County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Madera County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "039",
    "County\nCode": "06039",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Madison, IN",
    "CBSA Solo": "Madison",
    "CBSA State": "IN",
    CBSA: "Madison, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Jefferson County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "077",
    "County\nCode": 18077,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31540,
    "Metropolitan Division Code": "",
    "CSA Code": 357,
    "CBSA Full Title": "Madison, WI",
    "CBSA Solo": "Madison",
    "CBSA State": "WI",
    CBSA: "Madison, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Madison-Janesville-Beloit, WI",
    "County/County Equivalent": "Columbia County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Columbia County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "021",
    "County\nCode": 55021,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31540,
    "Metropolitan Division Code": "",
    "CSA Code": 357,
    "CBSA Full Title": "Madison, WI",
    "CBSA Solo": "Madison",
    "CBSA State": "WI",
    CBSA: "Madison, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Madison-Janesville-Beloit, WI",
    "County/County Equivalent": "Dane County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Dane County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "025",
    "County\nCode": 55025,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31540,
    "Metropolitan Division Code": "",
    "CSA Code": 357,
    "CBSA Full Title": "Madison, WI",
    "CBSA Solo": "Madison",
    "CBSA State": "WI",
    CBSA: "Madison, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Madison-Janesville-Beloit, WI",
    "County/County Equivalent": "Green County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Green County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "045",
    "County\nCode": 55045,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31540,
    "Metropolitan Division Code": "",
    "CSA Code": 357,
    "CBSA Full Title": "Madison, WI",
    "CBSA Solo": "Madison",
    "CBSA State": "WI",
    CBSA: "Madison, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Madison-Janesville-Beloit, WI",
    "County/County Equivalent": "Iowa County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Iowa County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "049",
    "County\nCode": 55049,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Madisonville, KY",
    "CBSA Solo": "Madisonville",
    "CBSA State": "KY",
    CBSA: "Madisonville, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hopkins County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Hopkins County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 107,
    "County\nCode": 21107,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Magnolia, AR",
    "CBSA Solo": "Magnolia",
    "CBSA State": "AR",
    CBSA: "Magnolia, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Columbia County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Columbia County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "027",
    "County\nCode": "05027",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Malone, NY",
    "CBSA Solo": "Malone",
    "CBSA State": "NY",
    CBSA: "Malone, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Franklin County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Franklin County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "033",
    "County\nCode": 36033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31680,
    "Metropolitan Division Code": "",
    "CSA Code": 284,
    "CBSA Full Title": "Malvern, AR",
    "CBSA Solo": "Malvern",
    "CBSA State": "AR",
    CBSA: "Malvern, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Hot Springs-Malvern, AR",
    "County/County Equivalent": "Hot Spring County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Hot Spring County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "059",
    "County\nCode": "05059",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31700,
    "Metropolitan Division Code": "",
    "CSA Code": 148,
    "CBSA Full Title": "Manchester-Nashua, NH",
    "CBSA Solo": "Manchester",
    "CBSA State": "NH",
    CBSA: "Manchester, NH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Hillsborough County",
    "State Name": "New Hampshire",
    "State Abbr": "NH",
    "County, State": "Hillsborough County, NH",
    "FIPS State Code": 33,
    "FIPS County Code": "011",
    "County\nCode": 33011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Manhattan, KS",
    "CBSA Solo": "Manhattan",
    "CBSA State": "KS",
    CBSA: "Manhattan, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Geary County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Geary County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "061",
    "County\nCode": 20061,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Manhattan, KS",
    "CBSA Solo": "Manhattan",
    "CBSA State": "KS",
    CBSA: "Manhattan, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pottawatomie County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Pottawatomie County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 149,
    "County\nCode": 20149,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 31740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Manhattan, KS",
    "CBSA Solo": "Manhattan",
    "CBSA State": "KS",
    CBSA: "Manhattan, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Riley County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Riley County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 161,
    "County\nCode": 20161,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Manitowoc, WI",
    "CBSA Solo": "Manitowoc",
    "CBSA State": "WI",
    CBSA: "Manitowoc, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Manitowoc County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Manitowoc County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "071",
    "County\nCode": 55071,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31860,
    "Metropolitan Division Code": "",
    "CSA Code": 359,
    "CBSA Full Title": "Mankato, MN",
    "CBSA Solo": "Mankato",
    "CBSA State": "MN",
    CBSA: "Mankato, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mankato-New Ulm, MN",
    "County/County Equivalent": "Blue Earth County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Blue Earth County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "013",
    "County\nCode": 27013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31860,
    "Metropolitan Division Code": "",
    "CSA Code": 359,
    "CBSA Full Title": "Mankato, MN",
    "CBSA Solo": "Mankato",
    "CBSA State": "MN",
    CBSA: "Mankato, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mankato-New Ulm, MN",
    "County/County Equivalent": "Nicollet County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Nicollet County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 103,
    "County\nCode": 27103,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31900,
    "Metropolitan Division Code": "",
    "CSA Code": 360,
    "CBSA Full Title": "Mansfield, OH",
    "CBSA Solo": "Mansfield",
    "CBSA State": "OH",
    CBSA: "Mansfield, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mansfield-Ashland-Bucyrus, OH",
    "County/County Equivalent": "Richland County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Richland County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 139,
    "County\nCode": 39139,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31930,
    "Metropolitan Division Code": "",
    "CSA Code": 425,
    "CBSA Full Title": "Marietta, OH",
    "CBSA Solo": "Marietta",
    "CBSA State": "OH",
    CBSA: "Marietta, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Parkersburg-Marietta-Vienna, WV-OH",
    "County/County Equivalent": "Washington County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Washington County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 167,
    "County\nCode": 39167,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31940,
    "Metropolitan Division Code": "",
    "CSA Code": 361,
    "CBSA Full Title": "Marinette, WI-MI",
    "CBSA Solo": "Marinette",
    "CBSA State": "WI",
    CBSA: "Marinette, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Marinette-Iron Mountain, WI-MI",
    "County/County Equivalent": "Menominee County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Menominee County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 109,
    "County\nCode": 26109,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31940,
    "Metropolitan Division Code": "",
    "CSA Code": 361,
    "CBSA Full Title": "Marinette, WI-MI",
    "CBSA Solo": "Marinette",
    "CBSA State": "WI",
    CBSA: "Marinette, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Marinette-Iron Mountain, WI-MI",
    "County/County Equivalent": "Marinette County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Marinette County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "075",
    "County\nCode": 55075,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 31980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Marion, IN",
    "CBSA Solo": "Marion",
    "CBSA State": "IN",
    CBSA: "Marion, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Grant County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Grant County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "053",
    "County\nCode": 18053,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32000,
    "Metropolitan Division Code": "",
    "CSA Code": 120,
    "CBSA Full Title": "Marion, NC",
    "CBSA Solo": "Marion",
    "CBSA State": "NC",
    CBSA: "Marion, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Asheville-Marion-Brevard, NC",
    "County/County Equivalent": "McDowell County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "McDowell County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 111,
    "County\nCode": 37111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32020,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Marion, OH",
    "CBSA Solo": "Marion",
    "CBSA State": "OH",
    CBSA: "Marion, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Marion County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Marion County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 101,
    "County\nCode": 39101,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Marquette, MI",
    "CBSA Solo": "Marquette",
    "CBSA State": "MI",
    CBSA: "Marquette, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Marquette County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Marquette County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 103,
    "County\nCode": 26103,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Marshall, MN",
    "CBSA Solo": "Marshall",
    "CBSA State": "MN",
    CBSA: "Marshall, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lyon County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Lyon County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "083",
    "County\nCode": 27083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Marshall, MO",
    "CBSA Solo": "Marshall",
    "CBSA State": "MO",
    CBSA: "Marshall, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Saline County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Saline County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 195,
    "County\nCode": 29195,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Marshalltown, IA",
    "CBSA Solo": "Marshalltown",
    "CBSA State": "IA",
    CBSA: "Marshalltown, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Marshall County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Marshall County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 127,
    "County\nCode": 19127,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32280,
    "Metropolitan Division Code": "",
    "CSA Code": 362,
    "CBSA Full Title": "Martin, TN",
    "CBSA Solo": "Martin",
    "CBSA State": "TN",
    CBSA: "Martin, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Martin-Union City, TN",
    "County/County Equivalent": "Weakley County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Weakley County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 183,
    "County\nCode": 47183,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Martinsville, VA",
    "CBSA Solo": "Martinsville",
    "CBSA State": "VA",
    CBSA: "Martinsville, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Henry County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Henry County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "089",
    "County\nCode": 51089,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Martinsville, VA",
    "CBSA Solo": "Martinsville",
    "CBSA State": "VA",
    CBSA: "Martinsville, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Martinsville city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Martinsville city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 690,
    "County\nCode": 51690,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Maryville, MO",
    "CBSA Solo": "Maryville",
    "CBSA State": "MO",
    CBSA: "Maryville, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Nodaway County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Nodaway County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 147,
    "County\nCode": 29147,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Mason City, IA",
    "CBSA Solo": "Mason City",
    "CBSA State": "IA",
    CBSA: "Mason City, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cerro Gordo County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Cerro Gordo County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "033",
    "County\nCode": 19033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Mason City, IA",
    "CBSA Solo": "Mason City",
    "CBSA State": "IA",
    CBSA: "Mason City, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Worth County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Worth County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 195,
    "County\nCode": 19195,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 32420,
    "Metropolitan Division Code": "",
    "CSA Code": 364,
    "CBSA Full Title": "Mayagüez, PR",
    "CBSA Solo": "Mayagüez",
    "CBSA State": "PR",
    CBSA: "Mayagüez, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mayagüez-San Germán, PR",
    "County/County Equivalent": "Hormigueros Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Hormigueros Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "067",
    "County\nCode": 72067,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32420,
    "Metropolitan Division Code": "",
    "CSA Code": 364,
    "CBSA Full Title": "Mayagüez, PR",
    "CBSA Solo": "Mayagüez",
    "CBSA State": "PR",
    CBSA: "Mayagüez, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mayagüez-San Germán, PR",
    "County/County Equivalent": "Las Marías Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Las Marías Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "083",
    "County\nCode": 72083,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 32420,
    "Metropolitan Division Code": "",
    "CSA Code": 364,
    "CBSA Full Title": "Mayagüez, PR",
    "CBSA Solo": "Mayagüez",
    "CBSA State": "PR",
    CBSA: "Mayagüez, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mayagüez-San Germán, PR",
    "County/County Equivalent": "Mayagüez Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Mayagüez Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "097",
    "County\nCode": 72097,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32460,
    "Metropolitan Division Code": "",
    "CSA Code": 424,
    "CBSA Full Title": "Mayfield, KY",
    "CBSA Solo": "Mayfield",
    "CBSA State": "KY",
    CBSA: "Mayfield, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Paducah-Mayfield, KY-IL",
    "County/County Equivalent": "Graves County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Graves County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "083",
    "County\nCode": 21083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32500,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Maysville, KY",
    "CBSA Solo": "Maysville",
    "CBSA State": "KY",
    CBSA: "Maysville, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Mason County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Mason County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 161,
    "County\nCode": 21161,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "McAlester, OK",
    "CBSA Solo": "McAlester",
    "CBSA State": "OK",
    CBSA: "McAlester, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pittsburg County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Pittsburg County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 121,
    "County\nCode": 40121,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32580,
    "Metropolitan Division Code": "",
    "CSA Code": 365,
    "CBSA Full Title": "McAllen-Edinburg-Mission, TX",
    "CBSA Solo": "McAllen",
    "CBSA State": "TX",
    CBSA: "McAllen, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "McAllen-Edinburg, TX",
    "County/County Equivalent": "Hidalgo County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Hidalgo County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 215,
    "County\nCode": 48215,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "McComb, MS",
    "CBSA Solo": "McComb",
    "CBSA State": "MS",
    CBSA: "McComb, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pike County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Pike County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 113,
    "County\nCode": 28113,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "McMinnville, TN",
    "CBSA Solo": "McMinnville",
    "CBSA State": "TN",
    CBSA: "McMinnville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Warren County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Warren County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 177,
    "County\nCode": 47177,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "McPherson, KS",
    "CBSA Solo": "McPherson",
    "CBSA State": "KS",
    CBSA: "McPherson, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "McPherson County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "McPherson County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 113,
    "County\nCode": 20113,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32740,
    "Metropolitan Division Code": "",
    "CSA Code": 240,
    "CBSA Full Title": "Meadville, PA",
    "CBSA Solo": "Meadville",
    "CBSA State": "PA",
    CBSA: "Meadville, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Erie-Meadville, PA",
    "County/County Equivalent": "Crawford County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Crawford County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "039",
    "County\nCode": 42039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32780,
    "Metropolitan Division Code": "",
    "CSA Code": 366,
    "CBSA Full Title": "Medford, OR",
    "CBSA Solo": "Medford",
    "CBSA State": "OR",
    CBSA: "Medford, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Medford-Grants Pass, OR",
    "County/County Equivalent": "Jackson County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Jackson County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "029",
    "County\nCode": 41029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32820,
    "Metropolitan Division Code": "",
    "CSA Code": 368,
    "CBSA Full Title": "Memphis, TN-MS-AR",
    "CBSA Solo": "Memphis",
    "CBSA State": "TN",
    CBSA: "Memphis, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Memphis-Forrest City, TN-MS-AR",
    "County/County Equivalent": "Fayette County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Fayette County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "047",
    "County\nCode": 47047,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 32820,
    "Metropolitan Division Code": "",
    "CSA Code": 368,
    "CBSA Full Title": "Memphis, TN-MS-AR",
    "CBSA Solo": "Memphis",
    "CBSA State": "TN",
    CBSA: "Memphis, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Memphis-Forrest City, TN-MS-AR",
    "County/County Equivalent": "Crittenden County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Crittenden County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "035",
    "County\nCode": "05035",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32820,
    "Metropolitan Division Code": "",
    "CSA Code": 368,
    "CBSA Full Title": "Memphis, TN-MS-AR",
    "CBSA Solo": "Memphis",
    "CBSA State": "TN",
    CBSA: "Memphis, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Memphis-Forrest City, TN-MS-AR",
    "County/County Equivalent": "DeSoto County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "DeSoto County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "033",
    "County\nCode": 28033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32820,
    "Metropolitan Division Code": "",
    "CSA Code": 368,
    "CBSA Full Title": "Memphis, TN-MS-AR",
    "CBSA Solo": "Memphis",
    "CBSA State": "TN",
    CBSA: "Memphis, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Memphis-Forrest City, TN-MS-AR",
    "County/County Equivalent": "Marshall County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Marshall County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "093",
    "County\nCode": 28093,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 32820,
    "Metropolitan Division Code": "",
    "CSA Code": 368,
    "CBSA Full Title": "Memphis, TN-MS-AR",
    "CBSA Solo": "Memphis",
    "CBSA State": "TN",
    CBSA: "Memphis, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Memphis-Forrest City, TN-MS-AR",
    "County/County Equivalent": "Tate County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Tate County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 137,
    "County\nCode": 28137,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 32820,
    "Metropolitan Division Code": "",
    "CSA Code": 368,
    "CBSA Full Title": "Memphis, TN-MS-AR",
    "CBSA Solo": "Memphis",
    "CBSA State": "TN",
    CBSA: "Memphis, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Memphis-Forrest City, TN-MS-AR",
    "County/County Equivalent": "Tunica County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Tunica County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 143,
    "County\nCode": 28143,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 32820,
    "Metropolitan Division Code": "",
    "CSA Code": 368,
    "CBSA Full Title": "Memphis, TN-MS-AR",
    "CBSA Solo": "Memphis",
    "CBSA State": "TN",
    CBSA: "Memphis, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Memphis-Forrest City, TN-MS-AR",
    "County/County Equivalent": "Shelby County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Shelby County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 157,
    "County\nCode": 47157,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32820,
    "Metropolitan Division Code": "",
    "CSA Code": 368,
    "CBSA Full Title": "Memphis, TN-MS-AR",
    "CBSA Solo": "Memphis",
    "CBSA State": "TN",
    CBSA: "Memphis, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Memphis-Forrest City, TN-MS-AR",
    "County/County Equivalent": "Tipton County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Tipton County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 167,
    "County\nCode": 47167,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 32860,
    "Metropolitan Division Code": "",
    "CSA Code": 232,
    "CBSA Full Title": "Menomonie, WI",
    "CBSA Solo": "Menomonie",
    "CBSA State": "WI",
    CBSA: "Menomonie, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Eau Claire-Menomonie, WI",
    "County/County Equivalent": "Dunn County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Dunn County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "033",
    "County\nCode": 55033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32900,
    "Metropolitan Division Code": "",
    "CSA Code": 488,
    "CBSA Full Title": "Merced, CA",
    "CBSA Solo": "Merced",
    "CBSA State": "CA",
    CBSA: "Merced, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "Merced County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Merced County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "047",
    "County\nCode": "06047",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 32940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Meridian, MS",
    "CBSA Solo": "Meridian",
    "CBSA State": "MS",
    CBSA: "Meridian, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Clarke County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Clarke County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "023",
    "County\nCode": 28023,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 32940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Meridian, MS",
    "CBSA Solo": "Meridian",
    "CBSA State": "MS",
    CBSA: "Meridian, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Kemper County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Kemper County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "069",
    "County\nCode": 28069,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 32940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Meridian, MS",
    "CBSA Solo": "Meridian",
    "CBSA State": "MS",
    CBSA: "Meridian, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lauderdale County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Lauderdale County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "075",
    "County\nCode": 28075,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33020,
    "Metropolitan Division Code": "",
    "CSA Code": 190,
    "CBSA Full Title": "Mexico, MO",
    "CBSA Solo": "Mexico",
    "CBSA State": "MO",
    CBSA: "Mexico, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbia-Moberly-Mexico, MO",
    "County/County Equivalent": "Audrain County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Audrain County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "007",
    "County\nCode": 29007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33060,
    "Metropolitan Division Code": "",
    "CSA Code": 309,
    "CBSA Full Title": "Miami, OK",
    "CBSA Solo": "Miami",
    "CBSA State": "OK",
    CBSA: "Miami, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Joplin-Miami, MO-OK",
    "County/County Equivalent": "Ottawa County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Ottawa County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 115,
    "County\nCode": 40115,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33100,
    "Metropolitan Division Code": 22744,
    "CSA Code": 370,
    "CBSA Full Title": "Miami-Fort Lauderdale-Pompano Beach, FL",
    "CBSA Solo": "Miami",
    "CBSA State": "FL",
    CBSA: "Miami, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Fort Lauderdale-Pompano Beach-Sunrise, FL",
    "CSA Title": "Miami-Port St. Lucie-Fort Lauderdale, FL",
    "County/County Equivalent": "Broward County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Broward County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "011",
    "County\nCode": 12011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33100,
    "Metropolitan Division Code": 33124,
    "CSA Code": 370,
    "CBSA Full Title": "Miami-Fort Lauderdale-Pompano Beach, FL",
    "CBSA Solo": "Miami",
    "CBSA State": "FL",
    CBSA: "Miami, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Miami-Miami Beach-Kendall, FL",
    "CSA Title": "Miami-Port St. Lucie-Fort Lauderdale, FL",
    "County/County Equivalent": "Miami-Dade County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Miami-Dade County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "086",
    "County\nCode": 12086,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33100,
    "Metropolitan Division Code": 48424,
    "CSA Code": 370,
    "CBSA Full Title": "Miami-Fort Lauderdale-Pompano Beach, FL",
    "CBSA Solo": "Miami",
    "CBSA State": "FL",
    CBSA: "Miami, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "West Palm Beach-Boca Raton-Boynton Beach, FL",
    "CSA Title": "Miami-Port St. Lucie-Fort Lauderdale, FL",
    "County/County Equivalent": "Palm Beach County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Palm Beach County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "099",
    "County\nCode": 12099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33140,
    "Metropolitan Division Code": "",
    "CSA Code": 176,
    "CBSA Full Title": "Michigan City-La Porte, IN",
    "CBSA Solo": "Michigan City",
    "CBSA State": "IN",
    CBSA: "Michigan City, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "LaPorte County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "LaPorte County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "091",
    "County\nCode": 18091,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Middlesborough, KY",
    "CBSA Solo": "Middlesborough",
    "CBSA State": "KY",
    CBSA: "Middlesborough, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Bell County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Bell County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "013",
    "County\nCode": 21013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33220,
    "Metropolitan Division Code": "",
    "CSA Code": 474,
    "CBSA Full Title": "Midland, MI",
    "CBSA Solo": "Midland",
    "CBSA State": "MI",
    CBSA: "Midland, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Saginaw-Midland-Bay City, MI",
    "County/County Equivalent": "Midland County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Midland County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 111,
    "County\nCode": 26111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33260,
    "Metropolitan Division Code": "",
    "CSA Code": 372,
    "CBSA Full Title": "Midland, TX",
    "CBSA Solo": "Midland",
    "CBSA State": "TX",
    CBSA: "Midland, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Midland-Odessa, TX",
    "County/County Equivalent": "Martin County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Martin County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 317,
    "County\nCode": 48317,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33260,
    "Metropolitan Division Code": "",
    "CSA Code": 372,
    "CBSA Full Title": "Midland, TX",
    "CBSA Solo": "Midland",
    "CBSA State": "TX",
    CBSA: "Midland, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Midland-Odessa, TX",
    "County/County Equivalent": "Midland County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Midland County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 329,
    "County\nCode": 48329,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Milledgeville, GA",
    "CBSA Solo": "Milledgeville",
    "CBSA State": "GA",
    CBSA: "Milledgeville, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Baldwin County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Baldwin County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "009",
    "County\nCode": 13009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Milledgeville, GA",
    "CBSA Solo": "Milledgeville",
    "CBSA State": "GA",
    CBSA: "Milledgeville, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hancock County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Hancock County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 141,
    "County\nCode": 13141,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33340,
    "Metropolitan Division Code": "",
    "CSA Code": 376,
    "CBSA Full Title": "Milwaukee-Waukesha, WI",
    "CBSA Solo": "Milwaukee",
    "CBSA State": "WI",
    CBSA: "Milwaukee, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Milwaukee-Racine-Waukesha, WI",
    "County/County Equivalent": "Milwaukee County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Milwaukee County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "079",
    "County\nCode": 55079,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33340,
    "Metropolitan Division Code": "",
    "CSA Code": 376,
    "CBSA Full Title": "Milwaukee-Waukesha, WI",
    "CBSA Solo": "Milwaukee",
    "CBSA State": "WI",
    CBSA: "Milwaukee, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Milwaukee-Racine-Waukesha, WI",
    "County/County Equivalent": "Ozaukee County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Ozaukee County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "089",
    "County\nCode": 55089,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33340,
    "Metropolitan Division Code": "",
    "CSA Code": 376,
    "CBSA Full Title": "Milwaukee-Waukesha, WI",
    "CBSA Solo": "Milwaukee",
    "CBSA State": "WI",
    CBSA: "Milwaukee, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Milwaukee-Racine-Waukesha, WI",
    "County/County Equivalent": "Washington County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Washington County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": 131,
    "County\nCode": 55131,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33340,
    "Metropolitan Division Code": "",
    "CSA Code": 376,
    "CBSA Full Title": "Milwaukee-Waukesha, WI",
    "CBSA Solo": "Milwaukee",
    "CBSA State": "WI",
    CBSA: "Milwaukee, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Milwaukee-Racine-Waukesha, WI",
    "County/County Equivalent": "Waukesha County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Waukesha County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": 133,
    "County\nCode": 55133,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33380,
    "Metropolitan Division Code": "",
    "CSA Code": 508,
    "CBSA Full Title": "Minden, LA",
    "CBSA Solo": "Minden",
    "CBSA State": "LA",
    CBSA: "Minden, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Shreveport-Bossier City-Minden, LA",
    "County/County Equivalent": "Webster Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Webster Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": 119,
    "County\nCode": 22119,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33420,
    "Metropolitan Division Code": "",
    "CSA Code": 206,
    "CBSA Full Title": "Mineral Wells, TX",
    "CBSA Solo": "Mineral Wells",
    "CBSA State": "TX",
    CBSA: "Mineral Wells, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Palo Pinto County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Palo Pinto County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 363,
    "County\nCode": 48363,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Anoka County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Anoka County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "003",
    "County\nCode": 27003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Carver County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Carver County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "019",
    "County\nCode": 27019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Chisago County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Chisago County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "025",
    "County\nCode": 27025,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Dakota County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Dakota County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "037",
    "County\nCode": 27037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Hennepin County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Hennepin County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "053",
    "County\nCode": 27053,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Isanti County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Isanti County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "059",
    "County\nCode": 27059,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Le Sueur County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Le Sueur County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "079",
    "County\nCode": 27079,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Mille Lacs County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Mille Lacs County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "095",
    "County\nCode": 27095,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Ramsey County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Ramsey County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 123,
    "County\nCode": 27123,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Scott County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Scott County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 139,
    "County\nCode": 27139,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Sherburne County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Sherburne County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 141,
    "County\nCode": 27141,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Washington County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Washington County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 163,
    "County\nCode": 27163,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Wright County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Wright County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 171,
    "County\nCode": 27171,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Pierce County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Pierce County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "093",
    "County\nCode": 55093,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33460,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Minneapolis-St. Paul-Bloomington, MN-WI",
    "CBSA Solo": "Minneapolis",
    "CBSA State": "MN",
    CBSA: "Minneapolis, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "St. Croix County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "St. Croix County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": 109,
    "County\nCode": 55109,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Minot, ND",
    "CBSA Solo": "Minot",
    "CBSA State": "ND",
    CBSA: "Minot, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "McHenry County",
    "State Name": "North Dakota",
    "State Abbr": "ND",
    "County, State": "McHenry County, ND",
    "FIPS State Code": 38,
    "FIPS County Code": "049",
    "County\nCode": 38049,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Minot, ND",
    "CBSA Solo": "Minot",
    "CBSA State": "ND",
    CBSA: "Minot, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Renville County",
    "State Name": "North Dakota",
    "State Abbr": "ND",
    "County, State": "Renville County, ND",
    "FIPS State Code": 38,
    "FIPS County Code": "075",
    "County\nCode": 38075,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Minot, ND",
    "CBSA Solo": "Minot",
    "CBSA State": "ND",
    CBSA: "Minot, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ward County",
    "State Name": "North Dakota",
    "State Abbr": "ND",
    "County, State": "Ward County, ND",
    "FIPS State Code": 38,
    "FIPS County Code": 101,
    "County\nCode": 38101,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Missoula, MT",
    "CBSA Solo": "Missoula",
    "CBSA State": "MT",
    CBSA: "Missoula, MT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Missoula County",
    "State Name": "Montana",
    "State Abbr": "MT",
    "County, State": "Missoula County, MT",
    "FIPS State Code": 30,
    "FIPS County Code": "063",
    "County\nCode": 30063,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Mitchell, SD",
    "CBSA Solo": "Mitchell",
    "CBSA State": "SD",
    CBSA: "Mitchell, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Davison County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Davison County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "035",
    "County\nCode": 46035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Mitchell, SD",
    "CBSA Solo": "Mitchell",
    "CBSA State": "SD",
    CBSA: "Mitchell, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hanson County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Hanson County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "061",
    "County\nCode": 46061,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33620,
    "Metropolitan Division Code": "",
    "CSA Code": 190,
    "CBSA Full Title": "Moberly, MO",
    "CBSA Solo": "Moberly",
    "CBSA State": "MO",
    CBSA: "Moberly, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbia-Moberly-Mexico, MO",
    "County/County Equivalent": "Randolph County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Randolph County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 175,
    "County\nCode": 29175,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33660,
    "Metropolitan Division Code": "",
    "CSA Code": 380,
    "CBSA Full Title": "Mobile, AL",
    "CBSA Solo": "Mobile",
    "CBSA State": "AL",
    CBSA: "Mobile, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mobile-Daphne-Fairhope, AL",
    "County/County Equivalent": "Mobile County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Mobile County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "097",
    "County\nCode": "01097",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33660,
    "Metropolitan Division Code": "",
    "CSA Code": 380,
    "CBSA Full Title": "Mobile, AL",
    "CBSA Solo": "Mobile",
    "CBSA State": "AL",
    CBSA: "Mobile, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mobile-Daphne-Fairhope, AL",
    "County/County Equivalent": "Washington County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Washington County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": 129,
    "County\nCode": "01129",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33700,
    "Metropolitan Division Code": "",
    "CSA Code": 488,
    "CBSA Full Title": "Modesto, CA",
    "CBSA Solo": "Modesto",
    "CBSA State": "CA",
    CBSA: "Modesto, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "Stanislaus County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Stanislaus County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "099",
    "County\nCode": "06099",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33740,
    "Metropolitan Division Code": "",
    "CSA Code": 384,
    "CBSA Full Title": "Monroe, LA",
    "CBSA Solo": "Monroe",
    "CBSA State": "LA",
    CBSA: "Monroe, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Monroe-Ruston, LA",
    "County/County Equivalent": "Morehouse Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Morehouse Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "067",
    "County\nCode": 22067,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33740,
    "Metropolitan Division Code": "",
    "CSA Code": 384,
    "CBSA Full Title": "Monroe, LA",
    "CBSA Solo": "Monroe",
    "CBSA State": "LA",
    CBSA: "Monroe, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Monroe-Ruston, LA",
    "County/County Equivalent": "Ouachita Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Ouachita Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "073",
    "County\nCode": 22073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33740,
    "Metropolitan Division Code": "",
    "CSA Code": 384,
    "CBSA Full Title": "Monroe, LA",
    "CBSA Solo": "Monroe",
    "CBSA State": "LA",
    CBSA: "Monroe, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Monroe-Ruston, LA",
    "County/County Equivalent": "Union Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Union Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": 111,
    "County\nCode": 22111,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33780,
    "Metropolitan Division Code": "",
    "CSA Code": 220,
    "CBSA Full Title": "Monroe, MI",
    "CBSA Solo": "Monroe",
    "CBSA State": "MI",
    CBSA: "Monroe, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Detroit-Warren-Ann Arbor, MI",
    "County/County Equivalent": "Monroe County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Monroe County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 115,
    "County\nCode": 26115,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33860,
    "Metropolitan Division Code": "",
    "CSA Code": 388,
    "CBSA Full Title": "Montgomery, AL",
    "CBSA Solo": "Montgomery",
    "CBSA State": "AL",
    CBSA: "Montgomery, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Montgomery-Selma-Alexander City, AL",
    "County/County Equivalent": "Autauga County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Autauga County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "001",
    "County\nCode": "01001",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33860,
    "Metropolitan Division Code": "",
    "CSA Code": 388,
    "CBSA Full Title": "Montgomery, AL",
    "CBSA Solo": "Montgomery",
    "CBSA State": "AL",
    CBSA: "Montgomery, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Montgomery-Selma-Alexander City, AL",
    "County/County Equivalent": "Elmore County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Elmore County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "051",
    "County\nCode": "01051",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33860,
    "Metropolitan Division Code": "",
    "CSA Code": 388,
    "CBSA Full Title": "Montgomery, AL",
    "CBSA Solo": "Montgomery",
    "CBSA State": "AL",
    CBSA: "Montgomery, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Montgomery-Selma-Alexander City, AL",
    "County/County Equivalent": "Lowndes County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Lowndes County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "085",
    "County\nCode": "01085",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33860,
    "Metropolitan Division Code": "",
    "CSA Code": 388,
    "CBSA Full Title": "Montgomery, AL",
    "CBSA Solo": "Montgomery",
    "CBSA State": "AL",
    CBSA: "Montgomery, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Montgomery-Selma-Alexander City, AL",
    "County/County Equivalent": "Montgomery County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Montgomery County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": 101,
    "County\nCode": "01101",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Montrose, CO",
    "CBSA Solo": "Montrose",
    "CBSA State": "CO",
    CBSA: "Montrose, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Montrose County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Montrose County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "085",
    "County\nCode": "08085",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 33940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Montrose, CO",
    "CBSA Solo": "Montrose",
    "CBSA State": "CO",
    CBSA: "Montrose, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ouray County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Ouray County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "091",
    "County\nCode": "08091",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 33980,
    "Metropolitan Division Code": "",
    "CSA Code": 404,
    "CBSA Full Title": "Morehead City, NC",
    "CBSA Solo": "Morehead City",
    "CBSA State": "NC",
    CBSA: "Morehead City, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Bern-Morehead City, NC",
    "County/County Equivalent": "Carteret County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Carteret County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "031",
    "County\nCode": 37031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34020,
    "Metropolitan Division Code": "",
    "CSA Code": 318,
    "CBSA Full Title": "Morgan City, LA",
    "CBSA Solo": "Morgan City",
    "CBSA State": "LA",
    CBSA: "Morgan City, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lafayette-Opelousas-Morgan City, LA",
    "County/County Equivalent": "St. Mary Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "St. Mary Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": 101,
    "County\nCode": 22101,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34060,
    "Metropolitan Division Code": "",
    "CSA Code": 390,
    "CBSA Full Title": "Morgantown, WV",
    "CBSA Solo": "Morgantown",
    "CBSA State": "WV",
    CBSA: "Morgantown, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Morgantown-Fairmont, WV",
    "County/County Equivalent": "Monongalia County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Monongalia County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "061",
    "County\nCode": 54061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34060,
    "Metropolitan Division Code": "",
    "CSA Code": 390,
    "CBSA Full Title": "Morgantown, WV",
    "CBSA Solo": "Morgantown",
    "CBSA State": "WV",
    CBSA: "Morgantown, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Morgantown-Fairmont, WV",
    "County/County Equivalent": "Preston County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Preston County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "077",
    "County\nCode": 54077,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34100,
    "Metropolitan Division Code": "",
    "CSA Code": 315,
    "CBSA Full Title": "Morristown, TN",
    "CBSA Solo": "Morristown",
    "CBSA State": "TN",
    CBSA: "Morristown, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Knoxville-Morristown-Sevierville, TN",
    "County/County Equivalent": "Grainger County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Grainger County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "057",
    "County\nCode": 47057,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34100,
    "Metropolitan Division Code": "",
    "CSA Code": 315,
    "CBSA Full Title": "Morristown, TN",
    "CBSA Solo": "Morristown",
    "CBSA State": "TN",
    CBSA: "Morristown, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Knoxville-Morristown-Sevierville, TN",
    "County/County Equivalent": "Hamblen County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Hamblen County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "063",
    "County\nCode": 47063,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34100,
    "Metropolitan Division Code": "",
    "CSA Code": 315,
    "CBSA Full Title": "Morristown, TN",
    "CBSA Solo": "Morristown",
    "CBSA State": "TN",
    CBSA: "Morristown, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Knoxville-Morristown-Sevierville, TN",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Jefferson County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "089",
    "County\nCode": 47089,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34140,
    "Metropolitan Division Code": "",
    "CSA Code": 446,
    "CBSA Full Title": "Moscow, ID",
    "CBSA Solo": "Moscow",
    "CBSA State": "ID",
    CBSA: "Moscow, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pullman-Moscow, WA-ID",
    "County/County Equivalent": "Latah County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Latah County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "057",
    "County\nCode": 16057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34180,
    "Metropolitan Division Code": "",
    "CSA Code": 393,
    "CBSA Full Title": "Moses Lake, WA",
    "CBSA Solo": "Moses Lake",
    "CBSA State": "WA",
    CBSA: "Moses Lake, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Moses Lake-Othello, WA",
    "County/County Equivalent": "Grant County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Grant County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "025",
    "County\nCode": 53025,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Moultrie, GA",
    "CBSA Solo": "Moultrie",
    "CBSA State": "GA",
    CBSA: "Moultrie, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Colquitt County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Colquitt County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "071",
    "County\nCode": 13071,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Mountain Home, AR",
    "CBSA Solo": "Mountain Home",
    "CBSA State": "AR",
    CBSA: "Mountain Home, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Baxter County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Baxter County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "005",
    "County\nCode": "05005",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34300,
    "Metropolitan Division Code": "",
    "CSA Code": 147,
    "CBSA Full Title": "Mountain Home, ID",
    "CBSA Solo": "Mountain Home",
    "CBSA State": "ID",
    CBSA: "Mountain Home, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boise City-Mountain Home-Ontario, ID-OR",
    "County/County Equivalent": "Elmore County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Elmore County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "039",
    "County\nCode": 16039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34340,
    "Metropolitan Division Code": "",
    "CSA Code": 268,
    "CBSA Full Title": "Mount Airy, NC",
    "CBSA Solo": "Mount Airy",
    "CBSA State": "NC",
    CBSA: "Mount Airy, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greensboro--Winston-Salem--High Point, NC",
    "County/County Equivalent": "Surry County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Surry County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 171,
    "County\nCode": 37171,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34350,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Mount Gay-Shamrock, WV",
    "CBSA Solo": "Mount Gay",
    "CBSA State": "WV",
    CBSA: "Mount Gay, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Logan County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Logan County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "045",
    "County\nCode": 54045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34380,
    "Metropolitan Division Code": "",
    "CSA Code": 394,
    "CBSA Full Title": "Mount Pleasant, MI",
    "CBSA Solo": "Mount Pleasant",
    "CBSA State": "MI",
    CBSA: "Mount Pleasant, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mount Pleasant-Alma, MI",
    "County/County Equivalent": "Isabella County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Isabella County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "073",
    "County\nCode": 26073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Mount Pleasant, TX",
    "CBSA Solo": "Mount Pleasant",
    "CBSA State": "TX",
    CBSA: "Mount Pleasant, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Camp County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Camp County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "063",
    "County\nCode": 48063,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Mount Pleasant, TX",
    "CBSA Solo": "Mount Pleasant",
    "CBSA State": "TX",
    CBSA: "Mount Pleasant, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Titus County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Titus County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 449,
    "County\nCode": 48449,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34460,
    "Metropolitan Division Code": "",
    "CSA Code": 336,
    "CBSA Full Title": "Mount Sterling, KY",
    "CBSA Solo": "Mount Sterling",
    "CBSA State": "KY",
    CBSA: "Mount Sterling, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lexington-Fayette--Richmond--Frankfort, KY",
    "County/County Equivalent": "Bath County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Bath County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "011",
    "County\nCode": 21011,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34460,
    "Metropolitan Division Code": "",
    "CSA Code": 336,
    "CBSA Full Title": "Mount Sterling, KY",
    "CBSA Solo": "Mount Sterling",
    "CBSA State": "KY",
    CBSA: "Mount Sterling, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lexington-Fayette--Richmond--Frankfort, KY",
    "County/County Equivalent": "Menifee County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Menifee County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 165,
    "County\nCode": 21165,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34460,
    "Metropolitan Division Code": "",
    "CSA Code": 336,
    "CBSA Full Title": "Mount Sterling, KY",
    "CBSA Solo": "Mount Sterling",
    "CBSA State": "KY",
    CBSA: "Mount Sterling, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lexington-Fayette--Richmond--Frankfort, KY",
    "County/County Equivalent": "Montgomery County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Montgomery County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 173,
    "County\nCode": 21173,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Mount Vernon, IL",
    "CBSA Solo": "Mount Vernon",
    "CBSA State": "IL",
    CBSA: "Mount Vernon, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Jefferson County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "081",
    "County\nCode": 17081,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34540,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Mount Vernon, OH",
    "CBSA Solo": "Mount Vernon",
    "CBSA State": "OH",
    CBSA: "Mount Vernon, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Knox County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Knox County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "083",
    "County\nCode": 39083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34580,
    "Metropolitan Division Code": "",
    "CSA Code": 500,
    "CBSA Full Title": "Mount Vernon-Anacortes, WA",
    "CBSA Solo": "Mount Vernon",
    "CBSA State": "WA",
    CBSA: "Mount Vernon, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Seattle-Tacoma, WA",
    "County/County Equivalent": "Skagit County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Skagit County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "057",
    "County\nCode": 53057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34620,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Muncie, IN",
    "CBSA Solo": "Muncie",
    "CBSA State": "IN",
    CBSA: "Muncie, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Delaware County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Delaware County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "035",
    "County\nCode": 18035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Murray, KY",
    "CBSA Solo": "Murray",
    "CBSA State": "KY",
    CBSA: "Murray, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Calloway County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Calloway County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "035",
    "County\nCode": 21035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34700,
    "Metropolitan Division Code": "",
    "CSA Code": 209,
    "CBSA Full Title": "Muscatine, IA",
    "CBSA Solo": "Muscatine",
    "CBSA State": "IA",
    CBSA: "Muscatine, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Davenport-Moline, IA-IL",
    "County/County Equivalent": "Muscatine County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Muscatine County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 139,
    "County\nCode": 19139,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34740,
    "Metropolitan Division Code": "",
    "CSA Code": 266,
    "CBSA Full Title": "Muskegon, MI",
    "CBSA Solo": "Muskegon",
    "CBSA State": "MI",
    CBSA: "Muskegon, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Grand Rapids-Kentwood-Muskegon, MI",
    "County/County Equivalent": "Muskegon County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Muskegon County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 121,
    "County\nCode": 26121,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34780,
    "Metropolitan Division Code": "",
    "CSA Code": 538,
    "CBSA Full Title": "Muskogee, OK",
    "CBSA Solo": "Muskogee",
    "CBSA State": "OK",
    CBSA: "Muskogee, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tulsa-Muskogee-Bartlesville, OK",
    "County/County Equivalent": "Muskogee County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Muskogee County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 101,
    "County\nCode": 40101,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34820,
    "Metropolitan Division Code": "",
    "CSA Code": 396,
    "CBSA Full Title": "Myrtle Beach-Conway-North Myrtle Beach, SC-NC",
    "CBSA Solo": "Myrtle Beach",
    "CBSA State": "SC",
    CBSA: "Myrtle Beach, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Myrtle Beach-Conway, SC-NC",
    "County/County Equivalent": "Horry County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Horry County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "051",
    "County\nCode": 45051,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34820,
    "Metropolitan Division Code": "",
    "CSA Code": 396,
    "CBSA Full Title": "Myrtle Beach-Conway-North Myrtle Beach, SC-NC",
    "CBSA Solo": "Myrtle Beach",
    "CBSA State": "SC",
    CBSA: "Myrtle Beach, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Myrtle Beach-Conway, SC-NC",
    "County/County Equivalent": "Brunswick County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Brunswick County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "019",
    "County\nCode": 37019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Nacogdoches, TX",
    "CBSA Solo": "Nacogdoches",
    "CBSA State": "TX",
    CBSA: "Nacogdoches, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Nacogdoches County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Nacogdoches County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 347,
    "County\nCode": 48347,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34900,
    "Metropolitan Division Code": "",
    "CSA Code": 488,
    "CBSA Full Title": "Napa, CA",
    "CBSA Solo": "Napa",
    "CBSA State": "CA",
    CBSA: "Napa, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "Napa County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Napa County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "055",
    "County\nCode": "06055",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34940,
    "Metropolitan Division Code": "",
    "CSA Code": 163,
    "CBSA Full Title": "Naples-Marco Island, FL",
    "CBSA Solo": "Naples",
    "CBSA State": "FL",
    CBSA: "Naples, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cape Coral-Fort Myers-Naples, FL",
    "County/County Equivalent": "Collier County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Collier County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "021",
    "County\nCode": 12021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Nashville-Davidson--Murfreesboro--Franklin, TN",
    "CBSA Solo": "Nashville",
    "CBSA State": "TN",
    CBSA: "Nashville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Cannon County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Cannon County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "015",
    "County\nCode": 47015,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Nashville-Davidson--Murfreesboro--Franklin, TN",
    "CBSA Solo": "Nashville",
    "CBSA State": "TN",
    CBSA: "Nashville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Cheatham County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Cheatham County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "021",
    "County\nCode": 47021,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Nashville-Davidson--Murfreesboro--Franklin, TN",
    "CBSA Solo": "Nashville",
    "CBSA State": "TN",
    CBSA: "Nashville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Davidson County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Davidson County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "037",
    "County\nCode": 47037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Nashville-Davidson--Murfreesboro--Franklin, TN",
    "CBSA Solo": "Nashville",
    "CBSA State": "TN",
    CBSA: "Nashville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Dickson County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Dickson County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "043",
    "County\nCode": 47043,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Nashville-Davidson--Murfreesboro--Franklin, TN",
    "CBSA Solo": "Nashville",
    "CBSA State": "TN",
    CBSA: "Nashville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Macon County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Macon County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 111,
    "County\nCode": 47111,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Nashville-Davidson--Murfreesboro--Franklin, TN",
    "CBSA Solo": "Nashville",
    "CBSA State": "TN",
    CBSA: "Nashville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Maury County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Maury County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 119,
    "County\nCode": 47119,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Nashville-Davidson--Murfreesboro--Franklin, TN",
    "CBSA Solo": "Nashville",
    "CBSA State": "TN",
    CBSA: "Nashville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Robertson County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Robertson County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 147,
    "County\nCode": 47147,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Nashville-Davidson--Murfreesboro--Franklin, TN",
    "CBSA Solo": "Nashville",
    "CBSA State": "TN",
    CBSA: "Nashville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Rutherford County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Rutherford County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 149,
    "County\nCode": 47149,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Nashville-Davidson--Murfreesboro--Franklin, TN",
    "CBSA Solo": "Nashville",
    "CBSA State": "TN",
    CBSA: "Nashville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Smith County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Smith County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 159,
    "County\nCode": 47159,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Nashville-Davidson--Murfreesboro--Franklin, TN",
    "CBSA Solo": "Nashville",
    "CBSA State": "TN",
    CBSA: "Nashville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Sumner County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Sumner County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 165,
    "County\nCode": 47165,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Nashville-Davidson--Murfreesboro--Franklin, TN",
    "CBSA Solo": "Nashville",
    "CBSA State": "TN",
    CBSA: "Nashville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Trousdale County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Trousdale County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 169,
    "County\nCode": 47169,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 34980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Nashville-Davidson--Murfreesboro--Franklin, TN",
    "CBSA Solo": "Nashville",
    "CBSA State": "TN",
    CBSA: "Nashville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Williamson County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Williamson County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 187,
    "County\nCode": 47187,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 34980,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Nashville-Davidson--Murfreesboro--Franklin, TN",
    "CBSA Solo": "Nashville",
    "CBSA State": "TN",
    CBSA: "Nashville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Wilson County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Wilson County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 189,
    "County\nCode": 47189,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35020,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Natchez, MS-LA",
    "CBSA Solo": "Natchez",
    "CBSA State": "MS",
    CBSA: "Natchez, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Concordia Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Concordia Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "029",
    "County\nCode": 22029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35020,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Natchez, MS-LA",
    "CBSA Solo": "Natchez",
    "CBSA State": "MS",
    CBSA: "Natchez, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Adams County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Adams County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "001",
    "County\nCode": 28001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Natchitoches, LA",
    "CBSA Solo": "Natchitoches",
    "CBSA State": "LA",
    CBSA: "Natchitoches, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Natchitoches Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Natchitoches Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "069",
    "County\nCode": 22069,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35100,
    "Metropolitan Division Code": "",
    "CSA Code": 404,
    "CBSA Full Title": "New Bern, NC",
    "CBSA Solo": "New Bern",
    "CBSA State": "NC",
    CBSA: "New Bern, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Bern-Morehead City, NC",
    "County/County Equivalent": "Craven County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Craven County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "049",
    "County\nCode": 37049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35100,
    "Metropolitan Division Code": "",
    "CSA Code": 404,
    "CBSA Full Title": "New Bern, NC",
    "CBSA Solo": "New Bern",
    "CBSA State": "NC",
    CBSA: "New Bern, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Bern-Morehead City, NC",
    "County/County Equivalent": "Jones County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Jones County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 103,
    "County\nCode": 37103,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 35100,
    "Metropolitan Division Code": "",
    "CSA Code": 404,
    "CBSA Full Title": "New Bern, NC",
    "CBSA Solo": "New Bern",
    "CBSA State": "NC",
    CBSA: "New Bern, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Bern-Morehead City, NC",
    "County/County Equivalent": "Pamlico County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Pamlico County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 137,
    "County\nCode": 37137,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 35140,
    "Metropolitan Division Code": "",
    "CSA Code": 192,
    "CBSA Full Title": "Newberry, SC",
    "CBSA Solo": "Newberry",
    "CBSA State": "SC",
    CBSA: "Newberry, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbia-Orangeburg-Newberry, SC",
    "County/County Equivalent": "Newberry County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Newberry County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "071",
    "County\nCode": 45071,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35220,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "New Castle, IN",
    "CBSA Solo": "New Castle",
    "CBSA State": "IN",
    CBSA: "New Castle, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Henry County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Henry County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "065",
    "County\nCode": 18065,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35260,
    "Metropolitan Division Code": "",
    "CSA Code": 430,
    "CBSA Full Title": "New Castle, PA",
    "CBSA Solo": "New Castle",
    "CBSA State": "PA",
    CBSA: "New Castle, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    "County/County Equivalent": "Lawrence County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Lawrence County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "073",
    "County\nCode": 42073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35300,
    "Metropolitan Division Code": "",
    "CSA Code": 408,
    "CBSA Full Title": "New Haven-Milford, CT",
    "CBSA Solo": "New Haven",
    "CBSA State": "CT",
    CBSA: "New Haven, CT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "New Haven County",
    "State Name": "Connecticut",
    "State Abbr": "CT",
    "County, State": "New Haven County, CT",
    "FIPS State Code": "09",
    "FIPS County Code": "009",
    "County\nCode": "09009",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35380,
    "Metropolitan Division Code": "",
    "CSA Code": 406,
    "CBSA Full Title": "New Orleans-Metairie, LA",
    "CBSA Solo": "New Orleans",
    "CBSA State": "LA",
    CBSA: "New Orleans, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Orleans-Metairie-Hammond, LA-MS",
    "County/County Equivalent": "Jefferson Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Jefferson Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "051",
    "County\nCode": 22051,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35380,
    "Metropolitan Division Code": "",
    "CSA Code": 406,
    "CBSA Full Title": "New Orleans-Metairie, LA",
    "CBSA Solo": "New Orleans",
    "CBSA State": "LA",
    CBSA: "New Orleans, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Orleans-Metairie-Hammond, LA-MS",
    "County/County Equivalent": "Orleans Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Orleans Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "071",
    "County\nCode": 22071,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35380,
    "Metropolitan Division Code": "",
    "CSA Code": 406,
    "CBSA Full Title": "New Orleans-Metairie, LA",
    "CBSA Solo": "New Orleans",
    "CBSA State": "LA",
    CBSA: "New Orleans, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Orleans-Metairie-Hammond, LA-MS",
    "County/County Equivalent": "Plaquemines Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Plaquemines Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "075",
    "County\nCode": 22075,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35380,
    "Metropolitan Division Code": "",
    "CSA Code": 406,
    "CBSA Full Title": "New Orleans-Metairie, LA",
    "CBSA Solo": "New Orleans",
    "CBSA State": "LA",
    CBSA: "New Orleans, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Orleans-Metairie-Hammond, LA-MS",
    "County/County Equivalent": "St. Bernard Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "St. Bernard Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "087",
    "County\nCode": 22087,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35380,
    "Metropolitan Division Code": "",
    "CSA Code": 406,
    "CBSA Full Title": "New Orleans-Metairie, LA",
    "CBSA Solo": "New Orleans",
    "CBSA State": "LA",
    CBSA: "New Orleans, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Orleans-Metairie-Hammond, LA-MS",
    "County/County Equivalent": "St. Charles Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "St. Charles Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "089",
    "County\nCode": 22089,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35380,
    "Metropolitan Division Code": "",
    "CSA Code": 406,
    "CBSA Full Title": "New Orleans-Metairie, LA",
    "CBSA Solo": "New Orleans",
    "CBSA State": "LA",
    CBSA: "New Orleans, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Orleans-Metairie-Hammond, LA-MS",
    "County/County Equivalent": "St. James Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "St. James Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "093",
    "County\nCode": 22093,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 35380,
    "Metropolitan Division Code": "",
    "CSA Code": 406,
    "CBSA Full Title": "New Orleans-Metairie, LA",
    "CBSA Solo": "New Orleans",
    "CBSA State": "LA",
    CBSA: "New Orleans, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Orleans-Metairie-Hammond, LA-MS",
    "County/County Equivalent": "St. John the Baptist Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "St. John the Baptist Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "095",
    "County\nCode": 22095,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35380,
    "Metropolitan Division Code": "",
    "CSA Code": 406,
    "CBSA Full Title": "New Orleans-Metairie, LA",
    "CBSA Solo": "New Orleans",
    "CBSA State": "LA",
    CBSA: "New Orleans, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Orleans-Metairie-Hammond, LA-MS",
    "County/County Equivalent": "St. Tammany Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "St. Tammany Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": 103,
    "County\nCode": 22103,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 35420,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "New Philadelphia-Dover, OH",
    "CBSA Solo": "New Philadelphia",
    "CBSA State": "OH",
    CBSA: "New Philadelphia, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Tuscarawas County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Tuscarawas County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 157,
    "County\nCode": 39157,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35440,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Newport, OR",
    "CBSA Solo": "Newport",
    "CBSA State": "OR",
    CBSA: "Newport, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lincoln County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Lincoln County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "041",
    "County\nCode": 41041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35460,
    "Metropolitan Division Code": "",
    "CSA Code": 315,
    "CBSA Full Title": "Newport, TN",
    "CBSA Solo": "Newport",
    "CBSA State": "TN",
    CBSA: "Newport, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Knoxville-Morristown-Sevierville, TN",
    "County/County Equivalent": "Cocke County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Cocke County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "029",
    "County\nCode": 47029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35580,
    "Metropolitan Division Code": "",
    "CSA Code": 359,
    "CBSA Full Title": "New Ulm, MN",
    "CBSA Solo": "New Ulm",
    "CBSA State": "MN",
    CBSA: "New Ulm, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mankato-New Ulm, MN",
    "County/County Equivalent": "Brown County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Brown County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "015",
    "County\nCode": 27015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35004,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Nassau County-Suffolk County, NY",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Nassau County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Nassau County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "059",
    "County\nCode": 36059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35004,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Nassau County-Suffolk County, NY",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Suffolk County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Suffolk County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": 103,
    "County\nCode": 36103,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35084,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Newark, NJ-PA",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Essex County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Essex County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "013",
    "County\nCode": 34013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35084,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Newark, NJ-PA",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Hunterdon County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Hunterdon County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "019",
    "County\nCode": 34019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35084,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Newark, NJ-PA",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Morris County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Morris County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "027",
    "County\nCode": 34027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35084,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Newark, NJ-PA",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Sussex County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Sussex County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "037",
    "County\nCode": 34037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35084,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Newark, NJ-PA",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Union County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Union County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "039",
    "County\nCode": 34039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35084,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Newark, NJ-PA",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Pike County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Pike County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": 103,
    "County\nCode": 42103,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35154,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New Brunswick-Lakewood, NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Middlesex County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Middlesex County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "023",
    "County\nCode": 34023,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35154,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New Brunswick-Lakewood, NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Monmouth County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Monmouth County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "025",
    "County\nCode": 34025,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35154,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New Brunswick-Lakewood, NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Ocean County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Ocean County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "029",
    "County\nCode": 34029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35154,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New Brunswick-Lakewood, NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Somerset County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Somerset County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "035",
    "County\nCode": 34035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35614,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New York-Jersey City-White Plains, NY-NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Bergen County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Bergen County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "003",
    "County\nCode": 34003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35614,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New York-Jersey City-White Plains, NY-NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Hudson County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Hudson County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "017",
    "County\nCode": 34017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35614,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New York-Jersey City-White Plains, NY-NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Passaic County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Passaic County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "031",
    "County\nCode": 34031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35614,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New York-Jersey City-White Plains, NY-NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Bronx County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Bronx County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "005",
    "County\nCode": 36005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35614,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New York-Jersey City-White Plains, NY-NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Kings County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Kings County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "047",
    "County\nCode": 36047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35614,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New York-Jersey City-White Plains, NY-NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "New York County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "New York County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "061",
    "County\nCode": 36061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35614,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New York-Jersey City-White Plains, NY-NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Putnam County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Putnam County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "079",
    "County\nCode": 36079,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35614,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New York-Jersey City-White Plains, NY-NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Queens County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Queens County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "081",
    "County\nCode": 36081,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35614,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New York-Jersey City-White Plains, NY-NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Richmond County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Richmond County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "085",
    "County\nCode": 36085,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35614,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New York-Jersey City-White Plains, NY-NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Rockland County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Rockland County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "087",
    "County\nCode": 36087,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35620,
    "Metropolitan Division Code": 35614,
    "CSA Code": 408,
    "CBSA Full Title": "New York-Newark-Jersey City, NY-NJ-PA",
    "CBSA Solo": "New York",
    "CBSA State": "NY",
    CBSA: "New York, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "New York-Jersey City-White Plains, NY-NJ",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Westchester County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Westchester County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": 119,
    "County\nCode": 36119,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35660,
    "Metropolitan Division Code": "",
    "CSA Code": 515,
    "CBSA Full Title": "Niles, MI",
    "CBSA Solo": "Niles",
    "CBSA State": "MI",
    CBSA: "Niles, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "South Bend-Elkhart-Mishawaka, IN-MI",
    "County/County Equivalent": "Berrien County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Berrien County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "021",
    "County\nCode": 26021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35700,
    "Metropolitan Division Code": "",
    "CSA Code": 536,
    "CBSA Full Title": "Nogales, AZ",
    "CBSA Solo": "Nogales",
    "CBSA State": "AZ",
    CBSA: "Nogales, AZ",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tucson-Nogales, AZ",
    "County/County Equivalent": "Santa Cruz County",
    "State Name": "Arizona",
    "State Abbr": "AZ",
    "County, State": "Santa Cruz County, AZ",
    "FIPS State Code": "04",
    "FIPS County Code": "023",
    "County\nCode": "04023",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Norfolk, NE",
    "CBSA Solo": "Norfolk",
    "CBSA State": "NE",
    CBSA: "Norfolk, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Madison County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Madison County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 119,
    "County\nCode": 31119,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Norfolk, NE",
    "CBSA Solo": "Norfolk",
    "CBSA State": "NE",
    CBSA: "Norfolk, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pierce County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Pierce County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 139,
    "County\nCode": 31139,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 35740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Norfolk, NE",
    "CBSA Solo": "Norfolk",
    "CBSA State": "NE",
    CBSA: "Norfolk, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Stanton County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Stanton County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 167,
    "County\nCode": 31167,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 35820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "North Platte, NE",
    "CBSA Solo": "North Platte",
    "CBSA State": "NE",
    CBSA: "North Platte, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lincoln County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Lincoln County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 111,
    "County\nCode": 31111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "North Platte, NE",
    "CBSA Solo": "North Platte",
    "CBSA State": "NE",
    CBSA: "North Platte, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Logan County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Logan County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 113,
    "County\nCode": 31113,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 35820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "North Platte, NE",
    "CBSA Solo": "North Platte",
    "CBSA State": "NE",
    CBSA: "North Platte, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "McPherson County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "McPherson County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 117,
    "County\nCode": 31117,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 35840,
    "Metropolitan Division Code": "",
    "CSA Code": 412,
    "CBSA Full Title": "North Port-Sarasota-Bradenton, FL",
    "CBSA Solo": "North Port",
    "CBSA State": "FL",
    CBSA: "North Port, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "North Port-Sarasota, FL",
    "County/County Equivalent": "Manatee County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Manatee County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "081",
    "County\nCode": 12081,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35840,
    "Metropolitan Division Code": "",
    "CSA Code": 412,
    "CBSA Full Title": "North Port-Sarasota-Bradenton, FL",
    "CBSA Solo": "North Port",
    "CBSA State": "FL",
    CBSA: "North Port, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "North Port-Sarasota, FL",
    "County/County Equivalent": "Sarasota County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Sarasota County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": 115,
    "County\nCode": 12115,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35860,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "North Vernon, IN",
    "CBSA Solo": "North Vernon",
    "CBSA State": "IN",
    CBSA: "North Vernon, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Jennings County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Jennings County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "079",
    "County\nCode": 18079,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "North Wilkesboro, NC",
    "CBSA Solo": "North Wilkesboro",
    "CBSA State": "NC",
    CBSA: "North Wilkesboro, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Wilkes County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Wilkes County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 193,
    "County\nCode": 37193,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35940,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "Norwalk, OH",
    "CBSA Solo": "Norwalk",
    "CBSA State": "OH",
    CBSA: "Norwalk, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Huron County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Huron County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "077",
    "County\nCode": 39077,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 35980,
    "Metropolitan Division Code": "",
    "CSA Code": 278,
    "CBSA Full Title": "Norwich-New London, CT",
    "CBSA Solo": "Norwich",
    "CBSA State": "CT",
    CBSA: "Norwich, CT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Hartford-East Hartford, CT",
    "County/County Equivalent": "New London County",
    "State Name": "Connecticut",
    "State Abbr": "CT",
    "County, State": "New London County, CT",
    "FIPS State Code": "09",
    "FIPS County Code": "011",
    "County\nCode": "09011",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36020,
    "Metropolitan Division Code": "",
    "CSA Code": 500,
    "CBSA Full Title": "Oak Harbor, WA",
    "CBSA Solo": "Oak Harbor",
    "CBSA State": "WA",
    CBSA: "Oak Harbor, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Seattle-Tacoma, WA",
    "County/County Equivalent": "Island County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Island County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "029",
    "County\nCode": 53029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Ocala, FL",
    "CBSA Solo": "Ocala",
    "CBSA State": "FL",
    CBSA: "Ocala, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Marion County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Marion County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "083",
    "County\nCode": 12083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36140,
    "Metropolitan Division Code": "",
    "CSA Code": 428,
    "CBSA Full Title": "Ocean City, NJ",
    "CBSA Solo": "Ocean City",
    "CBSA State": "NJ",
    CBSA: "Ocean City, NJ",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Cape May County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Cape May County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "009",
    "County\nCode": 34009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36220,
    "Metropolitan Division Code": "",
    "CSA Code": 372,
    "CBSA Full Title": "Odessa, TX",
    "CBSA Solo": "Odessa",
    "CBSA State": "TX",
    CBSA: "Odessa, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Midland-Odessa, TX",
    "County/County Equivalent": "Ector County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Ector County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 135,
    "County\nCode": 48135,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36260,
    "Metropolitan Division Code": "",
    "CSA Code": 482,
    "CBSA Full Title": "Ogden-Clearfield, UT",
    "CBSA Solo": "Ogden",
    "CBSA State": "UT",
    CBSA: "Ogden, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salt Lake City-Provo-Orem, UT",
    "County/County Equivalent": "Box Elder County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Box Elder County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "003",
    "County\nCode": 49003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36260,
    "Metropolitan Division Code": "",
    "CSA Code": 482,
    "CBSA Full Title": "Ogden-Clearfield, UT",
    "CBSA Solo": "Ogden",
    "CBSA State": "UT",
    CBSA: "Ogden, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salt Lake City-Provo-Orem, UT",
    "County/County Equivalent": "Davis County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Davis County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "011",
    "County\nCode": 49011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36260,
    "Metropolitan Division Code": "",
    "CSA Code": 482,
    "CBSA Full Title": "Ogden-Clearfield, UT",
    "CBSA Solo": "Ogden",
    "CBSA State": "UT",
    CBSA: "Ogden, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salt Lake City-Provo-Orem, UT",
    "County/County Equivalent": "Morgan County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Morgan County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "029",
    "County\nCode": 49029,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 36260,
    "Metropolitan Division Code": "",
    "CSA Code": 482,
    "CBSA Full Title": "Ogden-Clearfield, UT",
    "CBSA Solo": "Ogden",
    "CBSA State": "UT",
    CBSA: "Ogden, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salt Lake City-Provo-Orem, UT",
    "County/County Equivalent": "Weber County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Weber County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "057",
    "County\nCode": 49057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Ogdensburg-Massena, NY",
    "CBSA Solo": "Ogdensburg",
    "CBSA State": "NY",
    CBSA: "Ogdensburg, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "St. Lawrence County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "St. Lawrence County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "089",
    "County\nCode": 36089,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Oil City, PA",
    "CBSA Solo": "Oil City",
    "CBSA State": "PA",
    CBSA: "Oil City, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Venango County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Venango County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": 121,
    "County\nCode": 42121,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Okeechobee, FL",
    "CBSA Solo": "Okeechobee",
    "CBSA State": "FL",
    CBSA: "Okeechobee, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Okeechobee County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Okeechobee County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "093",
    "County\nCode": 12093,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36420,
    "Metropolitan Division Code": "",
    "CSA Code": 416,
    "CBSA Full Title": "Oklahoma City, OK",
    "CBSA Solo": "Oklahoma City",
    "CBSA State": "OK",
    CBSA: "Oklahoma City, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Oklahoma City-Shawnee, OK",
    "County/County Equivalent": "Canadian County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Canadian County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "017",
    "County\nCode": 40017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36420,
    "Metropolitan Division Code": "",
    "CSA Code": 416,
    "CBSA Full Title": "Oklahoma City, OK",
    "CBSA Solo": "Oklahoma City",
    "CBSA State": "OK",
    CBSA: "Oklahoma City, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Oklahoma City-Shawnee, OK",
    "County/County Equivalent": "Cleveland County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Cleveland County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "027",
    "County\nCode": 40027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36420,
    "Metropolitan Division Code": "",
    "CSA Code": 416,
    "CBSA Full Title": "Oklahoma City, OK",
    "CBSA Solo": "Oklahoma City",
    "CBSA State": "OK",
    CBSA: "Oklahoma City, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Oklahoma City-Shawnee, OK",
    "County/County Equivalent": "Grady County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Grady County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "051",
    "County\nCode": 40051,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 36420,
    "Metropolitan Division Code": "",
    "CSA Code": 416,
    "CBSA Full Title": "Oklahoma City, OK",
    "CBSA Solo": "Oklahoma City",
    "CBSA State": "OK",
    CBSA: "Oklahoma City, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Oklahoma City-Shawnee, OK",
    "County/County Equivalent": "Lincoln County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Lincoln County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "081",
    "County\nCode": 40081,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 36420,
    "Metropolitan Division Code": "",
    "CSA Code": 416,
    "CBSA Full Title": "Oklahoma City, OK",
    "CBSA Solo": "Oklahoma City",
    "CBSA State": "OK",
    CBSA: "Oklahoma City, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Oklahoma City-Shawnee, OK",
    "County/County Equivalent": "Logan County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Logan County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "083",
    "County\nCode": 40083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36420,
    "Metropolitan Division Code": "",
    "CSA Code": 416,
    "CBSA Full Title": "Oklahoma City, OK",
    "CBSA Solo": "Oklahoma City",
    "CBSA State": "OK",
    CBSA: "Oklahoma City, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Oklahoma City-Shawnee, OK",
    "County/County Equivalent": "McClain County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "McClain County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "087",
    "County\nCode": 40087,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 36420,
    "Metropolitan Division Code": "",
    "CSA Code": 416,
    "CBSA Full Title": "Oklahoma City, OK",
    "CBSA Solo": "Oklahoma City",
    "CBSA State": "OK",
    CBSA: "Oklahoma City, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Oklahoma City-Shawnee, OK",
    "County/County Equivalent": "Oklahoma County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Oklahoma County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 109,
    "County\nCode": 40109,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36460,
    "Metropolitan Division Code": "",
    "CSA Code": 160,
    "CBSA Full Title": "Olean, NY",
    "CBSA Solo": "Olean",
    "CBSA State": "NY",
    CBSA: "Olean, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Buffalo-Cheektowaga-Olean, NY",
    "County/County Equivalent": "Cattaraugus County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Cattaraugus County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "009",
    "County\nCode": 36009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36500,
    "Metropolitan Division Code": "",
    "CSA Code": 500,
    "CBSA Full Title": "Olympia-Lacey-Tumwater, WA",
    "CBSA Solo": "Olympia",
    "CBSA State": "WA",
    CBSA: "Olympia, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Seattle-Tacoma, WA",
    "County/County Equivalent": "Thurston County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Thurston County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "067",
    "County\nCode": 53067,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36540,
    "Metropolitan Division Code": "",
    "CSA Code": 420,
    "CBSA Full Title": "Omaha-Council Bluffs, NE-IA",
    "CBSA Solo": "Omaha",
    "CBSA State": "NE",
    CBSA: "Omaha, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Omaha-Council Bluffs-Fremont, NE-IA",
    "County/County Equivalent": "Cass County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Cass County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "025",
    "County\nCode": 31025,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 36540,
    "Metropolitan Division Code": "",
    "CSA Code": 420,
    "CBSA Full Title": "Omaha-Council Bluffs, NE-IA",
    "CBSA Solo": "Omaha",
    "CBSA State": "NE",
    CBSA: "Omaha, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Omaha-Council Bluffs-Fremont, NE-IA",
    "County/County Equivalent": "Harrison County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Harrison County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "085",
    "County\nCode": 19085,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 36540,
    "Metropolitan Division Code": "",
    "CSA Code": 420,
    "CBSA Full Title": "Omaha-Council Bluffs, NE-IA",
    "CBSA Solo": "Omaha",
    "CBSA State": "NE",
    CBSA: "Omaha, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Omaha-Council Bluffs-Fremont, NE-IA",
    "County/County Equivalent": "Mills County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Mills County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 129,
    "County\nCode": 19129,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 36540,
    "Metropolitan Division Code": "",
    "CSA Code": 420,
    "CBSA Full Title": "Omaha-Council Bluffs, NE-IA",
    "CBSA Solo": "Omaha",
    "CBSA State": "NE",
    CBSA: "Omaha, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Omaha-Council Bluffs-Fremont, NE-IA",
    "County/County Equivalent": "Pottawattamie County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Pottawattamie County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 155,
    "County\nCode": 19155,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36540,
    "Metropolitan Division Code": "",
    "CSA Code": 420,
    "CBSA Full Title": "Omaha-Council Bluffs, NE-IA",
    "CBSA Solo": "Omaha",
    "CBSA State": "NE",
    CBSA: "Omaha, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Omaha-Council Bluffs-Fremont, NE-IA",
    "County/County Equivalent": "Douglas County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Douglas County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "055",
    "County\nCode": 31055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36540,
    "Metropolitan Division Code": "",
    "CSA Code": 420,
    "CBSA Full Title": "Omaha-Council Bluffs, NE-IA",
    "CBSA Solo": "Omaha",
    "CBSA State": "NE",
    CBSA: "Omaha, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Omaha-Council Bluffs-Fremont, NE-IA",
    "County/County Equivalent": "Sarpy County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Sarpy County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 153,
    "County\nCode": 31153,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36540,
    "Metropolitan Division Code": "",
    "CSA Code": 420,
    "CBSA Full Title": "Omaha-Council Bluffs, NE-IA",
    "CBSA Solo": "Omaha",
    "CBSA State": "NE",
    CBSA: "Omaha, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Omaha-Council Bluffs-Fremont, NE-IA",
    "County/County Equivalent": "Saunders County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Saunders County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 155,
    "County\nCode": 31155,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 36540,
    "Metropolitan Division Code": "",
    "CSA Code": 420,
    "CBSA Full Title": "Omaha-Council Bluffs, NE-IA",
    "CBSA Solo": "Omaha",
    "CBSA State": "NE",
    CBSA: "Omaha, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Omaha-Council Bluffs-Fremont, NE-IA",
    "County/County Equivalent": "Washington County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Washington County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 177,
    "County\nCode": 31177,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 36580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Oneonta, NY",
    "CBSA Solo": "Oneonta",
    "CBSA State": "NY",
    CBSA: "Oneonta, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Otsego County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Otsego County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "077",
    "County\nCode": 36077,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36620,
    "Metropolitan Division Code": "",
    "CSA Code": 147,
    "CBSA Full Title": "Ontario, OR-ID",
    "CBSA Solo": "Ontario",
    "CBSA State": "OR",
    CBSA: "Ontario, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boise City-Mountain Home-Ontario, ID-OR",
    "County/County Equivalent": "Payette County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Payette County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "075",
    "County\nCode": 16075,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36620,
    "Metropolitan Division Code": "",
    "CSA Code": 147,
    "CBSA Full Title": "Ontario, OR-ID",
    "CBSA Solo": "Ontario",
    "CBSA State": "OR",
    CBSA: "Ontario, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boise City-Mountain Home-Ontario, ID-OR",
    "County/County Equivalent": "Malheur County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Malheur County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "045",
    "County\nCode": 41045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36660,
    "Metropolitan Division Code": "",
    "CSA Code": 318,
    "CBSA Full Title": "Opelousas, LA",
    "CBSA Solo": "Opelousas",
    "CBSA State": "LA",
    CBSA: "Opelousas, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lafayette-Opelousas-Morgan City, LA",
    "County/County Equivalent": "St. Landry Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "St. Landry Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "097",
    "County\nCode": 22097,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36700,
    "Metropolitan Division Code": "",
    "CSA Code": 192,
    "CBSA Full Title": "Orangeburg, SC",
    "CBSA Solo": "Orangeburg",
    "CBSA State": "SC",
    CBSA: "Orangeburg, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbia-Orangeburg-Newberry, SC",
    "County/County Equivalent": "Orangeburg County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Orangeburg County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "075",
    "County\nCode": 45075,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36740,
    "Metropolitan Division Code": "",
    "CSA Code": 422,
    "CBSA Full Title": "Orlando-Kissimmee-Sanford, FL",
    "CBSA Solo": "Orlando",
    "CBSA State": "FL",
    CBSA: "Orlando, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Orlando-Lakeland-Deltona, FL",
    "County/County Equivalent": "Lake County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Lake County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "069",
    "County\nCode": 12069,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 36740,
    "Metropolitan Division Code": "",
    "CSA Code": 422,
    "CBSA Full Title": "Orlando-Kissimmee-Sanford, FL",
    "CBSA Solo": "Orlando",
    "CBSA State": "FL",
    CBSA: "Orlando, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Orlando-Lakeland-Deltona, FL",
    "County/County Equivalent": "Orange County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Orange County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "095",
    "County\nCode": 12095,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36740,
    "Metropolitan Division Code": "",
    "CSA Code": 422,
    "CBSA Full Title": "Orlando-Kissimmee-Sanford, FL",
    "CBSA Solo": "Orlando",
    "CBSA State": "FL",
    CBSA: "Orlando, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Orlando-Lakeland-Deltona, FL",
    "County/County Equivalent": "Osceola County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Osceola County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "097",
    "County\nCode": 12097,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 36740,
    "Metropolitan Division Code": "",
    "CSA Code": 422,
    "CBSA Full Title": "Orlando-Kissimmee-Sanford, FL",
    "CBSA Solo": "Orlando",
    "CBSA State": "FL",
    CBSA: "Orlando, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Orlando-Lakeland-Deltona, FL",
    "County/County Equivalent": "Seminole County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Seminole County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": 117,
    "County\nCode": 12117,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36780,
    "Metropolitan Division Code": "",
    "CSA Code": 118,
    "CBSA Full Title": "Oshkosh-Neenah, WI",
    "CBSA Solo": "Oshkosh",
    "CBSA State": "WI",
    CBSA: "Oshkosh, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Appleton-Oshkosh-Neenah, WI",
    "County/County Equivalent": "Winnebago County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Winnebago County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": 139,
    "County\nCode": 55139,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36820,
    "Metropolitan Division Code": "",
    "CSA Code": 218,
    "CBSA Full Title": "Oskaloosa, IA",
    "CBSA Solo": "Oskaloosa",
    "CBSA State": "IA",
    CBSA: "Oskaloosa, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Des Moines-Ames-West Des Moines, IA",
    "County/County Equivalent": "Mahaska County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Mahaska County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 123,
    "County\nCode": 19123,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36830,
    "Metropolitan Division Code": "",
    "CSA Code": 393,
    "CBSA Full Title": "Othello, WA",
    "CBSA Solo": "Othello",
    "CBSA State": "WA",
    CBSA: "Othello, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Moses Lake-Othello, WA",
    "County/County Equivalent": "Adams County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Adams County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "001",
    "County\nCode": 53001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36837,
    "Metropolitan Division Code": "",
    "CSA Code": 176,
    "CBSA Full Title": "Ottawa, IL",
    "CBSA Solo": "Ottawa",
    "CBSA State": "IL",
    CBSA: "Ottawa, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Bureau County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Bureau County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "011",
    "County\nCode": 17011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36837,
    "Metropolitan Division Code": "",
    "CSA Code": 176,
    "CBSA Full Title": "Ottawa, IL",
    "CBSA Solo": "Ottawa",
    "CBSA State": "IL",
    CBSA: "Ottawa, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "LaSalle County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "LaSalle County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "099",
    "County\nCode": 17099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36837,
    "Metropolitan Division Code": "",
    "CSA Code": 176,
    "CBSA Full Title": "Ottawa, IL",
    "CBSA Solo": "Ottawa",
    "CBSA State": "IL",
    CBSA: "Ottawa, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chicago-Naperville, IL-IN-WI",
    "County/County Equivalent": "Putnam County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Putnam County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 155,
    "County\nCode": 17155,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 36840,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Ottawa, KS",
    "CBSA Solo": "Ottawa",
    "CBSA State": "KS",
    CBSA: "Ottawa, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Franklin County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Franklin County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "059",
    "County\nCode": 20059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Ottumwa, IA",
    "CBSA Solo": "Ottumwa",
    "CBSA State": "IA",
    CBSA: "Ottumwa, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Wapello County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Wapello County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 179,
    "County\nCode": 19179,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36940,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Owatonna, MN",
    "CBSA Solo": "Owatonna",
    "CBSA State": "MN",
    CBSA: "Owatonna, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Steele County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Steele County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 147,
    "County\nCode": 27147,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Owensboro, KY",
    "CBSA Solo": "Owensboro",
    "CBSA State": "KY",
    CBSA: "Owensboro, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Daviess County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Daviess County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "059",
    "County\nCode": 21059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 36980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Owensboro, KY",
    "CBSA Solo": "Owensboro",
    "CBSA State": "KY",
    CBSA: "Owensboro, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hancock County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Hancock County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "091",
    "County\nCode": 21091,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 36980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Owensboro, KY",
    "CBSA Solo": "Owensboro",
    "CBSA State": "KY",
    CBSA: "Owensboro, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "McLean County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "McLean County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 149,
    "County\nCode": 21149,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 37060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Oxford, MS",
    "CBSA Solo": "Oxford",
    "CBSA State": "MS",
    CBSA: "Oxford, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lafayette County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Lafayette County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "071",
    "County\nCode": 28071,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37100,
    "Metropolitan Division Code": "",
    "CSA Code": 348,
    "CBSA Full Title": "Oxnard-Thousand Oaks-Ventura, CA",
    "CBSA Solo": "Oxnard",
    "CBSA State": "CA",
    CBSA: "Oxnard, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Los Angeles-Long Beach, CA",
    "County/County Equivalent": "Ventura County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Ventura County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": 111,
    "County\nCode": "06111",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37120,
    "Metropolitan Division Code": "",
    "CSA Code": 222,
    "CBSA Full Title": "Ozark, AL",
    "CBSA Solo": "Ozark",
    "CBSA State": "AL",
    CBSA: "Ozark, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dothan-Ozark, AL",
    "County/County Equivalent": "Dale County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Dale County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "045",
    "County\nCode": "01045",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37140,
    "Metropolitan Division Code": "",
    "CSA Code": 424,
    "CBSA Full Title": "Paducah, KY-IL",
    "CBSA Solo": "Paducah",
    "CBSA State": "KY",
    CBSA: "Paducah, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Paducah-Mayfield, KY-IL",
    "County/County Equivalent": "Massac County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Massac County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 127,
    "County\nCode": 17127,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 37140,
    "Metropolitan Division Code": "",
    "CSA Code": 424,
    "CBSA Full Title": "Paducah, KY-IL",
    "CBSA Solo": "Paducah",
    "CBSA State": "KY",
    CBSA: "Paducah, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Paducah-Mayfield, KY-IL",
    "County/County Equivalent": "Ballard County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Ballard County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "007",
    "County\nCode": 21007,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 37140,
    "Metropolitan Division Code": "",
    "CSA Code": 424,
    "CBSA Full Title": "Paducah, KY-IL",
    "CBSA Solo": "Paducah",
    "CBSA State": "KY",
    CBSA: "Paducah, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Paducah-Mayfield, KY-IL",
    "County/County Equivalent": "Livingston County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Livingston County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 139,
    "County\nCode": 21139,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 37140,
    "Metropolitan Division Code": "",
    "CSA Code": 424,
    "CBSA Full Title": "Paducah, KY-IL",
    "CBSA Solo": "Paducah",
    "CBSA State": "KY",
    CBSA: "Paducah, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Paducah-Mayfield, KY-IL",
    "County/County Equivalent": "McCracken County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "McCracken County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 145,
    "County\nCode": 21145,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37220,
    "Metropolitan Division Code": "",
    "CSA Code": 332,
    "CBSA Full Title": "Pahrump, NV",
    "CBSA Solo": "Pahrump",
    "CBSA State": "NV",
    CBSA: "Pahrump, NV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Las Vegas-Henderson, NV",
    "County/County Equivalent": "Nye County",
    "State Name": "Nevada",
    "State Abbr": "NV",
    "County, State": "Nye County, NV",
    "FIPS State Code": 32,
    "FIPS County Code": "023",
    "County\nCode": 32023,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37260,
    "Metropolitan Division Code": "",
    "CSA Code": 300,
    "CBSA Full Title": "Palatka, FL",
    "CBSA Solo": "Palatka",
    "CBSA State": "FL",
    CBSA: "Palatka, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jacksonville-St. Marys-Palatka, FL-GA",
    "County/County Equivalent": "Putnam County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Putnam County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": 107,
    "County\nCode": 12107,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Palestine, TX",
    "CBSA Solo": "Palestine",
    "CBSA State": "TX",
    CBSA: "Palestine, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Anderson County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Anderson County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "001",
    "County\nCode": 48001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Palm Bay-Melbourne-Titusville, FL",
    "CBSA Solo": "Palm Bay",
    "CBSA State": "FL",
    CBSA: "Palm Bay, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Brevard County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Brevard County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "009",
    "County\nCode": 12009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37420,
    "Metropolitan Division Code": "",
    "CSA Code": 108,
    "CBSA Full Title": "Pampa, TX",
    "CBSA Solo": "Pampa",
    "CBSA State": "TX",
    CBSA: "Pampa, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Amarillo-Pampa-Borger, TX",
    "County/County Equivalent": "Gray County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Gray County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 179,
    "County\nCode": 48179,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37420,
    "Metropolitan Division Code": "",
    "CSA Code": 108,
    "CBSA Full Title": "Pampa, TX",
    "CBSA Solo": "Pampa",
    "CBSA State": "TX",
    CBSA: "Pampa, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Amarillo-Pampa-Borger, TX",
    "County/County Equivalent": "Roberts County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Roberts County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 393,
    "County\nCode": 48393,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 37460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Panama City, FL",
    "CBSA Solo": "Panama City",
    "CBSA State": "FL",
    CBSA: "Panama City, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Bay County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Bay County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "005",
    "County\nCode": 12005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37500,
    "Metropolitan Division Code": "",
    "CSA Code": 308,
    "CBSA Full Title": "Paragould, AR",
    "CBSA Solo": "Paragould",
    "CBSA State": "AR",
    CBSA: "Paragould, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jonesboro-Paragould, AR",
    "County/County Equivalent": "Greene County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Greene County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "055",
    "County\nCode": "05055",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Paris, TN",
    "CBSA Solo": "Paris",
    "CBSA State": "TN",
    CBSA: "Paris, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Henry County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Henry County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "079",
    "County\nCode": 47079,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Paris, TX",
    "CBSA Solo": "Paris",
    "CBSA State": "TX",
    CBSA: "Paris, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lamar County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Lamar County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 277,
    "County\nCode": 48277,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37620,
    "Metropolitan Division Code": "",
    "CSA Code": 425,
    "CBSA Full Title": "Parkersburg-Vienna, WV",
    "CBSA Solo": "Parkersburg",
    "CBSA State": "WV",
    CBSA: "Parkersburg, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Parkersburg-Marietta-Vienna, WV-OH",
    "County/County Equivalent": "Wirt County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Wirt County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": 105,
    "County\nCode": 54105,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 37620,
    "Metropolitan Division Code": "",
    "CSA Code": 425,
    "CBSA Full Title": "Parkersburg-Vienna, WV",
    "CBSA Solo": "Parkersburg",
    "CBSA State": "WV",
    CBSA: "Parkersburg, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Parkersburg-Marietta-Vienna, WV-OH",
    "County/County Equivalent": "Wood County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Wood County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": 107,
    "County\nCode": 54107,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Parsons, KS",
    "CBSA Solo": "Parsons",
    "CBSA State": "KS",
    CBSA: "Parsons, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Labette County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Labette County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "099",
    "County\nCode": 20099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37740,
    "Metropolitan Division Code": "",
    "CSA Code": 429,
    "CBSA Full Title": "Payson, AZ",
    "CBSA Solo": "Payson",
    "CBSA State": "AZ",
    CBSA: "Payson, AZ",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Phoenix-Mesa, AZ",
    "County/County Equivalent": "Gila County",
    "State Name": "Arizona",
    "State Abbr": "AZ",
    "County, State": "Gila County, AZ",
    "FIPS State Code": "04",
    "FIPS County Code": "007",
    "County\nCode": "04007",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37770,
    "Metropolitan Division Code": "",
    "CSA Code": 484,
    "CBSA Full Title": "Pearsall, TX",
    "CBSA Solo": "Pearsall",
    "CBSA State": "TX",
    CBSA: "Pearsall, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Antonio-New Braunfels-Pearsall, TX",
    "County/County Equivalent": "Frio County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Frio County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 163,
    "County\nCode": 48163,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Pecos, TX",
    "CBSA Solo": "Pecos",
    "CBSA State": "TX",
    CBSA: "Pecos, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Loving County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Loving County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 301,
    "County\nCode": 48301,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 37780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Pecos, TX",
    "CBSA Solo": "Pecos",
    "CBSA State": "TX",
    CBSA: "Pecos, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Reeves County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Reeves County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 389,
    "County\nCode": 48389,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37800,
    "Metropolitan Division Code": "",
    "CSA Code": 218,
    "CBSA Full Title": "Pella, IA",
    "CBSA Solo": "Pella",
    "CBSA State": "IA",
    CBSA: "Pella, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Des Moines-Ames-West Des Moines, IA",
    "County/County Equivalent": "Marion County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Marion County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 125,
    "County\nCode": 19125,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37860,
    "Metropolitan Division Code": "",
    "CSA Code": 426,
    "CBSA Full Title": "Pensacola-Ferry Pass-Brent, FL",
    "CBSA Solo": "Pensacola",
    "CBSA State": "FL",
    CBSA: "Pensacola, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pensacola-Ferry Pass, FL-AL",
    "County/County Equivalent": "Escambia County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Escambia County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "033",
    "County\nCode": 12033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37860,
    "Metropolitan Division Code": "",
    "CSA Code": 426,
    "CBSA Full Title": "Pensacola-Ferry Pass-Brent, FL",
    "CBSA Solo": "Pensacola",
    "CBSA State": "FL",
    CBSA: "Pensacola, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pensacola-Ferry Pass, FL-AL",
    "County/County Equivalent": "Santa Rosa County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Santa Rosa County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": 113,
    "County\nCode": 12113,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Peoria, IL",
    "CBSA Solo": "Peoria",
    "CBSA State": "IL",
    CBSA: "Peoria, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Fulton County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Fulton County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "057",
    "County\nCode": 17057,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 37900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Peoria, IL",
    "CBSA Solo": "Peoria",
    "CBSA State": "IL",
    CBSA: "Peoria, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Marshall County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Marshall County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 123,
    "County\nCode": 17123,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 37900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Peoria, IL",
    "CBSA Solo": "Peoria",
    "CBSA State": "IL",
    CBSA: "Peoria, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Peoria County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Peoria County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 143,
    "County\nCode": 17143,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Peoria, IL",
    "CBSA Solo": "Peoria",
    "CBSA State": "IL",
    CBSA: "Peoria, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Stark County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Stark County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 175,
    "County\nCode": 17175,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 37900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Peoria, IL",
    "CBSA Solo": "Peoria",
    "CBSA State": "IL",
    CBSA: "Peoria, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Tazewell County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Tazewell County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 179,
    "County\nCode": 17179,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Peoria, IL",
    "CBSA Solo": "Peoria",
    "CBSA State": "IL",
    CBSA: "Peoria, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Woodford County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Woodford County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 203,
    "County\nCode": 17203,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37940,
    "Metropolitan Division Code": "",
    "CSA Code": 316,
    "CBSA Full Title": "Peru, IN",
    "CBSA Solo": "Peru",
    "CBSA State": "IN",
    CBSA: "Peru, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kokomo-Peru, IN",
    "County/County Equivalent": "Miami County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Miami County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 103,
    "County\nCode": 18103,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37980,
    "Metropolitan Division Code": 33874,
    "CSA Code": 428,
    "CBSA Full Title": "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    "CBSA Solo": "Philadelphia",
    "CBSA State": "PA",
    CBSA: "Philadelphia, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Montgomery County-Bucks County-Chester County, PA",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Bucks County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Bucks County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "017",
    "County\nCode": 42017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37980,
    "Metropolitan Division Code": 15804,
    "CSA Code": 428,
    "CBSA Full Title": "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    "CBSA Solo": "Philadelphia",
    "CBSA State": "PA",
    CBSA: "Philadelphia, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Camden, NJ",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Burlington County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Burlington County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "005",
    "County\nCode": 34005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37980,
    "Metropolitan Division Code": 15804,
    "CSA Code": 428,
    "CBSA Full Title": "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    "CBSA Solo": "Philadelphia",
    "CBSA State": "PA",
    CBSA: "Philadelphia, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Camden, NJ",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Camden County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Camden County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "007",
    "County\nCode": 34007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37980,
    "Metropolitan Division Code": 15804,
    "CSA Code": 428,
    "CBSA Full Title": "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    "CBSA Solo": "Philadelphia",
    "CBSA State": "PA",
    CBSA: "Philadelphia, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Camden, NJ",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Gloucester County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Gloucester County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "015",
    "County\nCode": 34015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37980,
    "Metropolitan Division Code": 33874,
    "CSA Code": 428,
    "CBSA Full Title": "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    "CBSA Solo": "Philadelphia",
    "CBSA State": "PA",
    CBSA: "Philadelphia, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Montgomery County-Bucks County-Chester County, PA",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Chester County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Chester County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "029",
    "County\nCode": 42029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37980,
    "Metropolitan Division Code": 33874,
    "CSA Code": 428,
    "CBSA Full Title": "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    "CBSA Solo": "Philadelphia",
    "CBSA State": "PA",
    CBSA: "Philadelphia, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Montgomery County-Bucks County-Chester County, PA",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Montgomery County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Montgomery County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "091",
    "County\nCode": 42091,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37980,
    "Metropolitan Division Code": 37964,
    "CSA Code": 428,
    "CBSA Full Title": "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    "CBSA Solo": "Philadelphia",
    "CBSA State": "PA",
    CBSA: "Philadelphia, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Philadelphia, PA",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Delaware County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Delaware County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "045",
    "County\nCode": 42045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37980,
    "Metropolitan Division Code": 37964,
    "CSA Code": 428,
    "CBSA Full Title": "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    "CBSA Solo": "Philadelphia",
    "CBSA State": "PA",
    CBSA: "Philadelphia, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Philadelphia, PA",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Philadelphia County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Philadelphia County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": 101,
    "County\nCode": 42101,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37980,
    "Metropolitan Division Code": 48864,
    "CSA Code": 428,
    "CBSA Full Title": "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    "CBSA Solo": "Philadelphia",
    "CBSA State": "PA",
    CBSA: "Philadelphia, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Wilmington, DE-MD-NJ",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "New Castle County",
    "State Name": "Delaware",
    "State Abbr": "DE",
    "County, State": "New Castle County, DE",
    "FIPS State Code": 10,
    "FIPS County Code": "003",
    "County\nCode": 10003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37980,
    "Metropolitan Division Code": 48864,
    "CSA Code": 428,
    "CBSA Full Title": "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    "CBSA Solo": "Philadelphia",
    "CBSA State": "PA",
    CBSA: "Philadelphia, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Wilmington, DE-MD-NJ",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Cecil County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Cecil County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "015",
    "County\nCode": 24015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 37980,
    "Metropolitan Division Code": 48864,
    "CSA Code": 428,
    "CBSA Full Title": "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD",
    "CBSA Solo": "Philadelphia",
    "CBSA State": "PA",
    CBSA: "Philadelphia, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Wilmington, DE-MD-NJ",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Salem County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Salem County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "033",
    "County\nCode": 34033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38060,
    "Metropolitan Division Code": "",
    "CSA Code": 429,
    "CBSA Full Title": "Phoenix-Mesa-Chandler, AZ",
    "CBSA Solo": "Phoenix",
    "CBSA State": "AZ",
    CBSA: "Phoenix, AZ",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Phoenix-Mesa, AZ",
    "County/County Equivalent": "Maricopa County",
    "State Name": "Arizona",
    "State Abbr": "AZ",
    "County, State": "Maricopa County, AZ",
    "FIPS State Code": "04",
    "FIPS County Code": "013",
    "County\nCode": "04013",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38060,
    "Metropolitan Division Code": "",
    "CSA Code": 429,
    "CBSA Full Title": "Phoenix-Mesa-Chandler, AZ",
    "CBSA Solo": "Phoenix",
    "CBSA State": "AZ",
    CBSA: "Phoenix, AZ",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Phoenix-Mesa, AZ",
    "County/County Equivalent": "Pinal County",
    "State Name": "Arizona",
    "State Abbr": "AZ",
    "County, State": "Pinal County, AZ",
    "FIPS State Code": "04",
    "FIPS County Code": "021",
    "County\nCode": "04021",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38100,
    "Metropolitan Division Code": "",
    "CSA Code": 406,
    "CBSA Full Title": "Picayune, MS",
    "CBSA Solo": "Picayune",
    "CBSA State": "MS",
    CBSA: "Picayune, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New Orleans-Metairie-Hammond, LA-MS",
    "County/County Equivalent": "Pearl River County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Pearl River County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 109,
    "County\nCode": 28109,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Pierre, SD",
    "CBSA Solo": "Pierre",
    "CBSA State": "SD",
    CBSA: "Pierre, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hughes County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Hughes County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "065",
    "County\nCode": 46065,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Pierre, SD",
    "CBSA Solo": "Pierre",
    "CBSA State": "SD",
    CBSA: "Pierre, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Stanley County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Stanley County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": 117,
    "County\nCode": 46117,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38220,
    "Metropolitan Division Code": "",
    "CSA Code": 340,
    "CBSA Full Title": "Pine Bluff, AR",
    "CBSA Solo": "Pine Bluff",
    "CBSA State": "AR",
    CBSA: "Pine Bluff, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Little Rock-North Little Rock, AR",
    "County/County Equivalent": "Cleveland County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Cleveland County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "025",
    "County\nCode": "05025",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 38220,
    "Metropolitan Division Code": "",
    "CSA Code": 340,
    "CBSA Full Title": "Pine Bluff, AR",
    "CBSA Solo": "Pine Bluff",
    "CBSA State": "AR",
    CBSA: "Pine Bluff, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Little Rock-North Little Rock, AR",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Jefferson County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "069",
    "County\nCode": "05069",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38220,
    "Metropolitan Division Code": "",
    "CSA Code": 340,
    "CBSA Full Title": "Pine Bluff, AR",
    "CBSA Solo": "Pine Bluff",
    "CBSA State": "AR",
    CBSA: "Pine Bluff, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Little Rock-North Little Rock, AR",
    "County/County Equivalent": "Lincoln County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Lincoln County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "079",
    "County\nCode": "05079",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 38240,
    "Metropolitan Division Code": "",
    "CSA Code": 246,
    "CBSA Full Title": "Pinehurst-Southern Pines, NC",
    "CBSA Solo": "Pinehurst",
    "CBSA State": "NC",
    CBSA: "Pinehurst, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fayetteville-Sanford-Lumberton, NC",
    "County/County Equivalent": "Moore County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Moore County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 125,
    "County\nCode": 37125,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Pittsburg, KS",
    "CBSA Solo": "Pittsburg",
    "CBSA State": "KS",
    CBSA: "Pittsburg, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Crawford County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Crawford County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "037",
    "County\nCode": 20037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38300,
    "Metropolitan Division Code": "",
    "CSA Code": 430,
    "CBSA Full Title": "Pittsburgh, PA",
    "CBSA Solo": "Pittsburgh",
    "CBSA State": "PA",
    CBSA: "Pittsburgh, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    "County/County Equivalent": "Allegheny County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Allegheny County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "003",
    "County\nCode": 42003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38300,
    "Metropolitan Division Code": "",
    "CSA Code": 430,
    "CBSA Full Title": "Pittsburgh, PA",
    "CBSA Solo": "Pittsburgh",
    "CBSA State": "PA",
    CBSA: "Pittsburgh, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    "County/County Equivalent": "Armstrong County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Armstrong County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "005",
    "County\nCode": 42005,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 38300,
    "Metropolitan Division Code": "",
    "CSA Code": 430,
    "CBSA Full Title": "Pittsburgh, PA",
    "CBSA Solo": "Pittsburgh",
    "CBSA State": "PA",
    CBSA: "Pittsburgh, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    "County/County Equivalent": "Beaver County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Beaver County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "007",
    "County\nCode": 42007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38300,
    "Metropolitan Division Code": "",
    "CSA Code": 430,
    "CBSA Full Title": "Pittsburgh, PA",
    "CBSA Solo": "Pittsburgh",
    "CBSA State": "PA",
    CBSA: "Pittsburgh, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    "County/County Equivalent": "Butler County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Butler County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "019",
    "County\nCode": 42019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38300,
    "Metropolitan Division Code": "",
    "CSA Code": 430,
    "CBSA Full Title": "Pittsburgh, PA",
    "CBSA Solo": "Pittsburgh",
    "CBSA State": "PA",
    CBSA: "Pittsburgh, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    "County/County Equivalent": "Fayette County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Fayette County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "051",
    "County\nCode": 42051,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 38300,
    "Metropolitan Division Code": "",
    "CSA Code": 430,
    "CBSA Full Title": "Pittsburgh, PA",
    "CBSA Solo": "Pittsburgh",
    "CBSA State": "PA",
    CBSA: "Pittsburgh, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    "County/County Equivalent": "Washington County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Washington County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": 125,
    "County\nCode": 42125,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38300,
    "Metropolitan Division Code": "",
    "CSA Code": 430,
    "CBSA Full Title": "Pittsburgh, PA",
    "CBSA Solo": "Pittsburgh",
    "CBSA State": "PA",
    CBSA: "Pittsburgh, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    "County/County Equivalent": "Westmoreland County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Westmoreland County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": 129,
    "County\nCode": 42129,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Pittsfield, MA",
    "CBSA Solo": "Pittsfield",
    "CBSA State": "MA",
    CBSA: "Pittsfield, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Berkshire County",
    "State Name": "Massachusetts",
    "State Abbr": "MA",
    "County, State": "Berkshire County, MA",
    "FIPS State Code": 25,
    "FIPS County Code": "003",
    "County\nCode": 25003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38380,
    "Metropolitan Division Code": "",
    "CSA Code": 352,
    "CBSA Full Title": "Plainview, TX",
    "CBSA Solo": "Plainview",
    "CBSA State": "TX",
    CBSA: "Plainview, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lubbock-Plainview-Levelland, TX",
    "County/County Equivalent": "Hale County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Hale County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 189,
    "County\nCode": 48189,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Platteville, WI",
    "CBSA Solo": "Platteville",
    "CBSA State": "WI",
    CBSA: "Platteville, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Grant County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Grant County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "043",
    "County\nCode": 55043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Plattsburgh, NY",
    "CBSA Solo": "Plattsburgh",
    "CBSA State": "NY",
    CBSA: "Plattsburgh, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Clinton County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Clinton County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "019",
    "County\nCode": 36019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38500,
    "Metropolitan Division Code": "",
    "CSA Code": 515,
    "CBSA Full Title": "Plymouth, IN",
    "CBSA Solo": "Plymouth",
    "CBSA State": "IN",
    CBSA: "Plymouth, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "South Bend-Elkhart-Mishawaka, IN-MI",
    "County/County Equivalent": "Marshall County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Marshall County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "099",
    "County\nCode": 18099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Pocatello, ID",
    "CBSA Solo": "Pocatello",
    "CBSA State": "ID",
    CBSA: "Pocatello, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Bannock County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Bannock County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "005",
    "County\nCode": 16005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Pocatello, ID",
    "CBSA Solo": "Pocatello",
    "CBSA State": "ID",
    CBSA: "Pocatello, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Power County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Power County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "077",
    "County\nCode": 16077,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 38580,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Point Pleasant, WV-OH",
    "CBSA Solo": "Point Pleasant",
    "CBSA State": "WV",
    CBSA: "Point Pleasant, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Gallia County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Gallia County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "053",
    "County\nCode": 39053,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38580,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Point Pleasant, WV-OH",
    "CBSA Solo": "Point Pleasant",
    "CBSA State": "WV",
    CBSA: "Point Pleasant, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Mason County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Mason County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "053",
    "County\nCode": 54053,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Ponca City, OK",
    "CBSA Solo": "Ponca City",
    "CBSA State": "OK",
    CBSA: "Ponca City, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Kay County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Kay County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "071",
    "County\nCode": 40071,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38660,
    "Metropolitan Division Code": "",
    "CSA Code": 434,
    "CBSA Full Title": "Ponce, PR",
    "CBSA Solo": "Ponce",
    "CBSA State": "PR",
    CBSA: "Ponce, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Ponce-Yauco-Coamo, PR",
    "County/County Equivalent": "Adjuntas Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Adjuntas Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "001",
    "County\nCode": 72001,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 38660,
    "Metropolitan Division Code": "",
    "CSA Code": 434,
    "CBSA Full Title": "Ponce, PR",
    "CBSA Solo": "Ponce",
    "CBSA State": "PR",
    CBSA: "Ponce, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Ponce-Yauco-Coamo, PR",
    "County/County Equivalent": "Juana Díaz Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Juana Díaz Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "075",
    "County\nCode": 72075,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 38660,
    "Metropolitan Division Code": "",
    "CSA Code": 434,
    "CBSA Full Title": "Ponce, PR",
    "CBSA Solo": "Ponce",
    "CBSA State": "PR",
    CBSA: "Ponce, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Ponce-Yauco-Coamo, PR",
    "County/County Equivalent": "Ponce Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Ponce Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 113,
    "County\nCode": 72113,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38660,
    "Metropolitan Division Code": "",
    "CSA Code": 434,
    "CBSA Full Title": "Ponce, PR",
    "CBSA Solo": "Ponce",
    "CBSA State": "PR",
    CBSA: "Ponce, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Ponce-Yauco-Coamo, PR",
    "County/County Equivalent": "Villalba Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Villalba Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 149,
    "County\nCode": 72149,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 38700,
    "Metropolitan Division Code": "",
    "CSA Code": 145,
    "CBSA Full Title": "Pontiac, IL",
    "CBSA Solo": "Pontiac",
    "CBSA State": "IL",
    CBSA: "Pontiac, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bloomington-Pontiac, IL",
    "County/County Equivalent": "Livingston County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Livingston County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 105,
    "County\nCode": 17105,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Poplar Bluff, MO",
    "CBSA Solo": "Poplar Bluff",
    "CBSA State": "MO",
    CBSA: "Poplar Bluff, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Butler County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Butler County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "023",
    "County\nCode": 29023,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Poplar Bluff, MO",
    "CBSA Solo": "Poplar Bluff",
    "CBSA State": "MO",
    CBSA: "Poplar Bluff, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ripley County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Ripley County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 181,
    "County\nCode": 29181,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 38780,
    "Metropolitan Division Code": "",
    "CSA Code": 188,
    "CBSA Full Title": "Portales, NM",
    "CBSA Solo": "Portales",
    "CBSA State": "NM",
    CBSA: "Portales, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Clovis-Portales, NM",
    "County/County Equivalent": "Roosevelt County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Roosevelt County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "041",
    "County\nCode": 35041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Port Angeles, WA",
    "CBSA Solo": "Port Angeles",
    "CBSA State": "WA",
    CBSA: "Port Angeles, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Clallam County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Clallam County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "009",
    "County\nCode": 53009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38860,
    "Metropolitan Division Code": "",
    "CSA Code": 438,
    "CBSA Full Title": "Portland-South Portland, ME",
    "CBSA Solo": "Portland",
    "CBSA State": "ME",
    CBSA: "Portland, ME",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Lewiston-South Portland, ME",
    "County/County Equivalent": "Cumberland County",
    "State Name": "Maine",
    "State Abbr": "ME",
    "County, State": "Cumberland County, ME",
    "FIPS State Code": 23,
    "FIPS County Code": "005",
    "County\nCode": 23005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38860,
    "Metropolitan Division Code": "",
    "CSA Code": 438,
    "CBSA Full Title": "Portland-South Portland, ME",
    "CBSA Solo": "Portland",
    "CBSA State": "ME",
    CBSA: "Portland, ME",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Lewiston-South Portland, ME",
    "County/County Equivalent": "Sagadahoc County",
    "State Name": "Maine",
    "State Abbr": "ME",
    "County, State": "Sagadahoc County, ME",
    "FIPS State Code": 23,
    "FIPS County Code": "023",
    "County\nCode": 23023,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 38860,
    "Metropolitan Division Code": "",
    "CSA Code": 438,
    "CBSA Full Title": "Portland-South Portland, ME",
    "CBSA Solo": "Portland",
    "CBSA State": "ME",
    CBSA: "Portland, ME",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Lewiston-South Portland, ME",
    "County/County Equivalent": "York County",
    "State Name": "Maine",
    "State Abbr": "ME",
    "County, State": "York County, ME",
    "FIPS State Code": 23,
    "FIPS County Code": "031",
    "County\nCode": 23031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38900,
    "Metropolitan Division Code": "",
    "CSA Code": 440,
    "CBSA Full Title": "Portland-Vancouver-Hillsboro, OR-WA",
    "CBSA Solo": "Portland",
    "CBSA State": "OR",
    CBSA: "Portland, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Vancouver-Salem, OR-WA",
    "County/County Equivalent": "Clackamas County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Clackamas County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "005",
    "County\nCode": 41005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38900,
    "Metropolitan Division Code": "",
    "CSA Code": 440,
    "CBSA Full Title": "Portland-Vancouver-Hillsboro, OR-WA",
    "CBSA Solo": "Portland",
    "CBSA State": "OR",
    CBSA: "Portland, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Vancouver-Salem, OR-WA",
    "County/County Equivalent": "Columbia County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Columbia County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "009",
    "County\nCode": 41009,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 38900,
    "Metropolitan Division Code": "",
    "CSA Code": 440,
    "CBSA Full Title": "Portland-Vancouver-Hillsboro, OR-WA",
    "CBSA Solo": "Portland",
    "CBSA State": "OR",
    CBSA: "Portland, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Vancouver-Salem, OR-WA",
    "County/County Equivalent": "Multnomah County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Multnomah County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "051",
    "County\nCode": 41051,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38900,
    "Metropolitan Division Code": "",
    "CSA Code": 440,
    "CBSA Full Title": "Portland-Vancouver-Hillsboro, OR-WA",
    "CBSA Solo": "Portland",
    "CBSA State": "OR",
    CBSA: "Portland, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Vancouver-Salem, OR-WA",
    "County/County Equivalent": "Washington County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Washington County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "067",
    "County\nCode": 41067,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38900,
    "Metropolitan Division Code": "",
    "CSA Code": 440,
    "CBSA Full Title": "Portland-Vancouver-Hillsboro, OR-WA",
    "CBSA Solo": "Portland",
    "CBSA State": "OR",
    CBSA: "Portland, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Vancouver-Salem, OR-WA",
    "County/County Equivalent": "Yamhill County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Yamhill County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "071",
    "County\nCode": 41071,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 38900,
    "Metropolitan Division Code": "",
    "CSA Code": 440,
    "CBSA Full Title": "Portland-Vancouver-Hillsboro, OR-WA",
    "CBSA Solo": "Portland",
    "CBSA State": "OR",
    CBSA: "Portland, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Vancouver-Salem, OR-WA",
    "County/County Equivalent": "Clark County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Clark County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "011",
    "County\nCode": 53011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38900,
    "Metropolitan Division Code": "",
    "CSA Code": 440,
    "CBSA Full Title": "Portland-Vancouver-Hillsboro, OR-WA",
    "CBSA Solo": "Portland",
    "CBSA State": "OR",
    CBSA: "Portland, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Vancouver-Salem, OR-WA",
    "County/County Equivalent": "Skamania County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Skamania County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "059",
    "County\nCode": 53059,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 38920,
    "Metropolitan Division Code": "",
    "CSA Code": 544,
    "CBSA Full Title": "Port Lavaca, TX",
    "CBSA Solo": "Port Lavaca",
    "CBSA State": "TX",
    CBSA: "Port Lavaca, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Victoria-Port Lavaca, TX",
    "County/County Equivalent": "Calhoun County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Calhoun County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "057",
    "County\nCode": 48057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38940,
    "Metropolitan Division Code": "",
    "CSA Code": 370,
    "CBSA Full Title": "Port St. Lucie, FL",
    "CBSA Solo": "Port St. Lucie",
    "CBSA State": "FL",
    CBSA: "Port St. Lucie, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Miami-Port St. Lucie-Fort Lauderdale, FL",
    "County/County Equivalent": "Martin County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Martin County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "085",
    "County\nCode": 12085,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 38940,
    "Metropolitan Division Code": "",
    "CSA Code": 370,
    "CBSA Full Title": "Port St. Lucie, FL",
    "CBSA Solo": "Port St. Lucie",
    "CBSA State": "FL",
    CBSA: "Port St. Lucie, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Miami-Port St. Lucie-Fort Lauderdale, FL",
    "County/County Equivalent": "St. Lucie County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "St. Lucie County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": 111,
    "County\nCode": 12111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39020,
    "Metropolitan Division Code": "",
    "CSA Code": 170,
    "CBSA Full Title": "Portsmouth, OH",
    "CBSA Solo": "Portsmouth",
    "CBSA State": "OH",
    CBSA: "Portsmouth, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charleston-Huntington-Ashland, WV-OH-KY",
    "County/County Equivalent": "Scioto County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Scioto County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 145,
    "County\nCode": 39145,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Pottsville, PA",
    "CBSA Solo": "Pottsville",
    "CBSA State": "PA",
    CBSA: "Pottsville, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Schuylkill County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Schuylkill County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": 107,
    "County\nCode": 42107,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39100,
    "Metropolitan Division Code": "",
    "CSA Code": 408,
    "CBSA Full Title": "Poughkeepsie-Newburgh-Middletown, NY",
    "CBSA Solo": "Poughkeepsie",
    "CBSA State": "NY",
    CBSA: "Poughkeepsie, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Dutchess County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Dutchess County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "027",
    "County\nCode": 36027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39100,
    "Metropolitan Division Code": "",
    "CSA Code": 408,
    "CBSA Full Title": "Poughkeepsie-Newburgh-Middletown, NY",
    "CBSA Solo": "Poughkeepsie",
    "CBSA State": "NY",
    CBSA: "Poughkeepsie, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Orange County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Orange County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "071",
    "County\nCode": 36071,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39150,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Prescott Valley-Prescott, AZ",
    "CBSA Solo": "Prescott Valley",
    "CBSA State": "AZ",
    CBSA: "Prescott Valley, AZ",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Yavapai County",
    "State Name": "Arizona",
    "State Abbr": "AZ",
    "County, State": "Yavapai County, AZ",
    "FIPS State Code": "04",
    "FIPS County Code": "025",
    "County\nCode": "04025",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Price, UT",
    "CBSA Solo": "Price",
    "CBSA State": "UT",
    CBSA: "Price, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Carbon County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Carbon County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "007",
    "County\nCode": 49007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39260,
    "Metropolitan Division Code": "",
    "CSA Code": 140,
    "CBSA Full Title": "Prineville, OR",
    "CBSA Solo": "Prineville",
    "CBSA State": "OR",
    CBSA: "Prineville, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bend-Prineville, OR",
    "County/County Equivalent": "Crook County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Crook County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "013",
    "County\nCode": 41013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39300,
    "Metropolitan Division Code": "",
    "CSA Code": 148,
    "CBSA Full Title": "Providence-Warwick, RI-MA",
    "CBSA Solo": "Providence",
    "CBSA State": "RI",
    CBSA: "Providence, RI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Bristol County",
    "State Name": "Rhode Island",
    "State Abbr": "RI",
    "County, State": "Bristol County, RI",
    "FIPS State Code": 44,
    "FIPS County Code": "001",
    "County\nCode": 44001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39300,
    "Metropolitan Division Code": "",
    "CSA Code": 148,
    "CBSA Full Title": "Providence-Warwick, RI-MA",
    "CBSA Solo": "Providence",
    "CBSA State": "RI",
    CBSA: "Providence, RI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Bristol County",
    "State Name": "Massachusetts",
    "State Abbr": "MA",
    "County, State": "Bristol County, MA",
    "FIPS State Code": 25,
    "FIPS County Code": "005",
    "County\nCode": 25005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39300,
    "Metropolitan Division Code": "",
    "CSA Code": 148,
    "CBSA Full Title": "Providence-Warwick, RI-MA",
    "CBSA Solo": "Providence",
    "CBSA State": "RI",
    CBSA: "Providence, RI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Kent County",
    "State Name": "Rhode Island",
    "State Abbr": "RI",
    "County, State": "Kent County, RI",
    "FIPS State Code": 44,
    "FIPS County Code": "003",
    "County\nCode": 44003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39300,
    "Metropolitan Division Code": "",
    "CSA Code": 148,
    "CBSA Full Title": "Providence-Warwick, RI-MA",
    "CBSA Solo": "Providence",
    "CBSA State": "RI",
    CBSA: "Providence, RI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Newport County",
    "State Name": "Rhode Island",
    "State Abbr": "RI",
    "County, State": "Newport County, RI",
    "FIPS State Code": 44,
    "FIPS County Code": "005",
    "County\nCode": 44005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39300,
    "Metropolitan Division Code": "",
    "CSA Code": 148,
    "CBSA Full Title": "Providence-Warwick, RI-MA",
    "CBSA Solo": "Providence",
    "CBSA State": "RI",
    CBSA: "Providence, RI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Providence County",
    "State Name": "Rhode Island",
    "State Abbr": "RI",
    "County, State": "Providence County, RI",
    "FIPS State Code": 44,
    "FIPS County Code": "007",
    "County\nCode": 44007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39300,
    "Metropolitan Division Code": "",
    "CSA Code": 148,
    "CBSA Full Title": "Providence-Warwick, RI-MA",
    "CBSA Solo": "Providence",
    "CBSA State": "RI",
    CBSA: "Providence, RI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Washington County",
    "State Name": "Rhode Island",
    "State Abbr": "RI",
    "County, State": "Washington County, RI",
    "FIPS State Code": 44,
    "FIPS County Code": "009",
    "County\nCode": 44009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39340,
    "Metropolitan Division Code": "",
    "CSA Code": 482,
    "CBSA Full Title": "Provo-Orem, UT",
    "CBSA Solo": "Provo",
    "CBSA State": "UT",
    CBSA: "Provo, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salt Lake City-Provo-Orem, UT",
    "County/County Equivalent": "Juab County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Juab County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "023",
    "County\nCode": 49023,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 39340,
    "Metropolitan Division Code": "",
    "CSA Code": 482,
    "CBSA Full Title": "Provo-Orem, UT",
    "CBSA Solo": "Provo",
    "CBSA State": "UT",
    CBSA: "Provo, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salt Lake City-Provo-Orem, UT",
    "County/County Equivalent": "Utah County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Utah County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "049",
    "County\nCode": 49049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39380,
    "Metropolitan Division Code": "",
    "CSA Code": 444,
    "CBSA Full Title": "Pueblo, CO",
    "CBSA Solo": "Pueblo",
    "CBSA State": "CO",
    CBSA: "Pueblo, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pueblo-Cañon City, CO",
    "County/County Equivalent": "Pueblo County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Pueblo County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": 101,
    "County\nCode": "08101",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39420,
    "Metropolitan Division Code": "",
    "CSA Code": 446,
    "CBSA Full Title": "Pullman, WA",
    "CBSA Solo": "Pullman",
    "CBSA State": "WA",
    CBSA: "Pullman, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pullman-Moscow, WA-ID",
    "County/County Equivalent": "Whitman County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Whitman County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "075",
    "County\nCode": 53075,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39460,
    "Metropolitan Division Code": "",
    "CSA Code": 412,
    "CBSA Full Title": "Punta Gorda, FL",
    "CBSA Solo": "Punta Gorda",
    "CBSA State": "FL",
    CBSA: "Punta Gorda, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "North Port-Sarasota, FL",
    "County/County Equivalent": "Charlotte County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Charlotte County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "015",
    "County\nCode": 12015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39500,
    "Metropolitan Division Code": "",
    "CSA Code": 448,
    "CBSA Full Title": "Quincy, IL-MO",
    "CBSA Solo": "Quincy",
    "CBSA State": "IL",
    CBSA: "Quincy, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Quincy-Hannibal, IL-MO",
    "County/County Equivalent": "Adams County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Adams County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "001",
    "County\nCode": 17001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39500,
    "Metropolitan Division Code": "",
    "CSA Code": 448,
    "CBSA Full Title": "Quincy, IL-MO",
    "CBSA Solo": "Quincy",
    "CBSA State": "IL",
    CBSA: "Quincy, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Quincy-Hannibal, IL-MO",
    "County/County Equivalent": "Lewis County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Lewis County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 111,
    "County\nCode": 29111,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 39540,
    "Metropolitan Division Code": "",
    "CSA Code": 376,
    "CBSA Full Title": "Racine, WI",
    "CBSA Solo": "Racine",
    "CBSA State": "WI",
    CBSA: "Racine, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Milwaukee-Racine-Waukesha, WI",
    "County/County Equivalent": "Racine County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Racine County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": 101,
    "County\nCode": 55101,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39580,
    "Metropolitan Division Code": "",
    "CSA Code": 450,
    "CBSA Full Title": "Raleigh-Cary, NC",
    "CBSA Solo": "Raleigh",
    "CBSA State": "NC",
    CBSA: "Raleigh, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Raleigh-Durham-Cary, NC",
    "County/County Equivalent": "Franklin County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Franklin County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "069",
    "County\nCode": 37069,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 39580,
    "Metropolitan Division Code": "",
    "CSA Code": 450,
    "CBSA Full Title": "Raleigh-Cary, NC",
    "CBSA Solo": "Raleigh",
    "CBSA State": "NC",
    CBSA: "Raleigh, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Raleigh-Durham-Cary, NC",
    "County/County Equivalent": "Johnston County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Johnston County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 101,
    "County\nCode": 37101,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39580,
    "Metropolitan Division Code": "",
    "CSA Code": 450,
    "CBSA Full Title": "Raleigh-Cary, NC",
    "CBSA Solo": "Raleigh",
    "CBSA State": "NC",
    CBSA: "Raleigh, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Raleigh-Durham-Cary, NC",
    "County/County Equivalent": "Wake County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Wake County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 183,
    "County\nCode": 37183,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39660,
    "Metropolitan Division Code": "",
    "CSA Code": 452,
    "CBSA Full Title": "Rapid City, SD",
    "CBSA Solo": "Rapid City",
    "CBSA State": "SD",
    CBSA: "Rapid City, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rapid City-Spearfish, SD",
    "County/County Equivalent": "Meade County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Meade County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "093",
    "County\nCode": 46093,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39660,
    "Metropolitan Division Code": "",
    "CSA Code": 452,
    "CBSA Full Title": "Rapid City, SD",
    "CBSA Solo": "Rapid City",
    "CBSA State": "SD",
    CBSA: "Rapid City, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rapid City-Spearfish, SD",
    "County/County Equivalent": "Pennington County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Pennington County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": 103,
    "County\nCode": 46103,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39700,
    "Metropolitan Division Code": "",
    "CSA Code": 154,
    "CBSA Full Title": "Raymondville, TX",
    "CBSA Solo": "Raymondville",
    "CBSA State": "TX",
    CBSA: "Raymondville, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Brownsville-Harlingen-Raymondville, TX",
    "County/County Equivalent": "Willacy County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Willacy County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 489,
    "County\nCode": 48489,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39740,
    "Metropolitan Division Code": "",
    "CSA Code": 428,
    "CBSA Full Title": "Reading, PA",
    "CBSA Solo": "Reading",
    "CBSA State": "PA",
    CBSA: "Reading, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Berks County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Berks County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "011",
    "County\nCode": 42011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39780,
    "Metropolitan Division Code": "",
    "CSA Code": 454,
    "CBSA Full Title": "Red Bluff, CA",
    "CBSA Solo": "Red Bluff",
    "CBSA State": "CA",
    CBSA: "Red Bluff, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Redding-Red Bluff, CA",
    "County/County Equivalent": "Tehama County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Tehama County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": 103,
    "County\nCode": "06103",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39820,
    "Metropolitan Division Code": "",
    "CSA Code": 454,
    "CBSA Full Title": "Redding, CA",
    "CBSA Solo": "Redding",
    "CBSA State": "CA",
    CBSA: "Redding, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Redding-Red Bluff, CA",
    "County/County Equivalent": "Shasta County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Shasta County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "089",
    "County\nCode": "06089",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39860,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "Red Wing, MN",
    "CBSA Solo": "Red Wing",
    "CBSA State": "MN",
    CBSA: "Red Wing, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Goodhue County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Goodhue County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "049",
    "County\nCode": 27049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39900,
    "Metropolitan Division Code": "",
    "CSA Code": 456,
    "CBSA Full Title": "Reno, NV",
    "CBSA Solo": "Reno",
    "CBSA State": "NV",
    CBSA: "Reno, NV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Reno-Carson City-Fernley, NV",
    "County/County Equivalent": "Storey County",
    "State Name": "Nevada",
    "State Abbr": "NV",
    "County, State": "Storey County, NV",
    "FIPS State Code": 32,
    "FIPS County Code": "029",
    "County\nCode": 32029,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 39900,
    "Metropolitan Division Code": "",
    "CSA Code": 456,
    "CBSA Full Title": "Reno, NV",
    "CBSA Solo": "Reno",
    "CBSA State": "NV",
    CBSA: "Reno, NV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Reno-Carson City-Fernley, NV",
    "County/County Equivalent": "Washoe County",
    "State Name": "Nevada",
    "State Abbr": "NV",
    "County, State": "Washoe County, NV",
    "FIPS State Code": 32,
    "FIPS County Code": "031",
    "County\nCode": 32031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39940,
    "Metropolitan Division Code": "",
    "CSA Code": 292,
    "CBSA Full Title": "Rexburg, ID",
    "CBSA Solo": "Rexburg",
    "CBSA State": "ID",
    CBSA: "Rexburg, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Idaho Falls-Rexburg-Blackfoot, ID",
    "County/County Equivalent": "Fremont County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Fremont County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "043",
    "County\nCode": 16043,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 39940,
    "Metropolitan Division Code": "",
    "CSA Code": 292,
    "CBSA Full Title": "Rexburg, ID",
    "CBSA Solo": "Rexburg",
    "CBSA State": "ID",
    CBSA: "Rexburg, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Idaho Falls-Rexburg-Blackfoot, ID",
    "County/County Equivalent": "Madison County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Madison County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "065",
    "County\nCode": 16065,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 39980,
    "Metropolitan Division Code": "",
    "CSA Code": 458,
    "CBSA Full Title": "Richmond, IN",
    "CBSA Solo": "Richmond",
    "CBSA State": "IN",
    CBSA: "Richmond, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Richmond-Connersville, IN",
    "County/County Equivalent": "Wayne County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Wayne County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 177,
    "County\nCode": 18177,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Amelia County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Amelia County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "007",
    "County\nCode": 51007,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Charles City County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Charles City County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "036",
    "County\nCode": 51036,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Chesterfield County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Chesterfield County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "041",
    "County\nCode": 51041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Dinwiddie County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Dinwiddie County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "053",
    "County\nCode": 51053,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Goochland County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Goochland County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "075",
    "County\nCode": 51075,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hanover County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Hanover County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "085",
    "County\nCode": 51085,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Henrico County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Henrico County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "087",
    "County\nCode": 51087,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "King and Queen County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "King and Queen County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "097",
    "County\nCode": 51097,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "King William County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "King William County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 101,
    "County\nCode": 51101,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "New Kent County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "New Kent County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 127,
    "County\nCode": 51127,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Powhatan County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Powhatan County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 145,
    "County\nCode": 51145,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Prince George County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Prince George County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 149,
    "County\nCode": 51149,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Sussex County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Sussex County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 183,
    "County\nCode": 51183,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Colonial Heights city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Colonial Heights city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 570,
    "County\nCode": 51570,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hopewell city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Hopewell city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 670,
    "County\nCode": 51670,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Petersburg city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Petersburg city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 730,
    "County\nCode": 51730,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Richmond, VA",
    "CBSA Solo": "Richmond",
    "CBSA State": "VA",
    CBSA: "Richmond, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Richmond city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Richmond city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 760,
    "County\nCode": 51760,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40080,
    "Metropolitan Division Code": "",
    "CSA Code": 336,
    "CBSA Full Title": "Richmond-Berea, KY",
    "CBSA Solo": "Richmond",
    "CBSA State": "KY",
    CBSA: "Richmond, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lexington-Fayette--Richmond--Frankfort, KY",
    "County/County Equivalent": "Estill County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Estill County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": "065",
    "County\nCode": 21065,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40080,
    "Metropolitan Division Code": "",
    "CSA Code": 336,
    "CBSA Full Title": "Richmond-Berea, KY",
    "CBSA Solo": "Richmond",
    "CBSA State": "KY",
    CBSA: "Richmond, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lexington-Fayette--Richmond--Frankfort, KY",
    "County/County Equivalent": "Madison County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Madison County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 151,
    "County\nCode": 21151,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40100,
    "Metropolitan Division Code": "",
    "CSA Code": 365,
    "CBSA Full Title": "Rio Grande City-Roma, TX",
    "CBSA Solo": "Rio Grande City",
    "CBSA State": "TX",
    CBSA: "Rio Grande City, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "McAllen-Edinburg, TX",
    "County/County Equivalent": "Starr County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Starr County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 427,
    "County\nCode": 48427,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40140,
    "Metropolitan Division Code": "",
    "CSA Code": 348,
    "CBSA Full Title": "Riverside-San Bernardino-Ontario, CA",
    "CBSA Solo": "Riverside",
    "CBSA State": "CA",
    CBSA: "Riverside, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Los Angeles-Long Beach, CA",
    "County/County Equivalent": "Riverside County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Riverside County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "065",
    "County\nCode": "06065",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40140,
    "Metropolitan Division Code": "",
    "CSA Code": 348,
    "CBSA Full Title": "Riverside-San Bernardino-Ontario, CA",
    "CBSA Solo": "Riverside",
    "CBSA State": "CA",
    CBSA: "Riverside, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Los Angeles-Long Beach, CA",
    "County/County Equivalent": "San Bernardino County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "San Bernardino County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "071",
    "County\nCode": "06071",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Riverton, WY",
    "CBSA Solo": "Riverton",
    "CBSA State": "WY",
    CBSA: "Riverton, WY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Fremont County",
    "State Name": "Wyoming",
    "State Abbr": "WY",
    "County, State": "Fremont County, WY",
    "FIPS State Code": 56,
    "FIPS County Code": "013",
    "County\nCode": 56013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Roanoke, VA",
    "CBSA Solo": "Roanoke",
    "CBSA State": "VA",
    CBSA: "Roanoke, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Botetourt County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Botetourt County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "023",
    "County\nCode": 51023,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Roanoke, VA",
    "CBSA Solo": "Roanoke",
    "CBSA State": "VA",
    CBSA: "Roanoke, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Craig County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Craig County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "045",
    "County\nCode": 51045,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Roanoke, VA",
    "CBSA Solo": "Roanoke",
    "CBSA State": "VA",
    CBSA: "Roanoke, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Franklin County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Franklin County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "067",
    "County\nCode": 51067,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Roanoke, VA",
    "CBSA Solo": "Roanoke",
    "CBSA State": "VA",
    CBSA: "Roanoke, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Roanoke County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Roanoke County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 161,
    "County\nCode": 51161,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Roanoke, VA",
    "CBSA Solo": "Roanoke",
    "CBSA State": "VA",
    CBSA: "Roanoke, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Roanoke city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Roanoke city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 770,
    "County\nCode": 51770,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Roanoke, VA",
    "CBSA Solo": "Roanoke",
    "CBSA State": "VA",
    CBSA: "Roanoke, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Salem city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Salem city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 775,
    "County\nCode": 51775,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40260,
    "Metropolitan Division Code": "",
    "CSA Code": 468,
    "CBSA Full Title": "Roanoke Rapids, NC",
    "CBSA Solo": "Roanoke Rapids",
    "CBSA State": "NC",
    CBSA: "Roanoke Rapids, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rocky Mount-Wilson-Roanoke Rapids, NC",
    "County/County Equivalent": "Halifax County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Halifax County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "083",
    "County\nCode": 37083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40260,
    "Metropolitan Division Code": "",
    "CSA Code": 468,
    "CBSA Full Title": "Roanoke Rapids, NC",
    "CBSA Solo": "Roanoke Rapids",
    "CBSA State": "NC",
    CBSA: "Roanoke Rapids, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rocky Mount-Wilson-Roanoke Rapids, NC",
    "County/County Equivalent": "Northampton County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Northampton County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 131,
    "County\nCode": 37131,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40300,
    "Metropolitan Division Code": "",
    "CSA Code": 466,
    "CBSA Full Title": "Rochelle, IL",
    "CBSA Solo": "Rochelle",
    "CBSA State": "IL",
    CBSA: "Rochelle, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rockford-Freeport-Rochelle, IL",
    "County/County Equivalent": "Ogle County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Ogle County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 141,
    "County\nCode": 17141,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40340,
    "Metropolitan Division Code": "",
    "CSA Code": 462,
    "CBSA Full Title": "Rochester, MN",
    "CBSA Solo": "Rochester",
    "CBSA State": "MN",
    CBSA: "Rochester, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rochester-Austin, MN",
    "County/County Equivalent": "Dodge County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Dodge County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "039",
    "County\nCode": 27039,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40340,
    "Metropolitan Division Code": "",
    "CSA Code": 462,
    "CBSA Full Title": "Rochester, MN",
    "CBSA Solo": "Rochester",
    "CBSA State": "MN",
    CBSA: "Rochester, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rochester-Austin, MN",
    "County/County Equivalent": "Fillmore County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Fillmore County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "045",
    "County\nCode": 27045,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40340,
    "Metropolitan Division Code": "",
    "CSA Code": 462,
    "CBSA Full Title": "Rochester, MN",
    "CBSA Solo": "Rochester",
    "CBSA State": "MN",
    CBSA: "Rochester, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rochester-Austin, MN",
    "County/County Equivalent": "Olmsted County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Olmsted County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 109,
    "County\nCode": 27109,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40340,
    "Metropolitan Division Code": "",
    "CSA Code": 462,
    "CBSA Full Title": "Rochester, MN",
    "CBSA Solo": "Rochester",
    "CBSA State": "MN",
    CBSA: "Rochester, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rochester-Austin, MN",
    "County/County Equivalent": "Wabasha County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Wabasha County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 157,
    "County\nCode": 27157,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40380,
    "Metropolitan Division Code": "",
    "CSA Code": 464,
    "CBSA Full Title": "Rochester, NY",
    "CBSA Solo": "Rochester",
    "CBSA State": "NY",
    CBSA: "Rochester, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rochester-Batavia-Seneca Falls, NY",
    "County/County Equivalent": "Livingston County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Livingston County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "051",
    "County\nCode": 36051,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40380,
    "Metropolitan Division Code": "",
    "CSA Code": 464,
    "CBSA Full Title": "Rochester, NY",
    "CBSA Solo": "Rochester",
    "CBSA State": "NY",
    CBSA: "Rochester, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rochester-Batavia-Seneca Falls, NY",
    "County/County Equivalent": "Monroe County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Monroe County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "055",
    "County\nCode": 36055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40380,
    "Metropolitan Division Code": "",
    "CSA Code": 464,
    "CBSA Full Title": "Rochester, NY",
    "CBSA Solo": "Rochester",
    "CBSA State": "NY",
    CBSA: "Rochester, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rochester-Batavia-Seneca Falls, NY",
    "County/County Equivalent": "Ontario County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Ontario County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "069",
    "County\nCode": 36069,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40380,
    "Metropolitan Division Code": "",
    "CSA Code": 464,
    "CBSA Full Title": "Rochester, NY",
    "CBSA Solo": "Rochester",
    "CBSA State": "NY",
    CBSA: "Rochester, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rochester-Batavia-Seneca Falls, NY",
    "County/County Equivalent": "Orleans County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Orleans County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "073",
    "County\nCode": 36073,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40380,
    "Metropolitan Division Code": "",
    "CSA Code": 464,
    "CBSA Full Title": "Rochester, NY",
    "CBSA Solo": "Rochester",
    "CBSA State": "NY",
    CBSA: "Rochester, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rochester-Batavia-Seneca Falls, NY",
    "County/County Equivalent": "Wayne County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Wayne County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": 117,
    "County\nCode": 36117,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40380,
    "Metropolitan Division Code": "",
    "CSA Code": 464,
    "CBSA Full Title": "Rochester, NY",
    "CBSA Solo": "Rochester",
    "CBSA State": "NY",
    CBSA: "Rochester, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rochester-Batavia-Seneca Falls, NY",
    "County/County Equivalent": "Yates County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Yates County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": 123,
    "County\nCode": 36123,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40420,
    "Metropolitan Division Code": "",
    "CSA Code": 466,
    "CBSA Full Title": "Rockford, IL",
    "CBSA Solo": "Rockford",
    "CBSA State": "IL",
    CBSA: "Rockford, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rockford-Freeport-Rochelle, IL",
    "County/County Equivalent": "Boone County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Boone County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "007",
    "County\nCode": 17007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40420,
    "Metropolitan Division Code": "",
    "CSA Code": 466,
    "CBSA Full Title": "Rockford, IL",
    "CBSA Solo": "Rockford",
    "CBSA State": "IL",
    CBSA: "Rockford, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rockford-Freeport-Rochelle, IL",
    "County/County Equivalent": "Winnebago County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Winnebago County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 201,
    "County\nCode": 17201,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Rockingham, NC",
    "CBSA Solo": "Rockingham",
    "CBSA State": "NC",
    CBSA: "Rockingham, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Richmond County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Richmond County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 153,
    "County\nCode": 37153,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40530,
    "Metropolitan Division Code": "",
    "CSA Code": 204,
    "CBSA Full Title": "Rockport, TX",
    "CBSA Solo": "Rockport",
    "CBSA State": "TX",
    CBSA: "Rockport, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Corpus Christi-Kingsville-Alice, TX",
    "County/County Equivalent": "Aransas County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Aransas County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "007",
    "County\nCode": 48007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Rock Springs, WY",
    "CBSA Solo": "Rock Springs",
    "CBSA State": "WY",
    CBSA: "Rock Springs, WY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Sweetwater County",
    "State Name": "Wyoming",
    "State Abbr": "WY",
    "County, State": "Sweetwater County, WY",
    "FIPS State Code": 56,
    "FIPS County Code": "037",
    "County\nCode": 56037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40580,
    "Metropolitan Division Code": "",
    "CSA Code": 468,
    "CBSA Full Title": "Rocky Mount, NC",
    "CBSA Solo": "Rocky Mount",
    "CBSA State": "NC",
    CBSA: "Rocky Mount, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rocky Mount-Wilson-Roanoke Rapids, NC",
    "County/County Equivalent": "Edgecombe County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Edgecombe County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "065",
    "County\nCode": 37065,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40580,
    "Metropolitan Division Code": "",
    "CSA Code": 468,
    "CBSA Full Title": "Rocky Mount, NC",
    "CBSA Solo": "Rocky Mount",
    "CBSA State": "NC",
    CBSA: "Rocky Mount, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rocky Mount-Wilson-Roanoke Rapids, NC",
    "County/County Equivalent": "Nash County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Nash County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 127,
    "County\nCode": 37127,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Rolla, MO",
    "CBSA Solo": "Rolla",
    "CBSA State": "MO",
    CBSA: "Rolla, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Phelps County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Phelps County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 161,
    "County\nCode": 29161,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40660,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Rome, GA",
    "CBSA Solo": "Rome",
    "CBSA State": "GA",
    CBSA: "Rome, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Floyd County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Floyd County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 115,
    "County\nCode": 13115,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Roseburg, OR",
    "CBSA Solo": "Roseburg",
    "CBSA State": "OR",
    CBSA: "Roseburg, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Douglas County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Douglas County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "019",
    "County\nCode": 41019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Roswell, NM",
    "CBSA Solo": "Roswell",
    "CBSA State": "NM",
    CBSA: "Roswell, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Chaves County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Chaves County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "005",
    "County\nCode": 35005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40760,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Ruidoso, NM",
    "CBSA Solo": "Ruidoso",
    "CBSA State": "NM",
    CBSA: "Ruidoso, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lincoln County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Lincoln County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "027",
    "County\nCode": 35027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Russellville, AR",
    "CBSA Solo": "Russellville",
    "CBSA State": "AR",
    CBSA: "Russellville, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pope County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Pope County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 115,
    "County\nCode": "05115",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Russellville, AR",
    "CBSA Solo": "Russellville",
    "CBSA State": "AR",
    CBSA: "Russellville, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Yell County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Yell County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 149,
    "County\nCode": "05149",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40820,
    "Metropolitan Division Code": "",
    "CSA Code": 384,
    "CBSA Full Title": "Ruston, LA",
    "CBSA Solo": "Ruston",
    "CBSA State": "LA",
    CBSA: "Ruston, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Monroe-Ruston, LA",
    "County/County Equivalent": "Lincoln Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Lincoln Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "061",
    "County\nCode": 22061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Rutland, VT",
    "CBSA Solo": "Rutland",
    "CBSA State": "VT",
    CBSA: "Rutland, VT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Rutland County",
    "State Name": "Vermont",
    "State Abbr": "VT",
    "County, State": "Rutland County, VT",
    "FIPS State Code": 50,
    "FIPS County Code": "021",
    "County\nCode": 50021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40900,
    "Metropolitan Division Code": "",
    "CSA Code": 472,
    "CBSA Full Title": "Sacramento-Roseville-Folsom, CA",
    "CBSA Solo": "Sacramento",
    "CBSA State": "CA",
    CBSA: "Sacramento, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Sacramento-Roseville, CA",
    "County/County Equivalent": "El Dorado County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "El Dorado County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "017",
    "County\nCode": "06017",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40900,
    "Metropolitan Division Code": "",
    "CSA Code": 472,
    "CBSA Full Title": "Sacramento-Roseville-Folsom, CA",
    "CBSA Solo": "Sacramento",
    "CBSA State": "CA",
    CBSA: "Sacramento, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Sacramento-Roseville, CA",
    "County/County Equivalent": "Placer County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Placer County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "061",
    "County\nCode": "06061",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40900,
    "Metropolitan Division Code": "",
    "CSA Code": 472,
    "CBSA Full Title": "Sacramento-Roseville-Folsom, CA",
    "CBSA Solo": "Sacramento",
    "CBSA State": "CA",
    CBSA: "Sacramento, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Sacramento-Roseville, CA",
    "County/County Equivalent": "Sacramento County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Sacramento County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "067",
    "County\nCode": "06067",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40900,
    "Metropolitan Division Code": "",
    "CSA Code": 472,
    "CBSA Full Title": "Sacramento-Roseville-Folsom, CA",
    "CBSA Solo": "Sacramento",
    "CBSA State": "CA",
    CBSA: "Sacramento, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Sacramento-Roseville, CA",
    "County/County Equivalent": "Yolo County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Yolo County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": 113,
    "County\nCode": "06113",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 40940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Safford, AZ",
    "CBSA Solo": "Safford",
    "CBSA State": "AZ",
    CBSA: "Safford, AZ",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Graham County",
    "State Name": "Arizona",
    "State Abbr": "AZ",
    "County, State": "Graham County, AZ",
    "FIPS State Code": "04",
    "FIPS County Code": "009",
    "County\nCode": "04009",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 40980,
    "Metropolitan Division Code": "",
    "CSA Code": 474,
    "CBSA Full Title": "Saginaw, MI",
    "CBSA Solo": "Saginaw",
    "CBSA State": "MI",
    CBSA: "Saginaw, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Saginaw-Midland-Bay City, MI",
    "County/County Equivalent": "Saginaw County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Saginaw County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 145,
    "County\nCode": 26145,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41060,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "St. Cloud, MN",
    "CBSA Solo": "St. Cloud",
    "CBSA State": "MN",
    CBSA: "St. Cloud, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Benton County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Benton County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "009",
    "County\nCode": 27009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41060,
    "Metropolitan Division Code": "",
    "CSA Code": 378,
    "CBSA Full Title": "St. Cloud, MN",
    "CBSA Solo": "St. Cloud",
    "CBSA State": "MN",
    CBSA: "St. Cloud, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Minneapolis-St. Paul, MN-WI",
    "County/County Equivalent": "Stearns County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Stearns County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 145,
    "County\nCode": 27145,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "St. George, UT",
    "CBSA Solo": "St. George",
    "CBSA State": "UT",
    CBSA: "St. George, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Washington County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Washington County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "053",
    "County\nCode": 49053,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "St. Joseph, MO-KS",
    "CBSA Solo": "St. Joseph",
    "CBSA State": "MO",
    CBSA: "St. Joseph, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Andrew County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Andrew County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "003",
    "County\nCode": 29003,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "St. Joseph, MO-KS",
    "CBSA Solo": "St. Joseph",
    "CBSA State": "MO",
    CBSA: "St. Joseph, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Doniphan County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Doniphan County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "043",
    "County\nCode": 20043,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "St. Joseph, MO-KS",
    "CBSA Solo": "St. Joseph",
    "CBSA State": "MO",
    CBSA: "St. Joseph, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Buchanan County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Buchanan County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "021",
    "County\nCode": 29021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41140,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "St. Joseph, MO-KS",
    "CBSA Solo": "St. Joseph",
    "CBSA State": "MO",
    CBSA: "St. Joseph, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "DeKalb County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "DeKalb County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "063",
    "County\nCode": 29063,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "Bond County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Bond County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "005",
    "County\nCode": 17005,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "Calhoun County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Calhoun County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "013",
    "County\nCode": 17013,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "Clinton County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Clinton County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "027",
    "County\nCode": 17027,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "Jersey County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Jersey County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "083",
    "County\nCode": 17083,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "Macoupin County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Macoupin County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 117,
    "County\nCode": 17117,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "Madison County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Madison County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 119,
    "County\nCode": 17119,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "Monroe County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Monroe County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 133,
    "County\nCode": 17133,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "St. Clair County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "St. Clair County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 163,
    "County\nCode": 17163,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "Franklin County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Franklin County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "071",
    "County\nCode": 29071,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Jefferson County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "099",
    "County\nCode": 29099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "Lincoln County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Lincoln County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 113,
    "County\nCode": 29113,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "St. Charles County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "St. Charles County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 183,
    "County\nCode": 29183,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "St. Louis County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "St. Louis County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 189,
    "County\nCode": 29189,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "Warren County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Warren County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 219,
    "County\nCode": 29219,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41180,
    "Metropolitan Division Code": "",
    "CSA Code": 476,
    "CBSA Full Title": "St. Louis, MO-IL",
    "CBSA Solo": "St. Louis",
    "CBSA State": "MO",
    CBSA: "St. Louis, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "St. Louis-St. Charles-Farmington, MO-IL",
    "County/County Equivalent": "St. Louis city",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "St. Louis city, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 510,
    "County\nCode": 29510,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41220,
    "Metropolitan Division Code": "",
    "CSA Code": 300,
    "CBSA Full Title": "St. Marys, GA",
    "CBSA Solo": "St. Marys",
    "CBSA State": "GA",
    CBSA: "St. Marys, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jacksonville-St. Marys-Palatka, FL-GA",
    "County/County Equivalent": "Camden County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Camden County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "039",
    "County\nCode": 13039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "St. Marys, PA",
    "CBSA Solo": "St. Marys",
    "CBSA State": "PA",
    CBSA: "St. Marys, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Elk County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Elk County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "047",
    "County\nCode": 42047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41400,
    "Metropolitan Division Code": "",
    "CSA Code": 566,
    "CBSA Full Title": "Salem, OH",
    "CBSA Solo": "Salem",
    "CBSA State": "OH",
    CBSA: "Salem, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Youngstown-Warren, OH-PA",
    "County/County Equivalent": "Columbiana County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Columbiana County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "029",
    "County\nCode": 39029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41420,
    "Metropolitan Division Code": "",
    "CSA Code": 440,
    "CBSA Full Title": "Salem, OR",
    "CBSA Solo": "Salem",
    "CBSA State": "OR",
    CBSA: "Salem, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Vancouver-Salem, OR-WA",
    "County/County Equivalent": "Marion County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Marion County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "047",
    "County\nCode": 41047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41420,
    "Metropolitan Division Code": "",
    "CSA Code": 440,
    "CBSA Full Title": "Salem, OR",
    "CBSA Solo": "Salem",
    "CBSA State": "OR",
    CBSA: "Salem, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Portland-Vancouver-Salem, OR-WA",
    "County/County Equivalent": "Polk County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Polk County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "053",
    "County\nCode": 41053,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Salina, KS",
    "CBSA Solo": "Salina",
    "CBSA State": "KS",
    CBSA: "Salina, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ottawa County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Ottawa County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 143,
    "County\nCode": 20143,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Salina, KS",
    "CBSA Solo": "Salina",
    "CBSA State": "KS",
    CBSA: "Salina, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Saline County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Saline County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 169,
    "County\nCode": 20169,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Salinas, CA",
    "CBSA Solo": "Salinas",
    "CBSA State": "CA",
    CBSA: "Salinas, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Monterey County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Monterey County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "053",
    "County\nCode": "06053",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41540,
    "Metropolitan Division Code": "",
    "CSA Code": 480,
    "CBSA Full Title": "Salisbury, MD-DE",
    "CBSA Solo": "Salisbury",
    "CBSA State": "MD",
    CBSA: "Salisbury, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salisbury-Cambridge, MD-DE",
    "County/County Equivalent": "Somerset County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Somerset County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "039",
    "County\nCode": 24039,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41540,
    "Metropolitan Division Code": "",
    "CSA Code": 480,
    "CBSA Full Title": "Salisbury, MD-DE",
    "CBSA Solo": "Salisbury",
    "CBSA State": "MD",
    CBSA: "Salisbury, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salisbury-Cambridge, MD-DE",
    "County/County Equivalent": "Sussex County",
    "State Name": "Delaware",
    "State Abbr": "DE",
    "County, State": "Sussex County, DE",
    "FIPS State Code": 10,
    "FIPS County Code": "005",
    "County\nCode": 10005,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41540,
    "Metropolitan Division Code": "",
    "CSA Code": 480,
    "CBSA Full Title": "Salisbury, MD-DE",
    "CBSA Solo": "Salisbury",
    "CBSA State": "MD",
    CBSA: "Salisbury, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salisbury-Cambridge, MD-DE",
    "County/County Equivalent": "Wicomico County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Wicomico County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "045",
    "County\nCode": 24045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41540,
    "Metropolitan Division Code": "",
    "CSA Code": 480,
    "CBSA Full Title": "Salisbury, MD-DE",
    "CBSA Solo": "Salisbury",
    "CBSA State": "MD",
    CBSA: "Salisbury, MD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salisbury-Cambridge, MD-DE",
    "County/County Equivalent": "Worcester County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Worcester County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "047",
    "County\nCode": 24047,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41620,
    "Metropolitan Division Code": "",
    "CSA Code": 482,
    "CBSA Full Title": "Salt Lake City, UT",
    "CBSA Solo": "Salt Lake City",
    "CBSA State": "UT",
    CBSA: "Salt Lake City, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salt Lake City-Provo-Orem, UT",
    "County/County Equivalent": "Salt Lake County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Salt Lake County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "035",
    "County\nCode": 49035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41620,
    "Metropolitan Division Code": "",
    "CSA Code": 482,
    "CBSA Full Title": "Salt Lake City, UT",
    "CBSA Solo": "Salt Lake City",
    "CBSA State": "UT",
    CBSA: "Salt Lake City, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Salt Lake City-Provo-Orem, UT",
    "County/County Equivalent": "Tooele County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Tooele County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "045",
    "County\nCode": 49045,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "San Angelo, TX",
    "CBSA Solo": "San Angelo",
    "CBSA State": "TX",
    CBSA: "San Angelo, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Irion County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Irion County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 235,
    "County\nCode": 48235,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "San Angelo, TX",
    "CBSA Solo": "San Angelo",
    "CBSA State": "TX",
    CBSA: "San Angelo, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Sterling County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Sterling County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 431,
    "County\nCode": 48431,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "San Angelo, TX",
    "CBSA Solo": "San Angelo",
    "CBSA State": "TX",
    CBSA: "San Angelo, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Tom Green County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Tom Green County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 451,
    "County\nCode": 48451,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41700,
    "Metropolitan Division Code": "",
    "CSA Code": 484,
    "CBSA Full Title": "San Antonio-New Braunfels, TX",
    "CBSA Solo": "San Antonio",
    "CBSA State": "TX",
    CBSA: "San Antonio, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Antonio-New Braunfels-Pearsall, TX",
    "County/County Equivalent": "Atascosa County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Atascosa County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "013",
    "County\nCode": 48013,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41700,
    "Metropolitan Division Code": "",
    "CSA Code": 484,
    "CBSA Full Title": "San Antonio-New Braunfels, TX",
    "CBSA Solo": "San Antonio",
    "CBSA State": "TX",
    CBSA: "San Antonio, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Antonio-New Braunfels-Pearsall, TX",
    "County/County Equivalent": "Bandera County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Bandera County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "019",
    "County\nCode": 48019,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41700,
    "Metropolitan Division Code": "",
    "CSA Code": 484,
    "CBSA Full Title": "San Antonio-New Braunfels, TX",
    "CBSA Solo": "San Antonio",
    "CBSA State": "TX",
    CBSA: "San Antonio, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Antonio-New Braunfels-Pearsall, TX",
    "County/County Equivalent": "Bexar County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Bexar County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "029",
    "County\nCode": 48029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41700,
    "Metropolitan Division Code": "",
    "CSA Code": 484,
    "CBSA Full Title": "San Antonio-New Braunfels, TX",
    "CBSA Solo": "San Antonio",
    "CBSA State": "TX",
    CBSA: "San Antonio, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Antonio-New Braunfels-Pearsall, TX",
    "County/County Equivalent": "Comal County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Comal County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "091",
    "County\nCode": 48091,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41700,
    "Metropolitan Division Code": "",
    "CSA Code": 484,
    "CBSA Full Title": "San Antonio-New Braunfels, TX",
    "CBSA Solo": "San Antonio",
    "CBSA State": "TX",
    CBSA: "San Antonio, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Antonio-New Braunfels-Pearsall, TX",
    "County/County Equivalent": "Guadalupe County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Guadalupe County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 187,
    "County\nCode": 48187,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41700,
    "Metropolitan Division Code": "",
    "CSA Code": 484,
    "CBSA Full Title": "San Antonio-New Braunfels, TX",
    "CBSA Solo": "San Antonio",
    "CBSA State": "TX",
    CBSA: "San Antonio, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Antonio-New Braunfels-Pearsall, TX",
    "County/County Equivalent": "Kendall County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Kendall County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 259,
    "County\nCode": 48259,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41700,
    "Metropolitan Division Code": "",
    "CSA Code": 484,
    "CBSA Full Title": "San Antonio-New Braunfels, TX",
    "CBSA Solo": "San Antonio",
    "CBSA State": "TX",
    CBSA: "San Antonio, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Antonio-New Braunfels-Pearsall, TX",
    "County/County Equivalent": "Medina County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Medina County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 325,
    "County\nCode": 48325,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41700,
    "Metropolitan Division Code": "",
    "CSA Code": 484,
    "CBSA Full Title": "San Antonio-New Braunfels, TX",
    "CBSA Solo": "San Antonio",
    "CBSA State": "TX",
    CBSA: "San Antonio, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Antonio-New Braunfels-Pearsall, TX",
    "County/County Equivalent": "Wilson County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Wilson County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 493,
    "County\nCode": 48493,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "San Diego-Chula Vista-Carlsbad, CA",
    "CBSA Solo": "San Diego",
    "CBSA State": "CA",
    CBSA: "San Diego, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "San Diego County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "San Diego County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "073",
    "County\nCode": "06073",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41760,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sandpoint, ID",
    "CBSA Solo": "Sandpoint",
    "CBSA State": "ID",
    CBSA: "Sandpoint, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Bonner County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Bonner County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "017",
    "County\nCode": 16017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41780,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "Sandusky, OH",
    "CBSA Solo": "Sandusky",
    "CBSA State": "OH",
    CBSA: "Sandusky, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Erie County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Erie County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "043",
    "County\nCode": 39043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41820,
    "Metropolitan Division Code": "",
    "CSA Code": 246,
    "CBSA Full Title": "Sanford, NC",
    "CBSA Solo": "Sanford",
    "CBSA State": "NC",
    CBSA: "Sanford, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fayetteville-Sanford-Lumberton, NC",
    "County/County Equivalent": "Lee County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Lee County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 105,
    "County\nCode": 37105,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41860,
    "Metropolitan Division Code": 36084,
    "CSA Code": 488,
    "CBSA Full Title": "San Francisco-Oakland-Berkeley, CA",
    "CBSA Solo": "San Francisco",
    "CBSA State": "CA",
    CBSA: "San Francisco, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Oakland-Berkeley-Livermore, CA",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "Alameda County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Alameda County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "001",
    "County\nCode": "06001",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41860,
    "Metropolitan Division Code": 36084,
    "CSA Code": 488,
    "CBSA Full Title": "San Francisco-Oakland-Berkeley, CA",
    "CBSA Solo": "San Francisco",
    "CBSA State": "CA",
    CBSA: "San Francisco, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Oakland-Berkeley-Livermore, CA",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "Contra Costa County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Contra Costa County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "013",
    "County\nCode": "06013",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41860,
    "Metropolitan Division Code": 41884,
    "CSA Code": 488,
    "CBSA Full Title": "San Francisco-Oakland-Berkeley, CA",
    "CBSA Solo": "San Francisco",
    "CBSA State": "CA",
    CBSA: "San Francisco, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "San Francisco-San Mateo-Redwood City, CA",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "San Francisco County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "San Francisco County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "075",
    "County\nCode": "06075",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41860,
    "Metropolitan Division Code": 41884,
    "CSA Code": 488,
    "CBSA Full Title": "San Francisco-Oakland-Berkeley, CA",
    "CBSA Solo": "San Francisco",
    "CBSA State": "CA",
    CBSA: "San Francisco, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "San Francisco-San Mateo-Redwood City, CA",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "San Mateo County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "San Mateo County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "081",
    "County\nCode": "06081",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41860,
    "Metropolitan Division Code": 42034,
    "CSA Code": 488,
    "CBSA Full Title": "San Francisco-Oakland-Berkeley, CA",
    "CBSA Solo": "San Francisco",
    "CBSA State": "CA",
    CBSA: "San Francisco, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "San Rafael, CA",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "Marin County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Marin County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "041",
    "County\nCode": "06041",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41900,
    "Metropolitan Division Code": "",
    "CSA Code": 364,
    "CBSA Full Title": "San Germán, PR",
    "CBSA Solo": "San Germán",
    "CBSA State": "PR",
    CBSA: "San Germán, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mayagüez-San Germán, PR",
    "County/County Equivalent": "Cabo Rojo Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Cabo Rojo Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "023",
    "County\nCode": 72023,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41900,
    "Metropolitan Division Code": "",
    "CSA Code": 364,
    "CBSA Full Title": "San Germán, PR",
    "CBSA Solo": "San Germán",
    "CBSA State": "PR",
    CBSA: "San Germán, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mayagüez-San Germán, PR",
    "County/County Equivalent": "Lajas Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Lajas Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "079",
    "County\nCode": 72079,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41900,
    "Metropolitan Division Code": "",
    "CSA Code": 364,
    "CBSA Full Title": "San Germán, PR",
    "CBSA Solo": "San Germán",
    "CBSA State": "PR",
    CBSA: "San Germán, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mayagüez-San Germán, PR",
    "County/County Equivalent": "Sabana Grande Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Sabana Grande Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 121,
    "County\nCode": 72121,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41900,
    "Metropolitan Division Code": "",
    "CSA Code": 364,
    "CBSA Full Title": "San Germán, PR",
    "CBSA Solo": "San Germán",
    "CBSA State": "PR",
    CBSA: "San Germán, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Mayagüez-San Germán, PR",
    "County/County Equivalent": "San Germán Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "San Germán Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 125,
    "County\nCode": 72125,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41940,
    "Metropolitan Division Code": "",
    "CSA Code": 488,
    "CBSA Full Title": "San Jose-Sunnyvale-Santa Clara, CA",
    "CBSA Solo": "San Jose",
    "CBSA State": "CA",
    CBSA: "San Jose, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "San Benito County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "San Benito County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "069",
    "County\nCode": "06069",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41940,
    "Metropolitan Division Code": "",
    "CSA Code": 488,
    "CBSA Full Title": "San Jose-Sunnyvale-Santa Clara, CA",
    "CBSA Solo": "San Jose",
    "CBSA State": "CA",
    CBSA: "San Jose, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "Santa Clara County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Santa Clara County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "085",
    "County\nCode": "06085",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Aguas Buenas Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Aguas Buenas Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "007",
    "County\nCode": 72007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Aibonito Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Aibonito Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "009",
    "County\nCode": 72009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Barceloneta Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Barceloneta Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "017",
    "County\nCode": 72017,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Barranquitas Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Barranquitas Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "019",
    "County\nCode": 72019,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Bayamón Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Bayamón Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "021",
    "County\nCode": 72021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Caguas Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Caguas Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "025",
    "County\nCode": 72025,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Canóvanas Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Canóvanas Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "029",
    "County\nCode": 72029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Carolina Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Carolina Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "031",
    "County\nCode": 72031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Cataño Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Cataño Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "033",
    "County\nCode": 72033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Cayey Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Cayey Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "035",
    "County\nCode": 72035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Ceiba Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Ceiba Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "037",
    "County\nCode": 72037,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Ciales Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Ciales Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "039",
    "County\nCode": 72039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Cidra Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Cidra Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "041",
    "County\nCode": 72041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Comerío Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Comerío Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "045",
    "County\nCode": 72045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Corozal Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Corozal Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "047",
    "County\nCode": 72047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Dorado Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Dorado Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "051",
    "County\nCode": 72051,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Fajardo Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Fajardo Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "053",
    "County\nCode": 72053,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Florida Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Florida Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "054",
    "County\nCode": 72054,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Guaynabo Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Guaynabo Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "061",
    "County\nCode": 72061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Gurabo Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Gurabo Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "063",
    "County\nCode": 72063,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Humacao Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Humacao Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "069",
    "County\nCode": 72069,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Juncos Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Juncos Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "077",
    "County\nCode": 72077,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Las Piedras Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Las Piedras Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "085",
    "County\nCode": 72085,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Loíza Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Loíza Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "087",
    "County\nCode": 72087,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Luquillo Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Luquillo Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "089",
    "County\nCode": 72089,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Manatí Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Manatí Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "091",
    "County\nCode": 72091,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Maunabo Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Maunabo Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "095",
    "County\nCode": 72095,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Morovis Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Morovis Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 101,
    "County\nCode": 72101,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Naguabo Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Naguabo Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 103,
    "County\nCode": 72103,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Naranjito Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Naranjito Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 105,
    "County\nCode": 72105,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Orocovis Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Orocovis Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 107,
    "County\nCode": 72107,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Río Grande Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Río Grande Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 119,
    "County\nCode": 72119,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "San Juan Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "San Juan Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 127,
    "County\nCode": 72127,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "San Lorenzo Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "San Lorenzo Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 129,
    "County\nCode": 72129,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Toa Alta Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Toa Alta Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 135,
    "County\nCode": 72135,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Toa Baja Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Toa Baja Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 137,
    "County\nCode": 72137,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Trujillo Alto Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Trujillo Alto Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 139,
    "County\nCode": 72139,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Vega Alta Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Vega Alta Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 143,
    "County\nCode": 72143,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Vega Baja Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Vega Baja Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 145,
    "County\nCode": 72145,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 41980,
    "Metropolitan Division Code": "",
    "CSA Code": 490,
    "CBSA Full Title": "San Juan-Bayamón-Caguas, PR",
    "CBSA Solo": "San Juan",
    "CBSA State": "PR",
    CBSA: "San Juan, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Juan-Bayamón, PR",
    "County/County Equivalent": "Yabucoa Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Yabucoa Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 151,
    "County\nCode": 72151,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42020,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "San Luis Obispo-Paso Robles, CA",
    "CBSA Solo": "San Luis Obispo",
    "CBSA State": "CA",
    CBSA: "San Luis Obispo, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "San Luis Obispo County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "San Luis Obispo County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "079",
    "County\nCode": "06079",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42100,
    "Metropolitan Division Code": "",
    "CSA Code": 488,
    "CBSA Full Title": "Santa Cruz-Watsonville, CA",
    "CBSA Solo": "Santa Cruz",
    "CBSA State": "CA",
    CBSA: "Santa Cruz, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "Santa Cruz County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Santa Cruz County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "087",
    "County\nCode": "06087",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42140,
    "Metropolitan Division Code": "",
    "CSA Code": 106,
    "CBSA Full Title": "Santa Fe, NM",
    "CBSA Solo": "Santa Fe",
    "CBSA State": "NM",
    CBSA: "Santa Fe, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Albuquerque-Santa Fe-Las Vegas, NM",
    "County/County Equivalent": "Santa Fe County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Santa Fe County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "049",
    "County\nCode": 35049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42180,
    "Metropolitan Division Code": "",
    "CSA Code": 434,
    "CBSA Full Title": "Santa Isabel, PR",
    "CBSA Solo": "Santa Isabel",
    "CBSA State": "PR",
    CBSA: "Santa Isabel, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Ponce-Yauco-Coamo, PR",
    "County/County Equivalent": "Santa Isabel Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Santa Isabel Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 133,
    "County\nCode": 72133,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42200,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Santa Maria-Santa Barbara, CA",
    "CBSA Solo": "Santa Maria",
    "CBSA State": "CA",
    CBSA: "Santa Maria, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Santa Barbara County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Santa Barbara County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "083",
    "County\nCode": "06083",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42220,
    "Metropolitan Division Code": "",
    "CSA Code": 488,
    "CBSA Full Title": "Santa Rosa-Petaluma, CA",
    "CBSA Solo": "Santa Rosa",
    "CBSA State": "CA",
    CBSA: "Santa Rosa, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "Sonoma County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Sonoma County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "097",
    "County\nCode": "06097",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sault Ste. Marie, MI",
    "CBSA Solo": "Sault Ste. Marie",
    "CBSA State": "MI",
    CBSA: "Sault Ste. Marie, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Chippewa County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Chippewa County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "033",
    "County\nCode": 26033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42340,
    "Metropolitan Division Code": "",
    "CSA Code": 496,
    "CBSA Full Title": "Savannah, GA",
    "CBSA Solo": "Savannah",
    "CBSA State": "GA",
    CBSA: "Savannah, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Savannah-Hinesville-Statesboro, GA",
    "County/County Equivalent": "Bryan County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Bryan County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "029",
    "County\nCode": 13029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42340,
    "Metropolitan Division Code": "",
    "CSA Code": 496,
    "CBSA Full Title": "Savannah, GA",
    "CBSA Solo": "Savannah",
    "CBSA State": "GA",
    CBSA: "Savannah, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Savannah-Hinesville-Statesboro, GA",
    "County/County Equivalent": "Chatham County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Chatham County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "051",
    "County\nCode": 13051,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42340,
    "Metropolitan Division Code": "",
    "CSA Code": 496,
    "CBSA Full Title": "Savannah, GA",
    "CBSA Solo": "Savannah",
    "CBSA State": "GA",
    CBSA: "Savannah, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Savannah-Hinesville-Statesboro, GA",
    "County/County Equivalent": "Effingham County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Effingham County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 103,
    "County\nCode": 13103,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 42380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sayre, PA",
    "CBSA Solo": "Sayre",
    "CBSA State": "PA",
    CBSA: "Sayre, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Bradford County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Bradford County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "015",
    "County\nCode": 42015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Scottsbluff, NE",
    "CBSA Solo": "Scottsbluff",
    "CBSA State": "NE",
    CBSA: "Scottsbluff, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Banner County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Banner County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "007",
    "County\nCode": 31007,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 42420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Scottsbluff, NE",
    "CBSA Solo": "Scottsbluff",
    "CBSA State": "NE",
    CBSA: "Scottsbluff, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Scotts Bluff County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Scotts Bluff County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 157,
    "County\nCode": 31157,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Scottsbluff, NE",
    "CBSA Solo": "Scottsbluff",
    "CBSA State": "NE",
    CBSA: "Scottsbluff, NE",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Sioux County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Sioux County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": 165,
    "County\nCode": 31165,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 42460,
    "Metropolitan Division Code": "",
    "CSA Code": 497,
    "CBSA Full Title": "Scottsboro, AL",
    "CBSA Solo": "Scottsboro",
    "CBSA State": "AL",
    CBSA: "Scottsboro, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Scottsboro-Fort Payne, AL",
    "County/County Equivalent": "Jackson County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Jackson County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "071",
    "County\nCode": "01071",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42500,
    "Metropolitan Division Code": "",
    "CSA Code": 350,
    "CBSA Full Title": "Scottsburg, IN",
    "CBSA Solo": "Scottsburg",
    "CBSA State": "IN",
    CBSA: "Scottsburg, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Louisville/Jefferson County--Elizabethtown--Bardstown, KY-IN",
    "County/County Equivalent": "Scott County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Scott County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 143,
    "County\nCode": 18143,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Scranton--Wilkes-Barre, PA",
    "CBSA Solo": "Scranton",
    "CBSA State": "PA",
    CBSA: "Scranton, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lackawanna County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Lackawanna County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "069",
    "County\nCode": 42069,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Scranton--Wilkes-Barre, PA",
    "CBSA Solo": "Scranton",
    "CBSA State": "PA",
    CBSA: "Scranton, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Luzerne County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Luzerne County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "079",
    "County\nCode": 42079,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Scranton--Wilkes-Barre, PA",
    "CBSA Solo": "Scranton",
    "CBSA State": "PA",
    CBSA: "Scranton, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Wyoming County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Wyoming County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": 131,
    "County\nCode": 42131,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 42620,
    "Metropolitan Division Code": "",
    "CSA Code": 340,
    "CBSA Full Title": "Searcy, AR",
    "CBSA Solo": "Searcy",
    "CBSA State": "AR",
    CBSA: "Searcy, AR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Little Rock-North Little Rock, AR",
    "County/County Equivalent": "White County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "White County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": 145,
    "County\nCode": "05145",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42660,
    "Metropolitan Division Code": 42644,
    "CSA Code": 500,
    "CBSA Full Title": "Seattle-Tacoma-Bellevue, WA",
    "CBSA Solo": "Seattle",
    "CBSA State": "WA",
    CBSA: "Seattle, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Seattle-Bellevue-Kent, WA",
    "CSA Title": "Seattle-Tacoma, WA",
    "County/County Equivalent": "King County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "King County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "033",
    "County\nCode": 53033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42660,
    "Metropolitan Division Code": 42644,
    "CSA Code": 500,
    "CBSA Full Title": "Seattle-Tacoma-Bellevue, WA",
    "CBSA Solo": "Seattle",
    "CBSA State": "WA",
    CBSA: "Seattle, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Seattle-Bellevue-Kent, WA",
    "CSA Title": "Seattle-Tacoma, WA",
    "County/County Equivalent": "Snohomish County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Snohomish County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "061",
    "County\nCode": 53061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42660,
    "Metropolitan Division Code": 45104,
    "CSA Code": 500,
    "CBSA Full Title": "Seattle-Tacoma-Bellevue, WA",
    "CBSA Solo": "Seattle",
    "CBSA State": "WA",
    CBSA: "Seattle, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Tacoma-Lakewood, WA",
    "CSA Title": "Seattle-Tacoma, WA",
    "County/County Equivalent": "Pierce County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Pierce County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "053",
    "County\nCode": 53053,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42680,
    "Metropolitan Division Code": "",
    "CSA Code": 370,
    "CBSA Full Title": "Sebastian-Vero Beach, FL",
    "CBSA Solo": "Sebastian",
    "CBSA State": "FL",
    CBSA: "Sebastian, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Miami-Port St. Lucie-Fort Lauderdale, FL",
    "County/County Equivalent": "Indian River County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Indian River County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "061",
    "County\nCode": 12061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sebring-Avon Park, FL",
    "CBSA Solo": "Sebring",
    "CBSA State": "FL",
    CBSA: "Sebring, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Highlands County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Highlands County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "055",
    "County\nCode": 12055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sedalia, MO",
    "CBSA Solo": "Sedalia",
    "CBSA State": "MO",
    CBSA: "Sedalia, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pettis County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Pettis County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 159,
    "County\nCode": 29159,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42780,
    "Metropolitan Division Code": "",
    "CSA Code": 146,
    "CBSA Full Title": "Selinsgrove, PA",
    "CBSA Solo": "Selinsgrove",
    "CBSA State": "PA",
    CBSA: "Selinsgrove, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bloomsburg-Berwick-Sunbury, PA",
    "County/County Equivalent": "Snyder County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Snyder County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": 109,
    "County\nCode": 42109,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42820,
    "Metropolitan Division Code": "",
    "CSA Code": 388,
    "CBSA Full Title": "Selma, AL",
    "CBSA Solo": "Selma",
    "CBSA State": "AL",
    CBSA: "Selma, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Montgomery-Selma-Alexander City, AL",
    "County/County Equivalent": "Dallas County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Dallas County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "047",
    "County\nCode": "01047",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42860,
    "Metropolitan Division Code": "",
    "CSA Code": 273,
    "CBSA Full Title": "Seneca, SC",
    "CBSA Solo": "Seneca",
    "CBSA State": "SC",
    CBSA: "Seneca, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greenville-Spartanburg-Anderson, SC",
    "County/County Equivalent": "Oconee County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Oconee County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "073",
    "County\nCode": 45073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42900,
    "Metropolitan Division Code": "",
    "CSA Code": 464,
    "CBSA Full Title": "Seneca Falls, NY",
    "CBSA Solo": "Seneca Falls",
    "CBSA State": "NY",
    CBSA: "Seneca Falls, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rochester-Batavia-Seneca Falls, NY",
    "County/County Equivalent": "Seneca County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Seneca County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "099",
    "County\nCode": 36099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42940,
    "Metropolitan Division Code": "",
    "CSA Code": 315,
    "CBSA Full Title": "Sevierville, TN",
    "CBSA Solo": "Sevierville",
    "CBSA State": "TN",
    CBSA: "Sevierville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Knoxville-Morristown-Sevierville, TN",
    "County/County Equivalent": "Sevier County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Sevier County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 155,
    "County\nCode": 47155,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 42980,
    "Metropolitan Division Code": "",
    "CSA Code": 294,
    "CBSA Full Title": "Seymour, IN",
    "CBSA Solo": "Seymour",
    "CBSA State": "IN",
    CBSA: "Seymour, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Indianapolis-Carmel-Muncie, IN",
    "County/County Equivalent": "Jackson County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Jackson County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "071",
    "County\nCode": 18071,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43020,
    "Metropolitan Division Code": "",
    "CSA Code": 267,
    "CBSA Full Title": "Shawano, WI",
    "CBSA Solo": "Shawano",
    "CBSA State": "WI",
    CBSA: "Shawano, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Green Bay-Shawano, WI",
    "County/County Equivalent": "Menominee County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Menominee County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "078",
    "County\nCode": 55078,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 43020,
    "Metropolitan Division Code": "",
    "CSA Code": 267,
    "CBSA Full Title": "Shawano, WI",
    "CBSA Solo": "Shawano",
    "CBSA State": "WI",
    CBSA: "Shawano, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Green Bay-Shawano, WI",
    "County/County Equivalent": "Shawano County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Shawano County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": 115,
    "County\nCode": 55115,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43060,
    "Metropolitan Division Code": "",
    "CSA Code": 416,
    "CBSA Full Title": "Shawnee, OK",
    "CBSA Solo": "Shawnee",
    "CBSA State": "OK",
    CBSA: "Shawnee, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Oklahoma City-Shawnee, OK",
    "County/County Equivalent": "Pottawatomie County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Pottawatomie County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 125,
    "County\nCode": 40125,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sheboygan, WI",
    "CBSA Solo": "Sheboygan",
    "CBSA State": "WI",
    CBSA: "Sheboygan, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Sheboygan County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Sheboygan County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": 117,
    "County\nCode": 55117,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43140,
    "Metropolitan Division Code": "",
    "CSA Code": 172,
    "CBSA Full Title": "Shelby, NC",
    "CBSA Solo": "Shelby",
    "CBSA State": "NC",
    CBSA: "Shelby, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Charlotte-Concord, NC-SC",
    "County/County Equivalent": "Cleveland County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Cleveland County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "045",
    "County\nCode": 37045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43180,
    "Metropolitan Division Code": "",
    "CSA Code": 400,
    "CBSA Full Title": "Shelbyville, TN",
    "CBSA Solo": "Shelbyville",
    "CBSA State": "TN",
    CBSA: "Shelbyville, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Nashville-Davidson--Murfreesboro, TN",
    "County/County Equivalent": "Bedford County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Bedford County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "003",
    "County\nCode": 47003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43220,
    "Metropolitan Division Code": "",
    "CSA Code": 500,
    "CBSA Full Title": "Shelton, WA",
    "CBSA Solo": "Shelton",
    "CBSA State": "WA",
    CBSA: "Shelton, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Seattle-Tacoma, WA",
    "County/County Equivalent": "Mason County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Mason County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "045",
    "County\nCode": 53045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sheridan, WY",
    "CBSA Solo": "Sheridan",
    "CBSA State": "WY",
    CBSA: "Sheridan, WY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Sheridan County",
    "State Name": "Wyoming",
    "State Abbr": "WY",
    "County, State": "Sheridan County, WY",
    "FIPS State Code": 56,
    "FIPS County Code": "033",
    "County\nCode": 56033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43300,
    "Metropolitan Division Code": "",
    "CSA Code": 206,
    "CBSA Full Title": "Sherman-Denison, TX",
    "CBSA Solo": "Sherman",
    "CBSA State": "TX",
    CBSA: "Sherman, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dallas-Fort Worth, TX-OK",
    "County/County Equivalent": "Grayson County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Grayson County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 181,
    "County\nCode": 48181,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43320,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Show Low, AZ",
    "CBSA Solo": "Show Low",
    "CBSA State": "AZ",
    CBSA: "Show Low, AZ",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Navajo County",
    "State Name": "Arizona",
    "State Abbr": "AZ",
    "County, State": "Navajo County, AZ",
    "FIPS State Code": "04",
    "FIPS County Code": "017",
    "County\nCode": "04017",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43340,
    "Metropolitan Division Code": "",
    "CSA Code": 508,
    "CBSA Full Title": "Shreveport-Bossier City, LA",
    "CBSA Solo": "Shreveport",
    "CBSA State": "LA",
    CBSA: "Shreveport, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Shreveport-Bossier City-Minden, LA",
    "County/County Equivalent": "Bossier Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Bossier Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "015",
    "County\nCode": 22015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43340,
    "Metropolitan Division Code": "",
    "CSA Code": 508,
    "CBSA Full Title": "Shreveport-Bossier City, LA",
    "CBSA Solo": "Shreveport",
    "CBSA State": "LA",
    CBSA: "Shreveport, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Shreveport-Bossier City-Minden, LA",
    "County/County Equivalent": "Caddo Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "Caddo Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "017",
    "County\nCode": 22017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43340,
    "Metropolitan Division Code": "",
    "CSA Code": 508,
    "CBSA Full Title": "Shreveport-Bossier City, LA",
    "CBSA Solo": "Shreveport",
    "CBSA State": "LA",
    CBSA: "Shreveport, LA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Shreveport-Bossier City-Minden, LA",
    "County/County Equivalent": "De Soto Parish",
    "State Name": "Louisiana",
    "State Abbr": "LA",
    "County, State": "De Soto Parish, LA",
    "FIPS State Code": 22,
    "FIPS County Code": "031",
    "County\nCode": 22031,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 43380,
    "Metropolitan Division Code": "",
    "CSA Code": 212,
    "CBSA Full Title": "Sidney, OH",
    "CBSA Solo": "Sidney",
    "CBSA State": "OH",
    CBSA: "Sidney, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dayton-Springfield-Kettering, OH",
    "County/County Equivalent": "Shelby County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Shelby County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 149,
    "County\nCode": 39149,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sierra Vista-Douglas, AZ",
    "CBSA Solo": "Sierra Vista",
    "CBSA State": "AZ",
    CBSA: "Sierra Vista, AZ",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cochise County",
    "State Name": "Arizona",
    "State Abbr": "AZ",
    "County, State": "Cochise County, AZ",
    "FIPS State Code": "04",
    "FIPS County Code": "003",
    "County\nCode": "04003",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43460,
    "Metropolitan Division Code": "",
    "CSA Code": 164,
    "CBSA Full Title": "Sikeston, MO",
    "CBSA Solo": "Sikeston",
    "CBSA State": "MO",
    CBSA: "Sikeston, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cape Girardeau-Sikeston, MO-IL",
    "County/County Equivalent": "Scott County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Scott County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 201,
    "County\nCode": 29201,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Silver City, NM",
    "CBSA Solo": "Silver City",
    "CBSA State": "NM",
    CBSA: "Silver City, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Grant County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Grant County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "017",
    "County\nCode": 35017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sioux City, IA-NE-SD",
    "CBSA Solo": "Sioux City",
    "CBSA State": "IA",
    CBSA: "Sioux City, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Woodbury County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Woodbury County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": 193,
    "County\nCode": 19193,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sioux City, IA-NE-SD",
    "CBSA Solo": "Sioux City",
    "CBSA State": "IA",
    CBSA: "Sioux City, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Dakota County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Dakota County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "043",
    "County\nCode": 31043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sioux City, IA-NE-SD",
    "CBSA Solo": "Sioux City",
    "CBSA State": "IA",
    CBSA: "Sioux City, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Dixon County",
    "State Name": "Nebraska",
    "State Abbr": "NE",
    "County, State": "Dixon County, NE",
    "FIPS State Code": 31,
    "FIPS County Code": "051",
    "County\nCode": 31051,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 43580,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sioux City, IA-NE-SD",
    "CBSA Solo": "Sioux City",
    "CBSA State": "IA",
    CBSA: "Sioux City, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Union County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Union County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": 127,
    "County\nCode": 46127,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sioux Falls, SD",
    "CBSA Solo": "Sioux Falls",
    "CBSA State": "SD",
    CBSA: "Sioux Falls, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lincoln County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Lincoln County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "083",
    "County\nCode": 46083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sioux Falls, SD",
    "CBSA Solo": "Sioux Falls",
    "CBSA State": "SD",
    CBSA: "Sioux Falls, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "McCook County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "McCook County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "087",
    "County\nCode": 46087,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 43620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sioux Falls, SD",
    "CBSA Solo": "Sioux Falls",
    "CBSA State": "SD",
    CBSA: "Sioux Falls, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Minnehaha County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Minnehaha County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "099",
    "County\nCode": 46099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sioux Falls, SD",
    "CBSA Solo": "Sioux Falls",
    "CBSA State": "SD",
    CBSA: "Sioux Falls, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Turner County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Turner County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": 125,
    "County\nCode": 46125,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 43660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Snyder, TX",
    "CBSA Solo": "Snyder",
    "CBSA State": "TX",
    CBSA: "Snyder, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Scurry County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Scurry County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 415,
    "County\nCode": 48415,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Somerset, KY",
    "CBSA Solo": "Somerset",
    "CBSA State": "KY",
    CBSA: "Somerset, KY",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pulaski County",
    "State Name": "Kentucky",
    "State Abbr": "KY",
    "County, State": "Pulaski County, KY",
    "FIPS State Code": 21,
    "FIPS County Code": 199,
    "County\nCode": 21199,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43740,
    "Metropolitan Division Code": "",
    "CSA Code": 306,
    "CBSA Full Title": "Somerset, PA",
    "CBSA Solo": "Somerset",
    "CBSA State": "PA",
    CBSA: "Somerset, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Johnstown-Somerset, PA",
    "County/County Equivalent": "Somerset County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Somerset County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": 111,
    "County\nCode": 42111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43760,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sonora, CA",
    "CBSA Solo": "Sonora",
    "CBSA State": "CA",
    CBSA: "Sonora, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Tuolumne County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Tuolumne County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": 109,
    "County\nCode": "06109",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43780,
    "Metropolitan Division Code": "",
    "CSA Code": 515,
    "CBSA Full Title": "South Bend-Mishawaka, IN-MI",
    "CBSA Solo": "South Bend",
    "CBSA State": "IN",
    CBSA: "South Bend, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "South Bend-Elkhart-Mishawaka, IN-MI",
    "County/County Equivalent": "St. Joseph County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "St. Joseph County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 141,
    "County\nCode": 18141,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43780,
    "Metropolitan Division Code": "",
    "CSA Code": 515,
    "CBSA Full Title": "South Bend-Mishawaka, IN-MI",
    "CBSA Solo": "South Bend",
    "CBSA State": "IN",
    CBSA: "South Bend, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "South Bend-Elkhart-Mishawaka, IN-MI",
    "County/County Equivalent": "Cass County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Cass County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "027",
    "County\nCode": 26027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43900,
    "Metropolitan Division Code": "",
    "CSA Code": 273,
    "CBSA Full Title": "Spartanburg, SC",
    "CBSA Solo": "Spartanburg",
    "CBSA State": "SC",
    CBSA: "Spartanburg, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greenville-Spartanburg-Anderson, SC",
    "County/County Equivalent": "Spartanburg County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Spartanburg County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "083",
    "County\nCode": 45083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43940,
    "Metropolitan Division Code": "",
    "CSA Code": 452,
    "CBSA Full Title": "Spearfish, SD",
    "CBSA Solo": "Spearfish",
    "CBSA State": "SD",
    CBSA: "Spearfish, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rapid City-Spearfish, SD",
    "County/County Equivalent": "Lawrence County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Lawrence County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "081",
    "County\nCode": 46081,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 43980,
    "Metropolitan Division Code": "",
    "CSA Code": 517,
    "CBSA Full Title": "Spencer, IA",
    "CBSA Solo": "Spencer",
    "CBSA State": "IA",
    CBSA: "Spencer, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Spencer-Spirit Lake, IA",
    "County/County Equivalent": "Clay County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Clay County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "041",
    "County\nCode": 19041,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44020,
    "Metropolitan Division Code": "",
    "CSA Code": 517,
    "CBSA Full Title": "Spirit Lake, IA",
    "CBSA Solo": "Spirit Lake",
    "CBSA State": "IA",
    CBSA: "Spirit Lake, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Spencer-Spirit Lake, IA",
    "County/County Equivalent": "Dickinson County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Dickinson County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "059",
    "County\nCode": 19059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44060,
    "Metropolitan Division Code": "",
    "CSA Code": 518,
    "CBSA Full Title": "Spokane-Spokane Valley, WA",
    "CBSA Solo": "Spokane",
    "CBSA State": "WA",
    CBSA: "Spokane, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Spokane-Spokane Valley-Coeur d'Alene, WA-ID",
    "County/County Equivalent": "Spokane County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Spokane County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "063",
    "County\nCode": 53063,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44060,
    "Metropolitan Division Code": "",
    "CSA Code": 518,
    "CBSA Full Title": "Spokane-Spokane Valley, WA",
    "CBSA Solo": "Spokane",
    "CBSA State": "WA",
    CBSA: "Spokane, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Spokane-Spokane Valley-Coeur d'Alene, WA-ID",
    "County/County Equivalent": "Stevens County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Stevens County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "065",
    "County\nCode": 53065,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 44100,
    "Metropolitan Division Code": "",
    "CSA Code": 522,
    "CBSA Full Title": "Springfield, IL",
    "CBSA Solo": "Springfield",
    "CBSA State": "IL",
    CBSA: "Springfield, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Springfield-Jacksonville-Lincoln, IL",
    "County/County Equivalent": "Menard County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Menard County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 129,
    "County\nCode": 17129,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 44100,
    "Metropolitan Division Code": "",
    "CSA Code": 522,
    "CBSA Full Title": "Springfield, IL",
    "CBSA Solo": "Springfield",
    "CBSA State": "IL",
    CBSA: "Springfield, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Springfield-Jacksonville-Lincoln, IL",
    "County/County Equivalent": "Sangamon County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Sangamon County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 167,
    "County\nCode": 17167,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Springfield, MA",
    "CBSA Solo": "Springfield",
    "CBSA State": "MA",
    CBSA: "Springfield, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Franklin County",
    "State Name": "Massachusetts",
    "State Abbr": "MA",
    "County, State": "Franklin County, MA",
    "FIPS State Code": 25,
    "FIPS County Code": "011",
    "County\nCode": 25011,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 44140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Springfield, MA",
    "CBSA Solo": "Springfield",
    "CBSA State": "MA",
    CBSA: "Springfield, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hampden County",
    "State Name": "Massachusetts",
    "State Abbr": "MA",
    "County, State": "Hampden County, MA",
    "FIPS State Code": 25,
    "FIPS County Code": "013",
    "County\nCode": 25013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Springfield, MA",
    "CBSA Solo": "Springfield",
    "CBSA State": "MA",
    CBSA: "Springfield, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hampshire County",
    "State Name": "Massachusetts",
    "State Abbr": "MA",
    "County, State": "Hampshire County, MA",
    "FIPS State Code": 25,
    "FIPS County Code": "015",
    "County\nCode": 25015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Springfield, MO",
    "CBSA Solo": "Springfield",
    "CBSA State": "MO",
    CBSA: "Springfield, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Christian County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Christian County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "043",
    "County\nCode": 29043,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Springfield, MO",
    "CBSA Solo": "Springfield",
    "CBSA State": "MO",
    CBSA: "Springfield, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Dallas County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Dallas County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "059",
    "County\nCode": 29059,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 44180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Springfield, MO",
    "CBSA Solo": "Springfield",
    "CBSA State": "MO",
    CBSA: "Springfield, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Greene County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Greene County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "077",
    "County\nCode": 29077,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Springfield, MO",
    "CBSA Solo": "Springfield",
    "CBSA State": "MO",
    CBSA: "Springfield, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Polk County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Polk County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 167,
    "County\nCode": 29167,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 44180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Springfield, MO",
    "CBSA Solo": "Springfield",
    "CBSA State": "MO",
    CBSA: "Springfield, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Webster County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Webster County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 225,
    "County\nCode": 29225,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 44220,
    "Metropolitan Division Code": "",
    "CSA Code": 212,
    "CBSA Full Title": "Springfield, OH",
    "CBSA Solo": "Springfield",
    "CBSA State": "OH",
    CBSA: "Springfield, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dayton-Springfield-Kettering, OH",
    "County/County Equivalent": "Clark County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Clark County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "023",
    "County\nCode": 39023,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Starkville, MS",
    "CBSA Solo": "Starkville",
    "CBSA State": "MS",
    CBSA: "Starkville, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Oktibbeha County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Oktibbeha County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 105,
    "County\nCode": 28105,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Starkville, MS",
    "CBSA Solo": "Starkville",
    "CBSA State": "MS",
    CBSA: "Starkville, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Webster County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Webster County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 155,
    "County\nCode": 28155,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 44300,
    "Metropolitan Division Code": "",
    "CSA Code": 524,
    "CBSA Full Title": "State College, PA",
    "CBSA Solo": "State College",
    "CBSA State": "PA",
    CBSA: "State College, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "State College-DuBois, PA",
    "County/County Equivalent": "Centre County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Centre County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "027",
    "County\nCode": 42027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44340,
    "Metropolitan Division Code": "",
    "CSA Code": 496,
    "CBSA Full Title": "Statesboro, GA",
    "CBSA Solo": "Statesboro",
    "CBSA State": "GA",
    CBSA: "Statesboro, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Savannah-Hinesville-Statesboro, GA",
    "County/County Equivalent": "Bulloch County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Bulloch County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "031",
    "County\nCode": 13031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44420,
    "Metropolitan Division Code": "",
    "CSA Code": 277,
    "CBSA Full Title": "Staunton, VA",
    "CBSA Solo": "Staunton",
    "CBSA State": "VA",
    CBSA: "Staunton, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Harrisonburg-Staunton, VA",
    "County/County Equivalent": "Augusta County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Augusta County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "015",
    "County\nCode": 51015,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44420,
    "Metropolitan Division Code": "",
    "CSA Code": 277,
    "CBSA Full Title": "Staunton, VA",
    "CBSA Solo": "Staunton",
    "CBSA State": "VA",
    CBSA: "Staunton, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Harrisonburg-Staunton, VA",
    "County/County Equivalent": "Staunton city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Staunton city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 790,
    "County\nCode": 51790,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44420,
    "Metropolitan Division Code": "",
    "CSA Code": 277,
    "CBSA Full Title": "Staunton, VA",
    "CBSA Solo": "Staunton",
    "CBSA State": "VA",
    CBSA: "Staunton, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Harrisonburg-Staunton, VA",
    "County/County Equivalent": "Waynesboro city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Waynesboro city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 820,
    "County\nCode": 51820,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44460,
    "Metropolitan Division Code": "",
    "CSA Code": 525,
    "CBSA Full Title": "Steamboat Springs, CO",
    "CBSA Solo": "Steamboat Springs",
    "CBSA State": "CO",
    CBSA: "Steamboat Springs, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Steamboat Springs-Craig, CO",
    "County/County Equivalent": "Routt County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Routt County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": 107,
    "County\nCode": "08107",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Stephenville, TX",
    "CBSA Solo": "Stephenville",
    "CBSA State": "TX",
    CBSA: "Stephenville, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Erath County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Erath County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 143,
    "County\nCode": 48143,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sterling, CO",
    "CBSA Solo": "Sterling",
    "CBSA State": "CO",
    CBSA: "Sterling, CO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Logan County",
    "State Name": "Colorado",
    "State Abbr": "CO",
    "County, State": "Logan County, CO",
    "FIPS State Code": "08",
    "FIPS County Code": "075",
    "County\nCode": "08075",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44580,
    "Metropolitan Division Code": "",
    "CSA Code": 221,
    "CBSA Full Title": "Sterling, IL",
    "CBSA Solo": "Sterling",
    "CBSA State": "IL",
    CBSA: "Sterling, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dixon-Sterling, IL",
    "County/County Equivalent": "Whiteside County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Whiteside County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": 195,
    "County\nCode": 17195,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44620,
    "Metropolitan Division Code": "",
    "CSA Code": 554,
    "CBSA Full Title": "Stevens Point, WI",
    "CBSA Solo": "Stevens Point",
    "CBSA State": "WI",
    CBSA: "Stevens Point, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Wausau-Stevens Point-Wisconsin Rapids, WI",
    "County/County Equivalent": "Portage County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Portage County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "097",
    "County\nCode": 55097,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Stillwater, OK",
    "CBSA Solo": "Stillwater",
    "CBSA State": "OK",
    CBSA: "Stillwater, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Payne County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Payne County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 119,
    "County\nCode": 40119,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44700,
    "Metropolitan Division Code": "",
    "CSA Code": 488,
    "CBSA Full Title": "Stockton, CA",
    "CBSA Solo": "Stockton",
    "CBSA State": "CA",
    CBSA: "Stockton, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "San Joaquin County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "San Joaquin County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "077",
    "County\nCode": "06077",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Storm Lake, IA",
    "CBSA Solo": "Storm Lake",
    "CBSA State": "IA",
    CBSA: "Storm Lake, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Buena Vista County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Buena Vista County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "021",
    "County\nCode": 19021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44780,
    "Metropolitan Division Code": "",
    "CSA Code": 310,
    "CBSA Full Title": "Sturgis, MI",
    "CBSA Solo": "Sturgis",
    "CBSA State": "MI",
    CBSA: "Sturgis, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kalamazoo-Battle Creek-Portage, MI",
    "County/County Equivalent": "St. Joseph County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "St. Joseph County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": 149,
    "County\nCode": 26149,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sulphur Springs, TX",
    "CBSA Solo": "Sulphur Springs",
    "CBSA State": "TX",
    CBSA: "Sulphur Springs, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hopkins County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Hopkins County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 223,
    "County\nCode": 48223,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44900,
    "Metropolitan Division Code": "",
    "CSA Code": 174,
    "CBSA Full Title": "Summerville, GA",
    "CBSA Solo": "Summerville",
    "CBSA State": "GA",
    CBSA: "Summerville, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Chattanooga-Cleveland-Dalton, TN-GA",
    "County/County Equivalent": "Chattooga County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Chattooga County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "055",
    "County\nCode": 13055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sumter, SC",
    "CBSA Solo": "Sumter",
    "CBSA State": "SC",
    CBSA: "Sumter, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Clarendon County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Clarendon County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "027",
    "County\nCode": 45027,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 44940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sumter, SC",
    "CBSA Solo": "Sumter",
    "CBSA State": "SC",
    CBSA: "Sumter, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Sumter County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Sumter County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "085",
    "County\nCode": 45085,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 44980,
    "Metropolitan Division Code": "",
    "CSA Code": 146,
    "CBSA Full Title": "Sunbury, PA",
    "CBSA Solo": "Sunbury",
    "CBSA State": "PA",
    CBSA: "Sunbury, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Bloomsburg-Berwick-Sunbury, PA",
    "County/County Equivalent": "Northumberland County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Northumberland County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "097",
    "County\nCode": 42097,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45000,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Susanville, CA",
    "CBSA Solo": "Susanville",
    "CBSA State": "CA",
    CBSA: "Susanville, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lassen County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Lassen County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "035",
    "County\nCode": "06035",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45020,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Sweetwater, TX",
    "CBSA Solo": "Sweetwater",
    "CBSA State": "TX",
    CBSA: "Sweetwater, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Nolan County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Nolan County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 353,
    "County\nCode": 48353,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45060,
    "Metropolitan Division Code": "",
    "CSA Code": 532,
    "CBSA Full Title": "Syracuse, NY",
    "CBSA Solo": "Syracuse",
    "CBSA State": "NY",
    CBSA: "Syracuse, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Syracuse-Auburn, NY",
    "County/County Equivalent": "Madison County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Madison County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "053",
    "County\nCode": 36053,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45060,
    "Metropolitan Division Code": "",
    "CSA Code": 532,
    "CBSA Full Title": "Syracuse, NY",
    "CBSA Solo": "Syracuse",
    "CBSA State": "NY",
    CBSA: "Syracuse, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Syracuse-Auburn, NY",
    "County/County Equivalent": "Onondaga County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Onondaga County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "067",
    "County\nCode": 36067,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45060,
    "Metropolitan Division Code": "",
    "CSA Code": 532,
    "CBSA Full Title": "Syracuse, NY",
    "CBSA Solo": "Syracuse",
    "CBSA State": "NY",
    CBSA: "Syracuse, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Syracuse-Auburn, NY",
    "County/County Equivalent": "Oswego County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Oswego County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "075",
    "County\nCode": 36075,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45140,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tahlequah, OK",
    "CBSA Solo": "Tahlequah",
    "CBSA State": "OK",
    CBSA: "Tahlequah, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Cherokee County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Cherokee County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "021",
    "County\nCode": 40021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45180,
    "Metropolitan Division Code": "",
    "CSA Code": 142,
    "CBSA Full Title": "Talladega-Sylacauga, AL",
    "CBSA Solo": "Talladega",
    "CBSA State": "AL",
    CBSA: "Talladega, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Birmingham-Hoover-Talladega, AL",
    "County/County Equivalent": "Talladega County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Talladega County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": 121,
    "County\nCode": "01121",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tallahassee, FL",
    "CBSA Solo": "Tallahassee",
    "CBSA State": "FL",
    CBSA: "Tallahassee, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Gadsden County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Gadsden County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "039",
    "County\nCode": 12039,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tallahassee, FL",
    "CBSA Solo": "Tallahassee",
    "CBSA State": "FL",
    CBSA: "Tallahassee, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Jefferson County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "065",
    "County\nCode": 12065,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tallahassee, FL",
    "CBSA Solo": "Tallahassee",
    "CBSA State": "FL",
    CBSA: "Tallahassee, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Leon County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Leon County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "073",
    "County\nCode": 12073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tallahassee, FL",
    "CBSA Solo": "Tallahassee",
    "CBSA State": "FL",
    CBSA: "Tallahassee, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Wakulla County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Wakulla County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": 129,
    "County\nCode": 12129,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tampa-St. Petersburg-Clearwater, FL",
    "CBSA Solo": "Tampa",
    "CBSA State": "FL",
    CBSA: "Tampa, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hernando County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Hernando County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "053",
    "County\nCode": 12053,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tampa-St. Petersburg-Clearwater, FL",
    "CBSA Solo": "Tampa",
    "CBSA State": "FL",
    CBSA: "Tampa, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hillsborough County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Hillsborough County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "057",
    "County\nCode": 12057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tampa-St. Petersburg-Clearwater, FL",
    "CBSA Solo": "Tampa",
    "CBSA State": "FL",
    CBSA: "Tampa, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pasco County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Pasco County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": 101,
    "County\nCode": 12101,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tampa-St. Petersburg-Clearwater, FL",
    "CBSA Solo": "Tampa",
    "CBSA State": "FL",
    CBSA: "Tampa, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pinellas County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Pinellas County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": 103,
    "County\nCode": 12103,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Taos, NM",
    "CBSA Solo": "Taos",
    "CBSA State": "NM",
    CBSA: "Taos, NM",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Taos County",
    "State Name": "New Mexico",
    "State Abbr": "NM",
    "County, State": "Taos County, NM",
    "FIPS State Code": 35,
    "FIPS County Code": "055",
    "County\nCode": 35055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45380,
    "Metropolitan Division Code": "",
    "CSA Code": 522,
    "CBSA Full Title": "Taylorville, IL",
    "CBSA Solo": "Taylorville",
    "CBSA State": "IL",
    CBSA: "Taylorville, IL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Springfield-Jacksonville-Lincoln, IL",
    "County/County Equivalent": "Christian County",
    "State Name": "Illinois",
    "State Abbr": "IL",
    "County, State": "Christian County, IL",
    "FIPS State Code": 17,
    "FIPS County Code": "021",
    "County\nCode": 17021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Terre Haute, IN",
    "CBSA Solo": "Terre Haute",
    "CBSA State": "IN",
    CBSA: "Terre Haute, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Clay County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Clay County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "021",
    "County\nCode": 18021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Terre Haute, IN",
    "CBSA Solo": "Terre Haute",
    "CBSA State": "IN",
    CBSA: "Terre Haute, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Parke County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Parke County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 121,
    "County\nCode": 18121,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Terre Haute, IN",
    "CBSA Solo": "Terre Haute",
    "CBSA State": "IN",
    CBSA: "Terre Haute, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Sullivan County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Sullivan County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 153,
    "County\nCode": 18153,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Terre Haute, IN",
    "CBSA Solo": "Terre Haute",
    "CBSA State": "IN",
    CBSA: "Terre Haute, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Vermillion County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Vermillion County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 165,
    "County\nCode": 18165,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Terre Haute, IN",
    "CBSA Solo": "Terre Haute",
    "CBSA State": "IN",
    CBSA: "Terre Haute, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Vigo County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Vigo County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 167,
    "County\nCode": 18167,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Texarkana, TX-AR",
    "CBSA Solo": "Texarkana",
    "CBSA State": "TX",
    CBSA: "Texarkana, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Bowie County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Bowie County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "037",
    "County\nCode": 48037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Texarkana, TX-AR",
    "CBSA Solo": "Texarkana",
    "CBSA State": "TX",
    CBSA: "Texarkana, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Little River County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Little River County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "081",
    "County\nCode": "05081",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45500,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Texarkana, TX-AR",
    "CBSA Solo": "Texarkana",
    "CBSA State": "TX",
    CBSA: "Texarkana, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Miller County",
    "State Name": "Arkansas",
    "State Abbr": "AR",
    "County, State": "Miller County, AR",
    "FIPS State Code": "05",
    "FIPS County Code": "091",
    "County\nCode": "05091",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45520,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "The Dalles, OR",
    "CBSA Solo": "The Dalles",
    "CBSA State": "OR",
    CBSA: "The Dalles, OR",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Wasco County",
    "State Name": "Oregon",
    "State Abbr": "OR",
    "County, State": "Wasco County, OR",
    "FIPS State Code": 41,
    "FIPS County Code": "065",
    "County\nCode": 41065,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45540,
    "Metropolitan Division Code": "",
    "CSA Code": 422,
    "CBSA Full Title": "The Villages, FL",
    "CBSA Solo": "The Villages",
    "CBSA State": "FL",
    CBSA: "The Villages, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Orlando-Lakeland-Deltona, FL",
    "County/County Equivalent": "Sumter County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Sumter County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": 119,
    "County\nCode": 12119,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45580,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Thomaston, GA",
    "CBSA Solo": "Thomaston",
    "CBSA State": "GA",
    CBSA: "Thomaston, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Upson County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Upson County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 293,
    "County\nCode": 13293,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Thomasville, GA",
    "CBSA Solo": "Thomasville",
    "CBSA State": "GA",
    CBSA: "Thomasville, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Thomas County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Thomas County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 275,
    "County\nCode": 13275,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45660,
    "Metropolitan Division Code": "",
    "CSA Code": 534,
    "CBSA Full Title": "Tiffin, OH",
    "CBSA Solo": "Tiffin",
    "CBSA State": "OH",
    CBSA: "Tiffin, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Toledo-Findlay-Tiffin, OH",
    "County/County Equivalent": "Seneca County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Seneca County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 147,
    "County\nCode": 39147,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45700,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tifton, GA",
    "CBSA Solo": "Tifton",
    "CBSA State": "GA",
    CBSA: "Tifton, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Tift County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Tift County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 277,
    "County\nCode": 13277,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45740,
    "Metropolitan Division Code": "",
    "CSA Code": 122,
    "CBSA Full Title": "Toccoa, GA",
    "CBSA Solo": "Toccoa",
    "CBSA State": "GA",
    CBSA: "Toccoa, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Atlanta--Athens-Clarke County--Sandy Springs, GA-AL",
    "County/County Equivalent": "Stephens County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Stephens County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 257,
    "County\nCode": 13257,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45780,
    "Metropolitan Division Code": "",
    "CSA Code": 534,
    "CBSA Full Title": "Toledo, OH",
    "CBSA Solo": "Toledo",
    "CBSA State": "OH",
    CBSA: "Toledo, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Toledo-Findlay-Tiffin, OH",
    "County/County Equivalent": "Fulton County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Fulton County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "051",
    "County\nCode": 39051,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45780,
    "Metropolitan Division Code": "",
    "CSA Code": 534,
    "CBSA Full Title": "Toledo, OH",
    "CBSA Solo": "Toledo",
    "CBSA State": "OH",
    CBSA: "Toledo, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Toledo-Findlay-Tiffin, OH",
    "County/County Equivalent": "Lucas County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Lucas County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "095",
    "County\nCode": 39095,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45780,
    "Metropolitan Division Code": "",
    "CSA Code": 534,
    "CBSA Full Title": "Toledo, OH",
    "CBSA Solo": "Toledo",
    "CBSA State": "OH",
    CBSA: "Toledo, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Toledo-Findlay-Tiffin, OH",
    "County/County Equivalent": "Ottawa County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Ottawa County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 123,
    "County\nCode": 39123,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45780,
    "Metropolitan Division Code": "",
    "CSA Code": 534,
    "CBSA Full Title": "Toledo, OH",
    "CBSA Solo": "Toledo",
    "CBSA State": "OH",
    CBSA: "Toledo, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Toledo-Findlay-Tiffin, OH",
    "County/County Equivalent": "Wood County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Wood County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 173,
    "County\nCode": 39173,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Topeka, KS",
    "CBSA Solo": "Topeka",
    "CBSA State": "KS",
    CBSA: "Topeka, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jackson County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Jackson County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "085",
    "County\nCode": 20085,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Topeka, KS",
    "CBSA Solo": "Topeka",
    "CBSA State": "KS",
    CBSA: "Topeka, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Jefferson County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "087",
    "County\nCode": 20087,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Topeka, KS",
    "CBSA Solo": "Topeka",
    "CBSA State": "KS",
    CBSA: "Topeka, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Osage County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Osage County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 139,
    "County\nCode": 20139,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Topeka, KS",
    "CBSA Solo": "Topeka",
    "CBSA State": "KS",
    CBSA: "Topeka, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Shawnee County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Shawnee County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 177,
    "County\nCode": 20177,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Topeka, KS",
    "CBSA Solo": "Topeka",
    "CBSA State": "KS",
    CBSA: "Topeka, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Wabaunsee County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Wabaunsee County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 197,
    "County\nCode": 20197,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45860,
    "Metropolitan Division Code": "",
    "CSA Code": 408,
    "CBSA Full Title": "Torrington, CT",
    "CBSA Solo": "Torrington",
    "CBSA State": "CT",
    CBSA: "Torrington, CT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Litchfield County",
    "State Name": "Connecticut",
    "State Abbr": "CT",
    "County, State": "Litchfield County, CT",
    "FIPS State Code": "09",
    "FIPS County Code": "005",
    "County\nCode": "09005",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Traverse City, MI",
    "CBSA Solo": "Traverse City",
    "CBSA State": "MI",
    CBSA: "Traverse City, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Benzie County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Benzie County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "019",
    "County\nCode": 26019,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Traverse City, MI",
    "CBSA Solo": "Traverse City",
    "CBSA State": "MI",
    CBSA: "Traverse City, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Grand Traverse County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Grand Traverse County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "055",
    "County\nCode": 26055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Traverse City, MI",
    "CBSA Solo": "Traverse City",
    "CBSA State": "MI",
    CBSA: "Traverse City, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Kalkaska County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Kalkaska County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "079",
    "County\nCode": 26079,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Traverse City, MI",
    "CBSA Solo": "Traverse City",
    "CBSA State": "MI",
    CBSA: "Traverse City, MI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Leelanau County",
    "State Name": "Michigan",
    "State Abbr": "MI",
    "County, State": "Leelanau County, MI",
    "FIPS State Code": 26,
    "FIPS County Code": "089",
    "County\nCode": 26089,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 45940,
    "Metropolitan Division Code": "",
    "CSA Code": 408,
    "CBSA Full Title": "Trenton-Princeton, NJ",
    "CBSA Solo": "Trenton",
    "CBSA State": "NJ",
    CBSA: "Trenton, NJ",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "New York-Newark, NY-NJ-CT-PA",
    "County/County Equivalent": "Mercer County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Mercer County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "021",
    "County\nCode": 34021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 45980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Troy, AL",
    "CBSA Solo": "Troy",
    "CBSA State": "AL",
    CBSA: "Troy, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pike County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Pike County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": 109,
    "County\nCode": "01109",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46020,
    "Metropolitan Division Code": "",
    "CSA Code": 472,
    "CBSA Full Title": "Truckee-Grass Valley, CA",
    "CBSA Solo": "Truckee",
    "CBSA State": "CA",
    CBSA: "Truckee, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Sacramento-Roseville, CA",
    "County/County Equivalent": "Nevada County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Nevada County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "057",
    "County\nCode": "06057",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46060,
    "Metropolitan Division Code": "",
    "CSA Code": 536,
    "CBSA Full Title": "Tucson, AZ",
    "CBSA Solo": "Tucson",
    "CBSA State": "AZ",
    CBSA: "Tucson, AZ",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tucson-Nogales, AZ",
    "County/County Equivalent": "Pima County",
    "State Name": "Arizona",
    "State Abbr": "AZ",
    "County, State": "Pima County, AZ",
    "FIPS State Code": "04",
    "FIPS County Code": "019",
    "County\nCode": "04019",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tullahoma-Manchester, TN",
    "CBSA Solo": "Tullahoma",
    "CBSA State": "TN",
    CBSA: "Tullahoma, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Coffee County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Coffee County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "031",
    "County\nCode": 47031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tullahoma-Manchester, TN",
    "CBSA Solo": "Tullahoma",
    "CBSA State": "TN",
    CBSA: "Tullahoma, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Franklin County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Franklin County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": "051",
    "County\nCode": 47051,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tullahoma-Manchester, TN",
    "CBSA Solo": "Tullahoma",
    "CBSA State": "TN",
    CBSA: "Tullahoma, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Moore County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Moore County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 127,
    "County\nCode": 47127,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46140,
    "Metropolitan Division Code": "",
    "CSA Code": 538,
    "CBSA Full Title": "Tulsa, OK",
    "CBSA Solo": "Tulsa",
    "CBSA State": "OK",
    CBSA: "Tulsa, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tulsa-Muskogee-Bartlesville, OK",
    "County/County Equivalent": "Creek County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Creek County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "037",
    "County\nCode": 40037,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46140,
    "Metropolitan Division Code": "",
    "CSA Code": 538,
    "CBSA Full Title": "Tulsa, OK",
    "CBSA Solo": "Tulsa",
    "CBSA State": "OK",
    CBSA: "Tulsa, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tulsa-Muskogee-Bartlesville, OK",
    "County/County Equivalent": "Okmulgee County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Okmulgee County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 111,
    "County\nCode": 40111,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46140,
    "Metropolitan Division Code": "",
    "CSA Code": 538,
    "CBSA Full Title": "Tulsa, OK",
    "CBSA Solo": "Tulsa",
    "CBSA State": "OK",
    CBSA: "Tulsa, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tulsa-Muskogee-Bartlesville, OK",
    "County/County Equivalent": "Osage County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Osage County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 113,
    "County\nCode": 40113,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46140,
    "Metropolitan Division Code": "",
    "CSA Code": 538,
    "CBSA Full Title": "Tulsa, OK",
    "CBSA Solo": "Tulsa",
    "CBSA State": "OK",
    CBSA: "Tulsa, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tulsa-Muskogee-Bartlesville, OK",
    "County/County Equivalent": "Pawnee County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Pawnee County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 117,
    "County\nCode": 40117,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46140,
    "Metropolitan Division Code": "",
    "CSA Code": 538,
    "CBSA Full Title": "Tulsa, OK",
    "CBSA Solo": "Tulsa",
    "CBSA State": "OK",
    CBSA: "Tulsa, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tulsa-Muskogee-Bartlesville, OK",
    "County/County Equivalent": "Rogers County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Rogers County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 131,
    "County\nCode": 40131,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46140,
    "Metropolitan Division Code": "",
    "CSA Code": 538,
    "CBSA Full Title": "Tulsa, OK",
    "CBSA Solo": "Tulsa",
    "CBSA State": "OK",
    CBSA: "Tulsa, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tulsa-Muskogee-Bartlesville, OK",
    "County/County Equivalent": "Tulsa County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Tulsa County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 143,
    "County\nCode": 40143,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46140,
    "Metropolitan Division Code": "",
    "CSA Code": 538,
    "CBSA Full Title": "Tulsa, OK",
    "CBSA Solo": "Tulsa",
    "CBSA State": "OK",
    CBSA: "Tulsa, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tulsa-Muskogee-Bartlesville, OK",
    "County/County Equivalent": "Wagoner County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Wagoner County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 145,
    "County\nCode": 40145,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46180,
    "Metropolitan Division Code": "",
    "CSA Code": 539,
    "CBSA Full Title": "Tupelo, MS",
    "CBSA Solo": "Tupelo",
    "CBSA State": "MS",
    CBSA: "Tupelo, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tupelo-Corinth, MS",
    "County/County Equivalent": "Itawamba County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Itawamba County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "057",
    "County\nCode": 28057,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46180,
    "Metropolitan Division Code": "",
    "CSA Code": 539,
    "CBSA Full Title": "Tupelo, MS",
    "CBSA Solo": "Tupelo",
    "CBSA State": "MS",
    CBSA: "Tupelo, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tupelo-Corinth, MS",
    "County/County Equivalent": "Lee County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Lee County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "081",
    "County\nCode": 28081,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46180,
    "Metropolitan Division Code": "",
    "CSA Code": 539,
    "CBSA Full Title": "Tupelo, MS",
    "CBSA Solo": "Tupelo",
    "CBSA State": "MS",
    CBSA: "Tupelo, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tupelo-Corinth, MS",
    "County/County Equivalent": "Pontotoc County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Pontotoc County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 115,
    "County\nCode": 28115,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46180,
    "Metropolitan Division Code": "",
    "CSA Code": 539,
    "CBSA Full Title": "Tupelo, MS",
    "CBSA Solo": "Tupelo",
    "CBSA State": "MS",
    CBSA: "Tupelo, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tupelo-Corinth, MS",
    "County/County Equivalent": "Prentiss County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Prentiss County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 117,
    "County\nCode": 28117,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tuscaloosa, AL",
    "CBSA Solo": "Tuscaloosa",
    "CBSA State": "AL",
    CBSA: "Tuscaloosa, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Greene County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Greene County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "063",
    "County\nCode": "01063",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tuscaloosa, AL",
    "CBSA Solo": "Tuscaloosa",
    "CBSA State": "AL",
    CBSA: "Tuscaloosa, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hale County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Hale County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": "065",
    "County\nCode": "01065",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tuscaloosa, AL",
    "CBSA Solo": "Tuscaloosa",
    "CBSA State": "AL",
    CBSA: "Tuscaloosa, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pickens County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Pickens County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": 107,
    "County\nCode": "01107",
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Tuscaloosa, AL",
    "CBSA Solo": "Tuscaloosa",
    "CBSA State": "AL",
    CBSA: "Tuscaloosa, AL",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Tuscaloosa County",
    "State Name": "Alabama",
    "State Abbr": "AL",
    "County, State": "Tuscaloosa County, AL",
    "FIPS State Code": "01",
    "FIPS County Code": 125,
    "County\nCode": "01125",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Twin Falls, ID",
    "CBSA Solo": "Twin Falls",
    "CBSA State": "ID",
    CBSA: "Twin Falls, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jerome County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Jerome County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "053",
    "County\nCode": 16053,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Twin Falls, ID",
    "CBSA Solo": "Twin Falls",
    "CBSA State": "ID",
    CBSA: "Twin Falls, ID",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Twin Falls County",
    "State Name": "Idaho",
    "State Abbr": "ID",
    "County, State": "Twin Falls County, ID",
    "FIPS State Code": 16,
    "FIPS County Code": "083",
    "County\nCode": 16083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46340,
    "Metropolitan Division Code": "",
    "CSA Code": 540,
    "CBSA Full Title": "Tyler, TX",
    "CBSA Solo": "Tyler",
    "CBSA State": "TX",
    CBSA: "Tyler, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Tyler-Jacksonville, TX",
    "County/County Equivalent": "Smith County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Smith County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 423,
    "County\nCode": 48423,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Ukiah, CA",
    "CBSA Solo": "Ukiah",
    "CBSA State": "CA",
    CBSA: "Ukiah, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Mendocino County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Mendocino County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "045",
    "County\nCode": "06045",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46420,
    "Metropolitan Division Code": "",
    "CSA Code": 273,
    "CBSA Full Title": "Union, SC",
    "CBSA Solo": "Union",
    "CBSA State": "SC",
    CBSA: "Union, SC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greenville-Spartanburg-Anderson, SC",
    "County/County Equivalent": "Union County",
    "State Name": "South Carolina",
    "State Abbr": "SC",
    "County, State": "Union County, SC",
    "FIPS State Code": 45,
    "FIPS County Code": "087",
    "County\nCode": 45087,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46460,
    "Metropolitan Division Code": "",
    "CSA Code": 362,
    "CBSA Full Title": "Union City, TN",
    "CBSA Solo": "Union City",
    "CBSA State": "TN",
    CBSA: "Union City, TN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Martin-Union City, TN",
    "County/County Equivalent": "Obion County",
    "State Name": "Tennessee",
    "State Abbr": "TN",
    "County, State": "Obion County, TN",
    "FIPS State Code": 47,
    "FIPS County Code": 131,
    "County\nCode": 47131,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46500,
    "Metropolitan Division Code": "",
    "CSA Code": 212,
    "CBSA Full Title": "Urbana, OH",
    "CBSA Solo": "Urbana",
    "CBSA State": "OH",
    CBSA: "Urbana, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Dayton-Springfield-Kettering, OH",
    "County/County Equivalent": "Champaign County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Champaign County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "021",
    "County\nCode": 39021,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46520,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Urban Honolulu, HI",
    "CBSA Solo": "Urban Honolulu",
    "CBSA State": "HI",
    CBSA: "Urban Honolulu, HI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Honolulu County",
    "State Name": "Hawaii",
    "State Abbr": "HI",
    "County, State": "Honolulu County, HI",
    "FIPS State Code": 15,
    "FIPS County Code": "003",
    "County\nCode": 15003,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Utica-Rome, NY",
    "CBSA Solo": "Utica",
    "CBSA State": "NY",
    CBSA: "Utica, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Herkimer County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Herkimer County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "043",
    "County\nCode": 36043,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Utica-Rome, NY",
    "CBSA Solo": "Utica",
    "CBSA State": "NY",
    CBSA: "Utica, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Oneida County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Oneida County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "065",
    "County\nCode": 36065,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Uvalde, TX",
    "CBSA Solo": "Uvalde",
    "CBSA State": "TX",
    CBSA: "Uvalde, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Uvalde County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Uvalde County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 463,
    "County\nCode": 48463,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Valdosta, GA",
    "CBSA Solo": "Valdosta",
    "CBSA State": "GA",
    CBSA: "Valdosta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Brooks County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Brooks County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": "027",
    "County\nCode": 13027,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Valdosta, GA",
    "CBSA Solo": "Valdosta",
    "CBSA State": "GA",
    CBSA: "Valdosta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Echols County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Echols County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 101,
    "County\nCode": 13101,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Valdosta, GA",
    "CBSA Solo": "Valdosta",
    "CBSA State": "GA",
    CBSA: "Valdosta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lanier County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Lanier County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 173,
    "County\nCode": 13173,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 46660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Valdosta, GA",
    "CBSA Solo": "Valdosta",
    "CBSA State": "GA",
    CBSA: "Valdosta, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Lowndes County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Lowndes County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 185,
    "County\nCode": 13185,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46700,
    "Metropolitan Division Code": "",
    "CSA Code": 488,
    "CBSA Full Title": "Vallejo, CA",
    "CBSA Solo": "Vallejo",
    "CBSA State": "CA",
    CBSA: "Vallejo, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "San Jose-San Francisco-Oakland, CA",
    "County/County Equivalent": "Solano County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Solano County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": "095",
    "County\nCode": "06095",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46780,
    "Metropolitan Division Code": "",
    "CSA Code": 338,
    "CBSA Full Title": "Van Wert, OH",
    "CBSA Solo": "Van Wert",
    "CBSA State": "OH",
    CBSA: "Van Wert, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lima-Van Wert-Celina, OH",
    "County/County Equivalent": "Van Wert County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Van Wert County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 161,
    "County\nCode": 39161,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Vermillion, SD",
    "CBSA Solo": "Vermillion",
    "CBSA State": "SD",
    CBSA: "Vermillion, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Clay County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Clay County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "027",
    "County\nCode": 46027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46860,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Vernal, UT",
    "CBSA Solo": "Vernal",
    "CBSA State": "UT",
    CBSA: "Vernal, UT",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Uintah County",
    "State Name": "Utah",
    "State Abbr": "UT",
    "County, State": "Uintah County, UT",
    "FIPS State Code": 49,
    "FIPS County Code": "047",
    "County\nCode": 49047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Vernon, TX",
    "CBSA Solo": "Vernon",
    "CBSA State": "TX",
    CBSA: "Vernon, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Wilbarger County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Wilbarger County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 487,
    "County\nCode": 48487,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 46980,
    "Metropolitan Division Code": "",
    "CSA Code": 298,
    "CBSA Full Title": "Vicksburg, MS",
    "CBSA Solo": "Vicksburg",
    "CBSA State": "MS",
    CBSA: "Vicksburg, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Jackson-Vicksburg-Brookhaven, MS",
    "County/County Equivalent": "Warren County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Warren County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": 149,
    "County\nCode": 28149,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47020,
    "Metropolitan Division Code": "",
    "CSA Code": 544,
    "CBSA Full Title": "Victoria, TX",
    "CBSA Solo": "Victoria",
    "CBSA State": "TX",
    CBSA: "Victoria, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Victoria-Port Lavaca, TX",
    "County/County Equivalent": "Goliad County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Goliad County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 175,
    "County\nCode": 48175,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47020,
    "Metropolitan Division Code": "",
    "CSA Code": 544,
    "CBSA Full Title": "Victoria, TX",
    "CBSA Solo": "Victoria",
    "CBSA State": "TX",
    CBSA: "Victoria, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Victoria-Port Lavaca, TX",
    "County/County Equivalent": "Victoria County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Victoria County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 469,
    "County\nCode": 48469,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47080,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Vidalia, GA",
    "CBSA Solo": "Vidalia",
    "CBSA State": "GA",
    CBSA: "Vidalia, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Montgomery County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Montgomery County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 209,
    "County\nCode": 13209,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47080,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Vidalia, GA",
    "CBSA Solo": "Vidalia",
    "CBSA State": "GA",
    CBSA: "Vidalia, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Toombs County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Toombs County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 279,
    "County\nCode": 13279,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Vincennes, IN",
    "CBSA Solo": "Vincennes",
    "CBSA State": "IN",
    CBSA: "Vincennes, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Knox County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Knox County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "083",
    "County\nCode": 18083,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47220,
    "Metropolitan Division Code": "",
    "CSA Code": 428,
    "CBSA Full Title": "Vineland-Bridgeton, NJ",
    "CBSA Solo": "Vineland",
    "CBSA State": "NJ",
    CBSA: "Vineland, NJ",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Philadelphia-Reading-Camden, PA-NJ-DE-MD",
    "County/County Equivalent": "Cumberland County",
    "State Name": "New Jersey",
    "State Abbr": "NJ",
    "County, State": "Cumberland County, NJ",
    "FIPS State Code": 34,
    "FIPS County Code": "011",
    "County\nCode": 34011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47240,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Vineyard Haven, MA",
    "CBSA Solo": "Vineyard Haven",
    "CBSA State": "MA",
    CBSA: "Vineyard Haven, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Dukes County",
    "State Name": "Massachusetts",
    "State Abbr": "MA",
    "County, State": "Dukes County, MA",
    "FIPS State Code": 25,
    "FIPS County Code": "007",
    "County\nCode": 25007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Gloucester County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Gloucester County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "073",
    "County\nCode": 51073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Camden County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Camden County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "029",
    "County\nCode": 37029,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Currituck County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Currituck County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "053",
    "County\nCode": 37053,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Gates County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Gates County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "073",
    "County\nCode": 37073,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Isle of Wight County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Isle of Wight County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "093",
    "County\nCode": 51093,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "James City County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "James City County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "095",
    "County\nCode": 51095,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Mathews County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Mathews County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 115,
    "County\nCode": 51115,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Southampton County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Southampton County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 175,
    "County\nCode": 51175,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "York County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "York County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 199,
    "County\nCode": 51199,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Chesapeake city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Chesapeake city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 550,
    "County\nCode": 51550,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Franklin city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Franklin city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 620,
    "County\nCode": 51620,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Hampton city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Hampton city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 650,
    "County\nCode": 51650,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Newport News city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Newport News city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 700,
    "County\nCode": 51700,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Norfolk city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Norfolk city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 710,
    "County\nCode": 51710,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Poquoson city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Poquoson city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 735,
    "County\nCode": 51735,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Portsmouth city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Portsmouth city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 740,
    "County\nCode": 51740,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Suffolk city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Suffolk city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 800,
    "County\nCode": 51800,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Virginia Beach city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Virginia Beach city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 810,
    "County\nCode": 51810,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47260,
    "Metropolitan Division Code": "",
    "CSA Code": 545,
    "CBSA Full Title": "Virginia Beach-Norfolk-Newport News, VA-NC",
    "CBSA Solo": "Virginia Beach",
    "CBSA State": "VA",
    CBSA: "Virginia Beach, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Virginia Beach-Norfolk, VA-NC",
    "County/County Equivalent": "Williamsburg city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Williamsburg city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 830,
    "County\nCode": 51830,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Visalia, CA",
    "CBSA Solo": "Visalia",
    "CBSA State": "CA",
    CBSA: "Visalia, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Tulare County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Tulare County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": 107,
    "County\nCode": "06107",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47340,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Wabash, IN",
    "CBSA Solo": "Wabash",
    "CBSA State": "IN",
    CBSA: "Wabash, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Wabash County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Wabash County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": 169,
    "County\nCode": 18169,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Waco, TX",
    "CBSA Solo": "Waco",
    "CBSA State": "TX",
    CBSA: "Waco, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Falls County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Falls County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 145,
    "County\nCode": 48145,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Waco, TX",
    "CBSA Solo": "Waco",
    "CBSA State": "TX",
    CBSA: "Waco, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "McLennan County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "McLennan County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 309,
    "County\nCode": 48309,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47420,
    "Metropolitan Division Code": "",
    "CSA Code": 244,
    "CBSA Full Title": "Wahpeton, ND-MN",
    "CBSA Solo": "Wahpeton",
    "CBSA State": "ND",
    CBSA: "Wahpeton, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fargo-Wahpeton, ND-MN",
    "County/County Equivalent": "Wilkin County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Wilkin County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 167,
    "County\nCode": 27167,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47420,
    "Metropolitan Division Code": "",
    "CSA Code": 244,
    "CBSA Full Title": "Wahpeton, ND-MN",
    "CBSA Solo": "Wahpeton",
    "CBSA State": "ND",
    CBSA: "Wahpeton, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Fargo-Wahpeton, ND-MN",
    "County/County Equivalent": "Richland County",
    "State Name": "North Dakota",
    "State Abbr": "ND",
    "County, State": "Richland County, ND",
    "FIPS State Code": 38,
    "FIPS County Code": "077",
    "County\nCode": 38077,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47460,
    "Metropolitan Division Code": "",
    "CSA Code": 313,
    "CBSA Full Title": "Walla Walla, WA",
    "CBSA Solo": "Walla Walla",
    "CBSA State": "WA",
    CBSA: "Walla Walla, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kennewick-Richland-Walla Walla, WA",
    "County/County Equivalent": "Walla Walla County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Walla Walla County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "071",
    "County\nCode": 53071,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47540,
    "Metropolitan Division Code": "",
    "CSA Code": 338,
    "CBSA Full Title": "Wapakoneta, OH",
    "CBSA Solo": "Wapakoneta",
    "CBSA State": "OH",
    CBSA: "Wapakoneta, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Lima-Van Wert-Celina, OH",
    "County/County Equivalent": "Auglaize County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Auglaize County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "011",
    "County\nCode": 39011,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47580,
    "Metropolitan Division Code": "",
    "CSA Code": 356,
    "CBSA Full Title": "Warner Robins, GA",
    "CBSA Solo": "Warner Robins",
    "CBSA State": "GA",
    CBSA: "Warner Robins, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Macon-Bibb County--Warner Robins, GA",
    "County/County Equivalent": "Houston County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Houston County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 153,
    "County\nCode": 13153,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47580,
    "Metropolitan Division Code": "",
    "CSA Code": 356,
    "CBSA Full Title": "Warner Robins, GA",
    "CBSA Solo": "Warner Robins",
    "CBSA State": "GA",
    CBSA: "Warner Robins, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Macon-Bibb County--Warner Robins, GA",
    "County/County Equivalent": "Peach County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Peach County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 225,
    "County\nCode": 13225,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47620,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Warren, PA",
    "CBSA Solo": "Warren",
    "CBSA State": "PA",
    CBSA: "Warren, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Warren County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Warren County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": 123,
    "County\nCode": 42123,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47660,
    "Metropolitan Division Code": "",
    "CSA Code": 312,
    "CBSA Full Title": "Warrensburg, MO",
    "CBSA Solo": "Warrensburg",
    "CBSA State": "MO",
    CBSA: "Warrensburg, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Kansas City-Overland Park-Kansas City, MO-KS",
    "County/County Equivalent": "Johnson County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Johnson County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": 101,
    "County\nCode": 29101,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47700,
    "Metropolitan Division Code": "",
    "CSA Code": 515,
    "CBSA Full Title": "Warsaw, IN",
    "CBSA Solo": "Warsaw",
    "CBSA State": "IN",
    CBSA: "Warsaw, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "South Bend-Elkhart-Mishawaka, IN-MI",
    "County/County Equivalent": "Kosciusko County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Kosciusko County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "085",
    "County\nCode": 18085,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Washington, IN",
    "CBSA Solo": "Washington",
    "CBSA State": "IN",
    CBSA: "Washington, IN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Daviess County",
    "State Name": "Indiana",
    "State Abbr": "IN",
    "County, State": "Daviess County, IN",
    "FIPS State Code": 18,
    "FIPS County Code": "027",
    "County\nCode": 18027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47820,
    "Metropolitan Division Code": "",
    "CSA Code": 272,
    "CBSA Full Title": "Washington, NC",
    "CBSA Solo": "Washington",
    "CBSA State": "NC",
    CBSA: "Washington, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greenville-Kinston-Washington, NC",
    "County/County Equivalent": "Beaufort County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Beaufort County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "013",
    "County\nCode": 37013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "District of Columbia",
    "State Name": "District of Columbia",
    "State Abbr": "DC",
    "County, State": "District of Columbia, DC",
    "FIPS State Code": 11,
    "FIPS County Code": "001",
    "County\nCode": 11001,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 23224,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Frederick-Gaithersburg-Rockville, MD",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Frederick County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Frederick County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "021",
    "County\nCode": 24021,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 23224,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "Frederick-Gaithersburg-Rockville, MD",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Montgomery County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Montgomery County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "031",
    "County\nCode": 24031,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Calvert County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Calvert County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "009",
    "County\nCode": 24009,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Charles County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Charles County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "017",
    "County\nCode": 24017,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Prince George's County",
    "State Name": "Maryland",
    "State Abbr": "MD",
    "County, State": "Prince George's County, MD",
    "FIPS State Code": 24,
    "FIPS County Code": "033",
    "County\nCode": 24033,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Arlington County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Arlington County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "013",
    "County\nCode": 51013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Clarke County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Clarke County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "043",
    "County\nCode": 51043,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Culpeper County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Culpeper County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "047",
    "County\nCode": 51047,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Fairfax County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Fairfax County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "059",
    "County\nCode": 51059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Fauquier County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Fauquier County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "061",
    "County\nCode": 51061,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Loudoun County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Loudoun County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 107,
    "County\nCode": 51107,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Madison County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Madison County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 113,
    "County\nCode": 51113,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Prince William County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Prince William County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 153,
    "County\nCode": 51153,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Rappahannock County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Rappahannock County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 157,
    "County\nCode": 51157,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Spotsylvania County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Spotsylvania County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 177,
    "County\nCode": 51177,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Stafford County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Stafford County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 179,
    "County\nCode": 51179,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Warren County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Warren County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 187,
    "County\nCode": 51187,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Alexandria city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Alexandria city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 510,
    "County\nCode": 51510,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Fairfax city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Fairfax city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 600,
    "County\nCode": 51600,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Falls Church city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Falls Church city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 610,
    "County\nCode": 51610,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Fredericksburg city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Fredericksburg city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 630,
    "County\nCode": 51630,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Manassas city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Manassas city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 683,
    "County\nCode": 51683,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Manassas Park city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Manassas Park city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 685,
    "County\nCode": 51685,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47900,
    "Metropolitan Division Code": 47894,
    "CSA Code": 548,
    "CBSA Full Title": "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CBSA Solo": "Washington",
    "CBSA State": "DC",
    CBSA: "Washington, DC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title":
      "Washington-Arlington-Alexandria, DC-VA-MD-WV",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Jefferson County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Jefferson County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "037",
    "County\nCode": 54037,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47920,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Washington Court House, OH",
    "CBSA Solo": "Washington Court House",
    "CBSA State": "OH",
    CBSA: "Washington Court House, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Fayette County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Fayette County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "047",
    "County\nCode": 39047,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Waterloo-Cedar Falls, IA",
    "CBSA Solo": "Waterloo",
    "CBSA State": "IA",
    CBSA: "Waterloo, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Black Hawk County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Black Hawk County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "013",
    "County\nCode": 19013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Waterloo-Cedar Falls, IA",
    "CBSA Solo": "Waterloo",
    "CBSA State": "IA",
    CBSA: "Waterloo, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Bremer County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Bremer County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "017",
    "County\nCode": 19017,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47940,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Waterloo-Cedar Falls, IA",
    "CBSA Solo": "Waterloo",
    "CBSA State": "IA",
    CBSA: "Waterloo, IA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Grundy County",
    "State Name": "Iowa",
    "State Abbr": "IA",
    "County, State": "Grundy County, IA",
    "FIPS State Code": 19,
    "FIPS County Code": "075",
    "County\nCode": 19075,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 47980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Watertown, SD",
    "CBSA Solo": "Watertown",
    "CBSA State": "SD",
    CBSA: "Watertown, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Codington County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Codington County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "029",
    "County\nCode": 46029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 47980,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Watertown, SD",
    "CBSA Solo": "Watertown",
    "CBSA State": "SD",
    CBSA: "Watertown, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Hamlin County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Hamlin County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": "057",
    "County\nCode": 46057,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 48020,
    "Metropolitan Division Code": "",
    "CSA Code": 376,
    "CBSA Full Title": "Watertown-Fort Atkinson, WI",
    "CBSA Solo": "Watertown",
    "CBSA State": "WI",
    CBSA: "Watertown, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Milwaukee-Racine-Waukesha, WI",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Jefferson County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "055",
    "County\nCode": 55055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48060,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Watertown-Fort Drum, NY",
    "CBSA Solo": "Watertown",
    "CBSA State": "NY",
    CBSA: "Watertown, NY",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Jefferson County",
    "State Name": "New York",
    "State Abbr": "NY",
    "County, State": "Jefferson County, NY",
    "FIPS State Code": 36,
    "FIPS County Code": "045",
    "County\nCode": 36045,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48100,
    "Metropolitan Division Code": "",
    "CSA Code": 422,
    "CBSA Full Title": "Wauchula, FL",
    "CBSA Solo": "Wauchula",
    "CBSA State": "FL",
    CBSA: "Wauchula, FL",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Orlando-Lakeland-Deltona, FL",
    "County/County Equivalent": "Hardee County",
    "State Name": "Florida",
    "State Abbr": "FL",
    "County, State": "Hardee County, FL",
    "FIPS State Code": 12,
    "FIPS County Code": "049",
    "County\nCode": 12049,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48140,
    "Metropolitan Division Code": "",
    "CSA Code": 554,
    "CBSA Full Title": "Wausau-Weston, WI",
    "CBSA Solo": "Wausau",
    "CBSA State": "WI",
    CBSA: "Wausau, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Wausau-Stevens Point-Wisconsin Rapids, WI",
    "County/County Equivalent": "Lincoln County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Lincoln County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "069",
    "County\nCode": 55069,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 48140,
    "Metropolitan Division Code": "",
    "CSA Code": 554,
    "CBSA Full Title": "Wausau-Weston, WI",
    "CBSA Solo": "Wausau",
    "CBSA State": "WI",
    CBSA: "Wausau, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Wausau-Stevens Point-Wisconsin Rapids, WI",
    "County/County Equivalent": "Marathon County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Marathon County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": "073",
    "County\nCode": 55073,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Waycross, GA",
    "CBSA Solo": "Waycross",
    "CBSA State": "GA",
    CBSA: "Waycross, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pierce County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Pierce County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 229,
    "County\nCode": 13229,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 48180,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Waycross, GA",
    "CBSA Solo": "Waycross",
    "CBSA State": "GA",
    CBSA: "Waycross, GA",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ware County",
    "State Name": "Georgia",
    "State Abbr": "GA",
    "County, State": "Ware County, GA",
    "FIPS State Code": 13,
    "FIPS County Code": 299,
    "County\nCode": 13299,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48220,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Weatherford, OK",
    "CBSA Solo": "Weatherford",
    "CBSA State": "OK",
    CBSA: "Weatherford, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Custer County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Custer County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "039",
    "County\nCode": 40039,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48260,
    "Metropolitan Division Code": "",
    "CSA Code": 430,
    "CBSA Full Title": "Weirton-Steubenville, WV-OH",
    "CBSA Solo": "Weirton",
    "CBSA State": "WV",
    CBSA: "Weirton, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    "County/County Equivalent": "Brooke County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Brooke County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "009",
    "County\nCode": 54009,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48260,
    "Metropolitan Division Code": "",
    "CSA Code": 430,
    "CBSA Full Title": "Weirton-Steubenville, WV-OH",
    "CBSA Solo": "Weirton",
    "CBSA State": "WV",
    CBSA: "Weirton, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    "County/County Equivalent": "Jefferson County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Jefferson County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "081",
    "County\nCode": 39081,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48260,
    "Metropolitan Division Code": "",
    "CSA Code": 430,
    "CBSA Full Title": "Weirton-Steubenville, WV-OH",
    "CBSA Solo": "Weirton",
    "CBSA State": "WV",
    CBSA: "Weirton, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Pittsburgh-New Castle-Weirton, PA-OH-WV",
    "County/County Equivalent": "Hancock County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Hancock County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "029",
    "County\nCode": 54029,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Wenatchee, WA",
    "CBSA Solo": "Wenatchee",
    "CBSA State": "WA",
    CBSA: "Wenatchee, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Chelan County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Chelan County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "007",
    "County\nCode": 53007,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48300,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Wenatchee, WA",
    "CBSA Solo": "Wenatchee",
    "CBSA State": "WA",
    CBSA: "Wenatchee, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Douglas County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Douglas County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "017",
    "County\nCode": 53017,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "West Plains, MO",
    "CBSA Solo": "West Plains",
    "CBSA State": "MO",
    CBSA: "West Plains, MO",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Howell County",
    "State Name": "Missouri",
    "State Abbr": "MO",
    "County, State": "Howell County, MO",
    "FIPS State Code": 29,
    "FIPS County Code": "091",
    "County\nCode": 29091,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48500,
    "Metropolitan Division Code": "",
    "CSA Code": 200,
    "CBSA Full Title": "West Point, MS",
    "CBSA Solo": "West Point",
    "CBSA State": "MS",
    CBSA: "West Point, MS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-West Point, MS",
    "County/County Equivalent": "Clay County",
    "State Name": "Mississippi",
    "State Abbr": "MS",
    "County, State": "Clay County, MS",
    "FIPS State Code": 28,
    "FIPS County Code": "025",
    "County\nCode": 28025,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Wheeling, WV-OH",
    "CBSA Solo": "Wheeling",
    "CBSA State": "WV",
    CBSA: "Wheeling, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Marshall County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Marshall County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "051",
    "County\nCode": 54051,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Wheeling, WV-OH",
    "CBSA Solo": "Wheeling",
    "CBSA State": "WV",
    CBSA: "Wheeling, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Belmont County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Belmont County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "013",
    "County\nCode": 39013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48540,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Wheeling, WV-OH",
    "CBSA Solo": "Wheeling",
    "CBSA State": "WV",
    CBSA: "Wheeling, WV",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ohio County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Ohio County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "069",
    "County\nCode": 54069,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48580,
    "Metropolitan Division Code": "",
    "CSA Code": 376,
    "CBSA Full Title": "Whitewater, WI",
    "CBSA Solo": "Whitewater",
    "CBSA State": "WI",
    CBSA: "Whitewater, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Milwaukee-Racine-Waukesha, WI",
    "County/County Equivalent": "Walworth County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Walworth County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": 127,
    "County\nCode": 55127,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48620,
    "Metropolitan Division Code": "",
    "CSA Code": 556,
    "CBSA Full Title": "Wichita, KS",
    "CBSA Solo": "Wichita",
    "CBSA State": "KS",
    CBSA: "Wichita, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Wichita-Winfield, KS",
    "County/County Equivalent": "Butler County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Butler County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "015",
    "County\nCode": 20015,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 48620,
    "Metropolitan Division Code": "",
    "CSA Code": 556,
    "CBSA Full Title": "Wichita, KS",
    "CBSA Solo": "Wichita",
    "CBSA State": "KS",
    CBSA: "Wichita, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Wichita-Winfield, KS",
    "County/County Equivalent": "Harvey County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Harvey County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "079",
    "County\nCode": 20079,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 48620,
    "Metropolitan Division Code": "",
    "CSA Code": 556,
    "CBSA Full Title": "Wichita, KS",
    "CBSA Solo": "Wichita",
    "CBSA State": "KS",
    CBSA: "Wichita, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Wichita-Winfield, KS",
    "County/County Equivalent": "Sedgwick County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Sedgwick County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 173,
    "County\nCode": 20173,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48620,
    "Metropolitan Division Code": "",
    "CSA Code": 556,
    "CBSA Full Title": "Wichita, KS",
    "CBSA Solo": "Wichita",
    "CBSA State": "KS",
    CBSA: "Wichita, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Wichita-Winfield, KS",
    "County/County Equivalent": "Sumner County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Sumner County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": 191,
    "County\nCode": 20191,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 48660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Wichita Falls, TX",
    "CBSA Solo": "Wichita Falls",
    "CBSA State": "TX",
    CBSA: "Wichita Falls, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Archer County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Archer County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "009",
    "County\nCode": 48009,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 48660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Wichita Falls, TX",
    "CBSA Solo": "Wichita Falls",
    "CBSA State": "TX",
    CBSA: "Wichita Falls, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Clay County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Clay County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": "077",
    "County\nCode": 48077,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 48660,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Wichita Falls, TX",
    "CBSA Solo": "Wichita Falls",
    "CBSA State": "TX",
    CBSA: "Wichita Falls, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Wichita County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Wichita County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 485,
    "County\nCode": 48485,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48700,
    "Metropolitan Division Code": "",
    "CSA Code": 558,
    "CBSA Full Title": "Williamsport, PA",
    "CBSA Solo": "Williamsport",
    "CBSA State": "PA",
    CBSA: "Williamsport, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Williamsport-Lock Haven, PA",
    "County/County Equivalent": "Lycoming County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Lycoming County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "081",
    "County\nCode": 42081,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48780,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Williston, ND",
    "CBSA Solo": "Williston",
    "CBSA State": "ND",
    CBSA: "Williston, ND",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Williams County",
    "State Name": "North Dakota",
    "State Abbr": "ND",
    "County, State": "Williams County, ND",
    "FIPS State Code": 38,
    "FIPS County Code": 105,
    "County\nCode": 38105,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Willmar, MN",
    "CBSA Solo": "Willmar",
    "CBSA State": "MN",
    CBSA: "Willmar, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Kandiyohi County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Kandiyohi County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": "067",
    "County\nCode": 27067,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Wilmington, NC",
    "CBSA Solo": "Wilmington",
    "CBSA State": "NC",
    CBSA: "Wilmington, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "New Hanover County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "New Hanover County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 129,
    "County\nCode": 37129,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48900,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Wilmington, NC",
    "CBSA Solo": "Wilmington",
    "CBSA State": "NC",
    CBSA: "Wilmington, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Pender County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Pender County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 141,
    "County\nCode": 37141,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 48940,
    "Metropolitan Division Code": "",
    "CSA Code": 178,
    "CBSA Full Title": "Wilmington, OH",
    "CBSA Solo": "Wilmington",
    "CBSA State": "OH",
    CBSA: "Wilmington, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cincinnati-Wilmington-Maysville, OH-KY-IN",
    "County/County Equivalent": "Clinton County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Clinton County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "027",
    "County\nCode": 39027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 48980,
    "Metropolitan Division Code": "",
    "CSA Code": 468,
    "CBSA Full Title": "Wilson, NC",
    "CBSA Solo": "Wilson",
    "CBSA State": "NC",
    CBSA: "Wilson, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Rocky Mount-Wilson-Roanoke Rapids, NC",
    "County/County Equivalent": "Wilson County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Wilson County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 195,
    "County\nCode": 37195,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49020,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Winchester, VA-WV",
    "CBSA Solo": "Winchester",
    "CBSA State": "VA",
    CBSA: "Winchester, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Frederick County",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Frederick County, VA",
    "FIPS State Code": 51,
    "FIPS County Code": "069",
    "County\nCode": 51069,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49020,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Winchester, VA-WV",
    "CBSA Solo": "Winchester",
    "CBSA State": "VA",
    CBSA: "Winchester, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Winchester city",
    "State Name": "Virginia",
    "State Abbr": "VA",
    "County, State": "Winchester city, VA",
    "FIPS State Code": 51,
    "FIPS County Code": 840,
    "County\nCode": 51840,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49020,
    "Metropolitan Division Code": "",
    "CSA Code": 548,
    "CBSA Full Title": "Winchester, VA-WV",
    "CBSA Solo": "Winchester",
    "CBSA State": "VA",
    CBSA: "Winchester, VA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Washington-Baltimore-Arlington, DC-MD-VA-WV-PA",
    "County/County Equivalent": "Hampshire County",
    "State Name": "West Virginia",
    "State Abbr": "WV",
    "County, State": "Hampshire County, WV",
    "FIPS State Code": 54,
    "FIPS County Code": "027",
    "County\nCode": 54027,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 49060,
    "Metropolitan Division Code": "",
    "CSA Code": 556,
    "CBSA Full Title": "Winfield, KS",
    "CBSA Solo": "Winfield",
    "CBSA State": "KS",
    CBSA: "Winfield, KS",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Wichita-Winfield, KS",
    "County/County Equivalent": "Cowley County",
    "State Name": "Kansas",
    "State Abbr": "KS",
    "County, State": "Cowley County, KS",
    "FIPS State Code": 20,
    "FIPS County Code": "035",
    "County\nCode": 20035,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49080,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Winnemucca, NV",
    "CBSA Solo": "Winnemucca",
    "CBSA State": "NV",
    CBSA: "Winnemucca, NV",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Humboldt County",
    "State Name": "Nevada",
    "State Abbr": "NV",
    "County, State": "Humboldt County, NV",
    "FIPS State Code": 32,
    "FIPS County Code": "013",
    "County\nCode": 32013,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49100,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Winona, MN",
    "CBSA Solo": "Winona",
    "CBSA State": "MN",
    CBSA: "Winona, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Winona County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Winona County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 169,
    "County\nCode": 27169,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49180,
    "Metropolitan Division Code": "",
    "CSA Code": 268,
    "CBSA Full Title": "Winston-Salem, NC",
    "CBSA Solo": "Winston",
    "CBSA State": "NC",
    CBSA: "Winston, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greensboro--Winston-Salem--High Point, NC",
    "County/County Equivalent": "Davidson County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Davidson County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "057",
    "County\nCode": 37057,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49180,
    "Metropolitan Division Code": "",
    "CSA Code": 268,
    "CBSA Full Title": "Winston-Salem, NC",
    "CBSA Solo": "Winston",
    "CBSA State": "NC",
    CBSA: "Winston, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greensboro--Winston-Salem--High Point, NC",
    "County/County Equivalent": "Davie County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Davie County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "059",
    "County\nCode": 37059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49180,
    "Metropolitan Division Code": "",
    "CSA Code": 268,
    "CBSA Full Title": "Winston-Salem, NC",
    "CBSA Solo": "Winston",
    "CBSA State": "NC",
    CBSA: "Winston, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greensboro--Winston-Salem--High Point, NC",
    "County/County Equivalent": "Forsyth County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Forsyth County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": "067",
    "County\nCode": 37067,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49180,
    "Metropolitan Division Code": "",
    "CSA Code": 268,
    "CBSA Full Title": "Winston-Salem, NC",
    "CBSA Solo": "Winston",
    "CBSA State": "NC",
    CBSA: "Winston, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greensboro--Winston-Salem--High Point, NC",
    "County/County Equivalent": "Stokes County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Stokes County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 169,
    "County\nCode": 37169,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49180,
    "Metropolitan Division Code": "",
    "CSA Code": 268,
    "CBSA Full Title": "Winston-Salem, NC",
    "CBSA Solo": "Winston",
    "CBSA State": "NC",
    CBSA: "Winston, NC",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Greensboro--Winston-Salem--High Point, NC",
    "County/County Equivalent": "Yadkin County",
    "State Name": "North Carolina",
    "State Abbr": "NC",
    "County, State": "Yadkin County, NC",
    "FIPS State Code": 37,
    "FIPS County Code": 197,
    "County\nCode": 37197,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 49220,
    "Metropolitan Division Code": "",
    "CSA Code": 554,
    "CBSA Full Title": "Wisconsin Rapids-Marshfield, WI",
    "CBSA Solo": "Wisconsin Rapids",
    "CBSA State": "WI",
    CBSA: "Wisconsin Rapids, WI",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Wausau-Stevens Point-Wisconsin Rapids, WI",
    "County/County Equivalent": "Wood County",
    "State Name": "Wisconsin",
    "State Abbr": "WI",
    "County, State": "Wood County, WI",
    "FIPS State Code": 55,
    "FIPS County Code": 141,
    "County\nCode": 55141,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Woodward, OK",
    "CBSA Solo": "Woodward",
    "CBSA State": "OK",
    CBSA: "Woodward, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Ellis County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Ellis County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": "045",
    "County\nCode": 40045,
    "Central/Outlying County": "Outlying",
  },
  {
    "CBSA Code": 49260,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Woodward, OK",
    "CBSA Solo": "Woodward",
    "CBSA State": "OK",
    CBSA: "Woodward, OK",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Woodward County",
    "State Name": "Oklahoma",
    "State Abbr": "OK",
    "County, State": "Woodward County, OK",
    "FIPS State Code": 40,
    "FIPS County Code": 153,
    "County\nCode": 40153,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49300,
    "Metropolitan Division Code": "",
    "CSA Code": 184,
    "CBSA Full Title": "Wooster, OH",
    "CBSA Solo": "Wooster",
    "CBSA State": "OH",
    CBSA: "Wooster, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Cleveland-Akron-Canton, OH",
    "County/County Equivalent": "Wayne County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Wayne County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 169,
    "County\nCode": 39169,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49340,
    "Metropolitan Division Code": "",
    "CSA Code": 148,
    "CBSA Full Title": "Worcester, MA-CT",
    "CBSA Solo": "Worcester",
    "CBSA State": "MA",
    CBSA: "Worcester, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Worcester County",
    "State Name": "Massachusetts",
    "State Abbr": "MA",
    "County, State": "Worcester County, MA",
    "FIPS State Code": 25,
    "FIPS County Code": "027",
    "County\nCode": 25027,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49340,
    "Metropolitan Division Code": "",
    "CSA Code": 148,
    "CBSA Full Title": "Worcester, MA-CT",
    "CBSA Solo": "Worcester",
    "CBSA State": "MA",
    CBSA: "Worcester, MA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Boston-Worcester-Providence, MA-RI-NH-CT",
    "County/County Equivalent": "Windham County",
    "State Name": "Connecticut",
    "State Abbr": "CT",
    "County, State": "Windham County, CT",
    "FIPS State Code": "09",
    "FIPS County Code": "015",
    "County\nCode": "09015",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49380,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Worthington, MN",
    "CBSA Solo": "Worthington",
    "CBSA State": "MN",
    CBSA: "Worthington, MN",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Nobles County",
    "State Name": "Minnesota",
    "State Abbr": "MN",
    "County, State": "Nobles County, MN",
    "FIPS State Code": 27,
    "FIPS County Code": 105,
    "County\nCode": 27105,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49420,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Yakima, WA",
    "CBSA Solo": "Yakima",
    "CBSA State": "WA",
    CBSA: "Yakima, WA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Yakima County",
    "State Name": "Washington",
    "State Abbr": "WA",
    "County, State": "Yakima County, WA",
    "FIPS State Code": 53,
    "FIPS County Code": "077",
    "County\nCode": 53077,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49460,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Yankton, SD",
    "CBSA Solo": "Yankton",
    "CBSA State": "SD",
    CBSA: "Yankton, SD",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Yankton County",
    "State Name": "South Dakota",
    "State Abbr": "SD",
    "County, State": "Yankton County, SD",
    "FIPS State Code": 46,
    "FIPS County Code": 135,
    "County\nCode": 46135,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49500,
    "Metropolitan Division Code": "",
    "CSA Code": 434,
    "CBSA Full Title": "Yauco, PR",
    "CBSA Solo": "Yauco",
    "CBSA State": "PR",
    CBSA: "Yauco, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Ponce-Yauco-Coamo, PR",
    "County/County Equivalent": "Guánica Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Guánica Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "055",
    "County\nCode": 72055,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49500,
    "Metropolitan Division Code": "",
    "CSA Code": 434,
    "CBSA Full Title": "Yauco, PR",
    "CBSA Solo": "Yauco",
    "CBSA State": "PR",
    CBSA: "Yauco, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Ponce-Yauco-Coamo, PR",
    "County/County Equivalent": "Guayanilla Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Guayanilla Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": "059",
    "County\nCode": 72059,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49500,
    "Metropolitan Division Code": "",
    "CSA Code": 434,
    "CBSA Full Title": "Yauco, PR",
    "CBSA Solo": "Yauco",
    "CBSA State": "PR",
    CBSA: "Yauco, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Ponce-Yauco-Coamo, PR",
    "County/County Equivalent": "Peñuelas Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Peñuelas Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 111,
    "County\nCode": 72111,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49500,
    "Metropolitan Division Code": "",
    "CSA Code": 434,
    "CBSA Full Title": "Yauco, PR",
    "CBSA Solo": "Yauco",
    "CBSA State": "PR",
    CBSA: "Yauco, PR",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Ponce-Yauco-Coamo, PR",
    "County/County Equivalent": "Yauco Municipio",
    "State Name": "Puerto Rico",
    "State Abbr": "PR",
    "County, State": "Yauco Municipio, PR",
    "FIPS State Code": 72,
    "FIPS County Code": 153,
    "County\nCode": 72153,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49620,
    "Metropolitan Division Code": "",
    "CSA Code": 276,
    "CBSA Full Title": "York-Hanover, PA",
    "CBSA Solo": "York",
    "CBSA State": "PA",
    CBSA: "York, PA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Harrisburg-York-Lebanon, PA",
    "County/County Equivalent": "York County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "York County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": 133,
    "County\nCode": 42133,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49660,
    "Metropolitan Division Code": "",
    "CSA Code": 566,
    "CBSA Full Title": "Youngstown-Warren-Boardman, OH-PA",
    "CBSA Solo": "Youngstown",
    "CBSA State": "OH",
    CBSA: "Youngstown, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Youngstown-Warren, OH-PA",
    "County/County Equivalent": "Mahoning County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Mahoning County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": "099",
    "County\nCode": 39099,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49660,
    "Metropolitan Division Code": "",
    "CSA Code": 566,
    "CBSA Full Title": "Youngstown-Warren-Boardman, OH-PA",
    "CBSA Solo": "Youngstown",
    "CBSA State": "OH",
    CBSA: "Youngstown, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Youngstown-Warren, OH-PA",
    "County/County Equivalent": "Trumbull County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Trumbull County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 155,
    "County\nCode": 39155,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49660,
    "Metropolitan Division Code": "",
    "CSA Code": 566,
    "CBSA Full Title": "Youngstown-Warren-Boardman, OH-PA",
    "CBSA Solo": "Youngstown",
    "CBSA State": "OH",
    CBSA: "Youngstown, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Youngstown-Warren, OH-PA",
    "County/County Equivalent": "Mercer County",
    "State Name": "Pennsylvania",
    "State Abbr": "PA",
    "County, State": "Mercer County, PA",
    "FIPS State Code": 42,
    "FIPS County Code": "085",
    "County\nCode": 42085,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49700,
    "Metropolitan Division Code": "",
    "CSA Code": 472,
    "CBSA Full Title": "Yuba City, CA",
    "CBSA Solo": "Yuba City",
    "CBSA State": "CA",
    CBSA: "Yuba City, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Sacramento-Roseville, CA",
    "County/County Equivalent": "Sutter County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Sutter County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": 101,
    "County\nCode": "06101",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49700,
    "Metropolitan Division Code": "",
    "CSA Code": 472,
    "CBSA Full Title": "Yuba City, CA",
    "CBSA Solo": "Yuba City",
    "CBSA State": "CA",
    CBSA: "Yuba City, CA",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Sacramento-Roseville, CA",
    "County/County Equivalent": "Yuba County",
    "State Name": "California",
    "State Abbr": "CA",
    "County, State": "Yuba County, CA",
    "FIPS State Code": "06",
    "FIPS County Code": 115,
    "County\nCode": "06115",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49740,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Yuma, AZ",
    "CBSA Solo": "Yuma",
    "CBSA State": "AZ",
    CBSA: "Yuma, AZ",
    "Metropolitan/Micropolitan Statistical Area":
      "Metropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Yuma County",
    "State Name": "Arizona",
    "State Abbr": "AZ",
    "County, State": "Yuma County, AZ",
    "FIPS State Code": "04",
    "FIPS County Code": "027",
    "County\nCode": "04027",
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49780,
    "Metropolitan Division Code": "",
    "CSA Code": 198,
    "CBSA Full Title": "Zanesville, OH",
    "CBSA Solo": "Zanesville",
    "CBSA State": "OH",
    CBSA: "Zanesville, OH",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "Columbus-Marion-Zanesville, OH",
    "County/County Equivalent": "Muskingum County",
    "State Name": "Ohio",
    "State Abbr": "OH",
    "County, State": "Muskingum County, OH",
    "FIPS State Code": 39,
    "FIPS County Code": 119,
    "County\nCode": 39119,
    "Central/Outlying County": "Central",
  },
  {
    "CBSA Code": 49820,
    "Metropolitan Division Code": "",
    "CSA Code": "",
    "CBSA Full Title": "Zapata, TX",
    "CBSA Solo": "Zapata",
    "CBSA State": "TX",
    CBSA: "Zapata, TX",
    "Metropolitan/Micropolitan Statistical Area":
      "Micropolitan Statistical Area",
    "Metropolitan Division Title": "",
    "CSA Title": "",
    "County/County Equivalent": "Zapata County",
    "State Name": "Texas",
    "State Abbr": "TX",
    "County, State": "Zapata County, TX",
    "FIPS State Code": 48,
    "FIPS County Code": 505,
    "County\nCode": 48505,
    "Central/Outlying County": "Central",
  },
];
