import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTheme } from "@emotion/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Plan, PlanCycle } from "backend/utils/plan";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import { Text } from "components/Text";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import useUser, { useUserData } from "hooks/useUser";
import { currencyFormatter } from "lib/currencyFormatter";
import { getIconForCardType } from "lib/getIconForCardType";
import { getRelativePathname } from "lib/getRelativePathname";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import PlanService from "services/PlanService";
import Stripe from "stripe";
import { EditCard } from "./EditCard";
import { UpgradeSuccessfulModal } from "./UpgradeSuccessfulModal";
import posthog from "posthog-js";
import { splitPlanName } from "lib/splitPlanName";
import moment from "moment";
import { useDiscountCode } from "hooks/useDiscount";
import { DiscountCodeField } from "components/DiscountCodeField";
import { CardUpdateContext } from "lib/context";
import { getStateNameByCode } from "lib/helpers/getArea";

export const UpgradePlanModal = () => {
  const router = useRouter();
  const theme = useTheme();
  const user = useUserData();
  const { changedPlanFrom, setChangedPlanFrom } = useUser();
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { plans, setShowPlanModal, setPlanPopupTrigger } =
    useContext(PlanModalContext);
  const [latestInvoice, setLatestInvoice] = useState<Stripe.Invoice>();
  const { cardUpdating, setCardUpdating } = useContext(CardUpdateContext);

  const plan = useMemo(() => {
    return plans.find((pln) => pln.id === router.query.selectedPlanId);
  }, [router.query.selectedPlanId, plans]);

  const {
    discount,
    discountCode,
    handleDiscountCodeChange,
    isValidDiscount,
    loading: checkingDiscountValidity,
  } = useDiscountCode({ productId: plan?.productId || "" });

  const discountCodePreviuslyApplied = useMemo(() => {
    if (router?.query?.discountCode) {
      handleDiscountCodeChange(router.query.discountCode);
    }
  }, [router]);

  useEffect(() => {
    if (!user.paymentStatus && !!latestInvoice) {
      posthog.capture("Plan upgraded", {
        nextBillingCycleDate: moment
          .unix(latestInvoice.lines.data.at(-1)?.period.end!)
          .format("MM/DD/YYYY"),
        newPlanName: plan?.name,
        changedPlanFrom: changedPlanFrom,
        cadence: splitPlanName(plan?.name!).planCycle,
        amount: (latestInvoice.amount_due / 100).toFixed(2),
        platform: "Web",
        activeState: getStateNameByCode(user?.activeState),
      });
      router.replace(
        `${getRelativePathname(router)}?upgradeStatus=success&amountPaid=${
          latestInvoice.amount_due
        }`,
      );
    }
  }, [user.paymentStatus, latestInvoice]);

  useEffect(() => {
    setLatestInvoice(undefined);
  }, [router.query.openUpgradeModal]);

  const handleGoBack = async () => {
    switch (router.query.previousModal) {
      case "changePlan": {
        router.replace(
          `${getRelativePathname(router)}?showChangePlanModal=true`,
        );
        break;
      }
      case "cancelSubscription": {
        router.replace(
          `${getRelativePathname(router)}?showCancelSubscription=true`,
        );
        break;
      }
      case "planModal": {
        await router.replace(`${getRelativePathname(router)}`);
        setPlanPopupTrigger("From url link");
        setShowPlanModal(true);
        break;
      }
      default:
        router.replace(`${getRelativePathname(router)}`);
    }
  };

  const handlePayNow = async () => {
    if (loading || user.paymentStatus === "processing") return;
    setChangedPlanFrom(`${user.plan} ${user.planCycle}`);
    try {
      setLoading(true);
      const data = await PlanService.changePlan(
        user.id!,
        plan?.id!,
        discountCode,
      );
      setLatestInvoice(data.data);
    } catch (error: any) {
      toast(error.message, {
        type: "error",
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const discountAmount =
    ((plan?.amount || 0) * (discount?.coupon.percent_off! || 0)) / 100;

  return (
    <>
      <UpgradeSuccessfulModal />
      <Modal
        isOpen={!!router.query.openUpgradeModal}
        contentStyle={{
          maxWidth: 330,
          background: "white",
          width: "100%",
          padding: 0,
        }}
      >
        <EditCard
          onClose={() => {
            setShowEditModal(false);
          }}
          setCardUpdating={setCardUpdating}
          showEditModal={showEditModal}
          user={user}
        />
        <Flex
          justify="space-between"
          css={{ padding: "14px 22px" }}
          align="center"
        >
          <Flex align="center" gap={10}>
            <ArrowLeftOutlined
              style={{ fontSize: 22 }}
              onClick={handleGoBack}
            />
            <Text tagName="h1" fontSize="large" bolder>
              Payment Details
            </Text>
          </Flex>
          <Flex
            justify="center"
            align="center"
            css={{
              borderRadius: theme.radii.smaller,
              background: theme.colors.lightBg,
              height: 30,
              width: 30,
              cursor: "pointer",
            }}
            onClick={() => {
              let relativeURL;
              const slug = router.query.slug;
              if (slug) {
                relativeURL = router.pathname.replace("[slug]", slug as string);
              } else {
                relativeURL = router.pathname;
              }
              router.replace(`${relativeURL}`);
            }}
          >
            <Icon path={mdiClose} color={theme.colors.gray200} size="18px" />
          </Flex>
        </Flex>
        <div
          css={{
            borderTop: `1px solid ${theme.colors.radioBorder}`,
            width: "100%",
          }}
        />
        <div css={{ padding: "14px 22px" }}>
          <div
            css={{
              border: `1px solid ${theme.colors.radioBorder}`,
              width: "100%",
              padding: "9px 11px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: theme.radii.smaller,
            }}
          >
            {cardUpdating !== user?.paymentMethod?.last4 ? (
              <>
                <Flex gap={18} align="center">
                  <Image
                    alt="visa"
                    src={getIconForCardType(user.paymentMethod?.brand || "")}
                    width={40}
                    height={40}
                  />
                  <Flex gap={1} direction="column">
                    <Text>**** **** **** {user?.paymentMethod?.last4}</Text>
                    <Text>
                      {user.paymentMethod?.brand?.replace(
                        user.paymentMethod?.brand?.[0],
                        user.paymentMethod?.brand?.[0]?.toUpperCase(),
                      )}
                    </Text>
                  </Flex>
                </Flex>
                <Button
                  css={{
                    border: `1px solid ${theme.colors.radioBorder}`,
                    padding: "6px 12px",
                    background: "white",
                    borderRadius: theme.radii.smaller,
                    fontSize: theme.fontSizes.default,
                  }}
                  onClick={() => setShowEditModal(true)}
                >
                  Edit
                </Button>
              </>
            ) : (
              <Text style={{ fontStyle: "italic", color: theme.colors.text3 }}>
                Updating card info...
              </Text>
            )}
          </div>
          <DiscountCodeField
            labelGap={8}
            discountCode={discountCode}
            discount={discount}
            isValidDiscount={isValidDiscount}
            loading={checkingDiscountValidity}
            onDiscountChange={(e) => handleDiscountCodeChange(e.target.value)}
          />
          <div css={{ marginTop: 12 }}>
            <Flex justify="space-between">
              <Text css={{ opacity: 0.65 }}>Plan</Text>
              <Text>
                {plan?.name === Plan.Premium_Monthly
                  ? PlanCycle.Monthly
                  : plan?.name === Plan.Premium_Yearly
                    ? PlanCycle.Yearly
                    : plan?.name}
              </Text>
            </Flex>
            <Flex justify="space-between" css={{ marginTop: 5 }}>
              <Text css={{ opacity: 0.65 }}>Amount</Text>
              <Text>{currencyFormatter((plan?.amount || 0) / 100)}</Text>
            </Flex>
            {discount && isValidDiscount && (
              <Flex justify="space-between" css={{ marginTop: 5 }}>
                <Text css={{ opacity: 0.65 }}>Discount</Text>
                <Text>{currencyFormatter(discountAmount / 100)}</Text>
              </Flex>
            )}
            <div
              css={{
                borderTop: `1px solid ${theme.colors.radioBorder}`,
                width: "100%",
                marginTop: 12,
              }}
            />
            <Flex justify="space-between" css={{ marginTop: 5 }}>
              <Text bolder>Total:</Text>
              <Text bolder>
                {currencyFormatter(
                  ((plan?.amount || 0) - discountAmount) / 100,
                )}
              </Text>
            </Flex>
          </div>
          <Button
            onClick={handlePayNow}
            css={{ width: "100%", marginTop: 14 }}
            variant="alternate"
          >
            {loading
              ? "Loading..."
              : user.paymentStatus === "processing"
                ? "Processing payment..."
                : "Pay Now"}
          </Button>
        </div>
      </Modal>
    </>
  );
};
