import { useTheme } from "@emotion/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import { getRelativePathname } from "lib/getRelativePathname";
import { useUserData } from "hooks/useUser";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import PlanService from "services/PlanService";
import posthog from "posthog-js";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { splitPlanName } from "lib/splitPlanName";
import { PlanCycle } from "backend/utils/plan";
import moment from "moment";
import { Spin } from "antd";
import { getStateNameByCode } from "lib/helpers/getArea";

export const DowngradingModal = () => {
  const user = useUserData();
  const theme = useTheme();
  const router = useRouter();
  const { plans } = useContext(PlanModalContext);

  const plan = useMemo(() => {
    return plans.find((pln) => pln.id === router.query.selectedPlanId);
  }, [router.query.selectedPlanId, plans]);

  useEffect(() => {
    (async () => {
      if (router.query.selectedPlanId && router.query.downgradingModal) {
        try {
          const previousPlan = `${user.plan} ${user.planCycle}`;
          await PlanService.changePlan(
            user.id!,
            router?.query.selectedPlanId as string,
          );
          posthog.capture("State restricted plan", {
            nextBillingCycleDate: moment
              .unix(user.nextPaymentDate!)
              .format("MM/DD/YYYY"),
            newPlanName: plan?.name,
            changedPlanFrom: previousPlan,
            cadence: splitPlanName(plan?.name!).planCycle || PlanCycle.Monthly,
            amount: (plan?.amount! / 100).toFixed(2),
            platform: "Web",
            activeState: getStateNameByCode(user?.activeState),
          });
          router.replace(
            `${getRelativePathname(router)}?showDowngradeModal=true`,
          );
        } catch (error: any) {
          toast(error.message, {
            type: "error",
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    })();
  }, [router.query.selectedPlanId]);

  return (
    <Modal
      overlayStyle={{ overflow: "auto", padding: "34px 12px" }}
      contentStyle={{
        maxWidth: 360,
        background: "white",
        width: "100%",
        padding: "15px 24px",
        border: `1px solid ${theme.colors.radioBorder}`,
        overflow: "unset",
      }}
      isOpen={!!router.query.downgradingModal}
    >
      <div
        css={{
          marginTop: -45,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          css={{
            border: "10px solid white",
            borderRadius: theme.radii.full,
          }}
        >
          <Image
            alt="headshot"
            src="/nick-headshot.png"
            css={{ borderRadius: theme.radii.full }}
            width={96}
            height={96}
          />
        </div>
      </div>
      <Flex direction="column" align="center">
        <h1
          css={{
            fontSize: theme.fontSizes.larger,
            fontWeight: theme.fontWeights.heading,
            margin: 0,
            textAlign: "center",
          }}
        >
          Downgrading your plan, this will only take a moment!
        </h1>
      </Flex>
      <Flex justify="center" align="center" css={{ margin: `30px 0 20px` }}>
        <Spin />
      </Flex>
    </Modal>
  );
};
