import { ScoreDatapoints } from "types/cube";

const HPMDisabled =
  process.env.NEXT_PUBLIC_SCORE_HOME_PRICE_MOMENTUM_DISABLED === "true";
const LTGDisabled =
  process.env.NEXT_PUBLIC_SCORE_LONG_TERM_GROWTH_DISABLED === "true";
const InvestorDisabled =
  process.env.NEXT_PUBLIC_SCORE_INVESTOR_DISABLED === "true";

export interface ScoreDatapointsMap {
  label: string;
  code: ScoreDatapoints;
  ytVideoCode: string;
  premium: boolean;
  isNew?: boolean;
  raw?: ScoreDatapoints | ScoreDatapoints[] | false;
  summary?: string;
  shortSummary?: string;
  isComingSoon?: boolean;
  parent?: ScoreDatapoints[];
}

const scoreDatapointsMap: { [key: string]: ScoreDatapointsMap } = {
  // Home Price Forecast
  [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE]: {
    label: "Home Price Forecast",
    code: ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE,
    premium: true,
    isComingSoon: HPMDisabled,
    ytVideoCode: "8C2cITZfN6g",
    summary:
      "The momentum of home values based on fundamental data points like appreciation, inventory, price cuts, and days on market. This score can be used to project the directional growth of home values over the next 12 months. A score below 50 indicates that future home values are more likely to decline, while a score above 50 indicates that prices are more likely to increase.",
    shortSummary: `Home value forecast based on fundamental data points like appreciation, inventory, price cuts, and days on market. This score ranges from 0 to 100, with values above 50 corresponding to increasing future home values.`,
  },
  [ScoreDatapoints.RECENT_APPRECIATION_SCORE]: {
    label: "Recent Appreciation",
    code: ScoreDatapoints.RECENT_APPRECIATION_SCORE,
    premium: true,
    isComingSoon: HPMDisabled,
    ytVideoCode: "",
    raw: ScoreDatapoints.RECENT_APPRECIATION_PERCENTAGE,
    parent: [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE],
    summary:
      "How much home values have appreciated over the last 12 months compared to the long-term annual baseline of 2%.",
  },
  [ScoreDatapoints.DAYS_ON_MARKET_SCORE]: {
    label: "Days on Market",
    code: ScoreDatapoints.DAYS_ON_MARKET_SCORE,
    premium: true,
    isComingSoon: HPMDisabled,
    ytVideoCode: "",
    raw: ScoreDatapoints.DAYS_ON_MARKET_PERCENTAGE,
    parent: [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE],
    summary:
      "How long homes are staying on the market compared to long-term norms. A higher Days on Market results in a lower home price momentum score, and vice versa.",
  },
  [ScoreDatapoints.MORTGAGE_RATES_SCORE]: {
    label: "Mortgage Rates",
    code: ScoreDatapoints.MORTGAGE_RATES_SCORE,
    premium: true,
    isComingSoon: HPMDisabled,
    ytVideoCode: "",
    raw: [
      ScoreDatapoints.MORTGAGE_RATES_YOY_PERCENTAGE,
      ScoreDatapoints.MORTGAGE_RATES_FIVE_YEAR_PERCENTAGE,
    ],
    parent: [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE],
    summary:
      "How the recent trend in Mortgage Rates is impacting market demand. When Mortgage Rates are trending higher than their 1 and 5-year averages, homebuying demand is restricted, leading to a lower price momentum score.",
  },
  [ScoreDatapoints.INVENTORY_LEVELS_SCORE]: {
    label: "Inventory",
    code: ScoreDatapoints.INVENTORY_LEVELS_SCORE,
    premium: true,
    isComingSoon: HPMDisabled,
    ytVideoCode: "",
    raw: ScoreDatapoints.INVENTORY_LEVELS_PERCENTAGE,
    parent: [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE],
    summary:
      "How much inventory is on the market compared to long-term norms. Higher inventory results in a lower home price momentum score, while lower inventory results in a higher price momentum score.",
  },
  [ScoreDatapoints.PRICE_CUTS_SCORE]: {
    label: "Price Cuts",
    code: ScoreDatapoints.PRICE_CUTS_SCORE,
    premium: true,
    isComingSoon: HPMDisabled,
    ytVideoCode: "",
    raw: ScoreDatapoints.PRICE_CUTS_PERCENTAGE,
    parent: [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE],
    summary:
      "How many sellers are cutting the price on houses compared to long-term norms. High price cuts results in a lower price momentum score, and vice versa.",
  },

  // Long-Term Growth Forecast
  [ScoreDatapoints.HOME_BUYER_SCORE]: {
    label: "Long-Term Growth",
    code: ScoreDatapoints.HOME_BUYER_SCORE,
    premium: true,
    isComingSoon: LTGDisabled,
    ytVideoCode: "",
    summary:
      "The higher the score, the more likely that home prices will increase in the area over the long term (think at least 5-10 years). This score, which is ranked from 0 to 100, is based on the fundamental drivers of long-term growth, such as appreciation, population growth, wealth, as well as existing levels of affordability and overvaluation.",
  },
  [ScoreDatapoints.LONG_TERM_APPRECIATION_PERCENTILE]: {
    label: "Long Term Appreciation",
    code: ScoreDatapoints.LONG_TERM_APPRECIATION_PERCENTILE,
    premium: true,
    isComingSoon: LTGDisabled,
    ytVideoCode: "",
    raw: false,
    parent: [ScoreDatapoints.HOME_BUYER_SCORE, ScoreDatapoints.INVESTOR_SCORE],
    summary:
      "The long-term growth rate in appreciation over the last 15 years in the area.",
  },
  [ScoreDatapoints.DEMOGRAPHIC_GROWTH_PERCENTILE]: {
    label: "Demographic Growth",
    code: ScoreDatapoints.DEMOGRAPHIC_GROWTH_PERCENTILE,
    premium: true,
    isComingSoon: LTGDisabled,
    ytVideoCode: "",
    raw: false,
    parent: [ScoreDatapoints.HOME_BUYER_SCORE, ScoreDatapoints.INVESTOR_SCORE],
    summary:
      "The trailing 5-year growth rate in population. The higher the demographic growth, the better the chances for strong appreciation over the long-run.",
  },
  [ScoreDatapoints.WEALTH_INCOME_PERCENTILE]: {
    label: "Wealth/Income",
    code: ScoreDatapoints.WEALTH_INCOME_PERCENTILE,
    premium: true,
    isComingSoon: LTGDisabled,
    ytVideoCode: "",
    raw: false,
    parent: [ScoreDatapoints.HOME_BUYER_SCORE],
    summary:
      "The wealth levels in the Housing Market measured by the median household income and poverty rate, in comparison to other geographic areas across America.",
  },
  [ScoreDatapoints.VALUE_INCOME_RATIO_PERCENTILE]: {
    label: "Affordability",
    code: ScoreDatapoints.VALUE_INCOME_RATIO_PERCENTILE,
    premium: true,
    isComingSoon: LTGDisabled,
    ytVideoCode: "",
    raw: false,
    parent: [ScoreDatapoints.HOME_BUYER_SCORE],
    summary:
      'How affordable Home Prices are compared to other areas in America, relative to local incomes. The more affordability there is, the more "room" for future growth.',
  },
  [ScoreDatapoints.OVERVALUATION_PERCENTILE]: {
    label: "Valuation",
    code: ScoreDatapoints.OVERVALUATION_PERCENTILE,
    premium: true,
    isComingSoon: LTGDisabled,
    ytVideoCode: "",
    raw: false,
    parent: [ScoreDatapoints.HOME_BUYER_SCORE, ScoreDatapoints.INVESTOR_SCORE],
    summary:
      "How fairly valued home values are to historical norms. A low valuation score indicates overvalued home prices, while a high valuation score indicates fairly valued or undervalued home prices.",
  },

  // Investor Forecast
  [ScoreDatapoints.INVESTOR_SCORE]: {
    label: "Investor Forecast",
    code: ScoreDatapoints.INVESTOR_SCORE,
    premium: true,
    isComingSoon: InvestorDisabled,
    isNew: true,
    ytVideoCode: "",
    summary:
      "A score indicating how favorable a housing market is for investors over the long-term (think 5-10 years), based on the area's long-term appreciation, demographic growth, rent growth, and cash flow profit (aka Cap Rate). The higher the score ranked from 0-100, the more favorable for investors based on the underlying fundamentals.",
  },
  [ScoreDatapoints.CAP_RATE_PERCENTILE]: {
    label: "Cap Rate",
    code: ScoreDatapoints.CAP_RATE_PERCENTILE,
    premium: true,
    isComingSoon: InvestorDisabled,
    ytVideoCode: "",
    raw: false,
    parent: [ScoreDatapoints.INVESTOR_SCORE],
    summary:
      "The annual, unlevered cash flow return (%) real estate investors can expect by buying an investment property at market prices and renting it out at market rents (subtracting expenses).",
  },
  [ScoreDatapoints.RENT_PERCENTILE]: {
    label: "Rent Growth",
    code: ScoreDatapoints.RENT_PERCENTILE,
    premium: true,
    isComingSoon: InvestorDisabled,
    ytVideoCode: "",
    raw: false,
    parent: [ScoreDatapoints.INVESTOR_SCORE],
    summary:
      "The trailing 5-year average rent growth in the Housing Market compared to other geographic areas. The higher the rent growth, the better growth in returns of investors over the long-run.",
  },
};

export default scoreDatapointsMap;
